<template>
  <div id="subjects">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4"> Subjects </v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-4"
          @click="createItem"
          :disabled="offline"
        >
          New Subject
        </v-btn>
      </v-row>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="headers"
          :items="subjects"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          @update:options="updateSortOptions"
          show-group-by
          :search="search"
          :loading="!subjectSubscriptionSynced && subjects?.length === 0"
          loading-text="Loading Subjects..."
          no-data-text="No Subjects found"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              :disabled="offline"
            >
              mdi-pencil
            </v-icon>
            <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="save">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.grade"
                    label="Grade"
                    :rules="formRules.grade"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.subject"
                    label="Subject"
                    :rules="formRules.subject"
                    required
                    :error-messages="
                      duplicateSubject
                        ? ['Subject already added to Grade ' + editedItem.grade]
                        : []
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close"> Cancel </v-btn>
            <v-btn
              :disabled="duplicateSubject || !valid"
              color="primary"
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- <v-dialog persistent v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title>Are you sure you want to delete this subject?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="closeDelete">Cancel</v-btn>
                  <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import { Subject } from "@/models";
import _ from "lodash";

export default {
  name: "AdminSubjects",
  data: () => ({
    initiallyLoaded: false,
    subjectSubscription: null,
    subjectSubscriptionSynced: false,
    syncedSubjectArray: [],
    valid: false,
    dialog: false,
    dialogDelete: false,
    sortBy: "grade",
    sortDesc: true,
    search: "",
    headers: [],
    subjects: [],
    editedIndex: -1,
    editedItem: {
      grade: "",
      subject: "",
    },
    defaultItem: {
      grade: "",
      subject: "",
    },
    formRules: {
      grade: [(v) => !!v || "Grade is required"],
      subject: [
        (v) => !!v || "Subject is required",
        // (duplicateSubject) => !!duplicateSubject || "Subject already exists",
      ],
    },
  }),

  async mounted() {
    try {
      this.headers = [
        {
          text: "Grade",
          value: "grade",
          sort: (a, b) => this.customSortGrade(a, b),
        },
        { text: "Subject", value: "subject" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          groupable: false,
        },
      ];
      // Sync the Subjects from the datastore
      await this.syncSubject();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
    }),

    formTitle() {
      return this.editedIndex === -1 ? "New Subject" : "Edit Subject";
    },

    duplicateSubject() {
      if (
        this.subjects.length > 0 &&
        this.editedItem.grade &&
        this.editedItem.subject
      ) {
        return this.subjects.some(
          (subject) =>
            subject.grade === this.editedItem.grade &&
            subject.subject === this.editedItem.subject &&
            subject.id !== this.editedItem.id
        );
      } else {
        return false;
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    updateSortOptions(options) {
      if (options.sortBy === "grade") {
        // Manual sorting when it's by 'Subject.grade'
        this.subjects.sort((a, b) => {
          a = a.grade === "R" ? -Infinity : parseInt(a.grade, 10);
          b = b.grade === "R" ? -Infinity : parseInt(b.grade, 10);
          if (options.sortDesc) {
            // Switch the order for descending
            [a, b] = [b, a];
          }
          return a - b;
        });
      } else {
        // Default sorting for other columns
        this.sortBy = options.sortBy;
        this.sortDesc = options.sortDesc;
      }
    },

    customSortGrade(a, b) {
      a = a === "R" ? -Infinity : parseInt(a, 10);
      b = b === "R" ? -Infinity : parseInt(b, 10);
      if (this.sortDesc) {
        // Switch the order for descending
        [a, b] = [b, a];
      }
      return a - b;
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.valid = false;
    },

    createItem() {
      this.resetForm();
      this.dialog = true;
    },

    editItem(item) {
      this.editedIndex = this.subjects.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    // deleteItem(item) {
    //   this.editedIndex = this.subjects.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialogDelete = true;
    // },

    // async deleteItemConfirm() {
    //   console.log('delete item')
    //   this.subjects.splice(this.editedIndex, 1);
    //   await DataStore.delete(this.syncedSubjectArray[this.editedIndex]);
    //   this.closeDelete();
    // },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update schedule
        await this.updateSubject();
      } else {
        // Create new schedule
        await this.createSubject();
      }
      this.close();
    },

    async createSubject() {
      try {
        await DataStore.save(
          new Subject({
            grade: this.editedItem.grade.trim(),
            subject: this.editedItem.subject.trim(),
            TeacherClassSubjects: [],
          })
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updateSubject() {
      try {
        await DataStore.save(
          Subject.copyOf(
            _.find(this.syncedSubjectArray, { id: this.editedItem.id }),
            (updateModel) => {
              (updateModel.grade = this.editedItem.grade.trim()),
                (updateModel.subject = this.editedItem.subject.trim());
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSubject() {
      try {
        this.subjectSubscription = DataStore.observeQuery(Subject).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedSubjectArray !== items) {
              this.syncedSubjectArray = items;
              this.subjects = _.cloneDeep(
                _.sortBy(items, (item) => {
                  if (item.grade === "R") {
                    return -Infinity; // 'R' should appear first
                  } else if (item.grade === "1") {
                    return 0; // '1' should appear second
                  } else {
                    return parseInt(item.grade); // Sort the remaining numbers normally
                  }
                })
              );

              this.subjectSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.subjectSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }
  },
};
</script>

<style>
.hide-items-dropdown .v-input__append-inner {
  display: none !important;
}

.hide-items-dropdown .v-select__selections {
  min-height: 32px !important;
}
</style>
