<template>
  <div id="productorders">
    <v-card class="mx-auto" max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4">
          {{ $i18n.t("headings.product") + " " + $i18n.t("headings.orders") }}
          <!-- <helper topic="classes" /> -->
        </v-card-title>
        <v-spacer />
        <v-btn
          v-show="
            isValidOwnerField($route.params.owner) &&
            isAmplifyDataStoreId($route.params.teacherProfileId)
          "
          color="primary"
          class="mx-4"
          @click="createItem"
          :disabled="offline"
        >
          New Order
        </v-btn>
      </v-row>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="headers"
          :items="nonPendingOrders"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          must-sort
          :search="search"
          show-group-by
          no-data-text="No Orders found"
          :loading="!syncedProductOrders && nonPendingOrders?.length === 0"
          loading-text="Loading Orders..."
        >
          <!-- <template v-slot:[`item.orderID`]="{ item }">
            {{ `#${item.orderID}` }}
          </template> -->
          <template v-slot:[`item.orderDateTime`]="{ item }">
            {{ formatDate(item.orderDateTime) }}
          </template>
          <template v-slot:[`item.orderTotal`]="{ item }">
            {{ "R" + item.orderTotal.toFixed(2) }}
          </template>
          <template v-slot:[`item.orderProducts`]="{ item }">
            {{ item.orderProducts?.length > 0 ? item.orderProducts.length : 0 }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-show="item.orderPaymentMethod === 'EFT'"
              small
              class="mr-2"
              @click="editItem(item)"
              :disabled="offline"
            >
              mdi-pencil
            </v-icon>
            <v-icon small @click="viewItem(item)"> mdi-eye </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card class="overflow-x-hidden overflow-y-auto" max-height="90vh">
        <v-form ref="form" v-model="valid" @submit.prevent="save">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <p>Date: {{ formatDate(editedItem.orderDateTime) }}</p>
                  <!-- <p>Order ID: {{ editedItem.orderID }}</p> -->
                  <p>
                    {{
                      $i18n.t("headings.order") +
                      " " +
                      $i18n.t("otherText.id") +
                      ": " +
                      editedItem.orderID
                    }}
                  </p>
                  <p
                    v-if="
                      editedIndex === -1 ||
                      (editedIndex > -1 &&
                        (editedItem.orderPaymentMethod !== 'EFT' ||
                          ((editedItem.orderStatus === 'PAID' ||
                            editedItem.orderStatus === 'CANCELLED') &&
                            !unsavedChanges)))
                    "
                  >
                    {{
                      $i18n.t("labels.status") + ": " + editedItem.orderStatus
                    }}
                  </p>
                  <v-select
                    v-else
                    v-model="editedItem.orderStatus"
                    :items="eftStatuses"
                    :label="`${$i18n.t('labels.status')}:`"
                  ></v-select>
                  <p>Payment Method: {{ editedItem.orderPaymentMethod }}</p>
                  <div
                    v-if="
                      editedIndex === -1 ||
                      editedItem.orderStatus !== 'PROCESSING'
                    "
                  >
                    <p>
                      {{ $i18n.t("headings.products") + ":" }}
                    </p>
                    <v-row style="gap: 8px" no-gutters>
                      <v-chip
                        v-for="(
                          orderProduct, opIndex
                        ) in editedItem.orderProducts"
                        :key="opIndex"
                        class="text-wrap py-1"
                        :to="`/app/products/${orderProduct.id}`"
                        style="height: fit-content"
                        >{{ orderProduct.title }}</v-chip
                      ></v-row
                    >
                  </div>
                  <v-autocomplete
                    v-else
                    v-model="editedItem.orderProducts"
                    @change="updateOrderProducts"
                    :items="productModels"
                    item-text="title"
                    item-value="id"
                    :label="`${$i18n.t('headings.products')}*`"
                    return-object
                    required
                    :rules="formRules.products"
                    chips
                    clearable
                    deletable-chips
                    multiple
                  ></v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  v-show="editedItem.orderProducts?.length > 0"
                  style="font-size: 1.3rem; line-height: 1.5"
                >
                  <v-row no-gutters>
                    {{ $i18n.t("headings.products") }}: <v-spacer></v-spacer> R
                    {{ editedItemProductsPrice.toFixed(2) }}
                  </v-row>
                  <v-row
                    no-gutters
                    v-show="editedItemPhysicalProducts?.length > 0"
                  >
                    {{ $i18n.t("headings.shippingFee") }}:
                    <v-spacer></v-spacer> R
                    {{ (160).toFixed(2) }}
                  </v-row>
                  <v-row no-gutters>
                    {{
                      $i18n.t("labels.subTotal") +
                      " (" +
                      $i18n.t("labels.vatIncluded") +
                      ")"
                    }}: <v-spacer></v-spacer> R
                    {{ editedItemSubTotal.toFixed(2) }}
                  </v-row>
                  <v-row no-gutters>
                    {{
                      $i18n.t("labels.total") +
                      " (" +
                      $i18n.t("labels.vatExcluded") +
                      ")"
                    }}: <v-spacer></v-spacer> R
                    {{ editedItemExclVAT.toFixed(2) }}
                  </v-row>
                  <v-row no-gutters>
                    {{ $i18n.t("labels.vat") }} ( 15% ): <v-spacer></v-spacer> R
                    {{ editedItemTotalVat.toFixed(2) }}
                  </v-row>
                  <v-row no-gutters>
                    {{ $i18n.t("labels.total") }}: <v-spacer></v-spacer> R
                    {{ editedItemSubTotal.toFixed(2) }}
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  v-show="editedItem.orderPaymentMethod === 'EFT'"
                >
                  <v-card class="pa-3">
                    <span class="mb-0 text-subtitle-1"
                      ><strong>Bank Details:</strong></span
                    >
                    <p class="mb-0">Account holder: A Dempers</p>
                    <p class="mb-0">Account number: 1216434069</p>
                    <p class="mb-0">Account type: Current account</p>
                    <p class="mb-0">Bank name: Nedbank</p>
                    <p class="mb-0">Branch code: 198765</p>
                    <p class="mb-0">Reference: Your Name</p>
                    <p class="mb-0">
                      Proof of payment: admin@missanrisclassroom.com /
                      0713230930
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <div v-if="editedItemPhysicalProductsQuestions?.length > 0">
            <v-card-title class="pt-0">
              <span class="text-h5">Questions</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <div
                  v-for="(
                    productWithQuestions, productWithQuestionsIndex
                  ) in editedItemPhysicalProductsQuestions"
                  :key="productWithQuestionsIndex"
                >
                  <span class="text-h6">{{ productWithQuestions.title }}</span>

                  <v-row>
                    <v-col cols="12">
                      <template
                        v-for="(
                          question, questionIndex
                        ) in productWithQuestions.questions"
                      >
                        <v-text-field
                          v-if="question.type === 'Text'"
                          :key="'text-' + questionIndex"
                          v-model="question.value"
                          :label="question.label"
                          :hint="question.hint"
                          :placeholder="question.placeholder"
                          @dblclick="copyPlaceholderToValue(question)"
                          :required="question.required"
                          :readonly="
                            editedIndex === -1 ||
                            editedItem.orderStatus !== 'PROCESSING'
                          "
                          :rules="question.required ? formRules.required : []"
                        ></v-text-field>
                        <v-textarea
                          v-if="question.type === 'Textarea'"
                          :key="'textarea-' + questionIndex"
                          v-model="question.value"
                          :label="question.label"
                          :hint="question.hint"
                          :placeholder="question.placeholder"
                          @dblclick="copyPlaceholderToValue(question)"
                          auto-grow
                          :required="question.required"
                          :readonly="
                            editedIndex === -1 ||
                            editedItem.orderStatus !== 'PROCESSING'
                          "
                          :rules="question.required ? formRules.required : []"
                        ></v-textarea>
                        <v-select
                          v-if="
                            question.type === 'Select' ||
                            question.type === 'Multi Select'
                          "
                          :key="'select-' + questionIndex"
                          v-model="question.value"
                          :label="question.label"
                          :items="question.items"
                          :hint="question.hint"
                          :placeholder="question.placeholder"
                          :multiple="question.type === 'Multi Select'"
                          :required="question.required"
                          :readonly="
                            editedIndex === -1 ||
                            editedItem.orderStatus !== 'PROCESSING'
                          "
                          :rules="question.required ? formRules.required : []"
                        ></v-select>
                        <v-radio-group
                          v-if="question.type === 'Radio'"
                          :key="'radio-' + questionIndex"
                          v-model="question.value"
                          :label="question.label"
                          :hint="question.hint"
                          :readonly="
                            editedIndex === -1 ||
                            editedItem.orderStatus !== 'PROCESSING'
                          "
                          :rules="question.required ? formRules.required : []"
                        >
                          <v-radio
                            v-for="(item, itemIndex) in question.items"
                            :key="'item-' + itemIndex"
                            :label="item"
                            :value="item"
                          ></v-radio>
                        </v-radio-group>
                        <div
                          v-if="question.type === 'Checkbox'"
                          :key="'checkbox-container-' + questionIndex"
                        >
                          <span class="v-label">{{ question.label }}</span>
                          <template>
                            <v-checkbox
                              v-for="(item, itemIndex) in question.items"
                              :key="
                                'checkbox-' + questionIndex + '-' + itemIndex
                              "
                              v-model="question.value"
                              :value="item"
                              :label="item"
                              :readonly="
                                editedIndex === -1 ||
                                editedItem.orderStatus !== 'PROCESSING'
                              "
                              :rules="
                                question.required ? formRules.required : []
                              "
                            ></v-checkbox>
                          </template>
                        </div>
                      </template>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-card-text>
          </div>

          <div v-if="editedItemPhysicalProducts?.length > 0">
            <v-card-title class="py-0" style="gap: 12px">
              <span class="text-h5">{{
                $i18n.t("headings.shippingInfo")
              }}</span>
              <v-spacer></v-spacer>
              <v-btn
                v-show="
                  editedItem.orderStatus === 'PROCESSING' &&
                  isValidOwnerField($route.params.owner) &&
                  isAmplifyDataStoreId($route.params.teacherProfileId)
                "
                color="primary"
                @click="fetchTeacherShippingDetails"
                :loading="gettingTeacherShippingDetails"
                :disabled="gettingTeacherShippingDetails || offline"
              >
                Fetch Shipping Details
              </v-btn>
            </v-card-title>

            <div
              v-show="noShippingDetailsFound && !gettingTeacherShippingDetails"
              class="px-4 pt-6"
            >
              <v-alert class="mb-0" type="warning">{{
                $i18n.t("products.noShippingDetailsFound")
              }}</v-alert>
            </div>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="shippingDetails.address1"
                      :label="`${$i18n.t('labels.address')} 1`"
                      :rules="formRules.address1"
                      required
                      :readonly="
                        editedIndex === -1 ||
                        editedItem.orderStatus !== 'PROCESSING'
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="shippingDetails.address2"
                      :label="`${$i18n.t('labels.address')} 2`"
                      :readonly="
                        editedIndex === -1 ||
                        editedItem.orderStatus !== 'PROCESSING'
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="shippingDetails.city"
                      :label="$i18n.t('labels.city')"
                      :rules="formRules.city"
                      required
                      :readonly="
                        editedIndex === -1 ||
                        editedItem.orderStatus !== 'PROCESSING'
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="shippingDetails.province"
                      :items="provinces"
                      :label="`${$i18n.t('labels.province')}*`"
                      :rules="formRules.province"
                      required
                      :readonly="
                        editedIndex === -1 ||
                        editedItem.orderStatus !== 'PROCESSING'
                      "
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="shippingDetails.postalCode"
                      :label="$i18n.t('labels.postalCode')"
                      :rules="formRules.postalCode"
                      required
                      :readonly="
                        editedIndex === -1 ||
                        editedItem.orderStatus !== 'PROCESSING'
                      "
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="shippingDetails.country"
                      readonly
                      :label="$i18n.t('labels.country')"
                      :rules="formRules.country"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="shippingDetails.phoneNumber"
                      :label="
                        $i18n.t('labels.phone') + ' ' + $i18n.t('labels.number')
                      "
                      :rules="formRules.phoneNumber"
                      required
                      :readonly="
                        editedIndex === -1 ||
                        editedItem.orderStatus !== 'PROCESSING'
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  v-show="
                    isValidOwnerField($route.params.owner) &&
                    isAmplifyDataStoreId($route.params.teacherProfileId) &&
                    (shippingChanged || savedShippingDetails)
                  "
                  no-gutters
                >
                  <div>
                    {{ $i18n.t("products.saveShipping") }}
                    <v-btn
                      color="green"
                      class="ml-3 white--text"
                      :disabled="
                        !shippingChanged ||
                        savingTeacherShippingDetails ||
                        offline
                      "
                      :loading="savingTeacherShippingDetails"
                      @click="saveTeacherShippingDetails"
                      >{{ $i18n.t("actions.yes") }}</v-btn
                    >
                  </div>

                  <div
                    v-if="savedShippingDetails && !savingTeacherShippingDetails"
                    class="ml-3 d-flex align-center"
                  >
                    <!-- {{ $i18n.t("actions.saved") + "!" }} -->
                    <v-icon color="success" class="slide-x-transition">
                      mdi-check-circle
                    </v-icon>
                  </div>
                </v-row>
              </v-container>
            </v-card-text>
          </div>

          <v-card-actions>
            <v-spacer v-show="editedIndex === -1"></v-spacer>
            <v-btn color="primary" @click="close">
              {{ $i18n.t("actions.close") }}
            </v-btn>
            <v-spacer v-show="editedIndex > -1"></v-spacer>
            <v-btn
              v-show="editedIndex > -1"
              :disabled="!valid || !unsavedChanges"
              color="primary"
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createDialog" max-width="500px">
      <v-card class="overflow-x-hidden overflow-y-auto" max-height="90vh">
        <v-form ref="form" v-model="validCreate" @submit.prevent="save">
          <v-card-title>
            <span class="text-h5">Create Product Order</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <!-- createdAt : "2024-10-03T13:25:49.684Z" 
                id : "04fb13c7-22cc-4411-9b6e-e4bcdbac7bcb"
                orderDateTime : "2024-10-03T13:26:03.000Z"
                orderID : "20241003-152603"
                orderPayment : null
                orderPaymentMethod : "EFT"
                orderProducts : [{…}]
                orderShippingDetails : null
                orderStatus : "PAID"
                orderTotal : 95
                orderVAT : 12.39
                owner : "anridempers"
                teacherprofileID : "7d2b4902-ca79-4539-a27c-86bd80044b28" -->
                <v-col cols="12">
                  <v-autocomplete
                    v-model="editedItem.orderProducts"
                    @change="updateOrderProducts"
                    :items="productModels"
                    item-text="title"
                    item-value="id"
                    :label="`${$i18n.t('headings.products')}*`"
                    return-object
                    required
                    :rules="formRules.products"
                    :loading="!syncedProducts"
                    chips
                    clearable
                    deletable-chips
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="editedItem.orderPaymentMethod"
                    :items="paymentMethods"
                    item-text="title"
                    item-value="value"
                    :label="`${$i18n.t('products.paymentMethod')}*`"
                    :rules="formRules.paymentMethod"
                    readonly
                    required
                  ></v-select>
                </v-col>

                <v-col
                  cols="12"
                  v-show="editedItem.orderProducts?.length > 0"
                  style="font-size: 1.3rem; line-height: 1.5"
                >
                  <v-row no-gutters>
                    {{ $i18n.t("headings.products") }}: <v-spacer></v-spacer> R
                    {{ editedItemProductsPrice.toFixed(2) }}
                  </v-row>
                  <v-row
                    no-gutters
                    v-show="editedItemPhysicalProducts?.length > 0"
                  >
                    {{ $i18n.t("headings.shippingFee") }}:
                    <v-spacer></v-spacer> R
                    {{ (160).toFixed(2) }}
                  </v-row>
                  <v-row no-gutters>
                    {{
                      $i18n.t("labels.subTotal") +
                      " (" +
                      $i18n.t("labels.vatIncluded") +
                      ")"
                    }}: <v-spacer></v-spacer> R
                    {{ editedItemSubTotal.toFixed(2) }}
                  </v-row>
                  <v-row no-gutters>
                    {{
                      $i18n.t("labels.total") +
                      " (" +
                      $i18n.t("labels.vatExcluded") +
                      ")"
                    }}: <v-spacer></v-spacer> R
                    {{ editedItemExclVAT.toFixed(2) }}
                  </v-row>
                  <v-row no-gutters>
                    {{ $i18n.t("labels.vat") }} ( 15% ): <v-spacer></v-spacer> R
                    {{ editedItemTotalVat.toFixed(2) }}
                  </v-row>
                  <v-row no-gutters>
                    {{ $i18n.t("labels.total") }}: <v-spacer></v-spacer> R
                    {{ editedItemSubTotal.toFixed(2) }}
                  </v-row>
                </v-col>
                <!-- <v-col cols="12" v-show="editedItem.orderPaymentMethod === 'EFT'">
                <v-card class="pa-3">
                  <span class="mb-0 text-subtitle-1"><strong>Bank Details:</strong></span>
                  <p class="mb-0">Account holder: A Dempers</p>
                  <p class="mb-0">Account number: 1216434069</p>
                  <p class="mb-0">Account type: Lopende rekening (Current account)</p>
                  <p class="mb-0">Bank name: Nedbank</p>
                  <p class="mb-0">Branch code: 198765</p>
                  <p class="mb-0">Reference: Your Name</p>
                  <p class="mb-0">Proof of payment: admin@missanrisclassroom.com / 0713230930</p>
                </v-card>
              </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>
          <div v-if="editedItemPhysicalProductsQuestions?.length > 0">
            <v-card-title class="pt-0">
              <span class="text-h5">Questions</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <div
                  v-for="(
                    productWithQuestions, productWithQuestionsIndex
                  ) in editedItemPhysicalProductsQuestions"
                  :key="productWithQuestionsIndex"
                >
                  <span class="text-h6">{{ productWithQuestions.title }}</span>

                  <v-row>
                    <v-col cols="12">
                      <template
                        v-for="(
                          question, questionIndex
                        ) in productWithQuestions.questions"
                      >
                        <v-text-field
                          v-if="question.type === 'Text'"
                          :key="'text-' + questionIndex"
                          v-model="question.value"
                          :label="question.label"
                          :hint="question.hint"
                          :placeholder="question.placeholder"
                          @dblclick="copyPlaceholderToValue(question)"
                          :required="question.required"
                          :rules="question.required ? formRules.required : []"
                        ></v-text-field>
                        <v-textarea
                          v-if="question.type === 'Textarea'"
                          :key="'textarea-' + questionIndex"
                          v-model="question.value"
                          :label="question.label"
                          :hint="question.hint"
                          :placeholder="question.placeholder"
                          @dblclick="copyPlaceholderToValue(question)"
                          auto-grow
                          :required="question.required"
                          :rules="question.required ? formRules.required : []"
                        ></v-textarea>
                        <v-select
                          v-if="
                            question.type === 'Select' ||
                            question.type === 'Multi Select'
                          "
                          :key="'select-' + questionIndex"
                          v-model="question.value"
                          :label="question.label"
                          :items="question.items"
                          :hint="question.hint"
                          :placeholder="question.placeholder"
                          :multiple="question.type === 'Multi Select'"
                          :required="question.required"
                          :rules="question.required ? formRules.required : []"
                        ></v-select>
                        <v-radio-group
                          v-if="question.type === 'Radio'"
                          :key="'radio-' + questionIndex"
                          v-model="question.value"
                          :label="question.label"
                          :hint="question.hint"
                          :rules="question.required ? formRules.required : []"
                        >
                          <v-radio
                            v-for="(item, itemIndex) in question.items"
                            :key="'item-' + itemIndex"
                            :label="item"
                            :value="item"
                          ></v-radio>
                        </v-radio-group>
                        <div
                          v-if="question.type === 'Checkbox'"
                          :key="'checkbox-container-' + questionIndex"
                        >
                          <span class="v-label">{{ question.label }}</span>
                          <template>
                            <v-checkbox
                              v-for="(item, itemIndex) in question.items"
                              :key="
                                'checkbox-' + questionIndex + '-' + itemIndex
                              "
                              v-model="question.value"
                              :value="item"
                              :label="item"
                              :rules="
                                question.required ? formRules.required : []
                              "
                            ></v-checkbox>
                          </template>
                        </div>
                      </template>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-card-text>
          </div>

          <div v-if="editedItemPhysicalProducts?.length > 0">
            <v-card-title class="py-0" style="gap: 12px">
              <span class="text-h5">{{
                $i18n.t("headings.shippingInfo")
              }}</span>
              <v-spacer></v-spacer>
              <v-btn
                v-show="
                  isValidOwnerField($route.params.owner) &&
                  isAmplifyDataStoreId($route.params.teacherProfileId)
                "
                color="primary"
                @click="fetchTeacherShippingDetails"
                :loading="gettingTeacherShippingDetails"
                :disabled="gettingTeacherShippingDetails || offline"
              >
                Fetch Shipping Details
              </v-btn>
            </v-card-title>

            <div
              v-show="noShippingDetailsFound && !gettingTeacherShippingDetails"
              class="px-4 pt-6"
            >
              <v-alert class="mb-0" type="warning">{{
                $i18n.t("products.noShippingDetailsFound")
              }}</v-alert>
            </div>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="shippingDetails.address1"
                      :label="`${$i18n.t('labels.address')} 1`"
                      :rules="formRules.address1"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="shippingDetails.address2"
                      :label="`${$i18n.t('labels.address')} 2`"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="shippingDetails.city"
                      :label="$i18n.t('labels.city')"
                      :rules="formRules.city"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="shippingDetails.province"
                      :items="provinces"
                      :label="`${$i18n.t('labels.province')}*`"
                      :rules="formRules.province"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="shippingDetails.postalCode"
                      :label="$i18n.t('labels.postalCode')"
                      :rules="formRules.postalCode"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="shippingDetails.country"
                      readonly
                      :label="$i18n.t('labels.country')"
                      :rules="formRules.country"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="shippingDetails.phoneNumber"
                      :label="
                        $i18n.t('labels.phone') + ' ' + $i18n.t('labels.number')
                      "
                      :rules="formRules.phoneNumber"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  v-show="shippingChanged || savedShippingDetails"
                  no-gutters
                >
                  <div>
                    {{ $i18n.t("products.saveShipping") }}
                    <v-btn
                      color="green"
                      class="ml-3 white--text"
                      :disabled="
                        !shippingChanged ||
                        savingTeacherShippingDetails ||
                        offline
                      "
                      :loading="savingTeacherShippingDetails"
                      @click="saveTeacherShippingDetails"
                      >{{ $i18n.t("actions.yes") }}</v-btn
                    >
                  </div>

                  <div
                    v-if="savedShippingDetails && !savingTeacherShippingDetails"
                    class="ml-3 d-flex align-center"
                  >
                    <!-- {{ $i18n.t("actions.saved") + "!" }} -->
                    <v-icon color="success" class="slide-x-transition">
                      mdi-check-circle
                    </v-icon>
                  </div>
                </v-row>
              </v-container>
            </v-card-text>
          </div>

          <v-card-actions>
            <v-btn color="primary" @click="close">
              {{ $i18n.t("actions.close") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="!validCreate" color="primary" @click="save">
              Create
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
// import Helper from "@/components/global-components/Helper.vue";
import { DataStore, SortDirection, Predicates, API } from "aws-amplify";
import { TeacherProductsOrder, Product } from "@/models";

export default {
  name: "ProductOrders",
  // components: { Helper },
  data: () => ({
    initiallyLoaded: false,
    gettingTeacherShippingDetails: false,
    savingTeacherShippingDetails: false,
    savedShippingDetails: false,
    noShippingDetailsFound: false,
    dialog: false,
    createDialog: false,
    sortBy: "orderDateTime",
    sortDesc: true,
    headers: [],
    search: "",

    editedIndex: -1,
    valid: false,
    validCreate: false,

    editedItem: {
      orderDateTime: "",
      orderID: "",
      orderPayment: null,
      orderPaymentMethod: "",
      orderProducts: [],
      orderShippingDetails: null,
      orderStatus: "",
      orderTotal: 0,
      orderVAT: 0,
      owner: "",
      teacherprofileID: "",
    },
    defaultItem: {
      orderDateTime: "",
      orderID: "",
      orderPayment: null,
      orderPaymentMethod: "",
      orderProducts: [],
      orderShippingDetails: null,
      orderStatus: "",
      orderTotal: 0,
      orderVAT: 0,
      owner: "",
      teacherprofileID: "",
    },

    syncedShippingDetails: null,
    shippingDetails: {
      address1: "",
      address2: "",
      city: "",
      province: "",
      postalCode: "",
      country: "South Africa",
      phoneNumber: "",
    },
    defaultShippingDetails: {
      address1: "",
      address2: "",
      city: "",
      province: "",
      postalCode: "",
      country: "South Africa",
      phoneNumber: "",
    },

    productsSubscription: null,
    syncedProductModels: [],
    productModels: [],
    syncedProducts: false,

    checkedRouteActions: false,

    productOrdersSubscription: null,
    syncedProductOrders: false,
    syncedProductOrderModels: [],
    productOrderModels: [],
    eftStatuses: ["PROCESSING", "PAID", "CANCELLED"],

    paymentMethods: [
      { title: "EFT", value: "EFT" },
      { title: "Free", value: "Free" },
      { title: "Card Payment", value: "Payfast" },
    ],

    provinces: [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "Northern Cape",
      "North West",
      "Western Cape",
    ],

    formRules: {
      required: [(v) => !!v || "This field is required"],
      paymentMethod: [(v) => !!v || "a Payment Method is required"],
      products: [(v) => (!!v && v.length > 0) || "Products are required"],
      address1: [(v) => !!v || "Address is required"],
      city: [(v) => !!v || "City is required"],
      province: [(v) => !!v || "Province is required"],
      postalCode: [(v) => !!v || "Postal code is required"],
      country: [(v) => !!v || "Country is required"],
      phoneNumber: [
        (v) => !!v || "Phone number is required",
        (v) =>
          /^(?:\+27|0)\s?\d{2}\s?\d{3}\s?\d{4}$/.test(v) ||
          "Invalid South African phone number",
      ],
    },
  }),

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
      userSignedIn: (state) => state.userSignedIn,
    }),

    nonPendingOrders() {
      return this.productOrderModels.filter(
        (order) => order.orderStatus !== "PENDING"
      );
    },
    formTitle() {
      return this.editedIndex === -1
        ? "View Product Order"
        : "Edit Product Order";
    },

    unsavedChanges() {
      const productShippingChanged =
        this.editedItemPhysicalProducts?.length > 0
          ? JSON.stringify(this.editedItem.orderShippingDetails) !==
            JSON.stringify(this.shippingDetails)
          : false;

      return (
        JSON.stringify(
          _.find(this.productOrderModels, { id: this.editedItem.id })
        ) !== JSON.stringify(this.editedItem) || productShippingChanged
      );
    },

    shippingChanged() {
      if (this.validCreate || this.valid) {
        const unchanged =
          JSON.stringify(this.syncedShippingDetails) ===
          JSON.stringify(this.shippingDetails);

        return unchanged ? false : true;
      } else {
        return false;
      }
    },

    editedItemPhysicalProducts() {
      return this.editedItem.orderProducts?.filter(
        (product) => product.physical === true
      );
    },

    editedItemPhysicalProductsQuestions() {
      return this.editedItemPhysicalProducts?.filter(
        (product) => product.questions?.length > 0
      );
    },

    editedItemProductsPrice() {
      if (this.editedItem.orderProducts?.length === 0) return 0;

      let price = 0;

      for (let product of this.editedItem.orderProducts) {
        price +=
          product.price && product.discountPercentage
            ? product.price - product.price * (product.discountPercentage / 100)
            : product.price;
      }

      return price;
    },

    editedItemSubTotal() {
      return this.editedItemPhysicalProducts?.length > 0
        ? 160 + this.editedItemProductsPrice
        : this.editedItemProductsPrice;
    },

    editedItemExclVAT() {
      return this.editedItemSubTotal
        ? Number.parseFloat(this.editedItemSubTotal / (1 + 0.15))
        : 0;
    },

    editedItemTotalVat() {
      return this.editedItemSubTotal
        ? Number.parseFloat(
            (this.editedItemSubTotal / (1 + 0.15) - this.editedItemSubTotal) *
              -1
          )
        : 0;
    },
  },

  async mounted() {
    try {
      // this.functions wont work in data because it this.something refers to something else withing data and not for example a method.
      this.headers = [
        { text: "Order #", value: "orderID", groupable: false },
        { text: "Date", value: "orderDateTime", groupable: false },
        { text: "User", value: "owner" },
        { text: "Status", value: "orderStatus" },
        { text: "Payment Method", value: "orderPaymentMethod" },
        { text: "Total", value: "orderTotal", groupable: false },
        { text: "Products Count", value: "orderProducts", groupable: false },
        {
          text: this.$i18n.t("labels.actions"),
          value: "actions",
          sortable: false,
          groupable: false,
        },
      ];

      this.syncProductOrders();
      this.syncProducts();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    createDialog(val) {
      val || this.close();
    },

    "$route.params": {
      handler(newVal, oldVal) {
        if (
          typeof newVal === "object" &&
          newVal.owner &&
          newVal.teacherProfileId &&
          (!this.isAmplifyDataStoreId(newVal.teacherProfileId) ||
            !this.isValidOwnerField(newVal.owner))
        ) {
          this.$router.push({
            path: "/admin/productorders",
          });
        }

        if (
          typeof oldVal === "object" &&
          this.syncedProductOrders &&
          this.syncedProductOrderModels.length > 0
        ) {
          if (typeof newVal.orderId === "string") {
            const order = _.find(this.syncedProductOrderModels, {
              orderID: newVal.orderId,
            });
            if (order) {
              this.viewItem(order);
            }
          } else if (this.isAmplifyDataStoreId(newVal.teacherProfileId)) {
            this.productOrderModels = _.filter(
              this.syncedProductOrderModels,
              (item) =>
                item.teacherprofileID === this.$route.params.teacherProfileId
            );

            if (
              this.isValidOwnerField(newVal.owner) &&
              typeof newVal.action === "string" &&
              newVal.action === "new"
            ) {
              this.createItem();
            }
          } else {
            this.productOrderModels = _.cloneDeep(
              this.syncedProductOrderModels
            );
          }
        }
        //  else if (
        //         this.isAmplifyDataStoreId(this.$route.params.teacherProfileId)
        //       ) {
        //         this.productOrderModels = _.filter(
        //           items,
        //           (item) =>
        //             item.teacherprofileID ===
        //             this.$route.params.teacherProfileId
        //         );
        //       } else {
        //         this.productOrderModels = _.cloneDeep(items);
        //       }
      },
      immediate: true,
    },

    // syncedProductOrderModels: {
    //   handler(val) {
    //     if (val?.length > 0) {
    //       if (typeof this.$route.params.orderId === "string") {
    //         const order = _.find(val, { orderID: this.$route.params.orderId });
    //         if (order) {
    //           this.viewItem(order);
    //         }
    //       } else if (
    //         typeof this.$route.params.action === "string" &&
    //         this.$route.params.action === "new"
    //       ) {
    //         this.createItem();
    //       }
    //     }
    //   },
    //   immediate: true,
    // },
  },

  methods: {
    updateOrderProducts() {
      // console.log("updateOrderProducts");
      this.editedItem.orderProducts = this.editedItem.orderProducts.map(
        (product) => _.cloneDeep(product)
      );

      if (
        this.editedItem.orderProducts?.length === 0 ||
        this.editedItemSubTotal > 0
      ) {
        this.editedItem.orderPaymentMethod = "EFT";
      } else if (this.editedItemSubTotal === 0) {
        this.editedItem.orderPaymentMethod = "Free";
      }
    },

    async saveTeacherShippingDetails() {
      try {
        this.savingTeacherShippingDetails = true;
        await API.put("shippingapi", "/item/saveTeacherShippingDetails", {
          body: {
            teacherProfileID: this.$route.params.teacherProfileId,
            shippingDetails: this.shippingDetails,
          },
          // headers: {} // OPTIONAL
        })
          .then(async (response) => {
            if (response.error) {
              console.log(response.error);
            } else if (typeof response.success === "string") {
              // console.log("Shipping details saved successfully");
              this.syncedShippingDetails = _.cloneDeep(this.shippingDetails);
            }
            this.savingTeacherShippingDetails = false;
            this.savedShippingDetails = true;
            setTimeout(() => {
              this.savedShippingDetails = false;
            }, 5000);
          })
          .catch((error) => {
            console.log(error);
            this.savingTeacherShippingDetails = false;
          });
      } catch (error) {
        console.log(error);
        this.savingTeacherShippingDetails = false;
      }
    },

    async fetchTeacherShippingDetails() {
      try {
        this.gettingTeacherShippingDetails = true;
        await API.get("shippingapi", "/item/getTeacherShippingDetails", {
          queryStringParameters: {
            teacherProfileID: this.$route.params.teacherProfileId,
          },
        })
          .then(async (response) => {
            if (response.error) {
              console.log(response.error);
            } else if (typeof response.shippingDetails === "string") {
              this.noShippingDetailsFound = false;
              const responseShippingDetails = JSON.parse(
                response.shippingDetails
              );
              // this.editedItem.orderShippingDetails = responseShippingDetails;
              this.shippingDetails = _.cloneDeep(responseShippingDetails);
              this.syncedShippingDetails = _.cloneDeep(responseShippingDetails);
            } else {
              // console.log("No shipping details found");
              this.noShippingDetailsFound = true;
              setTimeout(() => {
                this.noShippingDetailsFound = false;
              }, 5000);
            }

            this.gettingTeacherShippingDetails = false;
          })
          .catch((error) => {
            console.log(error);
            this.gettingTeacherShippingDetails = false;

            this.noShippingDetailsFound = true;
            setTimeout(() => {
              this.noShippingDetailsFound = false;
            }, 5000);
          });
      } catch (error) {
        console.log(error);
        this.gettingTeacherShippingDetails = false;

        this.noShippingDetailsFound = true;
        setTimeout(() => {
          this.noShippingDetailsFound = false;
        }, 5000);
      }
    },

    copyPlaceholderToValue(question) {
      if (!question.value && question.placeholder) {
        question.value = question.placeholder;
      }
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update Product Order
        await this.updateProductOrder();
      } else {
        // Create new Product Order
        await this.createProductOrder();
      }
      this.close();
    },

    async createProductOrder() {
      const newOrderID = this.getFormattedTimestamp();

      // const newProductOrder =
      await DataStore.save(
        new TeacherProductsOrder({
          orderDateTime: new Date().toISOString(),
          orderID: newOrderID,
          orderPaymentMethod: this.editedItem.orderPaymentMethod,
          orderProducts: this.editedItem.orderProducts,
          orderTotal: parseFloat(this.editedItemSubTotal.toFixed(2)),
          orderVAT: parseFloat(this.editedItemTotalVat.toFixed(2)),
          orderShippingDetails:
            this.editedItemPhysicalProducts?.length > 0
              ? this.shippingDetails
              : null,
          orderStatus:
            this.editedItem.orderPaymentMethod === "EFT"
              ? "PROCESSING"
              : "PAID",
          owner: this.$route.params.owner,
          teacherprofileID: this.$route.params.teacherProfileId,
        })
      );

      // await DataStore.save(
      //   TeacherProductsOrder.copyOf(newProductOrder, (updateModel) => {
      //     updateModel.owner = this.$route.params.owner;
      //   })
      // );
    },

    async updateProductOrder() {
      try {
        await DataStore.save(
          TeacherProductsOrder.copyOf(
            _.find(this.syncedProductOrderModels, { id: this.editedItem.id }),
            (updateModel) => {
              updateModel.orderStatus = this.editedItem.orderStatus;
              updateModel.orderProducts = this.editedItem.orderProducts;
              updateModel.orderShippingDetails =
                this.editedItemPhysicalProducts?.length > 0
                  ? this.shippingDetails
                  : null;
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    getFormattedTimestamp() {
      const now = new Date();
      const year = now.getFullYear().toString();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const hour = now.getHours().toString().padStart(2, "0");
      const minute = now.getMinutes().toString().padStart(2, "0");
      const second = now.getSeconds().toString().padStart(2, "0");
      return `${year}${month}${day}-${hour}${minute}${second}`;
    },

    isValidOwnerField(owner) {
      // Regular expression to validate the format cognitoSub::username
      const ownerRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}::[\w.-]+$/i;

      return ownerRegex.test(owner);
    },

    isAmplifyDataStoreId(id) {
      // Regular expression to check UUID format
      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

      return uuidRegex.test(id);
    },

    async syncProductOrders() {
      try {
        // console.log("syncProductOrders");
        this.productOrdersSubscription = DataStore.observeQuery(
          TeacherProductsOrder,
          Predicates.ALL,
          {
            sort: (s) => s.orderDateTime(SortDirection.DESCENDING),
          }
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items?.length > 0) {
              this.syncedProductOrderModels = items;

              if (
                this.isAmplifyDataStoreId(this.$route.params.teacherProfileId)
              ) {
                this.productOrderModels = _.filter(
                  items,
                  (item) =>
                    item.teacherprofileID ===
                    this.$route.params.teacherProfileId
                );
              } else {
                this.productOrderModels = _.cloneDeep(items);
              }

              if (this.checkedRouteActions) return;
              this.checkedRouteActions = true;

              if (typeof this.$route.params.orderId === "string") {
                const order = _.find(items, {
                  orderID: this.$route.params.orderId,
                });
                if (order) {
                  this.viewItem(order);
                }
              } else if (
                this.isValidOwnerField(this.$route.params.owner) &&
                this.isAmplifyDataStoreId(
                  this.$route.params.teacherProfileId
                ) &&
                typeof this.$route.params.action === "string" &&
                this.$route.params.action === "new"
              ) {
                this.createItem();
              }

              this.syncedProductOrders = true;
            } else if (isSynced && items?.length === 0 && this.userSignedIn) {
              this.syncedProductOrders = true;
            } else {
              this.syncedProductOrders = false;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncProducts() {
      try {
        // console.log("syncProductOrders");
        this.productsSubscription = DataStore.observeQuery(Product).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items) {
              this.syncedProductModels = items;
              this.productModels = _.cloneDeep(items);
              // .filter(
              //   (item) =>
              //     !this.editedItem.orderProducts.some(
              //       (orderProduct) => orderProduct.id === item.id
              //     )
              // );
              this.syncedProducts = true;
            } else {
              this.syncedProducts = false;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    formatDate(date) {
      if (!date) return "";
      return new Date(date).toLocaleString();
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.shippingDetails = Object.assign({}, this.defaultShippingDetails);
      this.valid = false;
      this.validCreate = false;
    },

    createItem() {
      this.resetForm();
      this.editedItem.owner = this.$route.params.owner;
      this.editedItem.teacherprofileID = this.$route.params.teacherProfileId;
      this.editedItem.orderPaymentMethod = "EFT";
      this.createDialog = true;
    },

    viewItem(item) {
      this.editedItem = Object.assign({}, item);

      if (item.orderShippingDetails) {
        this.shippingDetails = _.cloneDeep(item.orderShippingDetails);
        this.syncedShippingDetails = _.cloneDeep(item.orderShippingDetails);
      } else {
        this.shippingDetails = Object.assign({}, this.defaultShippingDetails);
        this.syncedShippingDetails = null;
      }

      this.dialog = true;
    },

    editItem(item) {
      this.resetForm();
      this.editedIndex = this.productOrderModels.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.editedItem = _.cloneDeep(item);

      if (item.orderShippingDetails) {
        this.shippingDetails = _.cloneDeep(item.orderShippingDetails);
        this.syncedShippingDetails = _.cloneDeep(item.orderShippingDetails);
      } else {
        this.shippingDetails = _.cloneDeep(this.defaultShippingDetails);
        this.syncedShippingDetails = null;
      }

      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.createDialog = false;
      this.$nextTick(() => {
        console.log("close");
        this.editedItem = _.cloneDeep(this.defaultItem);
        this.shippingDetails = _.cloneDeep(this.defaultShippingDetails);
        this.editedIndex = -1;
      });
    },
  },

  beforeDestroy() {
    if (this.productOrdersSubscription) {
      this.productOrdersSubscription.unsubscribe();
    }
    if (this.productsSubscription) {
      this.productsSubscription.unsubscribe();
    }
  },
};
</script>
