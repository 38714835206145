<template>
  <div id="holidays">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4"> Holidays </v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-4"
          @click="createItem"
          :disabled="offline"
        >
          New Holiday
        </v-btn>
      </v-row>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="headers"
          :items="holidays"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          :search="search"
          :loading="!holidaySubscriptionSynced && holidays?.length === 0"
          loading-text="Loading Holidays..."
          no-data-text="No Holidays found"
        >
          <!-- <template v-slot:[`item.date`]="{ item }">
            {{ item.date ? formatDate(item.date) : "" }}
          </template> -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              :disabled="offline"
            >
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" :disabled="offline">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="save">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.label"
                    label="Label"
                    :rules="formRules.label"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <!-- <v-text-field v-model="editedItem.startTime" label="Start Time"></v-text-field> -->
                  <v-dialog
                    ref="dialogDate"
                    v-model="timePickerDateModel"
                    :return-value.sync="editedItem.date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.date"
                        label="Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="formRules.date"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-if="timePickerDateModel"
                      v-model="editedItem.date"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="timePickerDateModel = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogDate.save(editedItem.date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close"> Cancel </v-btn>
            <v-btn :disabled="!valid" color="primary" @click="save">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete this holiday?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDelete">Cancel</v-btn>
          <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import { Holiday } from "@/models";
import _ from "lodash";

export default {
  name: "AdminHolidays",
  data: () => ({
    initiallyLoaded: false,
    holidaySubscription: null,
    holidaySubscriptionSynced: false,
    syncedHolidayArray: [],
    timePickerDateModel: null,
    valid: false,
    dialog: false,
    dialogDelete: false,
    sortBy: "label",
    sortDesc: true,
    search: "",
    headers: [
      { text: "Label", value: "label" },
      { text: "Date", value: "date" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    holidays: [],
    editedIndex: -1,
    editedItem: {
      label: "",
      date: null,
    },
    defaultItem: {
      label: "",
      date: null,
    },
    formRules: {
      label: [(v) => !!v || "Label is required"],
      date: [(v) => !!v || "Date is required"],
    },
  }),

  async mounted() {
    try {
      // Sync the Holidays from the datastore
      await this.syncHolidays();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
      appLoading: (state) => !state.appLoading,
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Holiday" : "Edit Holiday";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.valid = false;
    },

    createItem() {
      this.resetForm();
      this.dialog = true;
    },

    editItem(item) {
      this.editedIndex = this.holidays.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.holidays.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.holidays.splice(this.editedIndex, 1);
      await DataStore.delete(this.syncedHolidayArray[this.editedIndex]);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update schedule
        await this.updateHoliday();
      } else {
        // Create new schedule
        await this.createHoliday();
      }
      this.close();
    },

    async createHoliday() {
      try {
        this.editedItem.date = this.convertToAwsTimeFormat(
          this.editedItem.date
        );
        await DataStore.save(
          new Holiday({
            label: this.editedItem.label.trim(),
            date: this.editedItem.date,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updateHoliday() {
      try {
        this.editedItem.date = this.convertToAwsTimeFormat(
          this.editedItem.date
        );
        await DataStore.save(
          Holiday.copyOf(
            _.find(this.syncedHolidayArray, { id: this.editedItem.id }),
            (updateModel) => {
              (updateModel.label = this.editedItem.label.trim()),
                (updateModel.date = this.editedItem.date);
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    formatDate(dateString) {
      if (!dateString) return ""; // Return an empty string when date is null
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month =
        date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1; // month is 0-indexed
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return `${year}-${month}-${day}`;
    },

    convertToAwsTimeFormat(dateString) {
      if (!dateString) return null; // Return null when date is null
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month =
        date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1; // month is 0-indexed
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return `${year}-${month}-${day}`;
    },

    async syncHolidays() {
      try {
        this.holidaySubscription = DataStore.observeQuery(Holiday).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedHolidayArray !== items) {
              this.syncedHolidayArray = items;

              this.holidays = items.map((newObj) => {
                const newObjCopy = _.cloneDeep(newObj);
                newObjCopy.date = this.formatDate(newObj.date);
                return newObjCopy;
              });

              this.holidaySubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.holidaySubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.holidaySubscription) {
      this.holidaySubscription.unsubscribe();
    }
  },
};
</script>
