// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Secret, Announcement, Communication, TeacherProductsOrder, ProductCategory, ProductTag, ProductContent, Product, Taxonomy, TeacherAssessmentTemplate, AssessmentTemplate, TeacherSubjectTemplate, SubjectTemplate, School, TeacherActivity, Term, Holiday, TeacherClassSubject, Subject, TeacherSchedule, ReportingTeacher, TeacherProfile, CognitiveLevelJSON, BiWeekJSON } = initSchema(schema);

export {
  Secret,
  Announcement,
  Communication,
  TeacherProductsOrder,
  ProductCategory,
  ProductTag,
  ProductContent,
  Product,
  Taxonomy,
  TeacherAssessmentTemplate,
  AssessmentTemplate,
  TeacherSubjectTemplate,
  SubjectTemplate,
  School,
  TeacherActivity,
  Term,
  Holiday,
  TeacherClassSubject,
  Subject,
  TeacherSchedule,
  ReportingTeacher,
  TeacherProfile,
  CognitiveLevelJSON,
  BiWeekJSON
};