<template>
  <div id="assessmenttemplates">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4"> Assessment Templates </v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-4"
          @click="createItem"
          :disabled="offline"
        >
          New Template
        </v-btn>
      </v-row>
      <v-card-title
        ><v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="search"
              :items="syncedSchoolArray"
              :item-text="schoolName"
              label="School"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="headers"
          :items="computedAssessmentTemplates"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          :search="search"
          :custom-filter="filterDataTable"
          :loading="
            !assessmentTemplateSubscriptionSynced &&
            computedAssessmentTemplates?.length === 0
          "
          loading-text="Loading Assessments..."
          no-data-text="No Assessments found"
        >
          <template v-slot:[`item.totalMinutes`]="{ item }">
            {{ item.totalMinutes ? formattedTotalTime(item.totalMinutes) : "" }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="duplicateItem(item)">
              mdi-content-copy
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              :disabled="offline"
            >
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" :disabled="offline">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog persistent v-model="dialog" v-if="dialog">
      <div>
        <v-card>
          <v-form ref="form" v-model="valid" @submit.prevent="save">
            <v-row
              no-gutters
              :style="
                screenWidth >= 960 ? 'overflow-y: hidden;' : 'overflow-y: auto;'
              "
              style="max-height: calc(100vh - 164px)"
            >
              <v-col
                cols="12"
                md="6"
                class="pa-0"
                :style="
                  screenWidth >= 960
                    ? 'overflow-y: auto; overflow-x: hidden; max-height: calc(100vh - 164px);'
                    : ''
                "
              >
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-tabs
                  v-model="dialogTab"
                  background-color="transparent"
                  color="primary"
                  grow
                >
                  <v-tab v-for="item in dialogTabItems" :key="item">
                    {{ item }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="dialogTab">
                  <v-tab-item>
                    <v-expansion-panels
                      class="px-2 mb-3"
                      style="justify-content: left"
                      v-model="expPanels"
                    >
                      <v-card-subtitle class="pb-0 pt-2">
                        <span class="text-h6">Headings</span>
                      </v-card-subtitle>

                      <v-expansion-panel class="panelTopBorder">
                        <v-expansion-panel-header>
                          Change Defaults
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          id="expPanelContent"
                          color="grey lighten-3"
                        >
                          <v-row class="py-2">
                            <v-col
                              cols="12"
                              sm="6"
                              lg="4"
                              class="py-0"
                              v-for="(
                                heading, headingIndex
                              ) in editedItem.headings"
                              :key="headingIndex"
                            >
                              <v-text-field
                                v-model="heading.value"
                                :label="heading.label"
                                required
                                :rules="formRules.required"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-card-subtitle class="pb-0 pt-2">
                        <span class="text-h6">Assessment</span>
                      </v-card-subtitle>

                      <v-expansion-panel class="panelTopBorder">
                        <v-expansion-panel-header>
                          Details
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          id="expPanelContent"
                          color="grey lighten-3"
                        >
                          <v-row class="py-2">
                            <v-col cols="12" sm="4" class="py-0">
                              <v-text-field
                                v-model="editedItem.title"
                                label="Assessment title"
                                :rules="formRules.title"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="3" class="py-0">
                              <v-select
                                v-model="editedItem.complexity"
                                label="Complexity"
                                :items="complexityLevels"
                                required
                                :rules="formRules.complexity"
                              ></v-select>
                            </v-col>
                            <v-col cols="4" sm="3" class="py-0">
                              <v-select
                                v-model="editedItem.fontFamily"
                                label="Font Family"
                                :items="fontFamilyItems"
                              ></v-select>
                            </v-col>
                            <v-col cols="4" sm="2" class="py-0">
                              <v-text-field
                                v-model="editedItem.totalMinutes"
                                label="Time in minutes"
                                :rules="formRules.minutes"
                                required
                                type="number"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                              <v-autocomplete
                                v-model="editedItem.school"
                                :items="syncedSchoolArray"
                                :item-text="schoolName"
                                label="School"
                                return-object
                                required
                                :rules="formRules.school"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                              <v-autocomplete
                                v-model="editedItem.subject"
                                :items="subjects"
                                :item-text="subjectName"
                                label="Subject"
                                return-object
                                required
                                :rules="formRules.subject"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" class="py-0">
                              <v-combobox
                                v-model="editedItem.instructions"
                                label="Instructions"
                                :rules="[instructionValidationRule]"
                                required
                                multiple
                                chips
                                deletable-chips
                                clearable
                              ></v-combobox>
                            </v-col>
                            <v-col cols="12" class="py-0">
                              <v-select
                                multiple
                                chips
                                v-model="editedItem.taxonomys"
                                :items="mergedTaxonomys"
                                :item-text="taxonomyText"
                                return-object
                                label="Taxonomy"
                                deletable-chips
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <div
                        v-if="
                          editedItem.questions &&
                          editedItem.questions.length > 0
                        "
                        style="width: 100%"
                        class="questionFieldPanelTopBorder"
                      >
                        <v-card-subtitle class="pb-0 pt-2">
                          <span class="text-h6">Questions</span>
                        </v-card-subtitle>

                        <draggable
                          v-model="editedItem.questions"
                          style="width: 100%"
                        >
                          <v-expansion-panel
                            v-for="(
                              questionObject, questionIndex
                            ) in editedItem.questions"
                            :key="questionIndex"
                          >
                            <v-expansion-panel-header class="pa-2">
                              <div class="d-flex align-center">
                                <v-icon class="mr-1">
                                  mdi-drag-vertical
                                </v-icon>
                                <div style="text-wrap: nowrap">
                                  <span
                                    >Question
                                    {{
                                      questionObject.number +
                                      (questionObject.title &&
                                      questionObject.title !== ""
                                        ? ":&nbsp;"
                                        : "")
                                    }}</span
                                  >
                                </div>
                                <div>
                                  <span>
                                    {{
                                      questionObject.title &&
                                      questionObject.title !== ""
                                        ? questionObject.title
                                        : ""
                                    }}
                                  </span>
                                </div>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content
                              id="expPanelContent"
                              color="grey lighten-3"
                            >
                              <v-row no-gutters>
                                <v-btn
                                  :color="
                                    isNewSection(questionIndex)
                                      ? 'secondary'
                                      : 'primary'
                                  "
                                  class="mr-6"
                                  @click="
                                    isNewSection(questionIndex)
                                      ? removeSection(questionIndex)
                                      : newSection(questionIndex)
                                  "
                                >
                                  {{
                                    isNewSection(questionIndex)
                                      ? "Not a Section"
                                      : "New Section?"
                                  }}
                                </v-btn>
                                <v-row
                                  v-if="isNewSection(questionIndex)"
                                  class="pt-2"
                                >
                                  <v-col cols="5" sm="3" class="py-0">
                                    <v-text-field
                                      v-model="questionObject.sectionNumber"
                                      label="Section No."
                                      required
                                      :rules="formRules.number"
                                      placeholder="eg. A"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="7" sm="9" class="py-0">
                                    <v-text-field
                                      v-model="questionObject.sectionTitle"
                                      label="Section Title"
                                      placeholder="Language and Grammer"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-row>
                              <v-row class="pb-2 mt-2">
                                <v-col cols="5" sm="3" md="2" class="py-0">
                                  <v-text-field
                                    v-model="questionObject.number"
                                    label="Question No."
                                    :rules="formRules.number"
                                    required
                                    placeholder="eg. 1"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="7" sm="5" md="6" class="py-0">
                                  <v-text-field
                                    v-model="questionObject.title"
                                    label="Question Title"
                                    placeholder="eg. Essay"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="2" md="2" class="py-0">
                                  <v-text-field
                                    v-model="questionObject.minutes"
                                    label="Time in minutes"
                                    type="number"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="2" md="2" class="py-0">
                                  <v-text-field
                                    v-model="questionObject.marks"
                                    @input="updateQuestionMarks"
                                    label="Question Marks"
                                    type="number"
                                    :rules="formRules.marks"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0 pb-3">
                                  <RichTextEditor
                                    v-model="questionObject.richText"
                                    s3Folder="Assessments"
                                  >
                                  </RichTextEditor>
                                </v-col>
                              </v-row>

                              <div
                                v-if="questionObject.fields.length > 0"
                                style="width: 100%"
                                class="questionFieldPanelTopBorder"
                              >
                                <v-card-title class="pa-0">
                                  <span class="text-h6">Fields</span>
                                </v-card-title>

                                <v-expansion-panels
                                  class="mb-3"
                                  style="justify-content: left"
                                  v-model="expFieldPanels"
                                >
                                  <draggable
                                    v-model="questionObject.fields"
                                    style="width: 100%"
                                  >
                                    <v-expansion-panel
                                      v-for="(
                                        formField, questionFieldIndex
                                      ) in questionObject.fields"
                                      :key="questionFieldIndex"
                                    >
                                      <v-expansion-panel-header class="pa-2">
                                        <div class="d-flex align-center">
                                          <v-icon class="mr-1">
                                            mdi-drag-vertical
                                          </v-icon>
                                          <div style="text-wrap: nowrap">
                                            <span
                                              >Field
                                              {{
                                                formField.number +
                                                (formField.question &&
                                                formField.question !== ""
                                                  ? ":&nbsp;"
                                                  : "")
                                              }}</span
                                            >
                                          </div>
                                          <div>
                                            <span>
                                              {{
                                                formField.question &&
                                                formField.question !== ""
                                                  ? formField.question
                                                  : ""
                                              }}</span
                                            >
                                          </div>
                                        </div>
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content
                                        id="expPanelContent"
                                        color="grey lighten-3"
                                      >
                                        <v-row class="pt-2 pb-3">
                                          <v-col cols="6" class="py-0">
                                            <v-text-field
                                              v-model="formField.number"
                                              label="Number"
                                              placeholder="eg. 1.1 or 1.a"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="6" class="py-0">
                                            <v-text-field
                                              v-model="formField.marks"
                                              label="Marks"
                                              type="number"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="12" class="py-0">
                                            <v-textarea
                                              v-model="formField.question"
                                              required
                                              rows="1"
                                              auto-grow
                                              :rules="formRules.required"
                                              label="Question"
                                            ></v-textarea>
                                          </v-col>
                                          <v-col cols="12" class="py-0">
                                            <span class="text-h6">Body</span>
                                            <div class="d-flex align-end">
                                              <div
                                                class="pr-3"
                                                style="width: calc(100% - 40px)"
                                              >
                                                <RichTextEditor
                                                  v-model="formField.richText"
                                                  s3Folder="Assessments"
                                                >
                                                </RichTextEditor>
                                              </div>

                                              <v-tooltip top>
                                                <template
                                                  v-slot:activator="{
                                                    on,
                                                    attrs,
                                                  }"
                                                >
                                                  <v-btn
                                                    color="red"
                                                    fab
                                                    small
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="
                                                      removeQuestionField(
                                                        questionIndex,
                                                        questionFieldIndex
                                                      )
                                                    "
                                                  >
                                                    <v-icon>mdi-delete</v-icon>
                                                  </v-btn>
                                                </template>
                                                <span>Delete Field</span>
                                              </v-tooltip>
                                            </div>
                                          </v-col>
                                        </v-row>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </draggable>
                                </v-expansion-panels>
                              </div>
                              <v-row no-gutters class="justify-center pb-2">
                                <v-btn
                                  color="primary"
                                  @click="addQuestionField(questionIndex)"
                                >
                                  Add Field
                                </v-btn>
                              </v-row>

                              <v-row no-gutters>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="red"
                                      v-bind="attrs"
                                      v-on="on"
                                      fab
                                      small
                                      icon
                                      @click="removeQuestion(questionIndex)"
                                    >
                                      <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Delete Question</span>
                                </v-tooltip>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </draggable>
                      </div>
                    </v-expansion-panels>

                    <v-card-actions class="justify-center pt-0 pb-1">
                      <v-btn color="primary" @click="addQuestion">
                        Add Question
                      </v-btn>
                    </v-card-actions>
                  </v-tab-item>
                  <v-tab-item>
                    <div class="pa-2">
                      <div
                        v-for="(
                          questionObject, questionIndex
                        ) in editedItem.questions"
                        :key="questionIndex"
                      >
                        <div v-if="isNewSection(questionIndex)">
                          <h3>
                            {{
                              headingValue("Section") +
                              " " +
                              questionObject.sectionNumber +
                              (questionObject.sectionTitle !== ""
                                ? ": " + questionObject.sectionTitle
                                : "")
                            }}
                          </h3>
                        </div>
                        <div class="d-flex">
                          <h3>
                            {{
                              headingValue("Question") +
                              " " +
                              questionObject.number +
                              (questionObject.title !== ""
                                ? ": " + questionObject.title
                                : "")
                            }}
                          </h3>
                          <v-spacer />
                          <div style="min-width: fit-content">
                            <h3>
                              (
                              {{
                                questionObject.marks +
                                " " +
                                headingValue("Marks")
                              }}
                              )
                            </h3>
                          </div>
                        </div>
                        <v-row
                          no-gutters
                          v-for="(
                            questionField, questionFieldIndex
                          ) in questionObject.fields"
                          :key="questionFieldIndex"
                        >
                          <div class="d-flex" style="width: 100%">
                            <div
                              v-if="questionField.number !== ''"
                              style="min-width: fit-content; text-align: right"
                            >
                              <span class="mr-2">{{
                                questionField.number
                              }}</span>
                            </div>
                            <div
                              class="d-flex flex-column mr-2"
                              style="width: -webkit-fill-available"
                            >
                              <span
                                v-if="
                                  questionField.question &&
                                  questionField.question !== ''
                                "
                                >{{ questionField.question }}</span
                              >
                            </div>
                            <div
                              v-if="questionField.marks"
                              style="min-width: fit-content"
                            >
                              <h3 style="line-height: 24px">
                                ( {{ questionField.marks }} )
                              </h3>
                            </div>
                          </div>
                          <v-textarea
                            class="pt-0 mt-0"
                            v-model="questionField.answer"
                            rows="1"
                            auto-grow
                          ></v-textarea>
                        </v-row>
                      </div>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="pa-0"
                :style="
                  screenWidth >= 960
                    ? 'overflow-y: auto; overflow-x: hidden; max-height: calc(100vh - 164px);'
                    : ''
                "
              >
                <div>
                  <v-card-title>
                    <span class="text-h5">Print Preview</span>
                  </v-card-title>
                  <v-card outlined class="mx-2 pa-2">
                    <AssessmentTemplatePrinterExample
                      v-if="dialogTab === 0"
                      :template="editedItem"
                    />
                    <AssessmentTemplateMemoPrinterExample
                      v-else
                      :template="editedItem"
                    />
                  </v-card>
                </div>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="close"> Cancel </v-btn>
              <v-btn :disabled="!valid" color="primary" @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete this template?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDelete">Cancel</v-btn>
          <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import { AssessmentTemplate, School, Subject, Taxonomy } from "@/models";
import _ from "lodash";
import AssessmentTemplatePrinterExample from "@/components/global-components/AssessmentTemplatePrinterExample.vue";
import AssessmentTemplateMemoPrinterExample from "@/components/global-components/AssessmentTemplateMemoPrinterExample.vue";
import RichTextEditor from "@/components/global-components/rich-text-editor/RichTextEditor.vue";
import draggable from "vuedraggable";

export default {
  name: "AdminAssessmentTemplates",
  components: {
    AssessmentTemplatePrinterExample,
    AssessmentTemplateMemoPrinterExample,
    RichTextEditor,
    draggable,
  },
  data: () => ({
    initiallyLoaded: false,
    expPanels: 1,
    expFieldPanels: 0,
    taxonomySubscription: null,
    assessmentTemplateSubscription: null,
    assessmentTemplateSubscriptionSynced: false,
    schoolSubscription: null,
    subjectSubscription: null,
    syncedTaxonomyArray: [],
    syncedAssessmentTemplateArray: [],
    syncedSchoolArray: [],
    syncedSubjectArray: [],
    subjects: [],
    valid: false,
    dialog: false,
    dialogTab: null,
    dialogTabItems: ["Questions", "Memorandum"],
    fontFamilyItems: ["Default", "Arial"],
    dialogDelete: false,
    sortBy: "title",
    sortDesc: false,
    search: "",
    headers: [],
    assessmentTemplates: [],
    editedIndex: -1,
    editedItem: {
      title: "",
      headings: [
        { label: "Grade", value: "Grade" },
        { label: "Date", value: "Date" },
        { label: "Term", value: "Term" },
        { label: "Total", value: "Total" },
        { label: "Time", value: "Time" },
        { label: "Minute", value: "Minute" },
        { label: "Minutes", value: "Minutes" },
        { label: "Hour", value: "Hour" },
        { label: "Hours", value: "Hours" },
        {
          label: "Information and Instructions",
          value: "Information and Instructions",
        },
        { label: "Section", value: "Section" },
        { label: "Sections", value: "Sections" },
        { label: "Question", value: "Question" },
        { label: "Questions", value: "Questions" },
        { label: "Marks", value: "Marks" },
        { label: "Award", value: "Award" },
      ],
      fontFamily: "Default",
      complexity: "",
      totalMarks: null,
      totalMinutes: null,
      questions: [
        // {
        //   number: "",
        //   title: "",
        //   // sectionNumber: "",
        //   // sectionTitle: "",
        //   minutes: null,
        //   marks: null,
        //   richText: "",
        //   fields: [
        //     {
        //       number: "",
        //       cognitiveLevel: null,
        //       marks: null,
        //       question: "",
        //       richText: "",
        //       answer: "",
        //     }
        //   ]
        // }
      ],
      instructions: [],
      taxonomys: [],
      school: null,
      subject: null,
      subjectID: "",
      schoolID: "",
    },
    defaultItem: {
      title: "",
      headings: [
        { label: "Grade", value: "Grade" },
        { label: "Date", value: "Date" },
        { label: "Term", value: "Term" },
        { label: "Total", value: "Total" },
        { label: "Time", value: "Time" },
        { label: "Minute", value: "Minute" },
        { label: "Minutes", value: "Minutes" },
        { label: "Hour", value: "Hour" },
        { label: "Hours", value: "Hours" },
        {
          label: "Information and Instructions",
          value: "Information and Instructions",
        },
        { label: "Section", value: "Section" },
        { label: "Sections", value: "Sections" },
        { label: "Question", value: "Question" },
        { label: "Questions", value: "Questions" },
        { label: "Marks", value: "Marks" },
        { label: "Award", value: "Award" },
      ],
      fontFamily: "Default",
      complexity: "",
      totalMarks: null,
      totalMinutes: null,
      questions: [],
      instructions: [],
      taxonomys: [],
      school: null,
      subject: null,
      subjectID: "",
      schoolID: "",
    },
    formRules: {
      required: [(v) => !!v || "This field is required"],
      name: [(v) => !!v || "Name is required"],
      type: [(v) => !!v || "Type is required"],
      label: [(v) => !!v || "Label is required"],
      title: [(v) => !!v || "Title is required"],
      subject: [(v) => !!v || "Subject is required"],
      school: [(v) => !!v || "School is required"],
      complexity: [(v) => !!v || "Complexity is required"],
      placeholder: [(v) => !!v || "Placeholder is required"],
      hint: [(v) => !!v || "Hint is required"],
      marks: [(v) => !!v || "Marks are required"],
      minutes: [(v) => !!v || "Time is required"],
      // difficulty: [(v) => !!v || "Difficulty is required"],
      number: [(v) => !!v || "A Number is required"],
    },
    formFieldTypes: ["Text", "Textarea", "Select", "Radio", "Checkbox"],
    complexityLevels: ["Simple", "Detailed"],
  }),

  async mounted() {
    try {
      this.headers = [
        { text: "Title", value: "title" },
        { text: "Complexity", value: "complexity" },
        { text: "Time", value: "totalMinutes" },
        { text: "Marks", value: "totalMarks" },
        { text: "School", value: "school.name" },
        {
          text: "Grade",
          value: "subject.grade",
          sort: (a, b) => this.customSortGrade(a, b),
        },
        { text: "Subject", value: "subject.subject" },
        { text: "Actions", value: "actions", sortable: false },
      ];

      await this.syncAssessmentTemplates();
      await this.syncTaxonomys();
      await this.syncSchools();
      await this.syncSubjects();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
    }),

    computedAssessmentTemplates() {
      if (this.subjects.length > 0 && this.syncedSchoolArray.length > 0) {
        this.assessmentTemplates.forEach((item) => {
          if (!item.subject) {
            const subject = _.find(this.subjects, { id: item.subjectID });
            if (item.subject !== subject)
              item.subject = subject || { grade: "", subject: "" };
          }

          if (!item.school) {
            const school = _.find(this.syncedSchoolArray, {
              id: item.schoolID,
            });
            if (item.school !== school) {
              item.school = school || {
                name: "",
                provinces: [],
                isGeneric: false,
              };
            }
          }
        });
      }

      return this.assessmentTemplates;
    },

    formFieldCognitiveLevels() {
      if (this.editedItem.taxonomys?.length > 0) {
        let cognitiveLevelsArray = [];
        this.editedItem.taxonomys.forEach((taxonomy) => {
          if (taxonomy?.cognitiveLevels)
            taxonomy.cognitiveLevels.forEach((cognitiveLevel) => {
              cognitiveLevelsArray.push(cognitiveLevel);
            });
        });
        return cognitiveLevelsArray;
      } else return [];
    },

    formTitle() {
      return this.editedIndex === -1 ? "New Template" : "Edit Template";
    },

    isNewSection() {
      return (questionIndex) => {
        const question = this.editedItem.questions[questionIndex];
        return question && question.sectionNumber !== undefined;
      };
    },

    headingValue() {
      return (param) => {
        if (this.editedItem.headings) {
          const foundItem = _.find(this.editedItem.headings, { label: param });
          return foundItem ? foundItem.value : "";
        } else return "";
      };
    },

    instructionValidationRule() {
      return (value) => {
        if (value.length < 1) {
          return "Instructions are required";
        }
        return true;
      };
    },

    // Computed property for the screen width
    screenWidth() {
      let width = this.$vuetify.breakpoint.width;
      return width;
    },

    mergedTaxonomys() {
      if (
        this.editedIndex > -1 &&
        this.assessmentTemplates[this.editedIndex]?.taxonomys
      ) {
        return _.unionBy(
          this.syncedTaxonomyArray,
          this.assessmentTemplates[this.editedIndex].taxonomys,
          "updatedAt"
        );
      } else return this.syncedTaxonomyArray;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    async valid(val) {
      if (!val && this.dialog) {
        await this.validateForm();
      }
    },
    async expPanels() {
      this.expFieldPanels = 0;
      await this.validateForm();
    },
  },

  methods: {
    filterDataTable(value, search, item) {
      let searches = search.trim().toLowerCase().split(" ");

      let stringified = JSON.stringify(item).toLowerCase();
      let found = 0;
      for (let s in searches) {
        if (stringified.indexOf(searches[s]) > -1) found++;
      }
      // console.log(found);
      return found === searches.length;
    },

    async validateForm() {
      setTimeout(() => {
        if (this.$refs.form) this.$refs.form.validate();
      }, 0);
    },

    updateQuestionMarks() {
      let newTotalMarks = 0;

      this.editedItem.questions.forEach((questionObj) => {
        if (typeof questionObj.marks !== "number") {
          newTotalMarks += questionObj.marks
            ? parseFloat(questionObj.marks)
            : 0;
        } else {
          newTotalMarks += questionObj.marks;
        }
      });

      this.editedItem.totalMarks = newTotalMarks ? newTotalMarks : 0;
    },

    formattedTotalTime(val) {
      const totalMinutes = typeof val !== "string" ? val : parseInt(val);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      if (hours === 0) {
        return `${minutes} Minute${minutes > 1 ? "s" : ""}`;
      } else if (minutes === 0) {
        return `${hours} Hour${hours > 1 ? "s" : ""}`;
      } else {
        return `${hours} Hour${hours > 1 ? "s" : ""} ${minutes} Minute${
          minutes > 1 ? "s" : ""
        }`;
      }
    },

    customSortGrade(a, b) {
      a = a === "R" ? -Infinity : parseInt(a, 10);
      b = b === "R" ? -Infinity : parseInt(b, 10);
      if (this.sortDesc) {
        // Switch the order for descending
        [a, b] = [b, a];
      }
      return a - b;
    },

    schoolName(item) {
      return `${item.name}`;
    },

    subjectName(item) {
      return `${"Grade: " + item.grade + ", Subject: " + item.subject}`;
    },

    taxonomyText(item) {
      if (item.cognitiveLevels) {
        let cogLevels =
          " (" +
          `${
            item.cognitiveLevels[0].label +
            ": " +
            item.cognitiveLevels[0].percentage +
            "%"
          }`;

        for (let i in item.cognitiveLevels) {
          if (i > 0) {
            cogLevels =
              cogLevels +
              ", " +
              item.cognitiveLevels[i].label +
              ": " +
              item.cognitiveLevels[i].percentage +
              "%";
          }
        }
        cogLevels = cogLevels + ")";
        return `${item.name + cogLevels}`;
      } else return `${item.name}`;
    },

    cognitiveLevelText(item) {
      const cogLevel = item.label + ": " + item.percentage + "%";
      return `${cogLevel}`;
    },

    async newSection(questionIndex) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.questions[questionIndex].sectionNumber = "";
        mutableObj.questions[questionIndex].sectionTitle = "";

        this.editedItem = Object.assign({}, mutableObj);

        await this.validateForm();
      } catch (error) {
        console.log(error);
      }
    },

    removeSection(questionIndex) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        delete mutableObj.questions[questionIndex].sectionNumber;
        delete mutableObj.questions[questionIndex].sectionTitle;

        this.editedItem = Object.assign({}, mutableObj);
      } catch (error) {
        console.log(error);
      }
    },

    async addQuestionField(questionIndex) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.questions[questionIndex].fields.push({
          number: "",
          cognitiveLevel: null,
          marks: null,
          question: "",
          richText: "",
          answer: "",
        });

        this.editedItem = Object.assign({}, mutableObj);

        await this.validateForm();
      } catch (error) {
        console.log(error);
      }
    },

    removeQuestionField(questionIndex, fieldIndex) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.questions[questionIndex].fields.splice(fieldIndex, 1);

        this.editedItem = Object.assign({}, mutableObj);
      } catch (error) {
        console.log(error);
      }
    },

    async addQuestion() {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.questions.push({
          number: "",
          title: "",
          // sectionNumber: "",
          // sectionTitle: "",
          minutes: null,
          marks: null,
          richText: "",
          fields: [],
          // subQuestions: []
        });

        this.editedItem = Object.assign({}, mutableObj);

        await this.validateForm();
      } catch (error) {
        console.log(error);
      }
    },

    removeQuestion(questionIndex) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.questions.splice(questionIndex, 1);

        this.editedItem = Object.assign({}, mutableObj);
      } catch (error) {
        console.log(error);
      }
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.editedIndex = -1;
      this.expPanels = 1;
      this.dialogTab = null;
      this.valid = false;
    },

    createItem() {
      this.resetForm();
      this.dialog = true;

      this.$nextTick(() => {
        if (this.$refs.form) this.$refs.form.validate();
      });
    },

    editItem(item) {
      this.editedIndex = this.assessmentTemplates.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (!this.editedItem.headings) {
        this.editedItem.headings = _.cloneDeep(this.defaultItem.headings);
      }
      if (!this.editedItem.fontFamily) {
        this.editedItem.fontFamily = _.cloneDeep(this.defaultItem.fontFamily);
      }

      this.editedItem.headings = this.editedItem.headings.filter(
        (item) => item.label !== "Points"
      );

      this.defaultItem.headings.forEach((defaultHeading) => {
        const existsInEdited = this.editedItem.headings.some(
          (editedHeading) => editedHeading.label === defaultHeading.label
        );

        if (!existsInEdited) {
          // Add the defaultHeading to this.editedItem.headings
          this.editedItem.headings.push(defaultHeading);
        }
      });

      this.dialog = true;

      this.$nextTick(() => {
        if (this.$refs.form) this.$refs.form.validate();
      });
    },

    duplicateItem(item) {
      this.editedItem = _.cloneDeep(item);

      if (!this.editedItem.headings) {
        this.editedItem.headings = _.cloneDeep(this.defaultItem.headings);
      }
      if (!this.editedItem.fontFamily) {
        this.editedItem.fontFamily = _.cloneDeep(this.defaultItem.fontFamily);
      }

      this.editedItem.headings = this.editedItem.headings.filter(
        (item) => item.label !== "Points"
      );

      this.defaultItem.headings.forEach((defaultHeading) => {
        const existsInEdited = this.editedItem.headings.some(
          (editedHeading) => editedHeading.label === defaultHeading.label
        );

        if (!existsInEdited) {
          // Add the defaultHeading to this.editedItem.headings
          this.editedItem.headings.push(defaultHeading);
        }
      });

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.assessmentTemplates.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await DataStore.delete(
        this.syncedAssessmentTemplateArray[this.editedIndex]
      );
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.resetForm();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = _.cloneDeep(this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update assessmentTemplate
        await this.updateAssessmentTemplate();
      } else {
        // Create new assessmentTemplate
        await this.createAssessmentTemplate();
      }
      this.$nextTick(() => {
        this.close();
      });
    },

    async createAssessmentTemplate() {
      try {
        // this.editedItem.totalMarks = 0;

        this.editedItem.questions.forEach((questionObj) => {
          questionObj.title = questionObj.title.trim();
          // this.editedItem.totalMarks += parseFloat(questionObj.marks);

          questionObj.fields.forEach((fieldObj) => {
            // this.editedItem.totalMarks += fieldObj.marks;

            if (
              fieldObj.type !== "Select" &&
              fieldObj.type !== "Radio" &&
              fieldObj.type !== "Checkbox"
            ) {
              fieldObj.items = [];
            }
          });
        });

        await DataStore.save(
          new AssessmentTemplate({
            title: this.editedItem.title.trim(),
            headings: this.editedItem.headings,
            fontFamily: this.editedItem.fontFamily,
            complexity: this.editedItem.complexity,
            totalMarks:
              typeof this.editedItem.totalMarks === "string"
                ? parseFloat(this.editedItem.totalMarks)
                : this.editedItem.totalMarks,
            totalMinutes: parseInt(this.editedItem.totalMinutes),
            questions: this.editedItem.questions,
            instructions: this.editedItem.instructions,
            taxonomys: this.editedItem.taxonomys,
            schoolID: this.editedItem.school.id,
            subjectID: this.editedItem.subject.id,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updateAssessmentTemplate() {
      try {
        // this.editedItem.totalMarks = 0;

        this.editedItem.questions.forEach((questionObj) => {
          questionObj.title = questionObj.title.trim();
          // this.editedItem.totalMarks += parseFloat(questionObj.marks);

          questionObj.fields.forEach((fieldObj) => {
            // this.editedItem.totalMarks += fieldObj.marks;

            if (
              fieldObj.type !== "Select" &&
              fieldObj.type !== "Radio" &&
              fieldObj.type !== "Checkbox"
            ) {
              fieldObj.items = [];
            }
          });
        });

        await DataStore.save(
          AssessmentTemplate.copyOf(
            _.find(this.syncedAssessmentTemplateArray, {
              id: this.editedItem.id,
            }),
            (updateModel) => {
              updateModel.title = this.editedItem.title.trim();
              updateModel.headings = this.editedItem.headings;
              updateModel.fontFamily = this.editedItem.fontFamily;
              updateModel.complexity = this.editedItem.complexity;
              updateModel.totalMarks =
                typeof this.editedItem.totalMarks === "string"
                  ? parseFloat(this.editedItem.totalMarks)
                  : this.editedItem.totalMarks;
              updateModel.totalMinutes = parseInt(this.editedItem.totalMinutes);
              updateModel.questions = this.editedItem.questions;
              updateModel.instructions = this.editedItem.instructions;
              updateModel.taxonomys = this.editedItem.taxonomys;
              updateModel.subjectID = this.editedItem.subject.id;
              updateModel.schoolID = this.editedItem.school.id;
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncTaxonomys() {
      try {
        this.taxonomySubscription = DataStore.observeQuery(Taxonomy).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedTaxonomyArray !== items) {
              this.syncedTaxonomyArray = items;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncAssessmentTemplates() {
      try {
        this.assessmentTemplateSubscription = DataStore.observeQuery(
          AssessmentTemplate
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              this.syncedAssessmentTemplateArray !== items
            ) {
              this.syncedAssessmentTemplateArray = items;

              this.assessmentTemplates = _.cloneDeep(items);

              this.assessmentTemplateSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.assessmentTemplateSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSchools() {
      try {
        this.schoolSubscription = DataStore.observeQuery(School).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedSchoolArray !== items
            ) {
              this.syncedSchoolArray = items;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSubjects() {
      try {
        this.subjectSubscription = DataStore.observeQuery(Subject).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedSubjectArray !== items
            ) {
              this.syncedSubjectArray = items;
              this.subjects = _.cloneDeep(
                _.sortBy(items, (item) => {
                  if (item.grade === "R") {
                    return -Infinity; // 'R' should appear first
                  } else if (item.grade === "1") {
                    return 0; // '1' should appear second
                  } else {
                    return parseInt(item.grade); // Sort the remaining numbers normally
                  }
                })
              );
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.taxonomySubscription) {
      this.taxonomySubscription.unsubscribe();
    }
    if (this.assessmentTemplateSubscription) {
      this.assessmentTemplateSubscription.unsubscribe();
    }
    if (this.schoolSubscription) {
      this.schoolSubscription.unsubscribe();
    }
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }
  },
};
</script>

<style>
.ProseMirror {
  min-height: 0px !important;
}

#expPanelContent > * {
  padding: 12px !important;
}

.panelTopBorder {
  border-radius: 4px !important;
}

.panelTopBorder::after {
  border-top: none !important;
}

.questionFieldPanelTopBorder .v-expansion-panel:first-child {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.questionFieldPanelTopBorder .v-expansion-panel--active + div {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.questionFieldPanelTopBorder .v-expansion-panel:last-child {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.questionFieldPanelTopBorder .v-expansion-panel--next-active {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.questionFieldPanelTopBorder .v-expansion-panel--active {
  border-radius: 4px !important;
}

.questionFieldPanelTopBorder .v-expansion-panel--active #expPanelContent {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    max-width: 100% !important;
    width: auto !important;
    min-width: 200px !important;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}
</style>
