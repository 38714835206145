<template>
  <v-container
    id="retrieveduserlogin"
    fluid
    style="
      display: flex;
      height: 90vh;
      justify-content: center;
      align-items: center;
    "
  >
    <!-- <v-card-title class="text-h5" v-if="logginIn">
      Logging in<LoadingDots />
    </v-card-title>
    <v-card-title class="text-h5" v-else-if="error > ''">
      <div v-html="error"></div>
    </v-card-title>
    <v-card-title class="text-h5" v-else-if="!logginIn">Done</v-card-title> -->
    <amplify-auth-container style="display: flex; width: min-content">
      <v-card max-width="min-content" class="d-flex flex-column" flat>
        <div
          style="
            position: absolute;
            align-self: end;
            z-index: 999;
            margin-top: 12px;
            margin-right: 12px;
          "
        >
          <!-- <helper topic="auth" /> -->
        </div>
        <amplify-authenticator>
          <amplify-sign-in
            slot="sign-in"
            hide-sign-up="false"
            :formFields.prop="formFields"
          >
            <div slot="amplify-form-section-footer" class="sign-in-form-footer">
              <slot name="footer">
                <slot name="secondary-footer-content">
                  <span
                    >No account?
                    <amplify-button
                      data-test="sign-in-create-account-link"
                      class="hydrated"
                      ><button class="anchor" type="button">
                        Create account
                      </button></amplify-button
                    ></span
                  >
                </slot>
                <div>
                  <slot name="primary-footer-content">
                    <amplify-button
                      data-test="sign-in-sign-in-button"
                      class="hydrated"
                    >
                      <button class="button" type="submit">
                        <span>Sign In</span>
                      </button>
                    </amplify-button>
                  </slot>
                </div>
              </slot>
            </div>
          </amplify-sign-in>
        </amplify-authenticator>
      </v-card>
    </amplify-auth-container>
  </v-container>
</template>

<script>
import "@aws-amplify/ui/dist/style.css";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
// import Helper from "@/components/global-components/Helper.vue";
// import LoadingDots from "@/components/global-components/LoadingDots.vue";
import store from "@/store";
import { API, Auth } from "aws-amplify";

export default {
  name: "RetrievedUserLogin",
  components: {
    // Helper,
    // LoadingDots,
  },
  data() {
    return {
      authState: undefined, // Holds the current authentication state
      unsubscribeAuth: undefined, // Function to unsubscribe from auth state changes
      formFields: [],
      logginIn: false,
      error: "",
    };
  },
  created() {
    // Subscribe to authentication state changes
    this.unsubscribeAuth = onAuthUIStateChange((authState) => {
      this.authState = authState;
    });
    // Extract email and password from the URL
    // const { email, password } = this.$route.params;
    // if (email && password) {
    //   console.log("Attempting auto-login with email:", email);
    //   this.autoFillLoginForm(email, password);
    // }
  },
  watch: {
    "$route.params": {
      handler(newVal) {
        if (newVal.email && newVal.password) {
          // console.log("Attempting auto-login with email:", newVal.email);
          this.autoFillLoginForm(newVal.email, newVal.password);
        }
      },
      immediate: true,
    },

    authState: {
      async handler() {
        // If user is signed in
        // console.log("authState:", this.authState);
        if (this.authState === AuthState.SignedIn) {
          try {
            await this.getUserGroups();

            // Redirect user
            // if (this.$route.query.redirect) {
            //   window.location.href = this.$route.query.redirect;
            // } else {
            window.location.href = "/app/products";
            // }
          } catch (error) {
            console.error("Error fetching user group: ", error);
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    // Method to autofill the login form
    autoFillLoginForm(email, password) {
      this.formFields = [
        {
          type: "email",
          label: "Email",
          placeholder: "Enter your email",
          value: email,
          // disabled: true, // Disable editing
        },
        {
          type: "password",
          label: "Password",
          placeholder: "Enter your password",
          value: password,
        },
      ];
      this.triggerLogin();
    },

    // Method to trigger login
    triggerLogin() {
      this.$nextTick(() => {
        const signInButton = this.$el.querySelector(
          "amplify-sign-in button[type='submit']"
        );

        // console.log("signInButton", signInButton);
        if (signInButton) {
          signInButton.click();
          console.log("Clicked sign in button");
        }
      });
    },

    // Method to handle auto-login
    // async autoLogin(email, password) {
    //   try {
    //     this.logginIn = true;
    //     const user = await Auth.signIn(email, password);
    //     // await this.getUserGroups();
    //     console.log("User signed in successfully:", user);
    //     this.error = "";
    //     this.logginIn = false;

    //     // Redirect user
    //     // window.location.href = "/app/products";
    //   } catch (error) {
    //     console.error("Error during auto-login:", error);
    //     this.error =
    //       'Password has expired. Please fetch your password here: <a href="/retrieveuserlogin">Retrieve Password</a>';
    //     this.logginIn = false;
    //   }
    // },

    async getUserGroups() {
      const user = await Auth.currentAuthenticatedUser().catch(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      });
      if (user) {
        await API.post("cognitolambdaapi", "/item/listUserGroups", {
          body: {
            userPoolId: user.pool.userPoolId,
            userName: user.username,
          },
        })
          .then(async (cognitoListUserGroupsResponse) => {
            if (cognitoListUserGroupsResponse.list) {
              const userGroups = JSON.parse(cognitoListUserGroupsResponse.list);
              store.commit("storeUserGroups", userGroups);
            }
          })
          .catch((error) => {
            console.log("error:", error);
          });
      }
    },
  },

  beforeDestroy() {
    // Unsubscribe from authentication state changes
    if (this.unsubscribeAuth) {
      this.unsubscribeAuth();
    }
  },
};
</script>

<style>
/* Customization of the AWS Amplify colors */
:root {
  --amplify-primary-color: #5ce1e6;
  --amplify-primary-tint: #4ab6b9;
  --amplify-primary-shade: #196264;
}

.password-requirements {
  font-size: 12px;
  color: #666;
  margin-top: 8px;
}

amplify-authenticator {
  margin-bottom: -20px;
  --container-display: contents !important;
}

amplify-section .section {
  border-radius: none !important;
}

amplify-section amplify-radio-button:nth-of-type(2) {
  display: none !important;
}
</style>
