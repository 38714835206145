<template>
  <!-- Wrapping v-sheet component for styling purposes -->
  <v-sheet id="contactform" class="grey lighten-1" dark tag="section" tile>
    <!-- Vertical padding -->
    <div class="py-12"></div>

    <!-- Main container for the form -->
    <v-container>
      <!-- Title for the form -->
      <h2
        class="display-2 font-weight-bold mb-3 text-uppercase text-center primary--text"
      >
        Contact Us
      </h2>

      <!-- Horizontal divider -->
      <v-responsive class="mx-auto mb-12" width="56">
        <v-divider></v-divider>
        <v-divider></v-divider>
      </v-responsive>

      <!-- The actual contact form -->
      <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
        <!-- Provide a light theme inside the form -->
        <v-theme-provider light>
          <v-row no-gutters>
            <!-- Input field for name -->
            <v-col cols="12">
              <v-text-field
                v-model="formfields.name"
                flat
                label="Name*"
                solo
                :rules="nameRules"
                required
              ></v-text-field>
            </v-col>

            <!-- Input field for email -->
            <v-col cols="12">
              <v-text-field
                v-model="formfields.email"
                flat
                label="Email*"
                solo
                :rules="emailRules"
                required
              ></v-text-field>
            </v-col>

            <!-- Input field for subject -->
            <v-col cols="12">
              <v-text-field
                v-model="formfields.subject"
                flat
                label="Subject*"
                solo
                :rules="subjectRules"
                required
              ></v-text-field>
            </v-col>

            <!-- Textarea for the message -->
            <v-col cols="12">
              <v-textarea
                v-model="formfields.message"
                flat
                label="Message*"
                solo
                :rules="messageRules"
                required
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="text-caption">
              This site is protected by reCAPTCHA and the Google
              <a target="_blank" href="https://policies.google.com/privacy">
                &nbsp;Privacy Policy&nbsp;
              </a>
              and
              <a target="_blank" href="https://policies.google.com/terms">
                &nbsp;Terms of Service&nbsp;
              </a>
              apply.
            </v-col>
            <!-- Submit button -->
            <v-col class="mx-auto" cols="auto">
              <v-btn
                :disabled="!valid || submitted"
                type="submit"
                x-large
                color="primary"
                :loading="submitting"
              >
                {{ submitted ? "Thank You" : "Submit" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-theme-provider>
      </v-form>
    </v-container>

    <!-- Vertical padding -->
    <div class="py-12"></div>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue";
import { API } from "aws-amplify";

export default Vue.extend({
  name: "ContactForm", // Name of the component
  data: () => ({
    // Data properties
    valid: true, // Form validation status
    submitting: false, // Is form currently submitting
    submitted: false, // Has form been submitted
    // Form fields
    formfields: {
      name: "",
      email: "",
      subject: "",
      message: "",
      recaptchaToken: "",
    },
    // Validation rules for the name field
    nameRules: [(v: string) => !!v || "Name is required"],
    // Validation rules for the email field
    emailRules: [
      (v: string) => !!v || "Email is required",
      (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    // Validation rules for the subject field
    subjectRules: [(v: string) => !!v || "Subject is required"],
    // Validation rules for the message field
    messageRules: [(v: string) => !!v || "Message is required"],
  }),
  methods: {
    // Method that handles form submission
    async onSubmit() {
      // Retrieve reCAPTCHA token
      const token = await this.$recaptcha("submit");

      // Assign reCAPTCHA token to formfields
      this.formfields.recaptchaToken = token;
      // Set submitting to true, indicating the form is being submitted
      this.submitting = true;

      // Send the form data to the API for processing
      await API.post("contactformapi", "/submit", {
        body: this.formfields,
      })
        .then((response) => {
          // Handle successful response from the API
          this.submitting = false; // Set submitting to false, indicating the form has been submitted
          this.submitted = response.success; // Update submitted status based on API response
        })
        .catch((error) => {
          // Log any errors
          console.error(error);
        });
    },
  },
});
</script>
