<template>
  <div id="pagenotfound">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6" offset-md="3">
          <v-card class="elevation-12 text-center">
            <v-card-title> {{ $i18n.t("404.title") }} </v-card-title>
            <v-card-text>
              <p>
                {{ $i18n.t("404.message") }}
              </p>
              <v-btn color="primary" to="/app/profile">
                {{ $i18n.t("404.button") }}</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
export default {
  name: "PageNotFoundView",
  data: () => ({}),
};
</script>
