<template>
  <div id="about">
    <section id="about-us">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">ABOUT US</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider></v-divider>

          <v-divider></v-divider>
        </v-responsive>
        <h3 class="font-weight-bold mb-3">
          Experience the Future of Education
        </h3>

        <v-responsive class="mx-auto font-weight-light mb-8" max-width="720">
          Welcome to Juffrou Anri, where innovation meets education. We are a
          passionate team of educators dedicated to revolutionizing teaching and
          learning. Our mission is to empower educators across South Africa with
          the tools and resources they need to create engaging, effective, and
          personalized learning experiences.
        </v-responsive>

        <!-- <v-avatar class="pa-3 elevation-12 mb-12" size="158">
          <v-img :src="require('@/assets/logo-v2-square.svg')"></v-img>
        </v-avatar> -->
      </v-container>
    </section>
    <section id="our-vision">
      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">Our Vision</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive class="mx-auto font-weight-light mb-8" max-width="720">
          At Juffrou Anri, we have a vision: to redefine education through
          innovative technology. We believe every learner deserves an
          exceptional education, and every educator should have the tools to
          make it possible. Our platform is designed to bridge the gap between
          traditional teaching methods and the limitless possibilities of modern
          technology.
        </v-responsive>

        <!-- <v-avatar class="pa-3 elevation-12 mb-12" size="158">
          <v-img :src="require('@/assets/logo-v2-square.svg')"></v-img>
        </v-avatar> -->
      </v-container>
    </section>

    <section id="what-we-believe">
      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">What We Believe</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive
          class="text-left mx-auto font-weight-light mb-8"
          max-width="720"
        >
          <ul>
            <li>
              <strong>Empowerment:</strong> We believe in empowering educators
              with the latest technology to inspire the next generation of
              leaders.
            </li>
            <li>
              <strong>Innovation:</strong> We're dedicated to staying at the
              forefront of educational technology, continuously improving our
              platforms to meet your evolving needs.
            </li>
            <li>
              <strong>Simplicity:</strong> Our user-friendly interfaces save
              educators time, making their work more efficient and enjoyable.
            </li>
            <li>
              <strong>Community:</strong> We're building a supportive community
              of educators where ideas are shared, collaboration thrives, and
              learning never stops.
            </li>
          </ul>
        </v-responsive>

        <v-avatar class="pa-3 elevation-12 mb-12" size="158">
          <v-img contain :src="require('@/assets/logo-v2-square.svg')"></v-img>
        </v-avatar>
      </v-container>
    </section>

    <section id="i-am-miss-anri" style="background-color: #ffa9b8">
      <div class="py-12"></div>
      <v-container class="text-center">
        <h2 class="display-2 mb-3">Hello</h2>
        <h2 class="display-2 font-weight-bold mb-3">I AM MISS ANRI</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider></v-divider>

          <v-divider></v-divider>
        </v-responsive>
        <v-row>
          <v-col>
            <v-responsive
              class="text-left mx-auto font-weight-light mb-8 px-3"
              max-width="720"
            >
              <p>
                Just after I graduated from the Cape Peninsula University of
                Technology in South Africa, the reality of COVID-19 and the
                lockdowns created a unique opportunity. During the pandemic, I
                identified an urgent need: teachers and learners lacked
                resources that matched the atp.
              </p>
              <p>
                Driven by my deep love of teaching, I took on the challenge and
                developed my first product, a Life Skills Grade 4 workbook. The
                demand for these resources grew rapidly. While working as a
                substitute teacher for a few months, it became clear that my
                heart not only belonged to the classroom, but also to this
                business. With big dreams and determination, I decided to give
                it my all—not just for extra income, but to make Juffrou Anri
                the largest educational resource business in South Africa.
              </p>
              <p>
                In 2021 we started building an incredible team. We are growing
                daily, driven by a massive passion for education and learning.
              </p>
              <p>
                We understand the mental health challenges teachers face,
                especially in these challenging times, and we're here to help.
                Our dream has always been big—to make an impact and serve as a
                pillar of support for teachers and parents across the country.
                For us it is much more than just a business!
              </p>
              <p>
                On January 1, 2024, we launched Juffrou Anri's new website,
                which aims to revolutionize teaching through technology.
                Inspired by my immeasurable love for teaching, we are focused on
                being there for teachers and parents every step of the way.
              </p>
            </v-responsive>
          </v-col>
          <v-col>
            <v-responsive class="mx-auto px-3" max-width="500">
              <v-avatar class="elevation-12 mb-12" size="100%">
                <v-img :src="require('@/assets/team/AnriDempers.png')"> </v-img>
              </v-avatar>
            </v-responsive>
          </v-col>
        </v-row>
      </v-container>
      <div class="py-12"></div>
    </section>
  </div>
</template>

<script lang="ts">
export default {
  name: "AboutView",
  data: () => ({}),
};
</script>
