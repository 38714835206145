<template>
  <div id="teachercommunications">
    <div class="px-3">
      <masonry
        :gutter="{ default: '16px', 700: '16px' }"
        :cols="{ default: 3, 1000: 3, 700: 2, 500: 1 }"
      >
        <CommunicationCard
          v-for="(communication, index) in syncedCommunicationArray"
          :key="'communication-' + index"
          :body="communication.body"
          :subject="communication.subject"
          :videoSrc="communication.video"
          :images="communication.images"
          :show="communication.show"
        ></CommunicationCard>
      </masonry>
    </div>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card class="overflow-x-hidden overflow-y-auto pa-3" max-height="90vh">
        <v-form ref="form">
          <v-card-title>
            <span class="text-h5"
              >{{ $i18n.t("actions.view") + " " + $i18n.t("headings.order") }}
              <!-- <helper topic="classesedit" /> -->
            </span>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12"> </v-col>
            </v-row>
          </v-card-text>

          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close">
              {{ $i18n.t("actions.close") }}
            </v-btn>
          </v-card-actions> -->
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import { DataStore, SortDirection } from "aws-amplify";
import { Communication } from "@/models";
import CommunicationCard from "@/components/global-components/CommunicationCard.vue";
// import Helper from "@/components/global-components/Helper.vue";

export default {
  name: "TeacherCommunications",
  components: {
    // Helper,
    CommunicationCard,
  },
  data: () => ({
    initiallyLoaded: false,
    dialog: false,
    editedItem: {},
    defaultItem: {},

    syncedCommunicationArray: [],
    communicationSubscription: null,
    communicationSubscriptionQueried: false,
  }),

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
      syncedTeacherProfileModel: (state) => state.syncedTeacherProfileModel,
    }),
  },

  async mounted() {
    try {
      document.addEventListener(
        "queryCommunications",
        this.queryCommunications
      );
      // await this.queryCommunications();
    } catch (error) {
      console.log(error);
    }
    // finally {
    //   if (!this.initiallyLoaded) {
    //     this.initiallyLoaded = true;
    //   }
    // }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    syncedTeacherProfileModel: {
      async handler(newVal) {
        if (newVal?.id && !this.initiallyLoaded) {
          await this.queryCommunications();
          this.initiallyLoaded = true;
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return "";
      return new Date(date).toLocaleString();
    },

    viewItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    async queryCommunications() {
      try {
        const validGrades =
          this.syncedTeacherProfileModel?.payfastSubscriptionPackage?.grades ||
          [];
        // console.log("validGrades: ", validGrades);

        // this.communicationSubscription = DataStore.observeQuery(
        //   Communication
        //   // (comunication) =>
        //   // comunication.or((comunication) => [
        //   // comunication.or((c) =>
        //   //   (grades ? grades : []).map((grade) => c.sendTo("contains", 6))
        //   // )
        //   // comunication.sendTo("contains", "ALL"),
        //   // ])
        // ).subscribe(
        //   async (snapshot) => {
        //     const { items, isSynced } = snapshot;
        //     if (isSynced && items && this.syncedCommunicationArray !== items) {
        //       this.syncedCommunicationArray = items;

        //       console.log("Communications synced: ", items);

        //       this.communicationSubscriptionSynced = true;
        //     }
        //   },
        //   (error) => {
        //     console.log(error);
        //     this.communicationSubscriptionSynced = true;
        //   }
        // );

        // const search = this.productsSearch.split(" ");

        const currentDateTime = new Date().toISOString();

        const queriedCommunications = await DataStore.query(
          Communication,
          (communication) =>
            communication.and((c) => [
              // c.or((c) => [
              //   c.eCommerceProduct("eq", true),
              //   c.subscriptionProduct("eq", true),
              // ]),
              // c.thumbnails("gt", []),
              communication.publishDate("lt", currentDateTime),
              communication.or((communication) => [
                communication.sendTo("contains", "ALL"),
                communication.sendTo("contains", "General"),
                communication.or((c) =>
                  validGrades.map((grade) => c.sendTo("contains", grade))
                ),
              ]),
            ]),
          {
            // limit: this.limit,
            sort: (s) =>
              s
                .publishDate(SortDirection.DESCENDING)
                .subject(SortDirection.DESCENDING),
          }
        );

        this.syncedCommunicationArray = queriedCommunications;

        // console.log("queriedCommunications", queriedCommunications);
      } catch (error) {
        console.log(error);
      } finally {
        this.communicationSubscriptionQueried = true;
      }
    },
  },

  beforeDestroy() {
    document.removeEventListener(
      "queryCommunications",
      this.queryCommunications
    );
    if (this.communicationSubscription) {
      this.communicationSubscription.unsubscribe();
    }
  },
};
</script>
