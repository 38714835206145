import Vue from "vue";
import Vuetify from "vuetify/lib";
import VueI18n from "vue-i18n";

// import af from 'vuetify/src/locale/af'
// import en from 'vuetify/src/locale/en'
// import af from 'vuetify/lib/locale/af'
import locales from "@/plugins/vuetify/locales/locales";

Vue.use(Vuetify);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "af",
  messages: locales,
});

const vuetify = new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
    // locales: { af, en },
    // current: 'en',
  },
  theme: {
    themes: {
      light: {
        primary: "#ff0082", //#ffa9b8
        secondary: "#ffa9b8",
        // accent: '#ff9800',
        // error: '#ff5722',
        // warning: '#ffc107',
        // info: '#607d8b',
        // success: '#03a9f4'
      },
      dark: {
        primary: "#ffa9b8", // your primary color
        secondary: "#fae472", // your secondary color
        //   accent: '#YourAccentColor', // your generated accent color
        //   info: '#YourInfoColor', // your generated info color
        //   warning: '#YourWarningColor', // your generated warning color
        //   error: '#YourErrorColor', // your generated error color
        //   success: '#YourSuccessColor' // your generated success color
      },
    },
  },
});

export { vuetify, i18n };
