<template>
  <div id="meet-the-team">
    <section id="about-us">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">
          {{ $i18n.t("headings.meetTheTeam") }}
        </h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row class="pt-6" justify="center">
          <v-col
            v-for="({ avatar, name, role }, i) in members"
            :key="i"
            cols="12"
            md="4"
          >
            <v-avatar class="elevation-12 mb-12" size="300">
              <v-img :src="avatar"></v-img>
            </v-avatar>
            <h2 class="font-weight-bold">{{ name }}</h2>
            {{ role }}
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>
  </div>
</template>

<script lang="ts">
export default {
  name: "MeetTheTeamView",
  data: () => ({
    members: [
      {
        avatar: require("@/assets/team/AnriDempers.png"),
        name: "Anri Dempers",
        role: "Founder & Owner",
      },
      {
        avatar: require("@/assets/team/NadineMoffatt.png"),
        name: "Nadine Moffatt",
        role: "Marketing & Brand Manager",
      },
      {
        avatar: require("@/assets/team/NatashaCoetzee.png"),
        name: "Natasha Coetzee",
        role: "Educational Resource Developer Specialist",
      },
      {
        avatar: require("@/assets/team/ClarisaVanZyl.png"),
        name: "Clarisa Van Zyl",
        role: "Educational Resource Developer Specialist",
      },
      {
        avatar: require("@/assets/team/IngeWessels.png"),
        name: "Inge Wessels",
        role: "Quality Controller Intermediate Phase",
      },
      {
        avatar: require("@/assets/team/NizeViveiros.png"),
        name: "Nize Viveiros",
        role: "Quality Controller Foundation Phase",
      },
      {
        avatar: require("@/assets/team/KarlaLoubser.png"),
        name: "Karla Loubser",
        role: "Content Creator",
      },
      {
        avatar: require("@/assets/team/MarinaSaayman.png"),
        name: "Marina Saayman",
        role: "Administrative Officer",
      },
      {
        avatar: require("@/assets/team/JohanDuPreez.png"),
        name: "Johan Du Preez",
        role: "Full Stack Developer",
      },
      {
        avatar: require("@/assets/team/ChristoDuPreez.png"),
        name: "Christo Du Preez",
        role: "Lead Developer",
      },
    ],
  }),
};
</script>
