/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "adminlambdaapi",
            "endpoint": "https://gv5xgznaq8.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        },
        {
            "name": "cognitolambdaapi",
            "endpoint": "https://37ftful2q8.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        },
        {
            "name": "contactformapi",
            "endpoint": "https://s0m59cv318.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        },
        {
            "name": "payfastsubscriptionapi",
            "endpoint": "https://yfoov0qf5j.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        },
        {
            "name": "productcontentapi",
            "endpoint": "https://j6gpwf4czf.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        },
        {
            "name": "shippingapi",
            "endpoint": "https://9wk04o66a8.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://gmvw276hnvhopebaxbm4wuqgcy.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "eu-west-2:f404c453-f126-48d1-b9ce-07e6b3fc5ad3",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_tzOVzkMIc",
    "aws_user_pools_web_client_id": "1oe7iqs8rk2alu1ogbqvk9m127",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "missanriscn-storage-c4dd76e095706-production",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
