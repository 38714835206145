<template>
  <div id="subjecttemplates">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4"> Lessons </v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-4"
          @click="createItem"
          :disabled="offline"
        >
          New Lesson
        </v-btn>
      </v-row>
      <v-card-title
        ><v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="search"
              :items="syncedSchoolArray"
              :item-text="schoolName"
              label="School"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="headers"
          :items="computedSubjectTemplates"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          :search="search"
          :custom-filter="filterDataTable"
          :loading="
            !subjectTemplateSubscriptionSynced &&
            computedSubjectTemplates?.length === 0
          "
          loading-text="Loading Lessons..."
          no-data-text="No Lessons found"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="duplicateItem(item)"
              :disabled="offline"
            >
              mdi-content-copy
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              :disabled="offline"
            >
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" :disabled="offline">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog persistent v-model="dialog" v-if="dialog">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="save">
          <v-row
            no-gutters
            :style="
              screenWidth >= 960 ? 'overflow-y: hidden;' : 'overflow-y: auto;'
            "
            style="max-height: calc(90vh - 52px)"
          >
            <v-col
              cols="12"
              md="6"
              class="pa-0"
              :style="
                screenWidth >= 960
                  ? 'overflow-y: auto; overflow-x: hidden; max-height: calc(90vh - 52px);'
                  : ''
              "
            >
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-expansion-panels
                class="px-2 mb-3"
                style="justify-content: left"
                v-model="expPanels"
              >
                <v-card-subtitle class="pb-0 pt-2">
                  <span class="text-h6">Headings</span>
                </v-card-subtitle>
                <v-expansion-panel class="panelTopBorder">
                  <v-expansion-panel-header>
                    Change Defaults
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    id="expPanelContent"
                    color="grey lighten-3"
                  >
                    <v-row class="py-2">
                      <v-col
                        cols="12"
                        sm="6"
                        lg="4"
                        class="py-0"
                        v-for="(heading, headingIndex) in editedItem.headings"
                        :key="headingIndex"
                      >
                        <v-text-field
                          v-model="heading.value"
                          :label="heading.label"
                          required
                          :rules="formRules.required"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-card-subtitle class="pb-0 pt-2">
                  <span class="text-h6">Lesson</span>
                </v-card-subtitle>
                <v-expansion-panel class="panelTopBorder">
                  <v-expansion-panel-header> Details </v-expansion-panel-header>
                  <v-expansion-panel-content
                    id="expPanelContent"
                    color="grey lighten-3"
                  >
                    <v-row class="py-2">
                      <v-col cols="12" sm="6" class="py-0">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name"
                          :rules="formRules.name"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0">
                        <v-autocomplete
                          v-model="editedItem.subject"
                          :items="subjects"
                          :item-text="subjectName"
                          label="Subject"
                          return-object
                          required
                          :rules="formRules.subject"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" class="py-0">
                        <v-autocomplete
                          v-model="editedItem.school"
                          :items="syncedSchoolArray"
                          :item-text="schoolName"
                          label="School"
                          return-object
                          required
                          :rules="formRules.school"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4" sm="3" class="py-0">
                        <v-select
                          v-model="editedItem.complexity"
                          label="Complexity"
                          :items="complexityLevels"
                          required
                          :rules="formRules.complexity"
                        ></v-select>
                      </v-col>
                      <v-col cols="4" sm="3" class="py-0">
                        <v-select
                          v-model="editedItem.fontFamily"
                          label="Font Family"
                          :items="fontFamilyItems"
                        ></v-select>
                      </v-col>
                      <!-- <v-col cols="4" sm="2" class="py-0">
                        <v-text-field v-model="editedItem.minutes" label="Time in minutes" :rules="formRules.minutes"
                          required type="number"></v-text-field>
                      </v-col> -->
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <div
                  v-if="editedItem.template?.length > 0"
                  class="plannerPanelTopBorder"
                  style="width: 100%"
                >
                  <v-card-subtitle class="pb-0 pt-2">
                    <span class="text-h6">Planner</span>
                  </v-card-subtitle>
                  <draggable v-model="editedItem.template" style="width: 100%">
                    <v-expansion-panel
                      v-for="(templateObject, i) in editedItem.template"
                      :key="i"
                    >
                      <v-expansion-panel-header class="pa-2">
                        <div class="d-flex align-center">
                          <v-icon class="mr-1"> mdi-drag-vertical </v-icon>
                          <div style="text-wrap: nowrap">
                            <span
                              >Step
                              {{
                                i +
                                1 +
                                (templateObject.name &&
                                templateObject.name !== ""
                                  ? ":&nbsp;"
                                  : "")
                              }}</span
                            >
                          </div>
                          <div>
                            <span>
                              {{
                                templateObject.name &&
                                templateObject.name !== ""
                                  ? templateObject.name
                                  : ""
                              }}
                            </span>
                          </div>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content
                        id="expPanelContent"
                        color="grey lighten-3"
                      >
                        <v-row class="pa-3">
                          <v-col cols="12" class="py-0">
                            <v-text-field
                              v-model="templateObject.name"
                              label="Title"
                              :rules="formRules.title"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <div
                          v-if="templateObject.fields.length > 0"
                          style="width: 100%"
                          class="plannerPanelTopBorder"
                        >
                          <v-card-title class="pa-0">
                            <span class="text-h6">Fields</span>
                          </v-card-title>

                          <v-expansion-panels
                            class="mb-3"
                            style="justify-content: left"
                            v-model="expFieldPanels"
                          >
                            <draggable
                              v-model="templateObject.fields"
                              style="width: 100%"
                            >
                              <v-expansion-panel
                                v-for="(
                                  formField, index
                                ) in templateObject.fields"
                                :key="index"
                              >
                                <v-expansion-panel-header class="pa-2">
                                  <div class="d-flex align-center">
                                    <v-icon class="mr-1">
                                      mdi-drag-vertical
                                    </v-icon>
                                    <div style="text-wrap: nowrap">
                                      <span
                                        >Field{{
                                          formField.label &&
                                          formField.label !== ""
                                            ? ":&nbsp;"
                                            : ""
                                        }}</span
                                      >
                                    </div>
                                    <div>
                                      <span>
                                        {{
                                          formField.label &&
                                          formField.label !== ""
                                            ? formField.label
                                            : ""
                                        }}</span
                                      >
                                    </div>
                                  </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content
                                  id="expPanelContent"
                                  color="grey lighten-3"
                                >
                                  <v-row class="pt-3">
                                    <v-col cols="12" sm="6" md="4" class="py-0">
                                      <v-text-field
                                        v-model="formField.label"
                                        label="Label"
                                        :rules="formRules.label"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4" class="py-0">
                                      <v-select
                                        v-model="formField.type"
                                        label="Type"
                                        :rules="formRules.type"
                                        required
                                        :items="formFieldTypes"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4" class="py-0">
                                      <v-text-field
                                        v-model="formField.placeholder"
                                        label="Placeholder"
                                        :rules="formRules.placeholder"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4" class="py-0">
                                      <v-text-field
                                        v-model="formField.hint"
                                        label="Hint"
                                        :rules="formRules.hint"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="6" md="4" class="py-0">
                                      <v-text-field v-model="formField.difficulty" label="Difficulty"
                                        type="number"></v-text-field>
                                    </v-col> -->
                                    <v-col
                                      v-if="
                                        formField.type === 'Select' ||
                                        formField.type === 'Multi Select' ||
                                        formField.type === 'Radio' ||
                                        formField.type === 'Checkbox'
                                      "
                                      cols="12"
                                      class="py-0"
                                    >
                                      <v-combobox
                                        v-model="formField.items"
                                        :label="
                                          formField.type === 'Select' ||
                                          formField.type === 'Multi Select'
                                            ? 'Select Values'
                                            : formField.type === 'Radio'
                                            ? 'Radio Values'
                                            : formField.type === 'Checkbox'
                                            ? 'Checkbox Values'
                                            : ''
                                        "
                                        :error-messages="
                                          comboboxErrors(
                                            formField.items,
                                            formField.type
                                          )
                                        "
                                        required
                                        multiple
                                        chips
                                        deletable-chips
                                        clearable
                                        lazy-validation
                                      ></v-combobox>
                                    </v-col>
                                  </v-row>
                                  <v-row no-gutters>
                                    <v-spacer />
                                    <v-tooltip top>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          color="red"
                                          fab
                                          small
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="removeField(index, i)"
                                        >
                                          <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Delete Field</span>
                                    </v-tooltip>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </draggable>
                          </v-expansion-panels>
                        </div>
                        <v-row no-gutters>
                          <v-btn color="primary" @click="addField(i)">
                            Add Field
                          </v-btn>
                          <v-spacer />
                          <v-btn
                            color="secondary"
                            class="black--text"
                            @click="removeTemplateObject(i)"
                          >
                            Remove Step
                          </v-btn>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </draggable>
                </div>
              </v-expansion-panels>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pa-0"
              :style="
                screenWidth >= 960
                  ? 'overflow-y: auto; overflow-x: hidden; max-height: calc(90vh - 52px);'
                  : ''
              "
            >
              <div>
                <v-card-title>
                  <span class="text-h5">Print Preview</span>
                </v-card-title>
                <v-card outlined class="mx-2 pa-2">
                  <LessonTemplatePrinterExample :template="editedItem" />
                </v-card>
              </div>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-btn color="primary" @click="addTemplateObject"> Add Step </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close"> Cancel </v-btn>
            <v-btn :disabled="!valid" color="primary" @click="save">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete this template?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDelete">Cancel</v-btn>
          <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import { SubjectTemplate, School, Subject } from "@/models";
import LessonTemplatePrinterExample from "@/components/global-components/LessonTemplatePrinterExample.vue";
import _ from "lodash";
import draggable from "vuedraggable";

export default {
  name: "AdminSubjectTemplates",

  components: {
    LessonTemplatePrinterExample,
    draggable,
  },

  data: () => ({
    initiallyLoaded: false,
    expPanels: 1,
    expFieldPanels: 0,
    subjectTemplateSubscription: null,
    subjectTemplateSubscriptionSynced: false,
    schoolSubscription: null,
    subjectSubscription: null,
    syncedSubjectTemplateArray: [],
    syncedSchoolArray: [],
    syncedSubjectArray: [],
    subjects: [],
    valid: false,
    dialog: false,
    dialogDelete: false,
    sortBy: "name",
    sortDesc: false,
    search: "",
    headers: [
      // { text: "Name", value: "name" },
      // { text: "Complexity", value: "complexity" },
      // { text: "Minutes", value: "minutes" },
      // { text: "School", value: "school.name" },
      // { text: "Grade", value: "subject.grade" },
      // { text: "Subject", value: "subject.subject" },
      // { text: "Actions", value: "actions", sortable: false },
    ],
    subjectTemplates: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      headings: [
        { label: "Grade", value: "Grade" },
        { label: "Date", value: "Date" },
        { label: "Term", value: "Term" },
        { label: "Total", value: "Total" },
        { label: "Time", value: "Time" },
        // { label: 'Difficulty', value: 'Difficulty' },
      ],
      fontFamily: "Default",
      complexity: "",
      totalPoints: null,
      minutes: null,
      template: [
        // {
        //   name: "",
        //   fields: [
        //     {
        //       type: "",
        //       label: "",
        //       value: "",
        //       hint: "",
        //       placeholder: "",
        //       difficulty: null,
        //       points: null,
        //       items: []
        //     }
        //   ]
        // }
      ],
      school: null,
      subject: null,
      subjectID: "",
      schoolID: "",
    },
    defaultItem: {
      name: "",
      headings: [
        { label: "Grade", value: "Grade" },
        { label: "Date", value: "Date" },
        { label: "Term", value: "Term" },
        { label: "Total", value: "Total" },
        { label: "Time", value: "Time" },
        // { label: 'Difficulty', value: 'Difficulty' },
      ],
      fontFamily: "Default",
      complexity: "",
      totalPoints: null,
      minutes: null,
      template: [],
      school: null,
      subject: null,
      subjectID: "",
      schoolID: "",
    },
    formRules: {
      required: [(v) => !!v || "This field is required"],
      name: [(v) => !!v || "Name is required"],
      type: [(v) => !!v || "Type is required"],
      label: [(v) => !!v || "Label is required"],
      title: [(v) => !!v || "Title is required"],
      subject: [(v) => !!v || "Subject is required"],
      complexity: [(v) => !!v || "Complexity is required"],
      school: [(v) => !!v || "School is required"],
      placeholder: [(v) => !!v || "Placeholder is required"],
      hint: [(v) => !!v || "Hint is required"],
      points: [(v) => !!v || "Points are required"],
      minutes: [(v) => !!v || "Time is required"],
      difficulty: [(v) => !!v || "Difficulty is required"],
    },
    formFieldTypes: [
      "Text",
      "Textarea",
      "Select",
      "Multi Select",
      "Radio",
      "Checkbox",
    ],
    templateTypes: ["Lesson"],
    complexityLevels: ["Simple", "Detailed"],
    fontFamilyItems: ["Default", "Arial"],
  }),

  async mounted() {
    try {
      this.headers = [
        { text: "Name", value: "name" },
        // { text: "Type", value: "type" },
        { text: "Complexity", value: "complexity" },
        // { text: "Minutes", value: "minutes" },
        { text: "School", value: "school.name" },
        {
          text: "Grade",
          value: "subject.grade",
          sort: (a, b) => this.customSortGrade(a, b),
        },
        { text: "Subject", value: "subject.subject" },
        { text: "Actions", value: "actions", sortable: false },
      ];

      // Sync the SubjectTemplates from the datastore
      await this.syncSubjectTemplates();
      // Sync the Schools from the datastore
      await this.syncSchools();
      // Sync the Subjects from the datastore
      await this.syncSubjects();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
    }),
    computedSubjectTemplates() {
      if (this.subjects.length > 0 && this.syncedSchoolArray.length > 0) {
        this.subjectTemplates.forEach((item) => {
          if (!item.subject) {
            const subject = _.find(this.subjects, { id: item.subjectID });
            if (item.subject !== subject)
              item.subject = subject || { grade: "", subject: "" };
          }

          if (!item.school) {
            const school = _.find(this.syncedSchoolArray, {
              id: item.schoolID,
            });
            if (item.school !== school) {
              item.school = school || {
                name: "",
                provinces: [],
                isGeneric: false,
              };
            }
          }
        });
      }
      return this.subjectTemplates;
    },
    // Computed property for the screen width
    screenWidth() {
      let width = this.$vuetify.breakpoint.width;
      return width;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Template" : "Edit Template";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    async valid(val) {
      if (!val && this.dialog) {
        await this.validateForm();
      }
    },
    async expPanels() {
      this.expFieldPanels = 0;
      await this.validateForm();
    },
  },

  methods: {
    filterDataTable(value, search, item) {
      let searches = search.trim().toLowerCase().split(" ");

      let stringified = JSON.stringify(item).toLowerCase();
      let found = 0;
      for (let s in searches) {
        if (stringified.indexOf(searches[s]) > -1) found++;
      }
      // console.log(found);
      return found === searches.length;
    },

    async validateForm() {
      setTimeout(() => {
        if (this.$refs.form) this.$refs.form.validate();
      }, 0);
    },

    comboboxErrors(itemArray, fieldType) {
      if (
        (fieldType === "Select" || fieldType === "Multi Select") &&
        itemArray.length < 1
      ) {
        return "Select values are required";
      } else if (fieldType === "Radio" && itemArray.length < 1) {
        return "Radio values are required";
      } else if (fieldType === "Checkbox" && itemArray.length < 1) {
        return "Checkbox values are required";
      } else return "";
    },
    customSortGrade(a, b) {
      a = a === "R" ? -Infinity : parseInt(a, 10);
      b = b === "R" ? -Infinity : parseInt(b, 10);
      if (this.sortDesc) {
        // Switch the order for descending
        [a, b] = [b, a];
      }
      return a - b;
    },
    schoolName(item) {
      return `${item.name}`;
    },
    subjectName(item) {
      return `${"Grade: " + item.grade + ", Subject: " + item.subject}`;
    },

    async addField(i) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.template[i].fields.push({
          type: "",
          label: "",
          value: "",
          hint: "",
          placeholder: "",
          // difficulty: null,
          // points: null,
          items: [],
        });

        this.editedItem = Object.assign({}, mutableObj);

        await this.validateForm();
      } catch (error) {
        console.log(error);
      }
    },

    removeField(fieldIndex, templateIndex) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.template[templateIndex].fields.splice(fieldIndex, 1);

        this.editedItem = Object.assign({}, mutableObj);
      } catch (error) {
        console.log(error);
      }
    },

    async addTemplateObject() {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.template.push({
          name: "",
          fields: [],
        });

        this.editedItem = Object.assign({}, mutableObj);

        await this.validateForm();
      } catch (error) {
        console.log(error);
      }
    },

    removeTemplateObject(i) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.template.splice(i, 1);

        this.editedItem = Object.assign({}, mutableObj);
      } catch (error) {
        console.log(error);
      }
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.editedIndex = -1;
      this.expPanels = 1;
      this.valid = false;
    },

    createItem() {
      this.resetForm();
      this.dialog = true;
    },

    duplicateItem(item) {
      this.editedItem = _.cloneDeep(item);

      if (!this.editedItem.headings) {
        this.editedItem.headings = _.cloneDeep(this.defaultItem.headings);
      }
      if (!this.editedItem.fontFamily) {
        this.editedItem.fontFamily = _.cloneDeep(this.defaultItem.fontFamily);
      }

      this.editedItem.headings = this.editedItem.headings.filter(
        (item) =>
          item.label !== "Difficulty" &&
          item.label !== "Minutes" &&
          item.label !== "Type"
      );

      this.defaultItem.headings.forEach((defaultHeading) => {
        const existsInEdited = this.editedItem.headings.some(
          (editedHeading) => editedHeading.label === defaultHeading.label
        );

        if (!existsInEdited) {
          // Add the defaultHeading to this.editedItem.headings
          this.editedItem.headings.push(defaultHeading);
        }
      });

      this.dialog = true;
    },

    editItem(item) {
      this.editedIndex = this.subjectTemplates.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (!this.editedItem.headings) {
        this.editedItem.headings = _.cloneDeep(this.defaultItem.headings);
      }
      if (!this.editedItem.fontFamily) {
        this.editedItem.fontFamily = _.cloneDeep(this.defaultItem.fontFamily);
      }

      this.editedItem.headings = this.editedItem.headings.filter(
        (item) =>
          item.label !== "Difficulty" &&
          item.label !== "Minutes" &&
          item.label !== "Type"
      );

      this.defaultItem.headings.forEach((defaultHeading) => {
        const existsInEdited = this.editedItem.headings.some(
          (editedHeading) => editedHeading.label === defaultHeading.label
        );

        if (!existsInEdited) {
          // Add the defaultHeading to this.editedItem.headings
          this.editedItem.headings.push(defaultHeading);
        }
      });

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.subjectTemplates.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await DataStore.delete(this.syncedSubjectTemplateArray[this.editedIndex]);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.resetForm();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = _.cloneDeep(this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update subjectTemplate
        await this.updateSubjectTemplate();
      } else {
        // Create new subjectTemplate
        await this.createSubjectTemplate();
      }
      this.close();
    },

    async createSubjectTemplate() {
      try {
        // this.editedItem.totalPoints = 0;

        this.editedItem.template.forEach((templateObj) => {
          templateObj.name = templateObj.name.trim();

          templateObj.fields.forEach((fieldObj) => {
            // fieldObj.points = parseInt(fieldObj.points);
            // fieldObj.difficulty = parseInt(fieldObj.difficulty);
            // this.editedItem.totalPoints += fieldObj.points;

            if (
              fieldObj.type !== "Select" &&
              fieldObj.type !== "Multi Select" &&
              fieldObj.type !== "Radio" &&
              fieldObj.type !== "Checkbox"
            ) {
              fieldObj.items = [];
            }
          });
        });

        await DataStore.save(
          new SubjectTemplate({
            name: this.editedItem.name.trim(),
            headings: this.editedItem.headings,
            fontFamily: this.editedItem.fontFamily,
            complexity: this.editedItem.complexity,
            totalPoints:
              typeof this.editedItem.totalPoints === "string"
                ? parseInt(this.editedItem.totalPoints)
                : this.editedItem.totalPoints,
            // minutes: parseInt(this.editedItem.minutes),
            template: this.editedItem.template,
            schoolID: this.editedItem.school.id,
            subjectID: this.editedItem.subject.id,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updateSubjectTemplate() {
      try {
        // this.editedItem.totalPoints = 0;

        this.editedItem.template.forEach((templateObj) => {
          templateObj.name = templateObj.name.trim();

          templateObj.fields.forEach((fieldObj) => {
            // fieldObj.points = parseInt(fieldObj.points);
            // fieldObj.difficulty = parseInt(fieldObj.difficulty);
            // this.editedItem.totalPoints += fieldObj.points;

            if (
              fieldObj.type !== "Select" &&
              fieldObj.type !== "Multi Select" &&
              fieldObj.type !== "Radio" &&
              fieldObj.type !== "Checkbox"
            ) {
              fieldObj.items = [];
            }
          });
        });

        await DataStore.save(
          SubjectTemplate.copyOf(
            _.find(this.syncedSubjectTemplateArray, { id: this.editedItem.id }),
            (updateModel) => {
              updateModel.name = this.editedItem.name.trim();
              updateModel.headings = this.editedItem.headings;
              updateModel.fontFamily = this.editedItem.fontFamily;
              updateModel.complexity = this.editedItem.complexity;
              updateModel.totalPoints =
                typeof this.editedItem.totalPoints === "string"
                  ? parseInt(this.editedItem.totalPoints)
                  : this.editedItem.totalPoints;
              // updateModel.minutes = parseInt(this.editedItem.minutes);
              updateModel.template = this.editedItem.template;
              updateModel.subjectID = this.editedItem.subject.id;
              updateModel.schoolID = this.editedItem.school.id;
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSubjectTemplates() {
      try {
        this.subjectTemplateSubscription = DataStore.observeQuery(
          SubjectTemplate
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              this.syncedSubjectTemplateArray !== items
            ) {
              this.syncedSubjectTemplateArray = items;

              this.subjectTemplates = _.cloneDeep(items);

              this.subjectTemplateSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.subjectTemplateSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSchools() {
      try {
        this.schoolSubscription = DataStore.observeQuery(School).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedSchoolArray !== items
            ) {
              this.syncedSchoolArray = items;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSubjects() {
      try {
        this.subjectSubscription = DataStore.observeQuery(Subject).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedSubjectArray !== items
            ) {
              this.syncedSubjectArray = items;
              this.subjects = _.cloneDeep(
                _.sortBy(items, (item) => {
                  if (item.grade === "R") {
                    return -Infinity; // 'R' should appear first
                  } else if (item.grade === "1") {
                    return 0; // '1' should appear second
                  } else {
                    return parseInt(item.grade); // Sort the remaining numbers normally
                  }
                })
              );
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.subjectTemplateSubscription) {
      this.subjectTemplateSubscription.unsubscribe();
    }
    if (this.schoolSubscription) {
      this.schoolSubscription.unsubscribe();
    }
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }
  },
};
</script>

<style>
#expPanelContent > * {
  padding: 12px !important;
}

.panelTopBorder {
  border-radius: 4px !important;
}

.panelTopBorder::after {
  border-top: none !important;
}

.plannerPanelTopBorder > div:nth-child(2) {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel:first-child {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel--active + div {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel:last-child {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel--next-active {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel--active {
  border-radius: 4px !important;
}

.plannerPanelTopBorder .v-expansion-panel--active #expPanelContent {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
</style>
