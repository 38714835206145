<template>
  <v-container
    id="retrieveuserlogin"
    fluid
    style="
      height: 90vh;
      justify-content: center;
      align-items: center;
      gap: 16px;
    "
    class="d-flex flex-column"
  >
    <v-card max-width="420px" outlined>
      <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
        <v-card-title class="text-h4">
          <!-- {{ $i18n.t("headings.support") }}
          <helper topic="contactsupport" /> -->
          Retrieve User Login</v-card-title
        >

        <v-card-text>
          <v-text-field
            v-model="formfields.email"
            :label="`${$i18n.t('labels.email')}*`"
            :rules="formRules.email"
            :readonly="submitting || (submitted && error === '')"
            required
          ></v-text-field>
          <v-text-field
            v-model="formfields.phoneNumber"
            :label="$i18n.t('labels.phone') + ' ' + $i18n.t('labels.number')"
            :rules="formRules.phoneNumber"
            :readonly="submitting || (submitted && error === '')"
            required
          ></v-text-field>

          <v-row no-gutters class="text-caption">
            This site is protected by reCAPTCHA and the Google
            <a target="_blank" href="https://policies.google.com/privacy">
              &nbsp;Privacy Policy&nbsp;
            </a>
            and
            <a target="_blank" href="https://policies.google.com/terms">
              &nbsp;Terms of Service&nbsp;
            </a>
            apply.
          </v-row>
        </v-card-text>
        <!-- Submit button -->
        <v-card-actions class="px-4 pb-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="submitting || !valid || (submitted && error === '')"
            type="submit"
            x-large
            color="primary"
            :loading="submitting"
          >
            {{ $i18n.t("actions.submit") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card>

    <v-alert v-if="error > ''" type="error" max-width="420px">
      <v-row align="center">
        <v-col class="grow"><div v-html="error"></div> </v-col>
      </v-row>
    </v-alert>
    <v-alert
      v-else-if="submitted && alreadyExistingUsername"
      type="success"
      max-width="420px"
    >
      <v-row align="center">
        <v-col class="grow">
          <div v-html="$i18n.t('lambda.existingUserFound')"></div>
          <strong>{{ alreadyExistingUsername }}</strong>
        </v-col>
      </v-row>
    </v-alert>
    <v-alert v-else-if="submitted" type="success" max-width="420px">
      <v-row align="center">
        <v-col class="grow">{{ $i18n.t("lambda.profileMatched") }}</v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>
import { API } from "aws-amplify";
// import Helper from "@/components/global-components/Helper.vue";

export default {
  name: "RetrieveUserLogin", // Name of the component
  // components: { Helper },
  data: () => ({
    // Data properties
    valid: true, // Form validation status
    submitting: false, // Is form currently submitting
    submitted: false, // Has form been submitted
    alreadyExistingUsername: false, // Has form been submitted
    error: "", // Has form been submitted
    // Form fields
    formfields: {
      email: "",
      recaptchaToken: "",
      phoneNumber: "",
    },

    formRules: {
      email: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneNumber: [
        (v) => !!v || "Phone number is required",
        (v) =>
          /^(?:\+27|0)\s?\d{2}\s?\d{3}\s?\d{4}$/.test(v) ||
          "Invalid South African phone number",
      ],
    },
  }),
  methods: {
    // Method that handles form submission
    async onSubmit() {
      this.error = "";
      // Retrieve reCAPTCHA token
      const token = await this.$recaptcha("submit");

      // Assign reCAPTCHA token to formfields
      this.formfields.recaptchaToken = token;
      // Set submitting to true, indicating the form is being submitted
      this.submitting = true;

      const email = this.formfields.email;

      let phoneNumber = this.formfields.phoneNumber;

      if (phoneNumber.startsWith("0")) {
        phoneNumber = "+27" + phoneNumber.substring(1);
      }

      const response = await API.get(
        "cognitolambdaapi",
        "/item/retrieveUserLogin",
        {
          queryStringParameters: {
            email: email,
            phoneNumber: phoneNumber,
            recaptchaToken: this.formfields.recaptchaToken,
          },
        }
      ).catch((error) => {
        console.error(error);
      });

      // console.log(response);
      if (response?.success) {
        this.submitted = true;
      } else if (response?.username) {
        this.submitted = true;
        this.alreadyExistingUsername = response.username;
      } else {
        this.error = this.$i18n.t("lambda.errorNoProfile");
      }

      this.submitting = false;
    },
  },
};
</script>
