<template>
  <div id="richTextEditor" style="max-width: 100%">
    <v-card outlined class="richtexteditor">
      <div
        v-if="editor"
        style="background-color: rgb(245, 245, 245); gap: 4px; flex-wrap: wrap"
        class="pa-2 d-flex align-center"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editor.chain().focus().undo().run()"
              v-bind="attrs"
              v-on="on"
              :disabled="!editor.can().chain().focus().undo().run()"
              icon
              small
            >
              <v-icon>mdi-undo</v-icon>
            </v-btn>
          </template>
          <span>Undo</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editor.chain().focus().redo().run()"
              v-bind="attrs"
              v-on="on"
              :disabled="!editor.can().chain().focus().redo().run()"
              icon
              small
            >
              <v-icon>mdi-redo</v-icon>
            </v-btn>
          </template>
          <span>Redo</span>
        </v-tooltip>
        <div class="rte-icon-divider"></div>
        <v-menu offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <!-- <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }"> -->
            <v-btn
              v-bind="attrs"
              v-on="{
                // ...tooltip,
                ...menu,
              }"
              text
              small
              :disabled="!editor.can().chain().focus().setParagraph().run()"
            >
              <!-- width="82px" -->
              <!-- :color="(editor.isActive('paragraph') || editorIsActiveHeadings(1) || editorIsActiveHeadings(2) || editorIsActiveHeadings(3) || editorIsActiveHeadings(4) || editorIsActiveHeadings(5) || editorIsActiveHeadings(6)) ? 'primary' : ''" -->
              <!-- {{ buttonText }} -->
              Text & Headings
            </v-btn>
            <!-- </template>
                            <span>Headings</span>
                        </v-tooltip> -->
          </template>
          <div class="d-flex headings-flexbox" style="background-color: white">
            <v-btn
              @click="editor.chain().focus().setParagraph().run()"
              :color="editor.isActive('paragraph') ? 'primary' : ''"
              text
              height="48"
            >
              <p class="ma-0" style="font-size: 16px">Paragraph</p>
            </v-btn>
            <v-btn
              v-for="headingLevel in headingLevels"
              :key="headingLevel"
              @click="applyHeading(headingLevel)"
              :color="editorIsActiveHeadings(headingLevel) ? 'primary' : ''"
              text
              height="48"
            >
              <div
                v-html="
                  '<h' +
                  headingLevel +
                  '>' +
                  'H' +
                  headingLevel +
                  '</h' +
                  headingLevel +
                  '>'
                "
              ></div>
            </v-btn>
          </div>
        </v-menu>
        <div v-if="datamodelId > ''" class="d-flex align-center">
          <div class="rte-icon-divider"></div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openDialogUploadImage"
                v-bind="attrs"
                v-on="on"
                icon
                small
              >
                <v-icon>mdi-image</v-icon>
              </v-btn>
            </template>
            <span>Image</span>
          </v-tooltip>
        </div>
        <v-tooltip v-if="selectedImage" top>
          <template v-slot:activator="{ on, attrs }">
            <v-slider
              v-bind="attrs"
              v-on="on"
              label=""
              v-model="selectedImageWidth"
              @input="updateSelectedImageWidth(selectedImageWidth)"
              hide-details
              :max="selectedImageMaxWidth"
              min="10"
              style="min-width: 50px"
            ></v-slider>
          </template>
          <span>Image Size</span>
        </v-tooltip>
        <div class="rte-icon-divider"></div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editor.chain().focus().toggleBold().run()"
              v-bind="attrs"
              v-on="on"
              :disabled="!editor.can().chain().focus().toggleBold().run()"
              :color="editor.isActive('bold') ? 'primary' : ''"
              icon
              small
            >
              <v-icon>mdi-format-bold</v-icon>
            </v-btn>
          </template>
          <span>Bold</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editor.chain().focus().toggleItalic().run()"
              v-bind="attrs"
              v-on="on"
              :disabled="!editor.can().chain().focus().toggleItalic().run()"
              :color="editor.isActive('italic') ? 'primary' : ''"
              icon
              small
            >
              <v-icon>mdi-format-italic</v-icon>
            </v-btn>
          </template>
          <span>Italic</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editor.chain().focus().toggleUnderline().run()"
              v-bind="attrs"
              v-on="on"
              :disabled="!editor.can().chain().focus().toggleUnderline().run()"
              :color="editor.isActive('underline') ? 'primary' : ''"
              icon
              small
            >
              <v-icon>mdi-format-underline</v-icon>
            </v-btn>
          </template>
          <span>Underline</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editor.chain().focus().toggleStrike().run()"
              v-bind="attrs"
              v-on="on"
              :disabled="!editor.can().chain().focus().toggleStrike().run()"
              :color="editor.isActive('strike') ? 'primary' : ''"
              icon
              small
            >
              <v-icon>mdi-format-strikethrough</v-icon>
            </v-btn>
          </template>
          <span>Strikethrough</span>
        </v-tooltip>
        <!-- <v-btn @click="editor.chain().focus().toggleCode().run()"
                    :disabled="!editor.can().chain().focus().toggleCode().run()"
                    :color="editor.isActive('code') ? 'primary' : ''" icon small>
                    <v-icon>mdi-xml</v-icon>
                </v-btn> -->
        <!-- <v-btn @click="editor.chain().focus().clearNodes().run()">
                    clear nodes
                </v-btn> -->
        <div class="rte-icon-divider"></div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editor.chain().focus().toggleBulletList().run()"
              v-bind="attrs"
              v-on="on"
              :color="editor.isActive('bulletList') ? 'primary' : ''"
              icon
              small
            >
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
          </template>
          <span>Bulleted List</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editor.chain().focus().toggleOrderedList().run()"
              v-bind="attrs"
              v-on="on"
              :color="editor.isActive('orderedList') ? 'primary' : ''"
              icon
              small
            >
              <v-icon>mdi-format-list-numbered</v-icon>
            </v-btn>
          </template>
          <span>Numbered List</span>
        </v-tooltip>
        <div class="rte-icon-divider"></div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="setTextAlign('left')"
              v-bind="attrs"
              v-on="on"
              :color="editor.isActive({ textAlign: 'left' }) ? 'primary' : ''"
              icon
              small
            >
              <v-icon>mdi-format-align-left</v-icon>
            </v-btn>
          </template>
          <span>Align Left</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="setTextAlign('center')"
              v-bind="attrs"
              v-on="on"
              :color="editor.isActive({ textAlign: 'center' }) ? 'primary' : ''"
              icon
              small
            >
              <v-icon>mdi-format-align-center</v-icon>
            </v-btn>
          </template>
          <span>Align Center</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="setTextAlign('right')"
              v-bind="attrs"
              v-on="on"
              :color="editor.isActive({ textAlign: 'right' }) ? 'primary' : ''"
              icon
              small
            >
              <v-icon>mdi-format-align-right</v-icon>
            </v-btn>
          </template>
          <span>Align Right</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="setTextAlign('justify')"
              v-bind="attrs"
              v-on="on"
              :color="
                editor.isActive({ textAlign: 'justify' }) ? 'primary' : ''
              "
              icon
              small
            >
              <v-icon>mdi-format-align-justify</v-icon>
            </v-btn>
          </template>
          <span>Align Justify</span>
        </v-tooltip>
        <div class="rte-icon-divider"></div>
        <!-- <v-btn @click="editor.chain().focus().toggleCodeBlock().run()"
                    :color="editor.isActive('codeBlock') ? 'primary' : ''" icon small>
                    <v-icon>mdi-code-not-equal-variant</v-icon>
                </v-btn> -->
        <!-- <v-btn @click="editor.chain().focus().toggleBlockquote().run()"
                    :color="editor.isActive('blockquote') ? 'primary' : ''" icon small>
                    <v-icon>mdi-comment-quote</v-icon>
                </v-btn> -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editor.chain().focus().setHorizontalRule().run()"
              icon
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-format-line-height</v-icon>
            </v-btn>
          </template>
          <span>Horizontal Line</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editor.chain().focus().setHardBreak().run()"
              icon
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-arrow-left-bottom</v-icon>
            </v-btn>
          </template>
          <span>Line Break</span>
        </v-tooltip>
        <!-- <v-btn @click="alignImage('left')" :color="editor.isActive({ textAlign: 'center' }) ? 'primary' : ''" icon
                    small>
                    <v-icon>mdi-format-align-left</v-icon>
                </v-btn>
                <v-btn @click="alignImage('center')" :color="editor.isActive({ textAlign: 'right' }) ? 'primary' : ''" icon
                    small>
                    <v-icon>mdi-format-align-center</v-icon>
                </v-btn>
                <v-btn @click="alignImage('right')" :color="editor.isActive({ textAlign: 'justify' }) ? 'primary' : ''" icon
                    small>
                    <v-icon>mdi-format-align-right</v-icon>
                </v-btn> -->
        <div class="rte-icon-divider"></div>
        <v-menu offset-y class="table-menu">
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-bind="attrs"
                  v-on="{
                    ...tooltip,
                    ...menu,
                  }"
                  icon
                  small
                >
                  <v-icon>mdi-table-large</v-icon>
                </v-btn>
              </template>
              <span>Tables</span>
            </v-tooltip>
          </template>
          <div class="d-flex" style="background-color: white">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="
                    editor
                      .chain()
                      .focus()
                      .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                      .run()
                  "
                  :disabled="
                    !editor
                      .can()
                      .chain()
                      .focus()
                      .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                      .run()
                  "
                  v-bind="attrs"
                  v-on="on"
                  icon
                  height="48"
                  width="48"
                >
                  <v-icon size="24">mdi-table-large-plus</v-icon>
                </v-btn>
              </template>
              <span>Add Table</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editor.chain().focus().addColumnBefore().run()"
                  :disabled="!editor.can().addColumnBefore()"
                  v-bind="attrs"
                  v-on="on"
                  height="48"
                  width="48"
                  icon
                >
                  <v-icon size="24">mdi-table-column-plus-before</v-icon>
                </v-btn>
              </template>
              <span>Add Column Before</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editor.chain().focus().addColumnAfter().run()"
                  :disabled="!editor.can().addColumnAfter()"
                  v-bind="attrs"
                  v-on="on"
                  height="48"
                  width="48"
                  icon
                >
                  <v-icon size="24">mdi-table-column-plus-after</v-icon>
                </v-btn>
              </template>
              <span>Add Column After</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editor.chain().focus().deleteColumn().run()"
                  :disabled="!editor.can().deleteColumn()"
                  height="48"
                  width="48"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="24">mdi-table-column-remove</v-icon>
                </v-btn>
              </template>
              <span>Delete Column</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editor.chain().focus().addRowBefore().run()"
                  :disabled="!editor.can().addRowBefore()"
                  v-bind="attrs"
                  v-on="on"
                  height="48"
                  width="48"
                  icon
                >
                  <v-icon size="24">mdi-table-row-plus-before</v-icon>
                </v-btn>
              </template>
              <span>Add Row Before</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editor.chain().focus().addRowAfter().run()"
                  :disabled="!editor.can().addRowAfter()"
                  v-bind="attrs"
                  v-on="on"
                  height="48"
                  width="48"
                  icon
                >
                  <v-icon size="24">mdi-table-row-plus-after</v-icon>
                </v-btn>
              </template>
              <span>Add Row After</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editor.chain().focus().deleteRow().run()"
                  :disabled="!editor.can().deleteRow()"
                  v-bind="attrs"
                  v-on="on"
                  height="48"
                  width="48"
                  icon
                >
                  <v-icon size="24">mdi-table-row-remove</v-icon>
                </v-btn>
              </template>
              <span>Delete Row</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editor.chain().focus().deleteTable().run()"
                  :disabled="!editor.can().deleteTable()"
                  v-bind="attrs"
                  v-on="on"
                  height="48"
                  width="48"
                  icon
                >
                  <v-icon size="24">mdi-table-large-remove</v-icon>
                </v-btn>
              </template>
              <span>Delete Table</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editor.chain().focus().mergeCells().run()"
                  :disabled="!editor.can().mergeCells()"
                  v-bind="attrs"
                  v-on="on"
                  height="48"
                  width="48"
                  icon
                >
                  <v-icon size="24">mdi-table-merge-cells</v-icon>
                </v-btn>
              </template>
              <span>Merge Cells</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editor.chain().focus().splitCell().run()"
                  :disabled="!editor.can().splitCell()"
                  v-bind="attrs"
                  v-on="on"
                  height="48"
                  width="48"
                  icon
                >
                  <v-icon size="24">mdi-table-split-cell</v-icon>
                </v-btn>
              </template>
              <span>Split Cell</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editor.chain().focus().toggleHeaderColumn().run()"
                  :disabled="!editor.can().toggleHeaderColumn()"
                  v-bind="attrs"
                  v-on="on"
                  height="48"
                  width="48"
                  icon
                >
                  <v-icon class="header-column-icon" size="24"
                    >mdi-open-in-app</v-icon
                  >
                </v-btn>
              </template>
              <span>Toggle Column Header</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editor.chain().focus().toggleHeaderRow().run()"
                  :disabled="!editor.can().toggleHeaderRow()"
                  v-bind="attrs"
                  v-on="on"
                  height="48"
                  width="48"
                  icon
                >
                  <v-icon size="24">mdi-open-in-app</v-icon>
                </v-btn>
              </template>
              <span>Toggle Row Header</span>
            </v-tooltip>
            <!-- <v-btn @click="editor.chain().focus().toggleHeaderCell().run()"
                            :disabled="!editor.can().toggleHeaderCell()" small text>
                            toggleHeaderCell
                        </v-btn> -->
            <!-- <v-btn @click="editor.chain().focus().mergeOrSplit().run()" :disabled="!editor.can().mergeOrSplit()"
                            small text>
                            mergeOrSplit
                        </v-btn> -->
            <!-- <v-btn @click="editor.chain().focus().setCellAttribute('backgroundColor', '#FAF594').run()"
                            :disabled="!editor.can().setCellAttribute('backgroundColor', '#FAF594')" small text>
                            setCellAttribute
                        </v-btn> -->
            <!-- <v-btn @click="editor.chain().focus().fixTables().run()" :disabled="!editor.can().fixTables()" small
                            text>
                            fixTables
                        </v-btn>
                        <v-btn @click="editor.chain().focus().goToNextCell().run()" :disabled="!editor.can().goToNextCell()"
                            small text>
                            goToNextCell
                        </v-btn>
                        <v-btn @click="editor.chain().focus().goToPreviousCell().run()"
                            :disabled="!editor.can().goToPreviousCell()" small text>
                            goToPreviousCell
                        </v-btn> -->
          </div>
        </v-menu>
        <div class="rte-icon-divider"></div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editor.chain().focus().unsetAllMarks().run()"
              v-bind="attrs"
              v-on="on"
              icon
              small
            >
              <v-icon>mdi-format-clear</v-icon>
            </v-btn>
          </template>
          <span>Clear Formatting</span>
        </v-tooltip>
      </div>
      <div class="editor-content-div pa-2">
        <editor-content :editor="editor" />
      </div>
    </v-card>
    <v-dialog persistent v-model="dialogUploadImage" max-width="500px">
      <v-card>
        <v-card-title class="justify-center">Upload Image</v-card-title>
        <v-container>
          <!-- <v-row no-gutters>
                        <v-text-field v-model="uploadingImageURL" :disabled="uploadingImage !== null"
                            prepend-icon="mdi-link" label="Image URL"></v-text-field>
                    </v-row>
                    <v-row no-gutters class="justify-center">
                        <span class="text-h6">OR</span>
                    </v-row> -->
          <v-row no-gutters>
            <v-file-input
              label="Upload Image"
              v-model="uploadingImage"
              prepend-icon="mdi-camera"
              accept="image/png, image/jpeg, image/bmp, image/gif"
            ></v-file-input>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDialogUploadImage">Cancel</v-btn>
          <v-btn
            color="primary"
            @click="uploadImage"
            :disabled="uploadingImage === null"
            >Import</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ResizableImage } from "@/components/global-components/rich-text-editor/resizable-image.js";
import { Editor, EditorContent } from "@tiptap/vue-2";
// import { Node } from '@tiptap/core'
import StarterKit from "@tiptap/starter-kit";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
// import Image from "@tiptap/extension-image";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import { Storage } from "@aws-amplify/storage";

export default {
  components: {
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    datamodelId: {
      type: String,
      default: "",
    },
    s3Folder: {
      type: String,
      default: "",
    },
    s3SubFolder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      editor: null,
      dialogUploadImage: false,
      selectedImageWidth: 0,
      selectedImageMaxWidth: 0,
      uploadingImage: null,
      // uploadingImageURL: '',
      headingLevels: [6, 5, 4, 3, 2, 1],
      CustomTableCell: TableCell.extend({
        addAttributes() {
          return {
            // extend the existing attributes …
            ...this.parent?.(),

            // and add a new one …
            backgroundColor: {
              default: null,
              parseHTML: (element) =>
                element.getAttribute("data-background-color"),
              renderHTML: (attributes) => {
                return {
                  "data-background-color": attributes.backgroundColor,
                  style: `background-color: ${attributes.backgroundColor}`,
                };
              },
            },
          };
        },
      }),
    };
  },

  async mounted() {
    // const processedContent = await this.processedValue(this.value);

    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit.configure({
          horizontalRule: {
            HTMLAttributes: {
              class: "hr-margin",
            },
          },
        }),
        // Image.configure({
        //   HTMLAttributes: {
        //     class: "tiptap-image-css",
        //   },
        // }),
        ResizableImage.configure({
          // inline: true
        }),
        Underline,
        TextAlign.configure({
          types: ["heading", "paragraph"],
          defaultAlignment: "left",
          alignments: ["left", "center", "right", "justify"],
        }),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        // Default TableCell
        // TableCell,
        // Custom TableCell with backgroundColor attribute
        this.CustomTableCell,
      ],
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML());

        // console.log('update editor')
        // this.updateEditorValue(this.editor.getHTML());

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    });
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      // console.log('value watch setContent')

      // this.updateEditorContent(value);
      this.editor.commands.setContent(value, false);
    },
  },

  computed: {
    buttonText() {
      if (this.editor.isActive("paragraph")) return "Paragraph";
      const activeHeading = this.getActiveHeading();
      return activeHeading ? `H${activeHeading}` : "Headings";
    },

    selectedImage() {
      if (
        this.editor?.state?.selection?.node &&
        this.editor.state.selection.node.type.name === "ResizableImage"
      ) {
        this.updateSelectedImageWidth();
        return true;
      }
      return false;
    },

    // // We show that button only if the node is of type 'ResizableImage'
    // showImageResizeButton() {
    //     return this.editor?.state?.selection?.node?.type?.name === 'ResizableImage';
    // },
    // // And let the button know what is going on with the img right now
    // isDraggable() {
    //     return this.editor?.state?.selection?.node?.attrs?.isDraggable;
    // }
  },

  methods: {
    updateSelectedImageWidth(width) {
      if (!width) {
        let element = document.querySelector(
          ".ResizableImageFig.ProseMirror-selectednode"
        );
        if (element) {
          let image = element.querySelector("img");

          if (image) {
            // Check if image is already loaded
            if (image.complete && image.naturalHeight !== 0) {
              this.setDimensions(image);
            } else {
              // Wait for image to load
              image.addEventListener("load", () => {
                this.setDimensions(image);
              });
            }
          }
        }
      } else {
        this.editor
          .chain()
          .focus()
          .updateAttributes("ResizableImage", { width: width + "px" })
          .run();
      }
    },

    setDimensions(image) {
      this.selectedImageMaxWidth = image.naturalWidth;
      this.selectedImageWidth = image.clientWidth;
    },

    setTextAlign(alignment) {
      if (
        this.editor?.state?.selection?.node &&
        this.editor.state.selection.node.type.name === "ResizableImage"
      ) {
        this.editor
          .chain()
          .focus()
          .updateAttributes("ResizableImage", { textAlign: alignment })
          .run();
      } else {
        // Otherwise, use the default setTextAlign method
        this.editor.chain().focus().setTextAlign(alignment).run();
      }
    },
    // async updateEditorValue(value) {
    //     const processedContent = await this.processedValue(value);
    //     // this.editor.commands.setContent(processedContent, false);

    //     this.$emit('input', processedContent)
    // },

    // async updateEditorContent(value) {
    //     // console.log('value: ', value)
    //     const processedContent = await this.processedValue(value);
    //     // console.log('processedContent: ', processedContent)
    //     this.editor.commands.setContent(processedContent, false);
    // },

    // async processedValue(value) {

    //     const parser = new DOMParser();
    //     const doc = parser.parseFromString(value, 'text/html');
    //     const imgElements = doc.querySelectorAll('img');
    //     const promises = [];

    //     imgElements.forEach((img) => {
    //         const imageSrcData = img.getAttribute('image-src-data');
    //         const imageSrc = img.getAttribute('src');
    //         if (!imageSrc || (imageSrc && imageSrc === '')) {
    //             if (imageSrcData && imageSrcData.startsWith('http')) {
    //                 img.setAttribute('src', imageSrcData);
    //             } else if (imageSrcData) {
    //                 // Create a promise for each image load operation
    //                 const promise = Storage.get(imageSrcData, { level: 'public' })
    //                     .then((newSrc) => {
    //                         img.setAttribute('src', newSrc);
    //                     })
    //                     .catch((error) => {
    //                         console.error(error);
    //                     });

    //                 promises.push(promise);
    //             }
    //         }
    //     });

    //     // Wait for all promises to complete before returning the modified HTML
    //     await Promise.all(promises);

    //     return doc.body.innerHTML;
    // },

    editorIsActiveHeadings(headingLevel) {
      return this.editor.isActive("heading", { level: headingLevel });
    },

    getActiveHeading() {
      for (const headingLevel of this.headingLevels) {
        if (this.editorIsActiveHeadings(headingLevel)) {
          return headingLevel;
        }
      }
      return null;
    },

    applyHeading(headingLevel) {
      this.editor.chain().focus().toggleHeading({ level: headingLevel }).run();
    },

    openDialogUploadImage() {
      this.dialogUploadImage = true;
    },

    closeDialogUploadImage() {
      this.dialogUploadImage = false;
      this.$nextTick(() => {
        this.uploadingImage = null;
        // this.uploadingImageURL = '';
      });
    },

    async uploadImage() {
      // Create an Image object
      const img = new Image();
      img.src = URL.createObjectURL(this.uploadingImage);

      img.onload = async () => {
        let width = img.width;
        let height = img.height;

        // Check if the image needs resizing
        if (width > 1240 || height > 1240) {
          const aspectRatio = width / height;
          if (width > height) {
            width = 1240;
            height = width / aspectRatio;
          } else {
            height = 1240;
            width = height * aspectRatio;
          }

          // Create a canvas and draw the resized image
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          // Convert canvas to blob and then to File
          canvas.toBlob(async (blob) => {
            const resizedImage = new File([blob], this.uploadingImage.name, {
              type: this.uploadingImage.type,
              lastModified: Date.now(),
            });

            // Proceed with the upload
            await this.uploadToS3(resizedImage);
          }, this.uploadingImage.type);
        } else {
          // If no resizing is needed, upload the original image
          await this.uploadToS3(this.uploadingImage);
        }
      };
    },

    async uploadToS3(imageFile) {
      const newImagePut = await Storage.put(
        `${
          this.datamodelId > ""
            ? this.s3Folder +
              "/" +
              this.datamodelId +
              (this.s3SubFolder ? "/" + this.s3SubFolder + "/" : "/")
            : this.s3Folder + "/"
        }` +
          Date.now() +
          "-" +
          imageFile.name,
        imageFile
      );

      const newImageGet =
        // "https://missanriscn-storage-c4dd76e095249-staging.s3.eu-west-2.amazonaws.com/public/" + // Staging/Dev
        "https://missanriscn-storage-c4dd76e095706-production.s3.eu-west-2.amazonaws.com/public/" + // Production
        newImagePut.key;

      this.editor
        .chain()
        .focus()
        .setImage({
          src: newImageGet,
          width: "auto",
          height: "auto",
          textAlign: "center",
        })
        .run();

      this.dialogUploadImage = false;
      this.$nextTick(() => {
        this.uploadingImage = null;
      });
    },
  },

  beforeDestroy() {
    if (this.editor) this.editor.destroy();
  },
};
</script>

<style lang="scss">
.ResizableImageFig.ProseMirror-selectednode img {
  border: 2px solid #fae472;
  /* You can customize this style */
}

.ResizableImageFig img {
  max-width: 100%;
  max-height: 100%;
}

// .tiptap-image-css {
//   max-width: 100%;
// }

// .tiptap-image-css.ProseMirror-selectednode {
//   border: 2px solid #fae472;
//   /* You can customize this style */
// }

.editor-content-div div.ProseMirror {
  border: none;
  /* Remove the border */
  outline: none;
  /* Remove the outline */
  box-shadow: none;
  /* Remove the shadow */
}

.richtexteditor {
  border-color: rgba(0, 0, 0, 0.16) !important;
}

.rte-icon-divider {
  width: 2px;
  height: 1.25rem;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 4px;
  margin-right: 4px;
}

.richtexteditor .v-btn--icon {
  border-radius: 25% !important;
}

.richtexteditor .v-btn--icon .v-ripple__animation {
  border-radius: 25% !important;
}

// .tiptap img.ProseMirror-selectednode {
//     outline: 2px solid #fae472;
// }

.headings-flexbox .v-btn__content {
  font-size: 16px !important;
}

/* .align-image-left {
    position: sticky;
}

.align-image-center {
    position: sticky;
}

.align-image-right {
    position: sticky;
} */

.hr-margin {
  margin-top: 16px;
}

/* Table-specific styling */
.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    max-width: 100% !important;
    width: auto !important;
    min-width: 200px !important;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.header-column-icon {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

// .table-menu {
//     min-width: fit-content !important;
// }
</style>
