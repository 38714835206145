<template>
  <div id="teacherassessments">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4">
          {{ $i18n.t("headings.assessments") }}
          <helper topic="assessments" />
        </v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-4"
          @click="dialogNewOrExistingTemplate = true"
          :disabled="offline"
        >
          {{ $i18n.t("otherText.new") + " " + $i18n.t("headings.assessment") }}
        </v-btn>
      </v-row>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="`${$i18n.t('labels.search')}`"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="6">
            <v-autocomplete v-model="search" :items="syncedSubjectArray" :item-text="subjectName" label="Subject"
              clearable></v-autocomplete>
          </v-col> -->
        </v-row>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="headers"
          :items="computedTeacherAssessmentTemplates"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          :search="search"
          :custom-filter="filterDataTable"
          :loading="
            !teacherAssessmentTemplateSubscriptionSynced &&
            computedTeacherAssessmentTemplates?.length === 0
          "
          loading-text="Loading Assessments..."
          no-data-text="No Assessments found"
        >
          <template v-slot:[`item.totalMinutes`]="{ item }">
            {{ item.totalMinutes ? formattedTotalTime(item.totalMinutes) : "" }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="duplicateItem(item)">
              mdi-content-copy
            </v-icon>
            <v-icon small class="mr-2" @click="printItem(item.id)">
              mdi-printer
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              :disabled="offline"
            >
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" :disabled="offline">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog persistent v-model="dialog" v-if="dialog">
      <div>
        <v-card>
          <v-form ref="form" v-model="valid" @submit.prevent="save">
            <v-row
              no-gutters
              :style="
                screenWidth >= 960 ? 'overflow-y: hidden;' : 'overflow-y: auto;'
              "
              style="max-height: calc(100vh - 164px)"
            >
              <v-col
                cols="12"
                md="6"
                class="pa-0"
                :style="
                  screenWidth >= 960
                    ? 'overflow-y: auto; overflow-x: hidden; max-height: calc(100vh - 164px);'
                    : ''
                "
              >
                <v-card-title>
                  <span class="text-h5"
                    >{{ formTitle }}
                    <helper
                      :topic="
                        editedIndex === -1
                          ? 'assessmentcreate'
                          : 'assessmentedit'
                      "
                    />
                  </span>
                </v-card-title>

                <v-tabs
                  v-model="dialogTab"
                  background-color="transparent"
                  color="primary"
                  grow
                >
                  <v-tab v-for="item in dialogTabItems" :key="item">
                    {{ item }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="dialogTab">
                  <v-tab-item>
                    <v-expansion-panels
                      class="px-2 mb-3"
                      style="justify-content: left"
                      v-model="expPanels"
                    >
                      <v-card-subtitle class="pb-0 pt-2">
                        <span class="text-h6">{{
                          $i18n.t("headings.headings")
                        }}</span>
                      </v-card-subtitle>
                      <v-expansion-panel class="panelTopBorder">
                        <v-expansion-panel-header>
                          {{
                            $i18n.t("otherText.change") +
                            " " +
                            $i18n.t("headings.defaults")
                          }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          id="expPanelContent"
                          color="grey lighten-3"
                        >
                          <v-row class="py-2">
                            <v-col
                              cols="12"
                              sm="6"
                              lg="4"
                              class="py-0"
                              v-for="(
                                heading, headingIndex
                              ) in editedItem.headings"
                              :key="headingIndex"
                            >
                              <v-text-field
                                v-model="heading.value"
                                :label="heading.label"
                                required
                                :rules="formRules.required"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-card-subtitle class="pb-0 pt-2">
                        <span class="text-h6">{{
                          $i18n.t("headings.assessment")
                        }}</span>
                      </v-card-subtitle>
                      <v-expansion-panel class="panelTopBorder">
                        <v-expansion-panel-header>
                          {{ $i18n.t("labels.details") }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          id="expPanelContent"
                          color="grey lighten-3"
                        >
                          <v-row class="py-2">
                            <v-col cols="12" sm="6" class="py-0">
                              <v-text-field
                                v-model="editedItem.title"
                                :label="`${
                                  $i18n.t('headings.assessment') +
                                  ' ' +
                                  $i18n.t('labels.title')
                                }`"
                                :rules="formRules.title"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="3" class="py-0">
                              <v-select
                                v-model="editedItem.complexity"
                                :label="`${$i18n.t('labels.complexity')}`"
                                :items="complexityLevels"
                                required
                                :rules="formRules.complexity"
                              ></v-select>
                            </v-col>
                            <v-col cols="6" sm="3" class="py-0">
                              <v-select
                                v-model="editedItem.fontFamily"
                                :label="`${
                                  $i18n.t('typography.font') +
                                  ' ' +
                                  $i18n.t('otherText.family')
                                }`"
                                :items="fontFamilyItems"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                              <v-text-field
                                v-model="editedItem.totalMinutes"
                                :label="`${
                                  $i18n.t('labels.time') +
                                  ' ' +
                                  $i18n.t('otherText.in') +
                                  ' ' +
                                  $i18n.t('labels.minutes')
                                }`"
                                :rules="formRules.minutes"
                                required
                                type="number"
                              ></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" sm="6" class="py-0">
  <v-autocomplete v-model="editedItem.school" :items="syncedSchoolArray" :item-text="schoolName"
    label="School" return-object></v-autocomplete>
</v-col> -->
                            <v-col cols="12" sm="6" class="py-0">
                              <v-autocomplete
                                v-model="editedItem.subject"
                                :items="
                                  subjects?.length > 0
                                    ? subjects.filter((a) =>
                                        syncedTeacherClassSubjectIDArray.some(
                                          (b) => a.id === b
                                        )
                                      )
                                    : []
                                "
                                :item-text="subjectName"
                                :label="`${$i18n.t('labels.subject')}`"
                                return-object
                                required
                                :rules="formRules.subject"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" class="py-0">
                              <v-combobox
                                v-model="editedItem.instructions"
                                :label="`${$i18n.t('labels.instructions')}`"
                                :rules="[instructionValidationRule]"
                                required
                                multiple
                                chips
                                deletable-chips
                                clearable
                              ></v-combobox>
                            </v-col>
                            <v-col cols="12" class="py-0">
                              <v-select
                                v-model="editedItem.taxonomy"
                                :items="mergedTaxonomys"
                                :item-text="taxonomyText"
                                return-object
                                :label="`${$i18n.t('labels.taxonomy')}`"
                                clearable
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <div
                        v-if="
                          editedItem.questions &&
                          editedItem.questions.length > 0
                        "
                        style="width: 100%"
                        class="questionFieldPanelTopBorder"
                      >
                        <v-card-subtitle class="pb-0 pt-2">
                          <span class="text-h6">{{
                            $i18n.t("headings.questions")
                          }}</span>
                        </v-card-subtitle>

                        <draggable
                          v-model="editedItem.questions"
                          style="width: 100%"
                        >
                          <v-expansion-panel
                            v-for="(
                              questionObject, questionIndex
                            ) in editedItem.questions"
                            :key="questionIndex"
                          >
                            <v-expansion-panel-header class="pa-2">
                              <div class="d-flex align-center">
                                <v-icon class="mr-1">
                                  mdi-drag-vertical
                                </v-icon>
                                <div style="text-wrap: nowrap">
                                  <span>{{
                                    $i18n.t("headings.question") +
                                    " " +
                                    questionObject.number +
                                    (questionObject.title &&
                                    questionObject.title !== ""
                                      ? ":&nbsp;"
                                      : "")
                                  }}</span>
                                </div>
                                <div>
                                  <span>
                                    {{
                                      questionObject.title &&
                                      questionObject.title !== ""
                                        ? questionObject.title
                                        : ""
                                    }}
                                  </span>
                                </div>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content
                              id="expPanelContent"
                              color="grey lighten-3"
                            >
                              <v-row no-gutters>
                                <v-btn
                                  :color="
                                    isNewSection(questionIndex)
                                      ? 'secondary'
                                      : 'primary'
                                  "
                                  class="mr-6"
                                  @click="
                                    isNewSection(questionIndex)
                                      ? removeSection(questionIndex)
                                      : newSection(questionIndex)
                                  "
                                >
                                  {{
                                    isNewSection(questionIndex)
                                      ? $i18n.t("assessments.notASection")
                                      : $i18n.t("assessments.newSection")
                                  }}
                                </v-btn>
                                <v-row
                                  v-if="isNewSection(questionIndex)"
                                  class="pt-2"
                                >
                                  <v-col cols="5" sm="3" class="py-0">
                                    <v-text-field
                                      v-model="questionObject.sectionNumber"
                                      :label="`${
                                        $i18n.t('headings.section') +
                                        ' ' +
                                        $i18n.t('labels.shortNumber')
                                      }`"
                                      required
                                      :rules="formRules.number"
                                      placeholder="eg. A"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="7" sm="9" class="py-0">
                                    <v-text-field
                                      v-model="questionObject.sectionTitle"
                                      :label="`${
                                        $i18n.t('headings.section') +
                                        ' ' +
                                        $i18n.t('labels.title')
                                      }`"
                                      placeholder="Language and Grammer"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-row>
                              <v-row class="pb-2 mt-2">
                                <v-col cols="5" sm="3" md="2" class="py-0">
                                  <v-text-field
                                    v-model="questionObject.number"
                                    :label="`${
                                      $i18n.t('headings.question') +
                                      ' ' +
                                      $i18n.t('labels.shortNumber')
                                    }`"
                                    :rules="formRules.number"
                                    required
                                    placeholder="eg. 1"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="7" sm="5" md="6" class="py-0">
                                  <v-text-field
                                    v-model="questionObject.title"
                                    :label="`${
                                      $i18n.t('headings.question') +
                                      ' ' +
                                      $i18n.t('labels.title')
                                    }`"
                                    placeholder="eg. Essay"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="2" md="2" class="py-0">
                                  <v-text-field
                                    v-model="questionObject.minutes"
                                    :label="`${
                                      $i18n.t('labels.time') +
                                      ' ' +
                                      $i18n.t('otherText.in') +
                                      ' ' +
                                      $i18n.t('labels.minutes')
                                    }`"
                                    type="number"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="2" md="2" class="py-0">
                                  <v-text-field
                                    v-model="questionObject.marks"
                                    @input="updateQuestionMarks"
                                    :label="`${
                                      $i18n.t('headings.question') +
                                      ' ' +
                                      $i18n.t('labels.marks')
                                    }`"
                                    type="number"
                                    :rules="formRules.marks"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0 pb-3">
                                  <RichTextEditor
                                    v-model="questionObject.richText"
                                    s3Folder="Assessments"
                                  >
                                  </RichTextEditor>
                                </v-col>
                              </v-row>

                              <div
                                v-if="questionObject.fields.length > 0"
                                style="width: 100%"
                                class="questionFieldPanelTopBorder"
                              >
                                <v-card-title class="pa-0">
                                  <span class="text-h6">{{
                                    $i18n.t("labels.fields")
                                  }}</span>
                                </v-card-title>

                                <v-expansion-panels
                                  class="mb-3"
                                  style="justify-content: left"
                                  v-model="expFieldPanels"
                                >
                                  <draggable
                                    v-model="questionObject.fields"
                                    style="width: 100%"
                                  >
                                    <v-expansion-panel
                                      v-for="(
                                        formField, questionFieldIndex
                                      ) in questionObject.fields"
                                      :key="questionFieldIndex"
                                    >
                                      <v-expansion-panel-header class="pa-2">
                                        <div class="d-flex align-center">
                                          <v-icon class="mr-1">
                                            mdi-drag-vertical
                                          </v-icon>
                                          <div style="text-wrap: nowrap">
                                            <span>{{
                                              $i18n.t("labels.field") +
                                              " " +
                                              formField.number +
                                              (formField.question &&
                                              formField.question !== ""
                                                ? ":&nbsp;"
                                                : "")
                                            }}</span>
                                          </div>
                                          <div>
                                            <span>
                                              {{
                                                formField.question &&
                                                formField.question !== ""
                                                  ? formField.question
                                                  : ""
                                              }}</span
                                            >
                                          </div>
                                        </div>
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content
                                        id="expPanelContent"
                                        color="grey lighten-3"
                                      >
                                        <v-row class="pt-2 pb-3">
                                          <v-col cols="4" class="py-0">
                                            <v-text-field
                                              v-model="formField.number"
                                              :label="`${$i18n.t(
                                                'labels.number'
                                              )}`"
                                              placeholder="eg. 1.1 or 1.a"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="4" class="py-0">
                                            <v-text-field
                                              v-model="formField.marks"
                                              :label="`${$i18n.t(
                                                'labels.marks'
                                              )}`"
                                              type="number"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="4" class="py-0">
                                            <v-select
                                              v-model="formField.cognitiveLevel"
                                              :items="formFieldCognitiveLevels"
                                              :item-text="cognitiveLevelText"
                                              return-object
                                              :label="`${
                                                $i18n.t('otherText.cognitive') +
                                                ' ' +
                                                $i18n.t('labels.level')
                                              }`"
                                              clearable
                                            ></v-select>
                                          </v-col>
                                          <v-col cols="12" class="py-0">
                                            <v-textarea
                                              v-model="formField.question"
                                              required
                                              rows="1"
                                              auto-grow
                                              :rules="formRules.required"
                                              :label="`${$i18n.t(
                                                'headings.question'
                                              )}`"
                                            ></v-textarea>
                                          </v-col>
                                          <v-col cols="12" class="py-0">
                                            <span class="text-h6">{{
                                              $i18n.t("headings.body")
                                            }}</span>
                                            <div class="d-flex align-end">
                                              <div
                                                class="pr-3"
                                                style="width: calc(100% - 40px)"
                                              >
                                                <RichTextEditor
                                                  v-model="formField.richText"
                                                  s3Folder="Assessments"
                                                >
                                                </RichTextEditor>
                                              </div>

                                              <v-tooltip top>
                                                <template
                                                  v-slot:activator="{
                                                    on,
                                                    attrs,
                                                  }"
                                                >
                                                  <v-btn
                                                    color="red"
                                                    fab
                                                    small
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="
                                                      removeQuestionField(
                                                        questionIndex,
                                                        questionFieldIndex
                                                      )
                                                    "
                                                  >
                                                    <v-icon>mdi-delete</v-icon>
                                                  </v-btn>
                                                </template>
                                                <span>{{
                                                  $i18n.t("actions.delete") +
                                                  " " +
                                                  $i18n.t("labels.field")
                                                }}</span>
                                              </v-tooltip>
                                            </div>
                                          </v-col>
                                        </v-row>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </draggable>
                                </v-expansion-panels>
                              </div>
                              <v-row no-gutters class="justify-center pb-2">
                                <v-btn
                                  color="primary"
                                  @click="addQuestionField(questionIndex)"
                                >
                                  {{
                                    $i18n.t("actions.add") +
                                    " " +
                                    $i18n.t("labels.field")
                                  }}
                                </v-btn>
                              </v-row>

                              <v-row no-gutters>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="red"
                                      v-bind="attrs"
                                      v-on="on"
                                      fab
                                      small
                                      icon
                                      @click="removeQuestion(questionIndex)"
                                    >
                                      <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{
                                    $i18n.t("actions.delete") +
                                    " " +
                                    $i18n.t("headings.question")
                                  }}</span>
                                </v-tooltip>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </draggable>
                      </div>
                    </v-expansion-panels>

                    <v-card-actions class="justify-center pt-0 pb-1">
                      <v-btn color="primary" @click="addQuestion">
                        {{
                          $i18n.t("actions.add") +
                          " " +
                          $i18n.t("headings.question")
                        }}
                      </v-btn>
                    </v-card-actions>
                  </v-tab-item>

                  <v-tab-item>
                    <div class="pa-2">
                      <div
                        v-for="(
                          questionObject, questionIndex
                        ) in editedItem.questions"
                        :key="questionIndex"
                      >
                        <div v-if="isNewSection(questionIndex)">
                          <h3>
                            {{
                              headingValue("Section") +
                              " " +
                              questionObject.sectionNumber +
                              (questionObject.sectionTitle !== ""
                                ? ": " + questionObject.sectionTitle
                                : "")
                            }}
                          </h3>
                        </div>
                        <div class="d-flex">
                          <h3>
                            {{
                              headingValue("Question") +
                              " " +
                              questionObject.number +
                              (questionObject.title !== ""
                                ? ": " + questionObject.title
                                : "")
                            }}
                          </h3>
                          <v-spacer />
                          <div style="min-width: fit-content">
                            <h3>
                              (
                              {{
                                questionObject.marks +
                                " " +
                                headingValue("Marks")
                              }}
                              )
                            </h3>
                          </div>
                        </div>
                        <v-row
                          no-gutters
                          v-for="(
                            questionField, questionFieldIndex
                          ) in questionObject.fields"
                          :key="questionFieldIndex"
                        >
                          <div class="d-flex" style="width: 100%">
                            <div
                              v-if="questionField.number !== ''"
                              style="min-width: fit-content; text-align: right"
                            >
                              <span class="mr-2">{{
                                questionField.number
                              }}</span>
                            </div>
                            <div
                              class="d-flex flex-column mr-2"
                              style="width: -webkit-fill-available"
                            >
                              <span
                                v-if="
                                  questionField.question &&
                                  questionField.question !== ''
                                "
                                >{{ questionField.question }}</span
                              >
                            </div>
                            <div
                              v-if="questionField.marks"
                              style="min-width: fit-content"
                            >
                              <h3 style="line-height: 24px">
                                ( {{ questionField.marks }} )
                              </h3>
                            </div>
                          </div>
                          <v-textarea
                            class="pt-0 mt-0"
                            v-model="questionField.answer"
                            rows="1"
                            auto-grow
                          ></v-textarea>
                        </v-row>
                      </div>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="pa-0"
                :style="
                  screenWidth >= 960
                    ? 'overflow-y: auto; overflow-x: hidden; max-height: calc(100vh - 164px);'
                    : ''
                "
              >
                <div>
                  <v-card-title>
                    <span class="text-h5">{{
                      $i18n.t("headings.print") +
                      " " +
                      $i18n.t("headings.preview")
                    }}</span>
                  </v-card-title>
                  <v-card outlined class="mx-2 pa-2">
                    <AssessmentTemplatePrinterExample
                      v-if="dialogTab === 0"
                      :template="editedItem"
                    />
                    <AssessmentTemplateMemoPrinterExample
                      v-else
                      :template="editedItem"
                    />
                  </v-card>
                </div>
              </v-col>
            </v-row>

            <v-card-actions class="align-end">
              <v-alert
                v-if="warningAlert !== ''"
                class="mb-0"
                style="padding: 6px"
                height="36"
                type="warning"
                >{{ warningAlert }}</v-alert
              >
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="close">
                {{ $i18n.t("actions.cancel") }}
              </v-btn>
              <v-btn
                :disabled="!valid || offline"
                color="primary"
                @click="save"
              >
                {{ $i18n.t("actions.save") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center">{{
          $i18n.t("otherText.deleteAssessment")
        }}</v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="closeDelete">{{
            $i18n.t("actions.cancel")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="deleteItemConfirm">{{
            $i18n.t("actions.ok")
          }}</v-btn>
          <!-- <v-spacer></v-spacer> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="dialogNewOrExistingTemplate"
      max-width="550px"
    >
      <v-card>
        <v-card-title class="justify-center">{{
          $i18n.t("assessments.createFrom")
        }}</v-card-title>
        <v-row no-gutters class="py-3 px-4" style="gap: 12px">
          <v-btn color="primary" @click="closeNewOrExistingTemplate">{{
            $i18n.t("actions.cancel")
          }}</v-btn>
          <v-spacer></v-spacer>
          <div
            class="d-flex align-center"
            :class="screenWidth < 422 ? 'flex-wrap' : ''"
            style="gap: 12px"
          >
            <v-btn color="primary" @click="openAssessmentTemplatesDialog">{{
              $i18n.t("headings.assessment") +
              " " +
              $i18n.t("headings.template")
            }}</v-btn>
            <span>{{ $i18n.t("otherText.or") }}</span>
            <v-btn color="primary" @click="createItem">{{
              $i18n.t("otherText.blank") + " " + $i18n.t("headings.assessment")
            }}</v-btn>
          </div>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogAssessmentTemplates">
      <v-card>
        <v-card-title class="justify-center">{{
          $i18n.t("assessments.pickAssessment")
        }}</v-card-title>
        <v-card-subtitle class="pb-0 pt-2 text-center"
          >{{
            syncedTeacherProfileModel?.payfastSubscriptionPackage?.grandFathered
              ? $i18n.t("templates.restrictedGrandFathered")
              : $i18n.t("templates.restricted")
          }}
        </v-card-subtitle>

        <v-card-title>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="searchAssessmentTemplates"
                append-icon="mdi-magnify"
                :label="`${$i18n.t('labels.search')}`"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" sm="6">
              <v-autocomplete v-model="searchAssessmentTemplates" :items="syncedSubjectArray" :item-text="subjectName"
                label="Subject" clearable></v-autocomplete>
            </v-col> -->
          </v-row>
        </v-card-title>
        <div class="px-4">
          <v-data-table
            :footer-props="{
              showFirstLastPage: true,
            }"
            v-model="selectedAssessmentTemplate"
            :headers="assessmentTemplateHeaders"
            :items="computedAssessmentTemplates"
            :sort-desc="sortDesc"
            :sort-by="sortBy"
            must-sort
            :search="searchAssessmentTemplates"
            single-select
            show-select
            :custom-filter="filterDataTable"
            :loading="
              !initiallyLoaded && computedAssessmentTemplates?.length === 0
            "
            loading-text="Loading Assessments..."
            no-data-text="No Assessments found"
          >
            <template v-slot:[`item.totalMinutes`]="{ item }">
              {{
                item.totalMinutes ? formattedTotalTime(item.totalMinutes) : ""
              }}
            </template>
          </v-data-table>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeAssessmentTemplates">{{
            $i18n.t("actions.cancel")
          }}</v-btn>
          <v-btn
            color="primary"
            :disabled="selectedAssessmentTemplate.length < 1"
            @click="createItemFromTemplate"
            >{{ $i18n.t("actions.create") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import {
  TeacherClassSubject,
  AssessmentTemplate,
  TeacherAssessmentTemplate,
  School,
  Subject,
  Taxonomy,
} from "@/models";
import _ from "lodash";
import AssessmentTemplatePrinterExample from "@/components/global-components/AssessmentTemplatePrinterExample.vue";
import AssessmentTemplateMemoPrinterExample from "@/components/global-components/AssessmentTemplateMemoPrinterExample.vue";
import RichTextEditor from "@/components/global-components/rich-text-editor/RichTextEditor.vue";
import Helper from "@/components/global-components/Helper.vue";
import draggable from "vuedraggable";

export default {
  name: "TeacherAssessments",
  components: {
    AssessmentTemplatePrinterExample,
    AssessmentTemplateMemoPrinterExample,
    RichTextEditor,
    Helper,
    draggable,
  },
  data: () => ({
    initiallyLoaded: false,
    expPanels: 1,
    expFieldPanels: 0,
    taxonomySubscription: null,
    teacherClassSubjectSubscription: null,
    schoolSubscription: null,
    subjectSubscription: null,
    assessmentTemplateSubscription: null,
    teacherAssessmentTemplateSubscription: null,
    teacherAssessmentTemplateSubscriptionSynced: false,
    syncedTaxonomyArray: [],
    syncedTeacherClassSubjectArray: [],
    syncedTeacherClassSubjectIDArray: [],
    syncedTeacherAssessmentTemplateArray: [],
    syncedAssessmentTemplateArray: [],
    syncedSchoolArray: [],
    syncedSubjectArray: [],
    syncedSubscribedSubjectIDArray: [],
    subjects: [],
    valid: false,
    dialog: false,
    dialogTab: null,
    dialogTabItems: ["Questions", "Memorandum"],
    fontFamilyItems: ["Default", "Arial"],
    dialogAssessmentTemplates: false,
    dialogNewOrExistingTemplate: false,
    dialogDelete: false,
    sortBy: "title",
    sortDesc: false,
    search: "",
    searchAssessmentTemplates: "",
    selectedAssessmentTemplate: [],
    headers: [],
    assessmentTemplateHeaders: [],
    teacherAssessmentTemplates: [],
    assessmentTemplates: [],
    editedIndex: -1,
    editedItem: {
      title: "",
      headings: [
        { label: "Grade", value: "Grade" },
        { label: "Date", value: "Date" },
        { label: "Term", value: "Term" },
        { label: "Total", value: "Total" },
        { label: "Time", value: "Time" },
        { label: "Minute", value: "Minute" },
        { label: "Minutes", value: "Minutes" },
        { label: "Hour", value: "Hour" },
        { label: "Hours", value: "Hours" },
        {
          label: "Information and Instructions",
          value: "Information and Instructions",
        },
        { label: "Section", value: "Section" },
        { label: "Sections", value: "Sections" },
        { label: "Question", value: "Question" },
        { label: "Questions", value: "Questions" },
        { label: "Marks", value: "Marks" },
        { label: "Award", value: "Award" },
      ],
      fontFamily: "Default",
      complexity: "",
      totalMarks: null,
      totalMinutes: null,
      questions: [
        //   {
        //     number: "",
        //     title: "",
        //     // sectionNumber: "",
        //     // sectionTitle: "",
        //     minutes: null,
        //     marks: null,
        //     richText: "",
        //     fields: [
        //       {
        //         number: "",
        //         cognitiveLevel: null,
        //         marks: null,
        //       question: "",
        //       richText: "",
        //       answer: "",
        //       }
        //     ]
        //   }
      ],
      instructions: [],
      taxonomys: [],
      school: null,
      subject: null,
      subjectID: "",
      schoolID: "",
      taxonomy: null,
    },
    defaultItem: {
      title: "",
      headings: [
        { label: "Grade", value: "Grade" },
        { label: "Date", value: "Date" },
        { label: "Term", value: "Term" },
        { label: "Total", value: "Total" },
        { label: "Time", value: "Time" },
        { label: "Minute", value: "Minute" },
        { label: "Minutes", value: "Minutes" },
        { label: "Hour", value: "Hour" },
        { label: "Hours", value: "Hours" },
        {
          label: "Information and Instructions",
          value: "Information and Instructions",
        },
        { label: "Section", value: "Section" },
        { label: "Sections", value: "Sections" },
        { label: "Question", value: "Question" },
        { label: "Questions", value: "Questions" },
        { label: "Marks", value: "Marks" },
        { label: "Award", value: "Award" },
      ],
      fontFamily: "Default",
      complexity: "",
      totalMarks: null,
      totalMinutes: null,
      questions: [],
      instructions: [],
      taxonomys: [],
      school: null,
      subject: null,
      subjectID: "",
      schoolID: "",
      taxonomy: null,
    },
    formRules: {
      required: [(v) => !!v || "This field is required"],
      name: [(v) => !!v || "Name is required"],
      type: [(v) => !!v || "Type is required"],
      label: [(v) => !!v || "Label is required"],
      title: [(v) => !!v || "Title is required"],
      subject: [(v) => !!v || "Subject is required"],
      complexity: [(v) => !!v || "Complexity is required"],
      placeholder: [(v) => !!v || "Placeholder is required"],
      hint: [(v) => !!v || "Hint is required"],
      marks: [(v) => !!v || "Marks are required"],
      minutes: [(v) => !!v || "Time is required"],
      // difficulty: [(v) => !!v || "Difficulty is required"],
      number: [(v) => !!v || "A Number is required"],
    },
    formFieldTypes: ["Text", "Textarea", "Select", "Radio", "Checkbox"],
    complexityLevels: ["Simple", "Detailed"],
  }),

  async mounted() {
    try {
      this.dialogTabItems = [
        this.$i18n.t("headings.questions"),
        this.$i18n.t("headings.memorandum"),
      ];

      this.headers = [
        { text: this.$i18n.t("labels.title"), value: "title" },
        { text: this.$i18n.t("labels.complexity"), value: "complexity" },
        { text: this.$i18n.t("labels.time"), value: "totalMinutes" },
        { text: this.$i18n.t("labels.marks"), value: "totalMarks" },
        {
          text: this.$i18n.t("headings.grade"),
          value: "subject.grade",
          sort: (a, b) => this.customSortGrade(a, b),
        },
        { text: this.$i18n.t("headings.subject"), value: "subject.subject" },
        {
          text: this.$i18n.t("labels.actions"),
          value: "actions",
          sortable: false,
        },
      ];

      this.assessmentTemplateHeaders = [
        { text: this.$i18n.t("labels.title"), value: "title" },
        { text: this.$i18n.t("labels.complexity"), value: "complexity" },
        { text: this.$i18n.t("labels.time"), value: "totalMinutes" },
        { text: this.$i18n.t("labels.marks"), value: "totalMarks" },
        { text: this.$i18n.t("labels.school"), value: "school.name" },
        {
          text: this.$i18n.t("headings.grade"),
          value: "subject.grade",
          sort: (a, b) => this.customSortGrade(a, b),
        },
        { text: this.$i18n.t("headings.subject"), value: "subject.subject" },
      ];

      await this.syncTeacherClassSubject();

      await this.syncTeacherAssessmentTemplates();

      await this.syncTaxonomies();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      teacherProfileSchoolID: (state) => state.teacherProfileSchoolID,
      teacherProfileProvince: (state) => state.teacherProfileProvince,
      offline: (state) => !state.isOnline,
      syncedTeacherProfileModel: (state) => state.syncedTeacherProfileModel,
    }),

    computedTeacherAssessmentTemplates() {
      if (this.subjects.length > 0) {
        this.teacherAssessmentTemplates.forEach((item) => {
          if (!item.subject) {
            const subject = _.find(this.subjects, { id: item.subjectID });
            if (item.subject !== subject)
              item.subject = subject || { grade: "", subject: "" };
          }
        });
      }

      return this.teacherAssessmentTemplates;
    },

    computedAssessmentTemplates() {
      if (this.subjects.length > 0 && this.syncedSchoolArray.length > 0) {
        this.assessmentTemplates.forEach((item) => {
          if (!item.subject) {
            const subject = _.find(this.subjects, { id: item.subjectID });
            if (item.subject !== subject)
              item.subject = subject || { grade: "", subject: "" };
          }

          if (!item.school) {
            const school = _.find(this.syncedSchoolArray, {
              id: item.schoolID,
            });
            if (item.school !== school) {
              item.school = school || {
                name: "",
                provinces: [],
                isGeneric: false,
              };
            }
          }
        });
      }
      return this.assessmentTemplates;
    },

    formTitle() {
      return this.editedIndex === -1
        ? this.$i18n.t("otherText.new") +
            " " +
            this.$i18n.t("headings.assessment")
        : this.$i18n.t("actions.edit") +
            " " +
            this.$i18n.t("headings.assessment");
    },

    isNewSection() {
      return (questionIndex) => {
        const question = this.editedItem.questions[questionIndex];
        return question && question.sectionNumber !== undefined;
      };
    },

    headingValue() {
      return (param) => {
        if (this.editedItem.headings) {
          const foundItem = _.find(this.editedItem.headings, { label: param });
          return foundItem ? foundItem.value : "";
        } else return "";
      };
    },

    instructionValidationRule() {
      return (value) => {
        if (value.length < 1) {
          return "Instructions are required";
        }
        return true;
      };
    },

    // Computed property for the screen width
    screenWidth() {
      let width = this.$vuetify.breakpoint.width;
      return width;
    },

    mergedTaxonomys() {
      if (this.editedItem.taxonomys?.length > 0) {
        return this.editedItem.taxonomys;
      } else if (
        this.editedIndex > -1 &&
        this.teacherAssessmentTemplates[this.editedIndex]?.taxonomy
      ) {
        const currentTaxonomy =
          this.teacherAssessmentTemplates[this.editedIndex].taxonomy;
        const taxonomyExists = this.syncedTaxonomyArray.some(
          (syncedTaxonomy) => {
            return syncedTaxonomy.updatedAt === currentTaxonomy.updatedAt;
          }
        );

        const mergedArray = taxonomyExists
          ? this.syncedTaxonomyArray
          : [...this.syncedTaxonomyArray, currentTaxonomy];

        return mergedArray;
      } else return this.syncedTaxonomyArray;
    },

    formFieldCognitiveLevels() {
      if (this.editedItem.taxonomy) {
        return this.editedItem.taxonomy.cognitiveLevels;
      } else return [];
    },

    warningAlert() {
      if (this.editedItem.questions.length > 0) {
        const cognitiveLevelMap = {}; // Create a map to store cognitive levels and their combined marks

        this.editedItem.questions.forEach((questionObj) => {
          questionObj.fields.forEach((fieldObj) => {
            if (fieldObj.cognitiveLevel) {
              const { label, percentage } = fieldObj.cognitiveLevel;
              const marks =
                typeof fieldObj.marks === "string"
                  ? parseFloat(fieldObj.marks)
                  : fieldObj.marks;

              if (!cognitiveLevelMap[label]) {
                cognitiveLevelMap[label] = { total: marks, percentage };
              } else cognitiveLevelMap[label].total += marks;
            }
          });
        });

        // Check if any cognitive level exceeds its allowed percentage
        const exceededCognitiveLevels = [];

        for (const label of Object.keys(cognitiveLevelMap)) {
          const { total, percentage } = cognitiveLevelMap[label];

          if (total > this.editedItem.totalMarks * (percentage / 100)) {
            exceededCognitiveLevels.push(label);
          }
        }

        if (exceededCognitiveLevels.length > 0) {
          const exceededLevelsMessage = exceededCognitiveLevels.join(", ");
          return `Cognitive level${
            exceededCognitiveLevels.length === 1
              ? " '" + exceededLevelsMessage
              : "s '" + exceededLevelsMessage
          }' exceed the allowed percentage of Total Marks.`;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },

  watch: {
    syncedTeacherProfileModel: {
      handler: function (val) {
        if (val) {
          if (!this.schoolSubscription) {
            this.syncSchools();
          }
          if (!this.subjectSubscription) {
            this.syncSubjects();
          }
        }
      },
      immediate: true,
      deep: true,
    },

    syncedSchoolArray: {
      handler: function (val) {
        if (
          !this.assessmentTemplateSubscription &&
          val.length > 0 &&
          this.syncedSubjectArray.length > 0
        ) {
          this.syncAssessmentTemplates();
        }
      },
      immediate: true,
      deep: true,
    },

    syncedSubjectArray: {
      handler: function (val) {
        if (
          !this.assessmentTemplateSubscription &&
          val.length > 0 &&
          this.syncedSchoolArray.length > 0
        ) {
          this.syncAssessmentTemplates();
        }
      },
      immediate: true,
      deep: true,
    },

    async valid(val) {
      if (!val && this.dialog) {
        await this.validateForm();
      }
    },
    async expPanels() {
      this.expFieldPanels = 0;
      await this.validateForm();
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    filterDataTable(value, search, item) {
      let searches = search.trim().toLowerCase().split(" ");

      let stringified = JSON.stringify(item).toLowerCase();
      let found = 0;
      for (let s in searches) {
        if (stringified.indexOf(searches[s]) > -1) found++;
      }
      // console.log(found);
      return found === searches.length;
    },

    async validateForm() {
      setTimeout(() => {
        if (this.$refs.form) this.$refs.form.validate();
      }, 0);
    },

    updateQuestionMarks() {
      let newTotalMarks = 0;

      this.editedItem.questions.forEach((questionObj) => {
        if (typeof questionObj.marks !== "number") {
          newTotalMarks += questionObj.marks
            ? parseFloat(questionObj.marks)
            : 0;
        } else {
          newTotalMarks += questionObj.marks;
        }
      });

      this.editedItem.totalMarks = newTotalMarks ? newTotalMarks : 0;
    },

    formattedTotalTime(val) {
      const totalMinutes = typeof val !== "string" ? val : parseInt(val);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      if (hours === 0) {
        return `${minutes} Minute${minutes > 1 ? "s" : ""}`;
      } else if (minutes === 0) {
        return `${hours} Hour${hours > 1 ? "s" : ""}`;
      } else {
        return `${hours} Hour${hours > 1 ? "s" : ""} ${minutes} Minute${
          minutes > 1 ? "s" : ""
        }`;
      }
    },

    openAssessmentTemplatesDialog() {
      this.dialogNewOrExistingTemplate = false;
      this.dialogAssessmentTemplates = true;
    },

    copyPlaceholderToValue(formField) {
      if (!formField.value) {
        formField.value = formField.placeholder;
      }
    },

    customSortGrade(a, b) {
      a = a === "R" ? -Infinity : parseInt(a, 10);
      b = b === "R" ? -Infinity : parseInt(b, 10);
      if (this.sortDesc) {
        // Switch the order for descending
        [a, b] = [b, a];
      }
      return a - b;
    },

    schoolName(item) {
      return `${item.name}`;
    },

    subjectName(item) {
      return `${"Grade: " + item.grade + ", Subject: " + item.subject}`;
    },

    taxonomyText(item) {
      if (item.cognitiveLevels) {
        let cogLevels =
          " (" +
          `${
            item.cognitiveLevels[0].label +
            ": " +
            item.cognitiveLevels[0].percentage +
            "%"
          }`;

        for (let i in item.cognitiveLevels) {
          if (i > 0) {
            cogLevels =
              cogLevels +
              ", " +
              item.cognitiveLevels[i].label +
              ": " +
              item.cognitiveLevels[i].percentage +
              "%";
          }
        }
        cogLevels = cogLevels + ")";
        return `${item.name + cogLevels}`;
      } else return `${item.name}`;
    },

    cognitiveLevelText(item) {
      const cogLevel = item.label + ": " + item.percentage + "%";
      return `${cogLevel}`;
    },

    async newSection(questionIndex) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.questions[questionIndex].sectionNumber = "";
        mutableObj.questions[questionIndex].sectionTitle = "";

        this.editedItem = Object.assign({}, mutableObj);

        await this.validateForm();
      } catch (error) {
        console.log(error);
      }
    },

    removeSection(questionIndex) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        delete mutableObj.questions[questionIndex].sectionNumber;
        delete mutableObj.questions[questionIndex].sectionTitle;

        this.editedItem = Object.assign({}, mutableObj);
      } catch (error) {
        console.log(error);
      }
    },

    async addQuestionField(questionIndex) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.questions[questionIndex].fields.push({
          number: "",
          cognitiveLevel: null,
          marks: null,
          question: "",
          richText: "",
          answer: "",
        });

        this.editedItem = Object.assign({}, mutableObj);

        await this.validateForm();
      } catch (error) {
        console.log(error);
      }
    },

    removeQuestionField(questionIndex, fieldIndex) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.questions[questionIndex].fields.splice(fieldIndex, 1);

        this.editedItem = Object.assign({}, mutableObj);
      } catch (error) {
        console.log(error);
      }
    },

    async addQuestion() {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.questions.push({
          number: "",
          title: "",
          // sectionNumber: "",
          // sectionTitle: "",
          minutes: null,
          marks: null,
          richText: "",
          fields: [],
          // subQuestions: []
        });

        this.editedItem = Object.assign({}, mutableObj);

        await this.validateForm();
      } catch (error) {
        console.log(error);
      }
    },

    removeQuestion(questionIndex) {
      try {
        const mutableObj = _.cloneDeep(this.editedItem);

        mutableObj.questions.splice(questionIndex, 1);

        this.editedItem = Object.assign({}, mutableObj);
      } catch (error) {
        console.log(error);
      }
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.editedIndex = -1;
      this.expPanels = 1;
      this.dialogTab = null;
      this.valid = false;
    },

    createItem() {
      this.dialogNewOrExistingTemplate = false;
      this.dialog = true;
    },

    createItemFromTemplate() {
      this.editedItem = _.cloneDeep(this.selectedAssessmentTemplate[0]);
      this.dialogAssessmentTemplates = false;

      if (!this.editedItem.headings) {
        this.editedItem.headings = _.cloneDeep(this.defaultItem.headings);
      }
      if (!this.editedItem.fontFamily) {
        this.editedItem.fontFamily = _.cloneDeep(this.defaultItem.fontFamily);
      }

      this.editedItem.headings = this.editedItem.headings.filter(
        (item) => item.label !== "Points"
      );

      this.defaultItem.headings.forEach((defaultHeading) => {
        const existsInEdited = this.editedItem.headings.some(
          (editedHeading) => editedHeading.label === defaultHeading.label
        );

        if (!existsInEdited) {
          // Add the defaultHeading to this.editedItem.headings
          this.editedItem.headings.push(defaultHeading);
        }
      });

      this.dialog = true;
    },

    printItem(itemId) {
      // window.open(`${process.env.BASE_URL}app/printtemplate/` + itemId, '_blank');
      this.$router.push({
        path: `${process.env.BASE_URL}app/print/assessment/` + itemId,
      });
    },

    duplicateItem(item) {
      this.editedItem = _.cloneDeep(item);
      // this.editedItem = Object.assign({}, item);

      if (!this.editedItem.headings) {
        this.editedItem.headings = _.cloneDeep(this.defaultItem.headings);
      }
      if (!this.editedItem.fontFamily) {
        this.editedItem.fontFamily = _.cloneDeep(this.defaultItem.fontFamily);
      }

      this.editedItem.headings = this.editedItem.headings.filter(
        (item) => item.label !== "Points"
      );

      this.defaultItem.headings.forEach((defaultHeading) => {
        const existsInEdited = this.editedItem.headings.some(
          (editedHeading) => editedHeading.label === defaultHeading.label
        );

        if (!existsInEdited) {
          // Add the defaultHeading to this.editedItem.headings
          this.editedItem.headings.push(defaultHeading);
        }
      });

      this.dialog = true;
    },

    async editItem(item) {
      this.editedIndex = this.teacherAssessmentTemplates.indexOf(item);
      this.editedItem = _.cloneDeep(item);
      // this.editedItem = Object.assign({}, item);

      if (!this.editedItem.headings) {
        this.editedItem.headings = _.cloneDeep(this.defaultItem.headings);
      }
      if (!this.editedItem.fontFamily) {
        this.editedItem.fontFamily = _.cloneDeep(this.defaultItem.fontFamily);
      }

      this.editedItem.headings = this.editedItem.headings.filter(
        (item) => item.label !== "Points"
      );

      this.defaultItem.headings.forEach((defaultHeading) => {
        const existsInEdited = this.editedItem.headings.some(
          (editedHeading) => editedHeading.label === defaultHeading.label
        );

        if (!existsInEdited) {
          // Add the defaultHeading to this.editedItem.headings
          this.editedItem.headings.push(defaultHeading);
        }
      });

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.teacherAssessmentTemplates.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await DataStore.delete(
        this.syncedTeacherAssessmentTemplateArray[this.editedIndex]
      );
      this.closeDelete();
    },

    closeNewOrExistingTemplate() {
      this.dialogNewOrExistingTemplate = false;
    },

    closeAssessmentTemplates() {
      this.dialogAssessmentTemplates = false;
      this.$nextTick(() => {
        this.selectedAssessmentTemplate = [];
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.selectedAssessmentTemplate = [];
        this.resetForm();
      });
    },

    closeDelete() {
      this.dialogDelete = false;

      this.$nextTick(() => {
        this.resetForm();
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update subjectTemplate
        await this.updateTeacherTemplate();
      } else {
        // Create new subjectTemplate
        await this.createTeacherTemplate();
      }
      this.close();
    },

    async createTeacherTemplate() {
      try {
        // this.editedItem.totalMarks = 0;

        this.editedItem.questions.forEach((questionObj) => {
          questionObj.title = questionObj.title.trim();
          // this.editedItem.totalMarks += parseFloat(questionObj.marks);

          questionObj.fields.forEach((fieldObj) => {
            // this.editedItem.totalMarks += fieldObj.marks;

            if (
              fieldObj.type !== "Select" &&
              fieldObj.type !== "Radio" &&
              fieldObj.type !== "Checkbox"
            ) {
              fieldObj.items = [];
            }
          });
        });

        await DataStore.save(
          new TeacherAssessmentTemplate({
            title: this.editedItem.title.trim(),
            headings: this.editedItem.headings,
            fontFamily: this.editedItem.fontFamily,
            complexity: this.editedItem.complexity,
            totalMarks:
              typeof this.editedItem.totalMarks === "string"
                ? parseFloat(this.editedItem.totalMarks)
                : this.editedItem.totalMarks,
            totalMinutes: parseInt(this.editedItem.totalMinutes),
            questions: this.editedItem.questions,
            instructions: this.editedItem.instructions,
            subjectID: this.editedItem.subject.id,
            taxonomy: this.editedItem.taxonomy,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updateTeacherTemplate() {
      try {
        // this.editedItem.totalMarks = 0;

        this.editedItem.questions.forEach((questionObj) => {
          questionObj.title = questionObj.title.trim();
          // this.editedItem.totalMarks += parseFloat(questionObj.marks);

          questionObj.fields.forEach((fieldObj) => {
            // this.editedItem.totalMarks += fieldObj.marks;

            if (
              fieldObj.type !== "Select" &&
              fieldObj.type !== "Radio" &&
              fieldObj.type !== "Checkbox"
            ) {
              fieldObj.items = [];
            }
          });
        });

        await DataStore.save(
          TeacherAssessmentTemplate.copyOf(
            _.find(this.syncedTeacherAssessmentTemplateArray, {
              id: this.editedItem.id,
            }),
            (updateModel) => {
              updateModel.title = this.editedItem.title.trim();
              updateModel.headings = this.editedItem.headings;
              updateModel.fontFamily = this.editedItem.fontFamily;
              updateModel.complexity = this.editedItem.complexity;
              updateModel.totalMarks =
                typeof this.editedItem.totalMarks === "string"
                  ? parseFloat(this.editedItem.totalMarks)
                  : this.editedItem.totalMarks;
              updateModel.totalMinutes = parseInt(this.editedItem.totalMinutes);
              updateModel.questions = this.editedItem.questions;
              updateModel.instructions = this.editedItem.instructions;
              updateModel.subjectID = this.editedItem.subject.id;
              updateModel.taxonomy = this.editedItem.taxonomy;
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncTaxonomies() {
      try {
        this.taxonomySubscription = DataStore.observeQuery(Taxonomy).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedTaxonomyArray !== items) {
              this.syncedTaxonomyArray = items;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncTeacherClassSubject() {
      try {
        this.teacherClassSubjectSubscription = DataStore.observeQuery(
          TeacherClassSubject
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedTeacherClassSubjectArray !== items
            ) {
              this.syncedTeacherClassSubjectArray = items;

              this.syncedTeacherClassSubjectIDArray = items.map(
                (ts) => ts.subjectID
              );

              // if (!this.subjectSubscription) {
              //   this.syncSubjects(this.syncedTeacherClassSubjectIDArray);
              // }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSubjects() {
      try {
        this.subjectSubscription = DataStore.observeQuery(Subject).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedSubjectArray !== items
            ) {
              this.syncedSubjectArray = items;

              const validGrades =
                this.syncedTeacherProfileModel?.payfastSubscriptionPackage
                  ?.grades || [];

              // Filter subjects where the grade is included in the validGrades array
              const filteredSubjects = items.filter((subject) =>
                validGrades.includes(subject.grade)
              );

              // Map the filtered subjects to their ids
              const subjectIds = filteredSubjects.map((subject) => subject.id);

              this.syncedSubscribedSubjectIDArray = subjectIds;

              // console.log(this.syncedSubscribedSubjectIDArray);

              this.subjects = _.cloneDeep(
                _.sortBy(items, (item) => {
                  if (item.grade === "R") {
                    return -Infinity; // 'R' should appear first
                  } else if (item.grade === "1") {
                    return 0; // '1' should appear second
                  } else {
                    return parseInt(item.grade); // Sort the remaining numbers normally
                  }
                })
              );
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSchools() {
      try {
        this.schoolSubscription = DataStore.observeQuery(School, (s) =>
          s.or((s) => [
            s.and((s) => [
              s.isGeneric("eq", true),
              s.provinces("contains", this.teacherProfileProvince),
            ]),
            s.id("eq", this.teacherProfileSchoolID), // Check for ID match with the query
          ])
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              items.length > 0 &&
              this.syncedSchoolArray !== items
            ) {
              this.syncedSchoolArray = items;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncTeacherAssessmentTemplates() {
      try {
        this.teacherAssessmentTemplateSubscription = DataStore.observeQuery(
          TeacherAssessmentTemplate
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              this.syncedTeacherAssessmentTemplateArray !== items
            ) {
              this.syncedTeacherAssessmentTemplateArray = items;

              this.teacherAssessmentTemplates = _.cloneDeep(items);

              this.teacherAssessmentTemplateSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.teacherAssessmentTemplateSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncAssessmentTemplates() {
      try {
        const schoolIdsArray = this.syncedSchoolArray.map(
          (school) => school.id
        );

        const grandFathered = this.syncedTeacherProfileModel
          ?.payfastSubscriptionPackage?.grandFathered
          ? true
          : false;

        const predicate = (assessmentTemplate) =>
          assessmentTemplate.and((assessmentTemplate) => [
            grandFathered
              ? assessmentTemplate.or((assessmentTemplate) =>
                  this.syncedTeacherClassSubjectIDArray.reduce(
                    (c, id) => c.subjectID("eq", id),
                    assessmentTemplate
                  )
                )
              : this.syncedSubscribedSubjectIDArray?.length > 0
              ? assessmentTemplate.or((assessmentTemplate) =>
                  this.syncedSubscribedSubjectIDArray.reduce(
                    (c, id) => c.subjectID("eq", id),
                    assessmentTemplate
                  )
                )
              : assessmentTemplate.id("eq", null),

            assessmentTemplate.or((assessmentTemplate) => [
              assessmentTemplate.schoolID("eq", this.teacherProfileSchoolID),

              assessmentTemplate.or((assessmentTemplate) =>
                schoolIdsArray.reduce(
                  (c, id) => c.schoolID("eq", id),
                  assessmentTemplate
                )
              ),
            ]),
          ]);

        this.assessmentTemplateSubscription = DataStore.observeQuery(
          AssessmentTemplate,
          predicate
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              this.syncedAssessmentTemplateArray !== items
            ) {
              const filteredItems = items.filter(
                (item) =>
                  this.syncedSubscribedSubjectIDArray.includes(
                    item.subjectID
                  ) &&
                  this.syncedTeacherClassSubjectIDArray.includes(item.subjectID)
              );

              this.syncedAssessmentTemplateArray = filteredItems;

              this.assessmentTemplates = _.cloneDeep(filteredItems);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.taxonomySubscription) {
      this.taxonomySubscription.unsubscribe();
    }
    if (this.teacherAssessmentTemplateSubscription) {
      this.teacherAssessmentTemplateSubscription.unsubscribe();
    }
    if (this.teacherClassSubjectSubscription) {
      this.teacherClassSubjectSubscription.unsubscribe();
    }
    if (this.assessmentTemplateSubscription) {
      this.assessmentTemplateSubscription.unsubscribe();
    }
    if (this.schoolSubscription) {
      this.schoolSubscription.unsubscribe();
    }
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }
  },
};
</script>

<style>
.ProseMirror {
  min-height: 0px !important;
}

#expPanelContent > * {
  padding: 12px !important;
}

.panelTopBorder {
  border-radius: 4px !important;
}

.panelTopBorder::after {
  border-top: none !important;
}

.questionFieldPanelTopBorder .v-expansion-panel:first-child {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.questionFieldPanelTopBorder .v-expansion-panel--active + div {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.questionFieldPanelTopBorder .v-expansion-panel:last-child {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.questionFieldPanelTopBorder .v-expansion-panel--next-active {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.questionFieldPanelTopBorder .v-expansion-panel--active {
  border-radius: 4px !important;
}

.questionFieldPanelTopBorder .v-expansion-panel--active #expPanelContent {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    max-width: 100% !important;
    width: auto !important;
    min-width: 200px !important;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}
</style>
