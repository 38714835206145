<template>
  <div id="teacherproductorders">
    <v-card class="mx-auto" max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4">
          {{ $i18n.t("headings.product") + " " + $i18n.t("headings.orders") }}
          <!-- <helper topic="classes" /> -->
        </v-card-title>
      </v-row>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="headers"
          :items="nonPendingOrders"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          must-sort
          :search="search"
          show-group-by
          no-data-text="No Orders found"
          :loading="
            !syncedTeacherProductsOrders && nonPendingOrders?.length === 0
          "
          loading-text="Loading Orders..."
        >
          <!-- <template v-slot:[`item.orderID`]="{ item }">
            {{ `#${item.orderID}` }}
          </template> -->
          <template v-slot:[`item.orderDateTime`]="{ item }">
            {{ formatDate(item.orderDateTime) }}
          </template>
          <template v-slot:[`item.orderTotal`]="{ item }">
            {{ "R" + item.orderTotal.toFixed(2) }}
          </template>
          <template v-slot:[`item.orderProducts`]="{ item }">
            {{ item.orderProducts?.length > 0 ? item.orderProducts.length : 0 }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="viewItem(item)">
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card class="overflow-x-hidden overflow-y-auto" max-height="90vh">
        <v-card-title>
          <span class="text-h5">
            {{ $i18n.t("actions.view") + " " + $i18n.t("headings.order") }}
            <!-- <helper topic="classesedit" /> -->
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p>Date: {{ formatDate(editedItem.orderDateTime) }}</p>

                <p>
                  {{
                    $i18n.t("headings.order") +
                    " " +
                    $i18n.t("otherText.id") +
                    ": " +
                    editedItem.orderID
                  }}
                </p>
                <p>
                  {{ $i18n.t("labels.status") + ": " + editedItem.orderStatus }}
                </p>
                <p>Payment Method: {{ editedItem.orderPaymentMethod }}</p>

                <div>
                  <p>
                    {{ $i18n.t("headings.products") + ":" }}
                  </p>
                  <v-row style="gap: 8px" no-gutters>
                    <v-chip
                      v-for="(
                        orderProduct, opIndex
                      ) in editedItem.orderProducts"
                      :key="opIndex"
                      class="text-wrap py-1"
                      :to="`/app/products/${orderProduct.id}`"
                      style="height: fit-content"
                      >{{ orderProduct.title }}</v-chip
                    ></v-row
                  >
                </div>
              </v-col>

              <v-col
                cols="12"
                v-show="editedItem.orderProducts?.length > 0"
                style="font-size: 1.3rem; line-height: 1.5"
              >
                <v-row no-gutters>
                  {{ $i18n.t("headings.products") }}: <v-spacer></v-spacer> R
                  {{ editedItemProductsPrice.toFixed(2) }}
                </v-row>
                <v-row
                  no-gutters
                  v-show="editedItemPhysicalProducts?.length > 0"
                >
                  {{ $i18n.t("headings.shippingFee") }}: <v-spacer></v-spacer> R
                  {{ (160).toFixed(2) }}
                </v-row>
                <v-row no-gutters>
                  {{
                    $i18n.t("labels.subTotal") +
                    " (" +
                    $i18n.t("labels.vatIncluded") +
                    ")"
                  }}: <v-spacer></v-spacer> R
                  {{ editedItemSubTotal.toFixed(2) }}
                </v-row>
                <v-row no-gutters>
                  {{
                    $i18n.t("labels.total") +
                    " (" +
                    $i18n.t("labels.vatExcluded") +
                    ")"
                  }}: <v-spacer></v-spacer> R
                  {{ editedItemExclVAT.toFixed(2) }}
                </v-row>
                <v-row no-gutters>
                  {{ $i18n.t("labels.vat") }} ( 15% ): <v-spacer></v-spacer> R
                  {{ editedItemTotalVat.toFixed(2) }}
                </v-row>
                <v-row no-gutters>
                  {{ $i18n.t("labels.total") }}: <v-spacer></v-spacer> R
                  {{ editedItemSubTotal.toFixed(2) }}
                </v-row>
              </v-col>

              <v-col cols="12" v-show="editedItem.orderPaymentMethod === 'EFT'">
                <v-card class="pa-3">
                  <span class="mb-0 text-subtitle-1"
                    ><strong>Bank Details:</strong></span
                  >
                  <p class="mb-0">Account holder: A Dempers</p>
                  <p class="mb-0">Account number: 1216434069</p>
                  <p class="mb-0">Account type: Current account</p>
                  <p class="mb-0">Bank name: Nedbank</p>
                  <p class="mb-0">Branch code: 198765</p>
                  <p class="mb-0">Reference: Your Name</p>
                  <p class="mb-0">
                    Proof of payment: admin@missanrisclassroom.com / 0713230930
                  </p>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <div v-if="editedItemPhysicalProductsQuestions?.length > 0">
          <v-card-title class="pt-0">
            <span class="text-h5">Questions</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <div
                v-for="(
                  productWithQuestions, productWithQuestionsIndex
                ) in editedItemPhysicalProductsQuestions"
                :key="productWithQuestionsIndex"
              >
                <span class="text-h6">{{ productWithQuestions.title }}</span>

                <v-row>
                  <v-col cols="12">
                    <template
                      v-for="(
                        question, questionIndex
                      ) in productWithQuestions.questions"
                    >
                      <v-text-field
                        v-if="question.type === 'Text'"
                        :key="'text-' + questionIndex"
                        :value="question.value"
                        :label="question.label"
                        :hint="question.hint"
                        :placeholder="question.placeholder"
                        :required="question.required"
                        readonly
                        :rules="question.required ? formRules.required : []"
                      ></v-text-field>
                      <v-textarea
                        v-if="question.type === 'Textarea'"
                        :key="'textarea-' + questionIndex"
                        :value="question.value"
                        :label="question.label"
                        :hint="question.hint"
                        :placeholder="question.placeholder"
                        auto-grow
                        :required="question.required"
                        readonly
                        :rules="question.required ? formRules.required : []"
                      ></v-textarea>
                      <v-select
                        v-if="
                          question.type === 'Select' ||
                          question.type === 'Multi Select'
                        "
                        :key="'select-' + questionIndex"
                        :value="question.value"
                        :label="question.label"
                        :items="question.items"
                        :hint="question.hint"
                        :placeholder="question.placeholder"
                        :multiple="question.type === 'Multi Select'"
                        :required="question.required"
                        readonly
                        :rules="question.required ? formRules.required : []"
                      ></v-select>
                      <v-radio-group
                        v-if="question.type === 'Radio'"
                        :key="'radio-' + questionIndex"
                        :value="question.value"
                        :label="question.label"
                        :hint="question.hint"
                        readonly
                        :rules="question.required ? formRules.required : []"
                      >
                        <v-radio
                          v-for="(item, itemIndex) in question.items"
                          :key="'item-' + itemIndex"
                          :label="item"
                          :value="item"
                        ></v-radio>
                      </v-radio-group>
                      <div
                        v-if="question.type === 'Checkbox'"
                        :key="'checkbox-container-' + questionIndex"
                      >
                        <span class="v-label">{{ question.label }}</span>
                        <template>
                          <v-checkbox
                            v-for="(item, itemIndex) in question.items"
                            :key="'checkbox-' + questionIndex + '-' + itemIndex"
                            v-model="question.value"
                            :value="item"
                            :label="item"
                            readonly
                            :rules="question.required ? formRules.required : []"
                          ></v-checkbox>
                        </template>
                      </div>
                    </template>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
        </div>

        <div v-if="editedItemPhysicalProducts?.length > 0">
          <v-card-title class="py-0" style="gap: 12px">
            <span class="text-h5">{{ $i18n.t("headings.shippingInfo") }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :value="shippingDetails.address1"
                    :label="`${$i18n.t('labels.address')} 1`"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :value="shippingDetails.address2"
                    :label="`${$i18n.t('labels.address')} 2`"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="shippingDetails.city"
                    :label="$i18n.t('labels.city')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    :value="shippingDetails.province"
                    :items="provinces"
                    :label="`${$i18n.t('labels.province')}*`"
                    readonly
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="shippingDetails.postalCode"
                    :label="$i18n.t('labels.postalCode')"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="shippingDetails.country"
                    readonly
                    :label="$i18n.t('labels.country')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="shippingDetails.phoneNumber"
                    :label="
                      $i18n.t('labels.phone') + ' ' + $i18n.t('labels.number')
                    "
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="close">
            {{ $i18n.t("actions.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
// import Helper from "@/components/global-components/Helper.vue";

export default {
  name: "TeacherProductOrders",
  // components: { Helper },
  data: () => ({
    initiallyLoaded: false,
    dialog: false,
    sortBy: "orderDateTime",
    sortDesc: true,
    headers: [],
    search: "",
    valid: false,

    editedItem: {
      orderDateTime: "",
      orderID: "",
      orderPayment: null,
      orderPaymentMethod: "",
      orderProducts: [],
      orderShippingDetails: null,
      orderStatus: "",
      orderTotal: 0,
      orderVAT: 0,
      owner: "",
      teacherprofileID: "",
    },
    defaultItem: {
      orderDateTime: "",
      orderID: "",
      orderPayment: null,
      orderPaymentMethod: "",
      orderProducts: [],
      orderShippingDetails: null,
      orderStatus: "",
      orderTotal: 0,
      orderVAT: 0,
      owner: "",
      teacherprofileID: "",
    },

    shippingDetails: {
      address1: "",
      address2: "",
      city: "",
      province: "",
      postalCode: "",
      country: "South Africa",
      phoneNumber: "",
    },

    defaultShippingDetails: {
      address1: "",
      address2: "",
      city: "",
      province: "",
      postalCode: "",
      country: "South Africa",
      phoneNumber: "",
    },

    provinces: [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "Northern Cape",
      "North West",
      "Western Cape",
    ],

    formRules: {
      required: [(v) => !!v || "This field is required"],
      paymentMethod: [(v) => !!v || "a Payment Method is required"],
      products: [(v) => (!!v && v.length > 0) || "Products are required"],
      address1: [(v) => !!v || "Address is required"],
      city: [(v) => !!v || "City is required"],
      province: [(v) => !!v || "Province is required"],
      postalCode: [(v) => !!v || "Postal code is required"],
      country: [(v) => !!v || "Country is required"],
      phoneNumber: [
        (v) => !!v || "Phone number is required",
        (v) =>
          /^(?:\+27|0)\s?\d{2}\s?\d{3}\s?\d{4}$/.test(v) ||
          "Invalid South African phone number",
      ],
    },
  }),

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
      syncedTeacherProductsOrders: (state) => state.syncedTeacherProductsOrders,
      syncedTeacherProductsOrderModels: (state) =>
        state.syncedTeacherProductsOrderModels,
    }),

    nonPendingOrders() {
      return this.syncedTeacherProductsOrderModels.filter(
        (order) => order.orderStatus !== "PENDING"
      );
    },

    editedItemPhysicalProducts() {
      return this.editedItem.orderProducts?.filter(
        (product) => product.physical === true
      );
    },

    editedItemPhysicalProductsQuestions() {
      return this.editedItemPhysicalProducts?.filter(
        (product) => product.questions?.length > 0
      );
    },

    editedItemProductsPrice() {
      if (this.editedItem.orderProducts?.length === 0) return 0;

      let price = 0;

      for (let product of this.editedItem.orderProducts) {
        price +=
          product.price && product.discountPercentage
            ? product.price - product.price * (product.discountPercentage / 100)
            : product.price;
      }

      return price;
    },

    editedItemSubTotal() {
      return this.editedItemPhysicalProducts?.length > 0
        ? 160 + this.editedItemProductsPrice
        : this.editedItemProductsPrice;
    },

    editedItemExclVAT() {
      return this.editedItemSubTotal
        ? Number.parseFloat(this.editedItemSubTotal / (1 + 0.15))
        : 0;
    },

    editedItemTotalVat() {
      return this.editedItemSubTotal
        ? Number.parseFloat(
            (this.editedItemSubTotal / (1 + 0.15) - this.editedItemSubTotal) *
              -1
          )
        : 0;
    },
  },

  async mounted() {
    try {
      // this.functions wont work in data because it this.something refers to something else withing data and not for example a method.
      this.headers = [
        { text: "Order #", value: "orderID", groupable: false },
        { text: "Date", value: "orderDateTime", groupable: false },
        { text: "Status", value: "orderStatus" },
        { text: "Payment Method", value: "orderPaymentMethod" },
        { text: "Total", value: "orderTotal", groupable: false },
        { text: "Products Count", value: "orderProducts", groupable: false },
        {
          text: this.$i18n.t("labels.actions"),
          value: "actions",
          sortable: false,
          groupable: false,
        },
      ];
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    // "$route.params.orderId": {
    //   handler(val) {
    //     if (typeof val === "string") {
    //       // this.orderId = val;
    //       console.log("orderId", val);
    //     }
    //   },
    //   immediate: true,
    // },
    syncedTeacherProductsOrderModels: {
      handler(val) {
        if (val?.length > 0 && typeof this.$route.params.orderId === "string") {
          const order = _.find(val, { orderID: this.$route.params.orderId });
          if (order) {
            this.viewItem(order);
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return "";
      return new Date(date).toLocaleString();
    },

    viewItem(item) {
      this.editedItem = Object.assign({}, item);

      if (item.orderShippingDetails) {
        this.shippingDetails = _.cloneDeep(item.orderShippingDetails);
      } else {
        this.shippingDetails = _.cloneDeep(this.defaultShippingDetails);
      }

      this.dialog = true;
    },

    resetForm() {
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.shippingDetails = _.cloneDeep(this.defaultShippingDetails);
      this.valid = false;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.resetForm();
      });
    },
  },
};
</script>
