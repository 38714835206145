const af = {
  headings: {
    home: "Tuis",
    about: "Meer oor ons",
    contactUs: "Kontak ons",
    login: "Teken in",
    register: "Registreer",
    signIn: "Teken in",
    signOut: "Teken uit",
    profile: "Profiel",
    howItWorks: "Hoe dit werk",
    pricing: "Koste",
    prices: "Pryse",
    meetTheTeam: "Ontmoet die span",
    subscription: "Lidmaatskap",
    package: "Pakket",
    subscribe: "Sluit aan",
    subscribed: "Lidmaatskap",
    schedule: "Skedule",
    classes: "Klasse",
    class: "Klas",
    teach: "Onderrig",
    recheck: "Herondersoek",
    validUntil: "Geldig Tot",
    grades: "Grade",
    grade: "Graad",
    downloadQuota: "Aflaaikwota",
    day: "Dag",
    subject: "Vakgebied",
    resource: "Hulpbron",
    library: "Biblioteek",
    resourceLibrary: "Hulpbronbiblioteek",
    calendar: "Kalender",
    classroom: "Klaskamer",
    lessons: "Lesse",
    lesson: "Les",
    assessments: "Assesserings",
    assessment: "Assessering",
    support: "Navrae",
    print: "Druk uit",
    category: "Kategorie",
    categories: "Kategorieë",
    featured: "Vertoonde",
    product: "Produk",
    products: "Produkte",
    order: "Bestelling",
    orders: "Bestellings",
    content: "Inhoud",
    planner: "Beplanner",
    headings: "Opskrifte",
    defaults: "Standaard Titels",
    template: "Templaat",
    preview: "Voorskou",
    memorandum: "Memorandum",
    questions: "Vrae",
    question: "Vraag",
    section: "Afdeling",
    body: "Liggaam",
    logo: "Logo",
    activity: "Aktiwiteit",
    cart: "Mandjie",
    shopping: "Inkopies",
    store: "Winkel",
    bought: "Gekoopte",
    shipping: "Aflewering",
    shippingInfo: "Afleweringsinformasie",
    shippingFee: "Afleweringsfooi",
  },
  labels: {
    title: "Titel",
    name: "Naam",
    surname: "Van",
    email: "E-pos",
    password: "Wagwoord",
    confirmPassword: "Bevestig wagwoord",
    language: "Taal",
    province: "Provinsie",
    school: "Skool",
    schoolDaysPerWeek: "Skooldae per week",
    periods: "Periodes",
    periodDuration: "Duur van periode",
    breaks: "Pouses",
    enterNumPressEnter: "Voer 'n nommer in en druk enter",
    startTime: "Begintyd",
    endTime: "Eindtyd",
    actions: "Aksies",
    subject: "Onderwerp",
    message: "Boodskap",
    search: "Soek",
    tags: "Etikette",
    more: "Meer",
    details: "Besonderhede",
    files: "Lêers",
    urls: "URL's",
    complexity: "Kompleksiteit",
    time: "Tyd",
    minutes: "Minute",
    instructions: "Instruksies",
    taxonomy: "Taksonomie",
    number: "Nommer",
    shortNumber: "Nr.",
    marks: "Punte",
    fields: "Velde",
    field: "Veld",
    level: "Vlak",
    size: "Grootte",
    label: "Etiket",
    type: "Tipe",
    repeat: "Herhaal",
    total: "Totaal",
    subTotal: "Subtotaal",
    vat: "BTW",
    vatIncluded: "BTW ingesluit",
    vatExcluded: "BTW uitgesluit",
    address: "Adres",
    city: "Stad",
    postalCode: "Poskode",
    country: "Land",
    phone: "Telefoon",
    status: "Status",
    free: "Gratis",
  },
  404: {
    title: "SLEGS LEDE!",
    message:
      "Sluit vandag aan en kry eksklusiewe toegang tot hierdie hulpbronne – propvol motivering, opvoedkundige wenke en geestesgesondheidsinhoud wat net vir onderwysers en ouers ontwerp is. Moenie dit misloop nie!",
    button: "Sluit nou aan",
  },
  lambda: {
    existingUserFound:
      "Bestaande profiel gevind, teken asseblief in om voort te gaan na <strong><a href='/app/profile'>jou profiel</a></strong> en gebruik jou bestaande gebruikersnaam: ",
    profileMatched:
      "Profiel gekoppel, verdere instruksies is per e-pos gestuur. Kontroleer asseblief jou e-pos.",
    errorNoProfile:
      "Geen profiel het ooreengestem met die opgegeven e-pos nie, probeer asseblief 'n ander e-pos of skep 'n nuwe profiel <strong><a href='/app/profile'>hier</a></strong>",
  },
  products: {
    noShippingDetailsFound: "Geen afleweringsbesonderhede gevind nie",
    paymentMethod: "Betaalmetode",
    saveShipping: "Stoor?",
    shoppingCart: "Inkopiesmandjie",
    addToCart: "Voeg by Mandjie",
    addToMyLibrary: "Voeg by My Biblioteek",
    subToGradeToDownload: "Sluit aan by Graad om af te laai",
    subToDownload: "Sluit aan om af te laai",
    currentMonthLimit: "Limiet Bereik Vir Hierdie Graad Vir ",
    noContent: "Hierdie Produk het geen inhoud nie.",
    buyNow: "Koop Nou Eenmalig",
  },
  calendar: {
    repeatFor: "Herhaal vir",
    weeksMax: "Week/Weke (Maks 12)",
  },
  lessons: {
    createFrom: "Skep 'n nuwe Les",
    pickLesson: "Kies 'n bestaande lestemplaat",
  },
  templates: {
    restricted:
      "Die template hier onder is beperk tot die grade waarop jy ingeteken is en die vakke wat jy by jou klasse gevoeg het.",
    restrictedGrandFathered:
      "Die template hier onder is beperk tot die vakke wat jy by jou klasse gevoeg het.",
  },
  assessments: {
    createFrom: "Skep 'n nuwe Assessering",
    pickAssessment: "Kies 'n bestaande assesseringstemplaat",
    newSection: "Nuwe Afdeling?",
    notASection: "Nie 'n Afdeling",
  },
  actions: {
    save: "Stoor",
    saved: "Gestoor",
    submit: "Dien in",
    cancel: "Kanselleer",
    close: "Maak toe",
    edit: "Wysig",
    delete: "Vee uit",
    add: "Voeg by",
    update: "Bring veranderinge aan",
    view: "Aanskou",
    retry: "Probeer weer",
    ok: "OK",
    yes: "Ja",
    show: "Wys",
    create: "Skep",
    remove: "Verwyder",
    checkout: "Plaas bestelling",
    checkoutForFree: "Plaas bestelling (Gratis)",
    proceed: "Gaan voort",
    confirm: "Bevestig",
  },
  status: {
    cancelled: "Gekanselleer",
    active: "Aktief",
    activateMySub: "Aktiveer my lidmaatskap",
    cancelMySub: "Kanselleer my lidmaatskap",
    noActiveSub: "Geen aktiewe lidmaatskap",
    processingSub: "Ons verwerk jou lidmaatskap",
    failedPayment: "Betaling onsuksesvol",
  },
  payfastPackages: {
    perMonth: "per Maand",
    every3Months: "elke 3 Maande",
    every6Months: "elke 6 Maande",
    perYear: "per Jaar",
    perAdditionalGrade: "per Addisionele Graad",
    downloadLimit: "Aflaai limiet",
    perMonthPerGrade: "per Maand per Graad",
    downloadsUnlimited: "Aflaai Onbeperk",
    titleMonthly: "Maandeliks",
    title3Monthly: "3 Maandeliks",
    title6Monthly: "6 Maandeliks",
    titleYearly: "Jaarliks",
    min1GradeRequired: "Minimum 1 graad benodig",
    newSubSummary: "Nuwe Lidmaatskap Opsomming",
    currentSubSummary: "Huidige Lidmaatskap Opsomming",
    immediatePayment: "Onmiddellike Betaling",
    nextPayment: "Volgende Betaling",
    amount: "Bedrag",
    updatePackage: "Dateer my Pakket op",
  },
  days: {
    today: "Vandag",
    tomorrow: "Môre",
    yesterday: "Gister",
    monday: "Maandag",
    tuesday: "Dinsdag",
    wednesday: "Woensdag",
    thursday: "Donderdag",
    friday: "Vrydag",
    saturday: "Saterdag",
    sunday: "Sondag",
  },
  otherText: {
    createdByMe: "Gemaak deur my",
    thankYou: "Dankie",
    frequency: "Tydsduur",
    new: "Nuwe",
    mine: "Myne",
    my: "My",
    change: "Verander",
    deleteScheduleDay: "Is jy seker jy wil hierdie dag verwyder?",
    deleteSubject: "Is jy seker jy wil hierdie vakgebied verwyder?",
    deleteTemplate: "Is jy seker jy wil hierdie templaat verwyder?",
    deleteAssessment: "Is jy seker jy wil hierdie assessering verwyder?",
    deleteActivity: "Is jy seker jy wil hierdie aktiwiteit verwyder?",
    your: "Jou",
    family: "Familie",
    in: "in",
    or: "of",
    to: "na",
    blank: "Blanko",
    cognitive: "Kognitiewe",
    from: "van",
    browse: "Snuffel",
    shopCartIsEmpty: "Jou inkopiesmandjie is leeg",
    id: "ID",
  },
  movement: {
    back: "Terug",
    goBack: "Gaan terug",
    forward: "Vorentoe",
    next: "Volgende",
    previous: "Vorige",
    up: "Op",
    down: "Af",
    left: "Links",
    right: "Regs",
  },
  typography: {
    font: "Lettertipe",
  },
  helperHints: {
    contactsupport: {
      title: "KONTAK HULP:",
      body: "<p>Stuur ondersteuning 'n boodskap nadat jy die velde ingevul het en die relevante kategorie gekies het, ons ondersteuningspan sal jou binnekort kontak.</p>",
    },
    auth: {
      title: "WAGWOORD:",
      body: "<p>Wagwoordvereistes:</p><ul><li>Minimum lengte 8 karakter(s)</li><li>Bevat ten minste 1 nommer</li><li>Bevat ten minste 1 spesiale karakter</li><li>Bevat ten minste 1 hoofletter</li><li>Bevat ten minste 1 kleinletter</li></ul>",
    },
    subscription: {
      title: "LIDMAATSKAP:",
      body: "<p>Dateer jou lidmaatskap hier op. Kies wat die beste by jou behoeftes pas!</p>",
    },
    profile: {
      title: "PROFIEL:",
      body: "<p>Dateer jou persoonlike inligting hier op en verseker dat dit akkuraat en op datum is vir 'n beter gebruikerservaring.</p>",
    },
    schedule: {
      title: "SKEDULE:",
      body: `<p>Dae wat hier bygevoeg word, sal outomaties op jou kalender reflekteer. Nota: As jy nie ál die skooldae invoer nie, sal dit jou kalender redigeringvermoë vir daardie datums beperk.</p>`,
    },
    scheduleedit: {
      title: "Pas die besonderhede van jou bestaande skedule aan.",
      body: "<p></p>",
    },
    classes: {
      title: "KLASSE:",
      body: "<p>In hierdie afdeling kan jy klasse direk kies en byvoeg vanaf 'n omvattende lys wat strek van graad R tot graad 7 gebaseer op die Suid-Afrikaanse skoolkurrikulum. Kies maklik die toepaslike grade en vakke en benoem jou klasse.</p>",
    },
    classesedit: {
      title: "Pas die besonderhede van jou bestaande klasse aan.",
      body: "<p></p>",
    },
    calendar: {
      title: "KALENDER:",
      body: "<p>Bly op datum met jou opvoedkundige reis! Hier kan jy al jou geskeduleerde skooldae sien en aktiwiteite byvoeg. Maak seker dat elke kritiese aktiwiteit bygevoeg word om jou voorbereiding betyds en doeltreffend te doen. Onthou, enige geskeduleerde dae sal uit jou insette in die 'Skedule'-afdeling getrek word.</p>",
    },
    calendaractivity: {
      title: "Hier kan jy al jou bestaande aktiwiteite wysig.",
      body: "<p></p>",
    },
    lessons: {
      title: "LESSE:",
      body: "<p>Verken ons saamgestelde lesplan-sjablone wat vir verskeie vakke en grade aangepas is. Sodra jy die perfekte pas gekry het, skeduleer dit maklik op jou kalender om jou onderrig georganiseer en op koers te hou. Maak seker dat elke dag die regte inhoud vir jou leerders het.</p>",
    },
    lessonedit: {
      title: "SKEP / WYSIG LES:",
      body: "<p><h3>SKEP</h3> Duik met gemak in kurrikulumbeplanning. Begin van vooraf. Hierdie afdeling laat jou toe om lesse te ontwerp en aan te pas om by jou onderrigstyl en studente se behoeftes te pas. Berei die verhoog vir innemende en praktiese leerervarings.</p>",
    },
    assessments: {
      title: "ASSESSERINGS:",
      body: "<p>Maak persoonlike assesserings of kies uit ons uitgebreide biblioteek van sjablone. Voel vry om enige sjabloon aan te pas om by jou leerders se behoeftes te pas, om 'n omvattende assessering van hul vordering te verseker.</p>",
    },
    assessmentedit: {
      title: "WYSIG ASSESSERING:",
      body: "<p>Pas die besonderhede van jou bestaande assesserings aan. Hier kan jy inhoud verander om in lyn te kom met spesifieke leeruitkomste gebaseer op die KABV-kurrikulum. Maak seker dat jou evaluerings relevant en prakties bly.</p>",
    },
    assessmentcreate: {
      title: "SKEP ASSESSERING:",
      body: "<p>Hierdie afdeling laat jou toe om betekenisvolle assesserings te skep wat in lyn is met jou kurrikulumdoelwitte. Maak seker dat jou studente regverdig en omvattend getoets word.</p>",
    },
  },
  $vuetify: {
    badge: "badge",
    close: "Close",
    dataIterator: {
      noResultsText: "Geen ooreenstemmende resultate is gevind nie",
      loadingText: "Loading item...",
    },
    dataTable: {
      itemsPerPageText: "Rye per bladsy:",
      ariaLabel: {
        sortDescending: "Sorted descending.",
        sortAscending: "Sorted ascending..",
        sortNone: "Not sorted.",
        activateNone: "Activate to remove sorting.",
        activateDescending: "Activate to sort descending.",
        activateAscending: "Activate to sort ascending.",
      },
      sortBy: "Sort by",
    },
    dataFooter: {
      itemsPerPageText: "Aantal per bladsy:",
      itemsPerPageAll: "Alles",
      nextPage: "Volgende bladsy",
      prevPage: "Vorige bladsy",
      firstPage: "Eerste bladsy",
      lastPage: "Laaste bladsy",
      pageText: "{0}-{1} van {2}",
    },
    datePicker: {
      itemsSelected: "{0} gekies",
      nextMonthAriaLabel: "Volgende maand",
      nextYearAriaLabel: "Volgende jaar",
      prevMonthAriaLabel: "Vorige maand",
      prevYearAriaLabel: "Vorige jaar",
    },
    noDataText: "Geen data is beskikbaar nie",
    carousel: {
      prev: "Vorige visuele",
      next: "Volgende visuele",
      ariaLabel: {
        delimiter: "Carousel slide {0} of {1}",
      },
    },
    calendar: {
      moreEvents: "{0} meer",
    },
    input: {
      clear: "Clear {0}",
      prependAction: "{0} prepended action",
      appendAction: "{0} appended action",
    },
    fileInput: {
      counter: "{0} files",
      counterSize: "{0} files ({1} in total)",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
    pagination: {
      ariaLabel: {
        wrapper: "Paginasie-navigasie",
        next: "Volgende bladsy",
        previous: "Vorige bladsy",
        page: "Gaan na bladsy {0}",
        currentPage: "Huidige bladsy, Bladsy {0}",
      },
    },
    rating: {
      ariaLabel: {
        icon: "Rating {0} of {1}",
      },
    },
    loading: "Loading...",
  },
};

export default af;
