<template>
  <div id="videoembed">
    <div v-if="videoPlatform === 'vimeo'">
      <div
        class="vimeo-container"
        style="padding: 56.25% 0 0 0; position: relative"
      >
        <iframe
          ref="iframe"
          :src="`https://player.vimeo.com/video/${src}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=1`"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          :title="title"
        ></iframe>
      </div>
    </div>
    <div v-else-if="videoPlatform === 'youtube'">
      <div class="vid-div">
        <iframe
          class="responsive-iframe"
          :src="`//www.youtube.com/embed/${src}?rel=0`"
          frameborder="0"
          style="max-width: 100%"
        ></iframe>
      </div>
    </div>
    <div v-else>No source</div>
  </div>
</template>
<script>
export default {
  name: "VideoEmbed",
  props: {
    src: {
      type: String,
      required: true,
    },
    // Optional title prop for accessibility
    title: {
      type: String,
      default: "Video",
    },
  },
  data: () => ({}),
  computed: {
    videoPlatform() {
      return /^[0-9]+$/.test(this.src) ? "vimeo" : "youtube";
    },
  },
  //   mounted() {
  //     // Dynamically load the Vimeo API script if it isn't already loaded
  //     if (!window.Vimeo) {
  //       const script = document.createElement("script");
  //       script.src = "https://player.vimeo.com/api/player.js";
  //       script.onload = this.createPlayer;
  //       document.body.appendChild(script);
  //     } else {
  //       // Vimeo API already loaded
  //       this.createPlayer();
  //     }
  //   },
  //   methods: {
  //     createPlayer() {
  //       const player = new Vimeo.Player(this.$refs.iframe);
  //       player.on("play", () => {
  //         console.log("Video is playing");
  //       });
  //     },
  //   },
};
</script>
<style scoped>
.vimeo-container {
  max-width: 100%;
  margin: 0 auto;
}

.vid-div {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  margin-bottom: 1.2rem;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
