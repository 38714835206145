<template>
  <!-- Root div with an ID 'adminlayout' -->
  <div id="adminlayout">
    <!-- Application bar component -->
    <v-app-bar app flat>
      <!-- Burger Icon -->
      <v-app-bar-nav-icon
        @click="leftDrawer = !leftDrawer"
      ></v-app-bar-nav-icon>
      <!-- Spacer to push menu buttons right -->
      <v-spacer />
      <!-- Menu icon buttons -->
      <div v-if="screenWidth > 259" class="d-flex flex-row" style="gap: 12px">
        <!-- <v-btn icon to="/"><v-icon>mdi-home</v-icon></v-btn> -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="$store.dispatch('signOut')"
              color="secondary"
              class="black--text"
            >
              <v-icon>mdi-logout-variant</v-icon>
            </v-btn>
          </template>
          <span>Sign Out</span>
        </v-tooltip>
      </div>
      <!-- Router link to the root path with the logo image -->
      <router-link to="/">
        <v-img
          class="ml-3"
          src="@/assets/logo-v2.svg"
          max-height="50"
          max-width="93"
          contain
        ></v-img>
      </router-link>
    </v-app-bar>

    <!-- Main content area -->
    <v-main fluid fill-height>
      <!-- Router view to render the component matched by the route -->
      <router-view />
    </v-main>

    <!-- Left navigation drawer -->
    <v-navigation-drawer v-model="leftDrawer" left app>
      <!-- Top section with a full logo -->
      <div class="d-flex flex-row pa-2">
        <v-img class="mr-3" src="@/assets/logo-v2-long.svg" contain></v-img>
      </div>
      <!-- Dividers and contents inside the drawer -->
      <v-responsive class="mx-auto" width="56">
        <v-divider></v-divider>
        <v-divider></v-divider>
      </v-responsive>
      <!-- Navigation list -->
      <v-list nav dense>
        <v-list-item-group active-class="secondary">
          <!-- <div>
            <v-file-input
              label="Upload CSV"
              v-model="fileToConvert"
              prepend-icon="mdi-file-document-outline"
              accept=".csv"
            ></v-file-input>
            <v-list-item
              :disabled="convertingFunction"
              @click="convertCSVToProducts"
            >
              <v-list-item-icon>
                <v-icon>mdi-upload</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="v-btn" style="justify-content: start"
                >Upload Data</v-list-item-title
              >
            </v-list-item>
          </div> -->

          <v-list-item to="/admin/communications">
            <v-list-item-icon>
              <v-icon>mdi-message-text</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Communications</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/admin/announcements">
            <v-list-item-icon>
              <v-icon>mdi-message-alert</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Announcements</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/admin/customers">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Customers</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/admin/teacherProfiles">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Teacher Profiles</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/admin/holidays">
            <v-list-item-icon>
              <v-icon>mdi-image-filter-hdr-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Holidays</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/admin/products">
            <v-list-item-icon>
              <v-icon>mdi-bookshelf</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Products</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/admin/productorders">
            <v-list-item-icon>
              <v-icon>mdi-book-open</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start">{{
              $i18n.t("headings.orders")
            }}</v-list-item-title>
          </v-list-item>

          <v-list-group
            prepend-icon="mdi-chart-bar-stacked"
            no-action
            color="rgba(0, 0, 0, 0.87)"
          >
            <template v-slot:activator>
              <v-list-item-title class="v-btn" style="justify-content: start"
                >Reporting</v-list-item-title
              >
            </template>
            <v-list-item to="/admin/reporting/teacherprofiles">
              <v-list-item-title class="v-btn" style="justify-content: start"
                >Teacher Profiles</v-list-item-title
              >
            </v-list-item>
            <v-list-item to="/admin/reporting/subscriptions">
              <v-list-item-title class="v-btn" style="justify-content: start"
                >Subscriptions</v-list-item-title
              >
            </v-list-item>
          </v-list-group>

          <v-list-item to="/admin/schools">
            <v-list-item-icon>
              <v-icon>mdi-school-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Schools</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/admin/subjects">
            <v-list-item-icon>
              <v-icon>mdi-notebook-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Subjects</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/admin/taxonomies">
            <v-list-item-icon>
              <v-icon>mdi-shape-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Taxonomies</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/admin/terms">
            <v-list-item-icon>
              <v-icon>mdi-calendar-text-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Terms</v-list-item-title
            >
          </v-list-item>

          <!-- <v-list-item to="/admin/subjecttemplates">
            <v-list-item-icon>
              <v-icon>mdi-file-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start">Templates</v-list-item-title>
          </v-list-item> -->

          <v-list-group
            prepend-icon="mdi-file-outline"
            no-action
            color="rgba(0, 0, 0, 0.87)"
          >
            <template v-slot:activator>
              <v-list-item-title class="v-btn" style="justify-content: start"
                >Templates</v-list-item-title
              >
            </template>
            <v-list-item to="/admin/lessontemplates">
              <v-list-item-title class="v-btn" style="justify-content: start"
                >Lessons</v-list-item-title
              >
            </v-list-item>
            <v-list-item to="/admin/assessmenttemplates">
              <v-list-item-title class="v-btn" style="justify-content: start"
                >Assessments</v-list-item-title
              >
            </v-list-item>
            <!-- <v-list-item to="/admin/lessontemplates">
              <v-list-item-title class="v-btn" style="justify-content: start">Lessons</v-list-item-title>
            </v-list-item> -->
          </v-list-group>

          <v-list-item @click="$store.dispatch('signOut')">
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start"
              >Sign Out</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <Footer :showLegal="false" />
  </div>
</template>

<script>
import Vue from "vue";
import Footer from "@/components/global-components/Footer.vue";
import { DataStore, Storage, API } from "aws-amplify";
import { Product, TeacherProfile } from "@/models";
import _ from "lodash";
import Papa from "papaparse";

import pLimit from "p-limit"; // If you're using p-limit for concurrency control

export default Vue.extend({
  name: "AdminLayout",
  data: () => ({
    leftDrawer: false, // State of the left navigation drawer
    // leftDrawerListGroup: null, // Currently selected list item group in left drawer

    convertingFunction: false,
    fileToConvert: null,

    productSearchExcludeFields: [
      "id",
      "fileKeys",
      "thumbnails",
      "searchField",
      "categoryId",
      "createdAt",
      "featured",
      "physical",
      "subscriptionProduct",
      "eCommerceProduct",
      "publishDate",
      "updatedAt",
      "_version",
      "_lastChangedAt",
      "_deleted",

      // "name",
      "sku",
      "subtitle",
      // "description",
      "category1",
      "category2",
      "category3",
      "image",
      "ribbon",
      "ribbonColor",
      "weight",
      // "price",
      "recommended_price",
      "quantity",
      "enabled",
      "taxClassCode",
      "shipping_freight",
      "fixed_shipping_rate_only",
      "shippingType",
      "shippingMethodMarkup",
      "shippingFlatRate",
      "shippingDisabledMethods",
      "shippingEnabledMethods",
      "upc",
      "brand",
      "seo_title",
      "seo_description",
      "product_url",
      "product_id",
    ],

    uploadCSVThumbnailResponse: null,
    uploadCSVThumbnailBlob: null,
    uploadCSVThumbnailResizedBlob: null,
    existingProductOrderNumbers: [],
    existingUserEmails: [],
    existingProductIds: [
      // 2024-12-11------------------------------------------------------------------
      713213542,
      713213541, 712781720, 713218022, 713218028, 713220021, 713190656,
      713213543, 713190657, 713213539, 713220012, 713218021, 713213540,
      713190655, 713190654, 712999554, 712999584, 712997325, 712999586,
      712999557, 712999589, 712523656, 714003537, 712998020, 712531318,
      714252553, 712946589, 713916366, 713467946, 713469353, 712523661,
      713978243, 712997025, 712531320, 714259533, 712929149, 713893984,
      713470044, 713469356, 712523663, 714003559, 712999510, 712523633,
      714259545, 712929150, 713961523, 713469309, 713472033, 712523665,
      714003633, 712531337, 714262031, 713966002, 713966003, 712929160,
      713893988, 713469340, 713469366, 713022770, 712997195, 712999720,
      712997213, 712998237, 712999651, 713896702, 714004390, 713087024,
      714262075, 712997277, 713035638, 713058647, 713472077, 713470100,
      712943871, 713896706, 714005621, 713087027, 714259602, 712999523,
      713044554, 713472089, 713469373, 712997260, 713896707, 714004616,
      713082813, 714252611, 712999533, 713055254, 713472111, 713472052,
      712999505, 713961524, 713966012, 713961554, 714005631, 713084296,
      714262085, 713044577, 712998019, 713472122, 713469377, 712710190,
      712725827, 713496398, 713502003, 712710194, 712710192, 712787401,
      712523716, 712725886, 713220054, 712806568, 712782464, 713213570,
      713190680, 712725976, 713246313, 712781625, 714190025, 712588766,
      714194254, 712588768, 714190026, 712571636, 712571385, 714189773,
      712571390, 714194256, 712571657, 714190040, 712531462, 712710204,
      712718869, 712725852, 713241047, 713218082, 712718989, 712781639,
      712725936, 712763630, 713241010,
      // 2024-12-02------------------------------------------------------------------
      708669744,
      709004805, 709007023, 709007028, 708669209, 709005326, 709005312,
      709004814, 709007025, 708675290, 709221219, 708673828, 709221234,
      709224112, 708676013, 709221187, 709004826, 709876332, 708675292,
      708675302, 709004816, 709006776, 708673908, 708675281, 708675287,
      709004811, 709007029, 708669203, 708669212, 709006774, 708675284,
      709005315, 709449259, 708649682, 704496971, 702279148, 702279114,
      708651099, 708649725, 711029027, 702279203, 702449794, 702446518,
      708491218, 704703483, 710993030, 702287038, 708463958, 708484039,
      704666813, 711006954, 708651247, 704498172, 702299578, 702298503,
      708650338, 708677329, 711010950, 702299592, 702444934, 702443956,
      708512296, 704703489, 710993035, 702298067, 708478073, 708494774,
      704667297, 711009212, 708661753, 704498174, 702195418, 702417050,
      708649625, 708676209, 711029030, 702414762, 702444936, 702444873,
      708514780, 704714121, 710972703, 702298230, 702402228, 708468678,
      708501048, 704667527, 711015146, 709796466, 709799163, 712218777,
      711392623, 712218815, 702415372, 707867605, 702414865, 709798734,
      709798745, 712218792, 710152053, 702424074, 710424675, 711856920,
      710444303, 711880078, 710168873, 710168396, 711824686, 709193046,
      710481682, 702418761, 707904322, 702415484, 702417209, 712036367,
      712041860, 705728852, 709798706, 709796495, 712223022, 711412526,
      712224812, 707865136, 707875021, 709835794, 709840256, 712221818,
      710151640, 707825801, 710420243, 711856921, 710447563, 711867644,
      710168367, 710186754, 711824715, 709194314, 710482158, 707811303,
      708001203, 707882119, 712031415, 712041856, 705729847, 709799161,
      709835773, 712224778, 711392628, 712221849, 707866670, 707866601,
      709835796, 709841009, 712224795, 710155537, 707825849, 710422946,
      711856925, 710449801, 711880081, 710168890, 710168404, 711821205,
      709191556, 710490106, 708007981, 707882386, 708009372, 712031392,
      712036403, 705727164, 709798707, 709835775, 712218786, 711388213,
      707866680, 707871606, 709838267, 709840269, 712218810, 710151644,
      707815178, 710442504, 711856930, 710449346, 711882810, 710490111,
      710168389, 710187507, 711853268, 709193056, 708009496, 708014194,
      707893887, 712031394, 712031426, 705728863, 711010919, 708463791,
      704665822, 708453841, 702247883, 702402203, 708723799, 704471218,
      702279716, 702279215, 708650384, 703187935, 708651765, 704731356,
      710993079, 708463985, 708496560, 704700372, 711009243, 702279724,
      702297005, 702299657, 702247926, 708725785, 704471221, 702287184,
      702287158, 708649663, 703187941, 708649525, 704718430, 710972741,
      708480801, 708496604, 704700376, 711010887, 702299649, 702298129,
      702415328, 702260053, 708730317, 704473927, 702414795, 702414788,
      708651881, 703187944, 708651766, 704731152, 710983647, 708480831,
      708491108, 704702139, 711015194, 702412457, 702412463, 702418985,
      702418949, 709938560, 709937113, 709952502, 711415794, 712223083,
      702418967, 707882259, 702424104, 709799223, 709838294, 709842472,
      710152151, 710442509, 711864295, 710447053, 711864426, 712031429,
      712038146, 705727175, 710373812, 711853402, 710373801, 709193219,
      710532561, 702423810, 708001209, 702423821, 707813727, 708063551,
      709945014, 709952532, 709944551, 711415818, 712221863, 707882014,
      707865208, 709840300, 709841039, 709842476, 710155629, 710441337,
      711864310, 710449368, 711864427, 712031430, 712041876, 705727177,
      710288741, 711853413, 710402042, 709204011, 710516643, 708009382,
      707893978, 707831785, 708056204, 709952564, 710113554, 709944558,
      710080138, 709966276, 710110538, 711392734, 712223109, 707886513,
      707867673, 709841054, 709842485, 709841062, 710160266, 710442521,
      711856703, 710449384, 711882823, 712036426, 712036438, 705731380,
      710373823, 711856827, 710288729, 709195697, 710516645, 708009386,
      707893991, 707833278, 708067451, 709944576, 709966287, 709966295,
      711417505, 707882042, 707882001, 709838305, 709838311, 709842497,
      710160270, 710442523, 711856704, 710447070, 711864431, 710527106,
      712036440, 712041878, 705742003, 710402048, 711853415, 710402044,
      709194438, 708007959, 707902570, 711010944, 708464424, 704701566,
      708456692, 711565220, 711566742, 711513916, 711371543, 711379303,
      711508974, 711371546, 711371698, 711508975, 711371548, 711371714,
      711371571, 711383366, 711327480, 711383614, 711327485, 711383384,
      710481557, 624294327, 710462343, 710736820, 710731316, 710731322,
      710736841, 710731303, 710736856, 710464451, 710464450, 710731320,
      710736570, 710459345, 710736539, 710757751, 710736793, 710757797,
      710736593, 710459344, 710464445, 710757938, 710731338, 710757823,
      710736597, 710736881, 710736580, 710462399, 710731341, 710731330,
      710757798, 709249082,
      // --------------------------------------------------------------------------
      695609520,
      357895552, 461262791, 473775821, 502945649, 463063270, 461265007,
      530329227, 461260252, 473766317, 502636781, 461261302, 461265027,
      473781558, 502947146, 463062015, 461261011, 530627513, 461259504,
      473768567, 502629594, 461262793, 461259291, 473775824, 502948415,
      463141640, 461259264, 530640506, 461259506, 473768568, 502628602,
      502654255, 473781561, 503560493, 461264764, 530329226, 461260255,
      473775768, 502629596, 502933191, 461262760, 530640508, 461261005,
      473781511, 502615104, 473766383, 502958021, 463062763, 461264770,
      530640509, 461259257, 473775771, 502582952, 473766387, 502934151,
      463060017, 461261022, 530318997, 461260007, 473767571, 502628606,
      473775831, 502948435, 463724279, 524562564, 502648269, 461264772,
      530403614, 461260008, 473775774, 502629152, 461260308, 473775834,
      502948438, 502933220, 624997278, 629561640, 619657834, 635047539,
      670611098, 524558082, 524556553, 524558056, 524558057, 524563058,
      524563060, 524563057, 524558055, 524563059, 524563322, 524561076,
      524563326, 524562156, 524558821, 524562157, 524563329, 524563083,
      524563082, 524556580, 524558830, 524558070, 461289002,
      // --------------------------------------------------------------------------
      609518767,
      609762480, 701688802, 701688912, 701674463, 701674466, 701697362,
      662977311, 701675457, 701676365, 666632443, 669258266, 666640791,
      669149677, 662987788, 666633649, 669253274, 701697321, 701676494,
      701703511, 701697378, 701675482, 701703516, 666633651, 669236339,
      666640795, 669236340, 701695996, 701688916, 666640794, 669253278,
      666633650, 666640793, 669236352, 701688891, 701676496, 666633648,
      669253289, 701697323, 701676498, 666646254, 669236329, 701703506,
      701703534, 666640792, 669253293, 662987774, 662232302, 662232323,
      666625748, 669253295, 701676382, 701697372, 701676471, 701703535,
      666632456, 669253296, 701676474, 701703536, 666640790, 669236354,
      663715888, 665672545, 666640788, 669236355, 663717880, 464606130,
      464642282, 629206345, 621198588, 463150031, 463143809, 618937020,
      474457733, 474317325, 536987404, 628722033, 628038004, 695648805,
      657435977, 691417202, 605536700, 691739801, 694173086, 694173090,
      694173092, 694175304, 694175329, 694173143, 536992839, 669939399,
      604730347, 669296285, 632451105, 645506262, 675364610, 615598094,
      615601133, 615583130, 626746608, 637582820, 637534467, 633554783,
      651636797, 665843965, 669644375, 657685957, 591562176, 674689005,
      686280650, 685558952, 594062789, 615597633, 637599105, 669939326,
      669930598, 603146536, 626753846, 636758888, 631498002, 645184326,
      659171097, 657685988, 591586562, 673540918, 688080684, 684999473,
      615581873, 615597557, 615598103, 637139995, 669644380, 702113161,
      656303669, 651636946, 702104954, 615583154, 615594618, 615606335,
      626753852, 634719047, 637143249, 631497539, 645216769, 659155317,
      669647701, 657718793, 591560670, 673506928, 686766017, 685012889,
      604714136, 669297286, 632469548, 645506263, 675364622, 695552988,
      615584079, 615594652, 615581867, 626753843, 637582822, 637534480,
      633550088, 651636800, 665854061, 669647245, 666233188, 591590142,
      674687770, 686283124, 691161754, 594317169, 615584117, 637603087,
      669939338, 669936034, 603130272, 626748852, 636757901, 631420242,
      645180554, 659171101, 591590145, 673538383, 688080688, 695885973,
      615606343, 615601090, 615583124, 637172001, 669662027, 626363658,
      702221086, 656303185, 651634982, 702112092, 615594647, 615583102,
      615598106, 626748864, 634718303, 637172029, 631499510, 645180720,
      659053485, 669667252, 669149725, 591562180, 673514803, 686733644,
      695894666, 604729603, 669296551, 632450887, 645434927, 675363421,
      695609542, 615601105, 615598126, 615581868, 626746866, 637534458,
      637574414, 633549618, 651636813, 665854062, 669668761, 669644005,
      591564933, 674689515, 686283170, 691165295, 594661027, 615583177,
      637615663, 669939837, 669930636, 603129300, 626744619, 636758889,
      631420244, 645196005, 659074230, 669603238, 591562186, 673549663,
      688079733, 695902188, 615601129, 615581844, 615594635, 637142934,
      669662075, 626373396, 656308855, 651637189, 702115808, 615606344,
      615594622, 615606338, 626753855, 634719069, 637174251, 631499766,
      645196234, 659074213, 669666307, 669258357, 591560681, 673514806,
      686733989, 695894673, 302899448, 307373249, 310565443, 310538726,
      514832795, 525224336, 620570287, 613817874, 613971793, 613712281,
      632168818, 548083929, 629410954, 644718061, 544825822, 631503775,
      666963577, 658865885, 470070539, 669253456, 567792658, 658170260,
      478861639, 697154993, 583931157, 684779618, 589514723, 691941748,
      594062821, 495583578, 591493575, 692492768, 690440623, 457515833,
      354368017, 502544924, 562197752, 660159838, 515829663, 607372190,
      424886158, 614733462, 612840258, 513374519, 612976906, 612840560,
      632169316, 542594880, 458178325, 632097441, 644705408, 348186551,
      541550649, 637217629, 666961060, 372294263, 670143723, 567038947,
      670143689, 474457737, 481045533, 697182503, 502498858, 583904458,
      684770136, 589501993, 688601330, 593350544, 494437081, 593351024,
      692778531, 355122814, 690440587, 614066541, 630080560, 554106803,
      636031988, 645149762, 657690297, 670162712, 678192978, 688152032,
      595884686, 690905706, 609639369, 630074853, 554022928, 636758838,
      645152043, 657644927, 677979456, 679604800, 688270956, 592167251,
      595921691, 691663004, 520873837, 622966402, 657420915, 546345670,
      641545267, 683848300, 569249614, 683489558, 701452593, 492967849,
      601055203, 513451585, 661263343, 520842990, 538971989, 555310081,
      500261284,

      519721330, 606002928, 528124013, 514328762, 612271827, 624957045,
      462109716, 630125799, 554706541, 536652404, 536230329, 631525513,
      342173447, 567036926, 478246573, 566483545, 647597213, 650843730,

      660363881, 560827571, 658598025, 468088782, 681032103, 500050788,
      688919641, 695967004,

      493850534, 588005194, 692393640, 518934103, 518589601, 546155149,
      518578397, 518930615, 518586866, 518934102, 518578399, 430242873,
      521732753, 308482952, 308034097, 521541838, 521467099, 521642801,
      521732752, 604743765, 619266104, 315183194, 463063620, 624020327,
      538465007, 631525813, 477930758, 645527769, 657408150, 561197385,
      659420518, 468148002, 684389579, 684938165, 593725637, 696557943,
      437457671, 521541841, 521639305, 521636061, 521624555, 521541839,
      521639301, 310248728, 310236830, 526570003, 420899966, 537844990,
      450718042, 473768023, 663249027, 562985655, 669954503, 468734039,
      501903716, 701605915, 490666369, 701830007, 320234201, 530317900,
      529435256, 517444790,

      541427178, 354312532, 566487604, 561343257, 472669419, 502171343,
      490667891, 478563286, 522646040, 522709350, 656303198, 651637192,

      615243005, 615195346,

      640421612,

      638287579,

      658025865, 657986718, 670167001,

      679613173, 689683105, 514832796, 607743648, 525229067, 620583754,
      613813678, 613915699, 613696807, 632170051, 629374947, 644718093,
      544795216, 631502071, 666962342, 658882853, 470071532, 669236471,
      567815573, 658083424, 479047514, 697181519, 583991766, 684782362,
      589500223, 691947392, 594058351, 495570474, 591457352, 692492787,
      690440367, 425830085, 548081929, 457515602, 502554204, 357140440,
      562139588, 660160582, 515790376, 607377163, 424894664, 614833546,
      612826269, 513365012, 612968451, 612854290, 632165849, 542487737,
      458180574, 632093488, 355129544, 644705423, 348187548, 541553455,
      637217720, 666962809, 670143737, 567041642, 670168818, 474458230,
      481040778, 697182523, 502557276, 583931152, 684776996, 589533035,
      691947369, 593350548, 494432087, 593347042, 692775827, 690439892,
      333287079, 614066551, 630080561, 554068155, 636031992, 645152762,
      657644922, 670187262, 678199352, 688151048, 595985032, 690911555,
      609631083, 630075853, 554130816, 636758839, 645152046, 657690305,
      677988715, 679600395, 688281683, 592153255, 595985046, 691663016,
      520843331, 622970334, 657435846, 546352652, 641541851, 683842195,
      569249136, 683525524, 492966625, 601018996, 520842991, 513450569,
      661250600, 538969983, 555307594, 500210396,

      519697335, 605998175, 528119013, 514349007, 612261862, 624933895,
      462102717, 630123031, 554707042, 536654345,

      536213679, 631503865, 342173478, 567041684, 478312726, 566494280,
      647597215, 650817450,

      660430587, 560824097, 658598523, 468088784, 500005182, 681039844,
      688919932, 695966539,

      493852032, 588074061, 692393656, 312899740, 518566624, 518586874,
      518608613, 518598112, 518566621, 546184498, 519169254, 518583916,
      518929109, 521669303, 521636078, 521658075, 308547395, 521669304,
      521639324, 604720771, 619274070, 463063275, 624023550, 538458881,
      631525578, 315180281, 477930740, 645506472, 657412075, 561495336,
      659340366, 468147755, 684389814, 684936729, 593728603, 696556966,
      439354503, 521731279, 521636075, 521589592, 521658074, 521639330,
      521563340, 521636088, 310565350, 310506937, 526569002, 421040726,
      537869216, 450715783, 473766276, 663279757, 562956863, 670162681,
      468737255, 501905492, 701610881, 490662366, 701830009, 322003094,
      530310415, 529430010, 517443067,

      541482640, 354368006, 566487614, 561341015, 473094962, 502167542,
      490666935, 478562526, 522719751, 522715627, 656302445, 651639696,

      615154721, 615154724,

      640473878,

      638294835, 658010194, 658025869, 670167010,

      679620998, 689683113, 515057751, 607758309, 525230073, 620589004,
      613809665, 614042501, 613696809, 632172156, 629537786, 644705472,
      544786487, 631490345, 666962345, 658878636, 470073056, 669253477,
      567033961, 658083439, 478799399, 698416687, 583981094, 684778942,
      589499922, 691954511, 594060322, 495582835, 591457353, 692503005,
      690439937, 548077948, 426093400, 457517363, 357142871, 502557450,
      562141598, 666355581, 515795390, 607372682, 424887912, 614754667,
      612825758, 513377015, 612973332, 612826295, 632169318, 542499737,
      458183826, 632093491, 644718053, 348182625, 541546235, 637218724,
      666962278, 670143739, 569564355, 670162620, 474457738, 481042286,
      698423259, 502517091, 583981091, 684779553, 589500221, 691947376,
      593347583, 494432312, 593356010, 692765705, 690439894, 614077777,
      630080563, 554112568, 636045793, 645152765, 657695762, 670187265,
      678192992, 688152047, 595934794, 690911570, 612195597, 630080616,
      554081097, 636757835, 645152048, 657695775, 677977908, 679599187,
      688270647, 592152758, 691660522, 520840842, 622966405, 355122817,
      657427604, 546360417, 641545334, 683845224, 569241374, 683515359,
      493125276, 601018998, 513609248, 661203733, 521563274, 538953715,
      555315584, 500240088,

      567041694, 519724836, 605987232, 528124015, 514328014, 612274055,
      624933899, 462109718, 630119287, 554707043, 536660270,

      536233346, 631524520, 342074887, 478313768, 566478026, 647597216,
      650843741,

      660430589, 478605055, 560809126, 658598532, 500044548, 681039868,
      688924839, 695967074,

      493850536, 588024654, 692396456, 518589388, 518615373, 546155150,
      518934110, 518566627, 518934109, 518608620, 518615375, 518578408,
      518930630, 309154814, 309251532, 521563321, 604743768, 619421121,
      463063622, 624027544, 538460081, 631498205, 316389977, 477934514,
      645506476, 657412103, 561207918, 659340370, 468148006, 684389820,
      684938236, 593728338, 696556973, 439368569, 521731265, 521658063,
      521467107, 310507962, 310508944, 526571502, 423572817, 537870413,
      451375207, 473685730, 663215805, 562951436, 669947218, 468737507,
      501927559, 701630262, 490662860, 701828820, 322678878, 530312176,
      529446258, 517640757,

      356756232, 541435874, 566488584, 561338262, 474149964, 502167029,
      490666412, 478563288, 522719754, 522709356, 656302454, 651678019,

      615158986, 615180613, 640473176,

      638287584, 658049708, 658060959,

      670167011,

      679613202, 689693522, 515059001, 525225111, 611047679, 632170071,
      544822354, 631490347, 658879380, 470622206, 567039702, 658171340,
      478925822, 698423284, 589530288, 691954512, 495586368, 591493585,
      692495519, 690439922, 548216288, 457515842, 660162312, 611032710,
      515820642, 513364015, 614073022, 632168815, 542903072, 458178328,
      348187553, 541546945, 669667352, 470071527, 569562068, 670168823,
      474499267, 481045535, 698416685, 502498869, 691941746, 494431607,
      593351028, 692775838, 366366179, 690440618, 669941486, 701827387,
      607849538, 462219340, 636922772, 624952344, 455588411, 660643495,
      663214319, 475101764, 697145021,

      495436240, 580860066, 696698181, 614077778, 629550283, 630080564,
      541418187, 636045803, 645152021, 657690303, 670162731, 678200841,
      688151550, 496156928, 697139772, 612192652, 629537834, 630083834,
      541491102, 636757836, 645167755, 657644931, 676581170, 679596731,
      688270973, 496443579, 592167252, 691667017, 657427623, 683845245,
      471976738, 683511201, 513451586, 538969985, 538462047, 631527080,
      477934272, 645506477, 657408176, 659155451, 468148752, 494119012,
      696557964, 521589518, 500146243, 661250603, 604714041, 624031784,
      684370201, 684938243, 567036927, 520842922, 605984933, 528123266,
      514328766, 615161744, 624963501, 462347567, 630119032, 554680037,
      536650425, 536207905, 631525756, 342173504, 478311027, 566477808,
      647697516, 650843744,

      660430594, 560792695, 658601514, 468089037, 468094508, 502941213,
      683820285, 688919939,

      493861527, 694032918, 520832599, 520840868, 520837107, 520843372,
      546156642, 520874878, 520858355, 520874877, 521563319, 449639015,
      561255115, 521589579, 521641305, 526571252, 476024289, 476026522,
      529446259, 450683783, 561339034, 476745160, 503098372, 478562529,
      522725506, 522715632, 490664625, 669973979, 701829092, 490662367,
      656302456, 651677770,

      615195345, 615158202, 640474477,

      659420660, 659504261,

      670653384, 679622507, 681910627,

      689693537, 671073915, 496152398, 477935016, 561248355, 468139274,
      494122756, 478245079, 561490314, 475517010,

      493853031, 496151654, 477935022, 561289044, 468148259, 494124512,
      561494317, 475518022, 493854775, 637636027, 604729574, 540649891,
      615597616, 637603501, 669939305, 669857207, 593861676, 636761878,
      631490275, 659233020, 663214402, 688098262, 695885968, 615606329,
      637139993, 669647052, 624997763, 702103408, 702103375, 615598092,
      634719045, 637144188, 631497263, 659155312, 669647558, 663249146,
      686741667, 695914771, 629565861, 615581893, 615597573, 615594643,
      626751381, 636882003, 637174284, 633549619, 645434974, 667886444,
      669647225, 657730521, 591564916, 676605755, 684749074, 685590288,
      539620780, 632469558, 657259217, 675368436, 691176787, 610839038,
      598467144, 619670918, 619673850, 629571084, 640334473, 635047541,
      650904573, 665850445, 662546752, 682936568, 688098293, 685002494,
      619657359, 619683218, 629570573, 638302272, 636008347, 650907098,
      666924179, 662516677, 598363668, 682936593, 686797206, 685020037,
      629571092, 615601135, 615597574, 615584101, 626750126, 636882005,
      637174291, 633551099, 645465995, 667886450, 669668752, 666324252,
      591590150, 676583158, 684767760, 691134498, 632451141, 657315616,
      675389263, 691172299, 610839297, 666565884, 598482324, 619683233,
      619680076, 629565840, 640337175, 635038562, 650907532, 665854148,
      671246188, 682936570, 688100012, 695902215, 619683234, 619673871,
      629571089, 638287657, 636007375, 650907111, 666936518, 671247826,
      598480080, 682928072, 686797217, 695894680, 629561126, 615583159,
      615584085, 615606340, 626744630, 636865545, 637144235, 633549623,
      645506326, 667886453, 669666317, 669668826, 591586585, 676581189,
      684767776, 691158260, 632450891, 657259220, 675368447, 691176810,
      610846038, 598467146, 619642958, 619657357, 629571086, 640358918,
      635041054, 650904584, 665854151, 676011793, 682947020, 688080714,
      695885993, 619673885, 619670916, 629570582, 638294899, 636007377,
      650907113, 666928589, 675982419, 598482326, 682928075, 686797248,
      695914999, 310627013, 526568003, 420892943, 450756334, 537943534,
      670650550, 473770012, 468737263, 562973837, 674267446, 501924616,
      701637002, 490662861, 701827411, 310236784, 690951215, 690949495,
      697800466, 515820519, 533068027, 612866760, 432265167, 618435039,
      617281619, 615128358, 548895260, 645216835, 634749581, 546357410,
      626775062, 671260796, 663708297, 568256506, 661521521, 485746202,
      681875761, 587774671, 685558915, 694695082, 495974061, 584447598,
      693344804, 459226285, 351454673, 468917019, 471561057, 503714530,
      564336043, 666936593, 434849453, 690951944, 690950978, 697800472,
      512679935, 533068028, 610653189, 544347783, 460786287, 354704805,
      645223797, 636026710, 543248692, 636007413, 471740137, 671055032,
      471453193, 568184261, 665850269, 485289673, 678178124, 502929310,
      691134413, 692788158, 501415329, 591447901, 695156318, 614066277,
      554130817, 630080570, 639329082, 651277940, 657695793, 674687776,
      681859612, 688270619, 595867000, 690919030, 609636360, 554207519,
      630083835, 640334493, 651277983, 657685911, 678001295, 681859660,
      688270974, 592152012, 595934807, 691657552, 539142001, 555743543,
      500153227, 492965357, 521639253, 513450571,

      615174172, 615237786,

      634739105,

      641503935, 669954743, 669956732,

      673687070, 683832077,

      700325619, 519720337, 605969880, 514341762, 612278085, 462069968,
      624957049, 342173458, 630126533, 536930622, 554707044, 536233351,
      636890262, 651277854, 478253330, 566477810, 658067571,

      468093754, 560827575, 669612685, 681043574, 500038664, 688925113,
      694697551,

      493849784, 588024656, 692393672, 528119016, 518608611, 312869916,
      518578402, 518930618, 518589603, 518589863, 518608608, 518578400,
      546207217, 567082564, 521698788, 521731260, 308077286, 521731259,
      521669289, 521658061, 521624557, 437461665, 521636064, 521642804,
      521467104, 521467100, 521589578, 604720779, 315138838, 624026539,
      463062265, 538458927, 638301843, 650993751, 477942765, 669285001,
      468148014, 561207903, 669253275, 684389826, 684973810, 593727379,
      696555917, 320232251, 530312178, 517448285,

      354312533, 541427187, 566492354, 472669421, 561351502, 502167305,
      490665681, 529445506, 478562530, 520874872, 622971373, 546353651,
      657644774, 641599524, 685604332, 569241109, 683525705, 601233083,
      526573001, 421034054, 450718063, 537943536, 670647594, 473766281,
      468736762, 562973838, 674264726, 501924379, 701639251, 490666895,
      701827414, 310565332, 690960503, 690954812, 697795445, 515789769,
      533064528, 612840547, 432854835, 618439798, 617355001, 615124380,
      548888759, 645223838, 634748121, 546427238, 626774586, 671260799,
      663715777, 568225673, 661512369, 485793113, 681851958, 587911777,
      685559076, 694697545, 495974062, 584453867, 693344421, 459226286,
      351453237, 468917265, 471561058, 503699635, 564342012, 666936597,
      435441800, 690954770, 690951909, 697820008, 512890770, 533068029,
      610642437, 544347785, 460753101, 354698083, 645218825, 636026711,
      543239107, 636009398, 471718641, 671059046, 471572041, 568184263,
      665850278, 485293416, 678176883, 502948552, 691134429, 692797006,
      501408588, 591489538, 695156337, 614066278, 554207518, 630080571,
      639334330, 651277943, 657695816, 674683935, 681852163, 688270629,
      595884688, 690913297, 609626624, 554181353, 630081364, 640421269,
      651290482, 657644995, 678001319, 681849681, 688281703, 592153259,
      595985047, 691657564, 539119283, 555743544, 500240107, 492970105,
      520837217, 513456333,

      615172670, 615172672,

      634734491,

      641503937, 669955925, 669955941,

      673687075, 683832768,

      700324366, 519724838, 606025764, 514333260, 612273338, 624971512,
      462154818, 342165792, 630119037, 536920082, 554680038, 536324769,
      636861868, 651278376, 478250323, 566492262, 658067574,

      468093756, 560792699, 669639652, 681039894, 500044551, 688919686,
      694697554,

      493851788, 588072290, 692404510, 528124021, 518594643, 312898699,
      518598114, 518586875, 518608617, 518615371, 518589870, 518589612,
      518594644, 546207218, 567036991, 521698800, 521669300, 308539761,
      521541853, 521467114, 439329178, 521589590, 521641571, 521563330,
      521642821, 521642818, 604714050, 624027550, 463062266, 315138897,
      538465041, 638301846, 650985515, 477935057, 669284012, 468148266,
      561300788, 669258281, 684387813, 684965974, 594058343, 696555933,
      521658070, 521624563, 322004105, 530310416, 517460551, 354362762,

      541495113, 566493360, 473089775, 561344026, 502166549, 490665682,
      529448511, 478563295, 520843353, 622975673, 546363893, 657635141,
      641593784, 685594597, 569245850, 683539322, 601213632, 526573002,
      423568589, 451371210, 537878435, 670653319, 473693232, 468736765,
      562946880, 674306357, 501924622, 701636511, 490665374, 701830067,
      310538575, 690949483, 690962751, 697791492, 515827763, 533064044,
      612842537, 432974667, 615062452, 548899255, 645216844, 634739249,
      546360403, 632856799, 671255701, 663708301, 569177593, 661524277,
      485874030, 681851963, 587911780, 685558935, 694692898, 495974073,
      584446353, 693344432, 459263040, 351465565, 468851238, 471561059,
      503714791, 564317190, 666957513, 435571258, 690951952, 690949436,
      697819763, 512887267, 533064529, 610653190, 544319097, 460729890,
      354689065, 645216819, 636027692, 543248700, 636006986, 471718179,
      671054838, 471571542, 569182043, 665843883, 485290636, 678176886,
      502945325, 691134451, 692788940, 501407582, 595369974, 695155575,
      614059026, 554022929, 630091831, 639324919, 651279687, 657644967,
      674687788, 681849653, 688270936, 595934796, 690919283, 612237531,
      554081099, 630083836, 640421547, 651290483, 657685912, 678001333,
      681859673, 688281705, 592163258, 691657565, 538969232, 555331831,
      500210416, 492967850, 521384199, 513669502,

      615158203, 615172673, 634736447,

      641508706, 669954746, 669956733,

      673677468, 683832096,

      700324375, 519724839, 478560025, 605998892, 514349008, 612274070,
      624968759, 462201761, 342165818, 630125813, 536920319, 554695296,
      536233352, 636861873, 651290360, 566492264, 658070060,

      468093757, 560798206, 669639663, 681039908, 499992230, 688919946,
      694696065,

      493851283, 588074063, 692393688, 528124763, 518589876, 519170751,
      519165251, 518589874, 519181251, 518930632, 519179751, 518594647,
      518586881, 546154622, 567100275, 521698796, 309151921, 439367587,
      521732761, 521541846, 521563322, 604729538, 316362968, 624029057,
      463062267, 538461902, 638302338, 650985517, 477934551, 669284016,
      468148268, 561248396, 669236353, 684389601, 684970233, 593725923,
      696558891, 367790408, 530317901, 517458554, 356780146,

      541491105, 566483653, 474114654, 561342510, 502171348, 491021124,
      529446257, 478564287, 520858351, 622965950, 546353653, 657635857,
      641609034, 685594627, 569242894, 683544867, 601204494, 476024295,
      526571253, 476025040, 674303589, 701827421,

      607850775, 462219342, 455643544, 624952609, 475089213, 670162641,
      670650719, 697138335,

      495439965, 580848204, 696699668, 690949486, 690962755, 698969291,
      515827764, 612847846, 548893512, 546359373, 632857264, 663717794,
      661524389, 485809070, 681849730, 694695093, 495979542, 693346264,
      459257793, 468851995, 479478754, 503713051, 662553054, 666962373,
      690951204, 690954757, 698388547, 512844290, 610628717, 460786061,
      354698094, 543248701, 636008448, 471771803, 479477011, 665848614,
      485286957, 678177965, 692788945, 501415668, 595364950, 695155867,
      450696359, 614068278, 629410996, 630091832, 541435885, 639334333,
      651278499, 657695820, 674689075, 681852172, 688270938, 496148901,
      697138279, 612237533, 629534091, 630074878, 541435887, 640422634,
      651304542, 657695823, 676581174, 681852244, 688270981, 496430496,
      592151270, 691657566, 471977979, 520872745, 539114105, 500283531,
      513455830, 538460095, 638302340, 650987276, 477934299, 669258376,
      468148271, 561495295, 669253297, 494124255, 696557967, 604723806,
      624020349, 678199342, 684389840, 684993763,

      615158204, 615154728, 634736449,

      669954747, 669973754,

      673687084, 683832774, 683834059,

      700432018, 520832670, 606007160, 514331029, 613710253, 624964775,
      462352180, 342214095, 630126547, 536913414,

      554704541, 536230342, 636864832, 651277864, 566477812, 478311034,
      658070064,

      468094514, 560824116, 678774099, 502929358, 683822295, 688919681,

      493861530, 694032928, 528126266, 520843369, 520840886, 520840884,
      520869655, 520873878, 521116360, 520874609, 546141123, 567102259,
      490824069, 521641304, 521731262, 521732759, 449585553, 450720505,
      561336031, 476741955, 503082604, 529440261, 478567536, 657654262,
      685626795, 683539394, 674303592, 490662368, 701829163, 496156940,
      477934558, 468148024, 561254662, 494125517, 478312268, 475517019,
      561498288,

      493852036, 496155389, 477930815, 468148026, 561254666, 494125518,
      475517024, 561498290, 493851284,

      610840785,

      640337179,

      665854142,

      688081396, 695894641, 619675072, 638287645, 636009335, 666936516,
      670599073, 686800544, 695894677, 540642519, 702103150, 702103158,
      471306290, 430372805, 607449027,

      628710821, 626367611, 420289229, 420335001, 430239955, 632109062,
      628704300, 628036512, 513454772, 615729971, 495679894, 615479528,
      622728106, 536993119, 463111644, 603906559, 635608703, 564088382,
      536990663, 430404770, 694174560, 694141677, 658067694, 658070203,
      658070214, 694175276, 694174584, 694174598, 694173150, 656800774,
      656800776, 315872062, 579326827, 419838926, 419973043, 419972281,
      419838967, 695195169, 422347634, 422464270, 543920510, 611032559,
      619039523, 674978582, 681899391, 622173503, 605349739, 602209760,
      630118161, 624253265, 645167853, 607456025, 656825787, 678753250,
      686480879, 673506760, 671611312, 675018254, 599472338, 628046251,
      667011104, 604102004, 667007407, 597439213, 626027077, 623436904,
      647597233, 595767064, 619264094, 649267150, 681899436, 622971129,
      605786293, 603058797, 630401068, 624564550, 607846509, 645152924,
      656816965, 678754689, 686479744, 673507261, 671617513, 675018286,
      599899255, 628708768, 667011107, 604278665, 667011908, 597971804,
      626006821, 623479262, 647597234, 595982382, 619670808, 649265633,
      681910595, 622965372, 606127522, 602605081, 625155228, 609758974,
      645152926, 656816975, 678769519, 686488067, 673507266, 671607592,
      675005120, 600205872, 628697812, 667007398, 604743762, 667011139,
      598480063, 626017116, 623497252, 647697546, 596389502, 685527754,
      685512545, 685524016, 685524049, 685526507, 685502859, 685524017,
      685526570, 685519272, 685502862, 685519308, 685524054, 685524011,
      685524002, 693121591, 685524057, 685527827, 599263374, 621138312,
      605230307, 601707867, 630125938, 624032065, 645152913, 603906285,
      626004375, 597190265, 623294073, 647619715, 595362936, 622966403,
      649263420, 681899323, 686497013, 609906652, 609903942, 609913198,
      645527932, 609903943, 657751461, 682157228, 632093389, 609913204,
      648623986, 609904414, 622979430, 656841079, 681899325, 686480963,
      609913216, 609918923, 609903956, 645527940, 609903957, 657743459,
      682156651, 632086966, 609904430, 648672902, 609906677, 622970343,
      656841082, 681899330, 686480939, 609918947, 609991782, 609991783,
      645531383, 609917653, 657744808, 682157237, 632097375, 609918955,
      648659431, 609911678, 609915303, 622966400, 609913566, 609922553,
      609913559, 609922556, 645531376, 609904298, 648672899, 609913573,
      543920509, 541010540, 634348347, 540997808, 642422643, 594945629,
      612988120, 638302381, 627093005, 598755965, 611060269,

      626011608, 430242946, 463143802, 463141801, 624294327, 631819668,
      628704329, 623331850, 471242229, 623299581, 619584239, 424140346,
      424142753, 601769758, 601769757, 601769759, 601683233, 601683234,
      601716859, 601769754, 601695224, 601769755, 601767017, 601767016,
      601688942, 696798264, 537009184, 315821053, 315821065, 430372817,
      621160469, 430290678, 536993130, 430339671,

      630837242, 627088300, 642415169, 421034112, 420685191, 635126897,
      669644012, 628710579, 628036021, 625158367, 464594940, 464642286,
      464642288,

      537009187, 621154871, 701816192, 425100917, 419702890, 471303819,
      430786999, 430290671,

      430784204, 419731181, 471241733, 615876487, 525505253, 525224373,
      525219869, 525224372, 525230104, 513448264, 564003959, 628689061,
      628036513, 696175703, 632498047, 695465635, 430240220, 563215621,
      474232618, 442367954, 442365424, 621138529, 628708780, 628045761,
      651692578, 651682462, 315823262, 559659232, 471242217, 628689063,
      628036514,

      633293681, 628710828, 628036782, 628710584, 628046526,
    ],
  }),
  components: {
    Footer,
  },
  computed: {
    // Computed property for the screen width
    screenWidth() {
      let width = this.$vuetify.breakpoint.width;
      return width;
    },
  },
  methods: {
    async convertCSVToProductOrders() {
      try {
        this.convertingFunction = true;
        console.log("Converting CSV to ProductOrders");
        console.log("File to convert:", this.fileToConvert);
        if (!this.fileToConvert) {
          console.log("No file to convert");
          return;
        }
        const file = this.fileToConvert;
        const fileText = await file.text();
        console.log("File text: ", fileText);

        const parsedData = Papa.parse(fileText, {
          delimiter: ";", // Specify your delimiter
          skipEmptyLines: true, // Skip empty lines
          dynamicTyping: true, // Automatically convert numeric values
          complete: (results) => {
            console.log("Parsed Results:", results);
          },
        });
        const headers = parsedData.data[0];
        const productOrders = [];
        // Start from 1 to skip the header row
        for (let i = 1; i < parsedData.data.length; i++) {
          const row = parsedData.data[i];
          // Skip empty rows
          if (row.length === 0 || row[0] === "") {
            continue;
          }
          const productOrder = {};
          for (let j = 0; j < headers.length; j++) {
            productOrder[headers[j]] = row[j] || null; // Use null if data is missing
          }
          productOrders.push(productOrder);
        }

        // Filter out orders that already exist
        const newProductOrders = productOrders.filter(
          (item) =>
            !this.existingProductOrderNumbers.some(
              (num) => num === item.order_number
            )
        );

        let newlyAddedProductsOrderNumbers = [
          ...new Set(newProductOrders.map((item) => item.order_number)),
        ];
        let failedProductsOrderNumbers = [];

        // Group orders by order_number
        const groupedByOrderNumber = _.groupBy(
          newProductOrders,
          "order_number"
        );

        // Limit the total number of concurrent API calls to 300
        const limit = pLimit(300); // Limit to 300 concurrent requests globally
        const batchSize = 3; // Max distinct order_numbers per batch

        // Process orders in batches of up to 3 distinct order_numbers
        const orderPromises = [];
        const orderNumberKeys = Object.keys(groupedByOrderNumber);

        for (let i = 0; i < orderNumberKeys.length; i += batchSize) {
          const batchOrderNumbers = orderNumberKeys.slice(i, i + batchSize);

          // Gather all orders that belong to the order_numbers in this batch
          const batchOrders = batchOrderNumbers.flatMap(
            (orderNumber) => groupedByOrderNumber[orderNumber]
          );

          // Submit the batch to the API
          orderPromises.push(
            limit(async () => {
              try {
                console.log(
                  `Processing batch for order numbers: ${batchOrderNumbers.join(
                    ", "
                  )}`
                );
                const response = await API.post(
                  "adminlambdaapi",
                  "/item/uploadProductOrders",
                  {
                    body: { productOrders: batchOrders },
                  }
                );

                if (response.success) {
                  console.log(
                    `Saved batch for order numbers: ${batchOrderNumbers.join(
                      ", "
                    )}`
                  );
                }
              } catch (error) {
                // Collect failed order numbers for later processing
                batchOrderNumbers.forEach((orderNumber) => {
                  failedProductsOrderNumbers.push(orderNumber);
                });
                console.error(
                  `Error uploading batch for order numbers ${batchOrderNumbers.join(
                    ", "
                  )}:`,
                  error
                );
              }
            })
          );
        }

        // Wait for all order batches to be processed concurrently
        await Promise.all(orderPromises);

        // Final log of success and failure
        console.log(
          "Final newly added product orders: ",
          newlyAddedProductsOrderNumbers
        );
        console.log(
          "Final failed product orders: ",
          failedProductsOrderNumbers
        );

        this.convertingFunction = false;
      } catch (error) {
        console.error("Error in convertCSVToProductOrders:", error);
      }
    },

    async convertCSVToUsers() {
      try {
        this.convertingFunction = true;
        console.log("Converting CSV to Users");
        console.log("File to convert:", this.fileToConvert);
        if (!this.fileToConvert) {
          console.log("No file to convert");
          return;
        }
        const file = this.fileToConvert;
        const fileText = await file.text();
        console.log("File text: ", fileText);
        // console.log("Products: ", products);
        let newlyAddedUserEmails = [];
        const parsedData = Papa.parse(fileText, {
          delimiter: ";", // Specify your delimiter
          skipEmptyLines: true, // Skip empty lines
          dynamicTyping: true, // Automatically convert numeric values
          complete: (results) => {
            console.log("Parsed Results:", results);
          },
        });
        const headers = parsedData.data[0];
        const users = [];
        // Start from 1 to skip the header row
        for (let i = 1; i < parsedData.data.length; i++) {
          const row = parsedData.data[i];
          // Skip empty rows
          if (row.length === 0 || row[0] === "") {
            continue;
          }
          const user = {};
          for (let j = 0; j < headers.length; j++) {
            user[headers[j]] = row[j] || null; // Use null if data is missing
          }
          users.push(user);
        }
        // console.log("users: ", users);
        const batchSize = 100;
        const newPhoneNumber = "+270000000000";
        // const newTemporaryPassword = "tempPass123!";
        let newlyAddedUsers = [];
        for (let i = 0; i < users.length; i += batchSize) {
          const batch = users.slice(i, i + batchSize);
          const newUsers = batch
            .filter(
              (item) =>
                !this.existingUserEmails.some(
                  (user) => user.email === item.email
                )
            )
            .map((item) => ({
              ...item,
              phoneNumber: newPhoneNumber,
              temporaryPassword: `tempPass${Math.floor(
                1000 + Math.random() * 9000
              )}!`, //Generate password ${Math.random().toString(36).slice(-4)}
            }));
          if (newUsers.length === 0) {
            console.log("No new users in batch " + i + ": ", batch);
            continue;
          }
          console.log("newUsers: ", newUsers);
          // Process the current batch
          await API.post("cognitolambdaapi", "/item/createUsers", {
            body: {
              users: newUsers,
            },
          })
            .then((response) => {
              console.log("Users saved batch " + i + ": ", batch);
              newlyAddedUserEmails.push(
                ...newUsers.map((item) => ({
                  email: item.email,
                  temporaryPassword: item.temporaryPassword,
                }))
              );
              if (response.success) {
                console.log(
                  "response.successfulUsers: ",
                  response.successfulUsers
                );
                console.log("failedUsers: ", response.failedUsers);
                newlyAddedUsers.push(...response.successfulUsers);
              }
            })
            .catch((error) => {
              // Log any errors
              console.log("error:", error);
            });
        }
        if (newlyAddedUsers.length > 0) {
          for (let i = 0; i < newlyAddedUsers.length; i++) {
            const user = newlyAddedUsers[i]?.User;
            console.log("user: ", user);
            const subAttribute = user?.Attributes?.find(
              (attr) => attr.Name === "sub"
            );
            if (!subAttribute) {
              console.log("No sub attribute found for user: ", user);
              continue;
            }
            const owner = `${subAttribute.Value}::${user.Username}`;
            console.log("Owner: ", owner);
            await DataStore.save(
              new TeacherProfile({
                title: "",
                name: "",
                surname: "",
                language: "",
                province: "",
                daysPerWeek: 0,
                status: "Active",
                TeacherSchedules: [],
                TeacherClassSubjects: [],
                type: "TeacherProfile",
                owner: owner,
              })
            );
          }
        }
        console.log("Users saved");
        console.log("newlyAddedUsers: ", newlyAddedUsers);
        console.log(
          "JSON.stringify(newlyAddedUsers): ",
          JSON.stringify(newlyAddedUsers)
        );
        console.log("Newly added user emails: ", newlyAddedUserEmails);
        this.convertingFunction = false;
      } catch (error) {
        console.error(error);
      }
    },

    async convertCSVToProductsUpdate() {
      try {
        this.convertingFunction = true;
        console.log("Converting CSV to Data Models");
        console.log("File to convert:", this.fileToConvert);
        if (!this.fileToConvert) {
          console.log("No file to convert");
          return;
        }
        const file = this.fileToConvert;
        const fileText = await file.text();
        console.log("File text: ", fileText);
        let updatedProductIds = [];
        const parsedData = Papa.parse(fileText, {
          delimiter: ";", // Specify your delimiter
          skipEmptyLines: true, // Skip empty lines
          dynamicTyping: true, // Automatically convert numeric values
          complete: (results) => {
            console.log("Parsed Results:", results);
          },
        });
        const headers = parsedData.data[0];
        const products = [];
        // Start from 1 to skip the header row
        for (let i = 1; i < parsedData.data.length; i++) {
          const row = parsedData.data[i];
          // Skip empty rows
          if (row.length === 0 || row[0] === "") {
            continue;
          }
          const product = {};
          for (let j = 0; j < headers.length; j++) {
            product[headers[j]] = row[j] || null; // Use null if data is missing
          }
          products.push(product);
        }
        console.log("Products: ", products);
        for (let i = 0; i < products.length; i++) {
          // console.log("Count: ", i);
          if (i % 100 === 0) {
            console.log(`Count: ${i}`);
          }

          // if (i === 9) {
          //   // 10th product (index 9)
          //   break; // Stops the loop after the 10th product
          // }

          if (products[i].product_id) {
            const productsQuery = await DataStore.query(Product, (product) =>
              product.and((f) => [
                f.title("eq", products[i].name.trim()),
                f.featured("ne", true),
                // products[i].description
                //   ? f.description("eq", products[i].description.trim())
                //   : true,
                f.or((f) => [
                  f.publishDate("eq", null),
                  f.publishDate("eq", ""),
                ]),
                // f.eCommerceProduct("ne", true),
                // f.subscriptionProduct("ne", true),
                // f.physical("ne", true),
              ])
            );

            if (!productsQuery || productsQuery.length === 0) {
              // console.log("Product not found: ", products[i].name);
              // console.log("products[i].product_id: ", products[i].product_id);
              // console.log("products[i].description: ", products[i].description);
              continue;
            } else if (productsQuery.length > 1) {
              console.log("Multiple products found");
              console.log("productsQuery: ", productsQuery);
              if (products[i].name === products[i + 1].name) {
                console.log("Duplicate CSV Product 1");

                const newProductSearchField = JSON.stringify(
                  _.values(_.omit(products[i], this.productSearchExcludeFields))
                ).toLowerCase();
                // console.log("New Product Search Field: ", newProductSearchField);

                await DataStore.save(
                  Product.copyOf(productsQuery[0], (product) => {
                    product.sku = products[i].sku ? products[i].sku : null;
                    product.searchField = newProductSearchField;
                  })
                ).catch((err) => {
                  console.log(err);
                });

                updatedProductIds.push(products[i].product_id);

                continue;
              } else if (i > 0 && products[i].name === products[i - 1].name) {
                console.log("Duplicate CSV Product 2");

                const newProductSearchField = JSON.stringify(
                  _.values(_.omit(products[i], this.productSearchExcludeFields))
                ).toLowerCase();
                // console.log("New Product Search Field: ", newProductSearchField);

                await DataStore.save(
                  Product.copyOf(productsQuery[1], (product) => {
                    product.sku = products[i].sku ? products[i].sku : null;
                    product.searchField = newProductSearchField;
                  })
                ).catch((err) => {
                  console.log(err);
                });

                updatedProductIds.push(products[i].product_id);

                continue;
              }
              // console.log("Query: ", productsQuery);

              const newProductSearchField = JSON.stringify(
                _.values(_.omit(products[i], this.productSearchExcludeFields))
              ).toLowerCase();
              // console.log("New Product Search Field: ", newProductSearchField);

              for (let iQ = 0; iQ < productsQuery.length; iQ++) {
                await DataStore.save(
                  Product.copyOf(productsQuery[iQ], (product) => {
                    product.sku = products[i].sku ? products[i].sku : null;
                    product.searchField = newProductSearchField;
                  })
                ).catch((err) => {
                  console.log(err);
                });
              }

              updatedProductIds.push(products[i].product_id);

              continue;
            } else if (
              typeof productsQuery[0].sku === "number" &&
              productsQuery[0].sku === products[i].sku
            ) {
              console.log("Product already updated: ", products[i].name);
              continue;
            }

            const newProductSearchField = JSON.stringify(
              _.values(_.omit(products[i], this.productSearchExcludeFields))
            ).toLowerCase();
            // console.log("New Product Search Field: ", newProductSearchField);

            await DataStore.save(
              Product.copyOf(productsQuery[0], (product) => {
                product.sku = products[i].sku ? products[i].sku : null;
                product.searchField = newProductSearchField;
              })
            ).catch((err) => {
              console.log(err);
            });

            updatedProductIds.push(products[i].product_id);
          } else continue;
        }

        console.log("Products saved");
        console.log("Updated product IDs: ", updatedProductIds);
        this.convertingFunction = false;
      } catch (error) {
        console.error(error);
      }
    },

    async convertCSVToProducts() {
      try {
        this.convertingFunction = true;
        console.log("Converting CSV to Data Models");
        console.log("File to convert:", this.fileToConvert);
        if (!this.fileToConvert) {
          console.log("No file to convert");
          return;
        }
        const file = this.fileToConvert;
        const fileText = await file.text();
        console.log("File text: ", fileText);

        let newlyAddedProductIds = [];
        const parsedData = Papa.parse(fileText, {
          delimiter: ";", // Specify your delimiter
          skipEmptyLines: true, // Skip empty lines
          dynamicTyping: true, // Automatically convert numeric values
          complete: (results) => {
            console.log("Parsed Results:", results);
          },
        });
        const headers = parsedData.data[0];
        const products = [];
        // Start from 1 to skip the header row
        for (let i = 1; i < parsedData.data.length; i++) {
          const row = parsedData.data[i];
          // Skip empty rows
          if (row.length === 0 || row[0] === "") {
            continue;
          }
          const product = {};
          for (let j = 0; j < headers.length; j++) {
            product[headers[j]] = row[j] || null; // Use null if data is missing
          }
          products.push(product);
        }

        console.log("Products: ", products);

        // const productsQuery = await DataStore.query(Product, (product) =>
        //   product.and((f) => [
        //     f.featured("ne", true),
        //     f.or((f) => [f.publishDate("eq", null), f.publishDate("eq", "")]),
        //     f.createdAt("gt", "2024-11-29T13:12:32.931Z"),
        //   ])
        // );

        // console.log("productsQuery: ", productsQuery);
        // const productIds = products
        //   .filter((product) =>
        //     productsQuery.some((item) => item.title === product.name)
        //   )
        //   .map((product) => product.product_id);
        // console.log("productIds", productIds);

        for (let i = 0; i < products.length; i++) {
          console.log("Count: ", i);
          // if (i === 9) {
          //   // 10th product (index 9)
          //   break; // Stops the loop after the 10th product
          // }
          if (products[i].product_id) {
            if (this.existingProductIds.includes(products[i].product_id)) {
              continue;
            } else {
              newlyAddedProductIds.push(products[i].product_id);
            }
          } else continue;
          const newProductSearchField = JSON.stringify(
            _.values(_.omit(products[i], this.productSearchExcludeFields))
          ).toLowerCase();
          // console.log("New Product Search Field: ", newProductSearchField);
          const newProduct = await DataStore.save(
            new Product({
              title: products[i].name,
              description: products[i].description
                ? products[i].description
                : "",
              price: products[i].price
                ? parseFloat(products[i].price)
                : products[i].recommended_price
                ? parseFloat(products[i].recommended_price)
                : 0,
              sku: products[i].sku ? products[i].sku : null,
              // thumbnails: ,
              // tags: this.editedItem.tags.length > 0 ? this.editedItem.tags : [],
              // questions: this.editedItem.questions,
              // publishDate: this.editedItem.publishDate,
              // featured: this.editedItem.featured,
              // physical: this.editedItem.physical,
              // subscriptionProduct: this.editedItem.subscriptionProduct,
              // eCommerceProduct: this.editedItem.eCommerceProduct,
              searchField: newProductSearchField,
              // categoryId: this.editedItem.categoryId,
            })
          );
          // console.log("ProductImage: ", products[i].image);
          if (!products[i].image || !products[i].image.includes("https://")) {
            continue;
          }
          try {
            let newThumbnailsArray = [];
            //------------------------------------------------------------
            // const inputElement = document.createElement("input");
            // inputElement.type = "file";
            // inputElement.accept = "image/*";
            // // inputElement.multiple = true; // Allow multiple files to be selected
            // inputElement.addEventListener("change", async (event) => {
            //   if (
            //     event.target instanceof HTMLInputElement &&
            //     event.target.files &&
            //     event.target.files.length > 0
            //   ) {
            //     const file = event.target.files[0];
            //------------------------------------------------------------
            const maxSize = 800; // Define your maximum size here
            // Create a function to resize the image
            const resizeImage = (image) => {
              return new Promise((resolve) => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                const img = new Image();
                img.onload = () => {
                  let width = img.width;
                  let height = img.height;
                  if (width > height) {
                    if (width > maxSize) {
                      height *= maxSize / width;
                      width = maxSize;
                    }
                  } else {
                    if (height > maxSize) {
                      width *= maxSize / height;
                      height = maxSize;
                    }
                  }
                  canvas.width = width;
                  canvas.height = height;
                  ctx.drawImage(img, 0, 0, width, height);
                  canvas.toBlob(
                    (blob) => {
                      resolve(blob);
                      URL.revokeObjectURL(img.src); // Revoke ObjectURL to free memory
                    },
                    "image/jpeg",
                    0.9
                  );
                };
                img.src = URL.createObjectURL(image);
              });
            };
            this.uploadCSVThumbnailResponse = await fetch(
              products[i].image
            ).catch((err) => {
              console.log(err);
            });
            if (!this.uploadCSVThumbnailResponse) {
              continue;
            }
            //  else continue;
            this.uploadCSVThumbnailBlob =
              await this.uploadCSVThumbnailResponse.blob();
            this.uploadCSVThumbnailResizedBlob = await resizeImage(
              this.uploadCSVThumbnailBlob
            );
            let fileName = products[i].image.split("/").pop();
            if (!fileName.includes(".jpeg")) {
              fileName += ".jpeg"; // or whatever default extension you need
            }
            // console.log("File name: ", fileName);
            const newThumbnailImgPutKey =
              "Products/" + newProduct.id + "/" + Date.now() + "-" + fileName;
            // Upload the resized file to S3
            await Storage.put(
              newThumbnailImgPutKey,
              this.uploadCSVThumbnailResizedBlob
            ).catch((err) => console.log(err));
            newThumbnailsArray.push(newThumbnailImgPutKey);
            const secondNewProductSearchField = JSON.stringify(
              _.values(
                _.omit(
                  { ...products[i], thumbnails: newThumbnailsArray },
                  this.productSearchExcludeFields
                )
              )
            ).toLowerCase();
            await DataStore.save(
              Product.copyOf(newProduct, (product) => {
                product.thumbnails = newThumbnailsArray;
                product.searchField = secondNewProductSearchField;
              })
            )
              .catch((err) => {
                console.log(err);
              })
              .then(() => {
                // console.log("Product thumbnail saved");
              });
            //------------------------------------------------------------
            //   }
            // });
            // document.body.appendChild(inputElement);
            // inputElement.click();
            //------------------------------------------------------------
          } catch (error) {
            console.log(error);
            this.loading = false;
          }
        }
        console.log("Products saved");
        console.log("Newly added product IDs: ", newlyAddedProductIds);
        this.convertingFunction = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
});
</script>
