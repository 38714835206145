<template>
  <!-- Root div with an ID 'printlayout' -->
  <div id="printlayout">
    <!-- Application bar component -->
    <v-app-bar app flat id="printlayout-app-bar" extension-height="44px">
      <!-- Burger Icon -->
      <v-app-bar-nav-icon
        @click="leftDrawer = !leftDrawer"
        height="36px"
        width="36px"
      ></v-app-bar-nav-icon>

      <div
        class="d-flex flex-row align-center"
        style="width: -webkit-fill-available"
      >
        <div>
          <v-tooltip
            bottom
            v-if="
              currentRoute.name === 'LessonPrinter'
                ? screenWidth > 411
                : screenWidth > 279
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-2 black--text"
                depressed
                fab
                height="36px"
                width="36px"
                v-bind="attrs"
                v-on="on"
                @click="back"
                color="secondary"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.t("movement.back") }}</span>
          </v-tooltip>
        </div>
        <div class="d-flex">
          <div
            v-if="screenWidth > 640 || currentRoute.name === 'LessonPrinter'"
            class="d-flex"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  fab
                  height="36px"
                  class="ml-2"
                  width="36px"
                  v-bind="attrs"
                  v-on="on"
                  @click="printContent"
                  color="primary"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>{{ $i18n.t("headings.print") }}</span>
            </v-tooltip>

            <v-menu
              offset-y
              permanent
              :close-on-content-click="closeOnContentClick"
            >
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      depressed
                      color="primary"
                      class="ml-2"
                      height="36px"
                      width="36px"
                      fab
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      <v-icon>mdi-format-size</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $i18n.t("typography.font") + " " + $i18n.t("labels.size")
                  }}</span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item-group>
                  <v-list-item
                    @click="changeFontSize(size)"
                    v-for="(size, index) in fontSizes"
                    :key="index"
                  >
                    <v-list-item-title>{{ size }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>

            <v-tooltip v-if="schoolImgKey !== ''" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  fab
                  height="36px"
                  width="36px"
                  v-bind="attrs"
                  v-on="on"
                  @click="changeImage()"
                  :color="
                    imgType === 'ShowSchool' ? 'primary' : 'grey lighten-2'
                  "
                  class="ml-2"
                >
                  <v-icon>mdi-image</v-icon>
                </v-btn>
              </template>
              <span>{{
                $i18n.t("actions.show") +
                " " +
                $i18n.t("labels.school") +
                " " +
                $i18n.t("headings.logo")
              }}</span>
            </v-tooltip>
          </div>

          <!-- Assessment Buttons -->
          <div v-if="currentRoute.name !== 'LessonPrinter'">
            <div v-if="screenWidth > 516">
              <v-btn
                text
                @click="changeType('Questions')"
                :color="type === 'Questions' ? 'primary' : ''"
                class="ml-2"
                >{{ $i18n.t("headings.questions") }}</v-btn
              >
              <v-btn
                text
                @click="changeType('Memo')"
                :color="type === 'Memo' ? 'primary' : ''"
                class="ml-2"
                >{{ $i18n.t("headings.memorandum") }}</v-btn
              >
            </div>
            <div v-else-if="screenWidth > 367">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    fab
                    height="36px"
                    width="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="changeType('Questions')"
                    :color="type === 'Questions' ? 'primary' : ''"
                    class="ml-2"
                  >
                    <v-icon>mdi-alpha-q</v-icon>
                  </v-btn>
                </template>
                <span>{{ $i18n.t("headings.questions") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    fab
                    height="36px"
                    width="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="changeType('Memo')"
                    :color="type === 'Memo' ? 'primary' : ''"
                    class="ml-2"
                  >
                    <v-icon>mdi-alpha-m</v-icon>
                  </v-btn>
                </template>
                <span>{{ $i18n.t("headings.memorandum") }}</span>
              </v-tooltip>
            </div>
          </div>
          <!-- ------------------ -->
        </div>
        <v-spacer />

        <v-tooltip bottom v-if="screenWidth > 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-3 black--text"
              v-bind="attrs"
              v-on="on"
              icon
              @click="$store.dispatch('signOut')"
              color="secondary"
            >
              <v-icon>mdi-logout-variant</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.t("headings.signOut") }}</span>
        </v-tooltip>
      </div>

      <!-- Router link to the root path with the logo image -->
      <router-link to="/">
        <v-img
          class="ml-3"
          src="@/assets/logo-v2.svg"
          max-height="50"
          max-width="93"
          contain
        ></v-img>
      </router-link>

      <template
        v-if="screenWidth < 641 && currentRoute.name !== 'LessonPrinter'"
        v-slot:extension
      >
        <div class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                fab
                height="36px"
                width="36px"
                v-bind="attrs"
                v-on="on"
                @click="printContent"
                color="primary"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.t("headings.print") }}</span>
          </v-tooltip>

          <v-menu
            offset-y
            permanent
            :close-on-content-click="closeOnContentClick"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    depressed
                    color="primary"
                    class="ml-2"
                    height="36px"
                    width="36px"
                    fab
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                  >
                    <v-icon>mdi-format-size</v-icon>
                  </v-btn>
                </template>
                <span>{{
                  $i18n.t("typography.font") + " " + $i18n.t("labels.size")
                }}</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item
                  @click="changeFontSize(size)"
                  v-for="(size, index) in fontSizes"
                  :key="index"
                >
                  <v-list-item-title>{{ size }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>

          <v-tooltip v-if="schoolImgKey !== ''" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                fab
                height="36px"
                width="36px"
                v-bind="attrs"
                v-on="on"
                @click="changeImage()"
                :color="imgType === 'ShowSchool' ? 'primary' : 'grey lighten-2'"
                class="ml-2"
              >
                <v-icon>mdi-image</v-icon>
              </v-btn>
            </template>
            <span>{{
              $i18n.t("actions.show") +
              " " +
              $i18n.t("labels.school") +
              " " +
              $i18n.t("headings.logo")
            }}</span>
          </v-tooltip>

          <div v-if="screenWidth < 368">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  fab
                  height="36px"
                  width="36px"
                  v-bind="attrs"
                  v-on="on"
                  @click="changeType('Questions')"
                  :color="type === 'Questions' ? 'primary' : ''"
                  class="ml-2"
                >
                  <v-icon>mdi-alpha-q</v-icon>
                </v-btn>
              </template>
              <span>{{ $i18n.t("headings.questions") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  fab
                  height="36px"
                  width="36px"
                  v-bind="attrs"
                  v-on="on"
                  @click="changeType('Memo')"
                  :color="type === 'Memo' ? 'primary' : ''"
                  class="ml-2"
                >
                  <v-icon>mdi-alpha-m</v-icon>
                </v-btn>
              </template>
              <span>{{ $i18n.t("headings.memorandum") }}</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </v-app-bar>

    <!-- Main content area -->
    <v-main id="printlayout-v-main" fluid fill-height>
      <!-- Router view to render the component matched by the route -->
      <router-view />
    </v-main>

    <!-- Left navigation drawer -->
    <v-navigation-drawer
      v-model="leftDrawer"
      left
      app
      id="printlayout-nav-drawer"
      width="275"
    >
      <!-- Top section with a full logo -->
      <div class="d-flex flex-row pa-2">
        <v-img class="mr-3" src="@/assets/logo-v2-long.svg" contain></v-img>
      </div>
      <!-- Dividers and contents inside the drawer -->
      <v-responsive class="mx-auto" width="56">
        <v-divider></v-divider>
        <v-divider></v-divider>
      </v-responsive>
      <!-- Navigation list -->
      <v-list nav dense>
        <v-list-item-group
          v-model="leftDrawerListGroup"
          active-class="secondary"
        >
          <!-- <v-btn color="primary" depressed block height="40px" @click="printContent" class="mb-1 px-2">
            <div class="d-flex flex-row align-center justify-left" style="width: -webkit-fill-available;">
              <v-icon class="mr-8">mdi-printer</v-icon>Print
            </div>
          </v-btn> -->

          <v-list-group
            v-if="
              userGroups?.includes('teacher') ||
              syncedBoughtProducts?.length > 0
            "
            class="mb-1"
            prepend-icon="mdi-bookshelf"
            no-action
            color="rgba(0, 0, 0, 0.87)"
            :value="
              $route?.path?.includes('subscribed-products') ||
              $route?.path?.includes('bought-products')
            "
          >
            <template v-slot:activator>
              <!-- <v-list-item-title class="v-btn" style="justify-content: start"
                >Products</v-list-item-title
              > -->
              <div class="d-flex" style="width: 100%">
                <v-btn to="/app/products" class="px-0" plain>
                  <v-list-item-title
                    class="v-btn"
                    style="justify-content: start"
                    >{{
                      $i18n.t("headings.resourceLibrary")
                    }}</v-list-item-title
                  >
                </v-btn>
              </div>
            </template>
            <v-list-item
              v-show="userGroups?.includes('teacher')"
              to="/app/subscribed-products"
            >
              <v-list-item-icon>
                <v-icon>mdi-book-check</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="v-btn" style="justify-content: start">{{
                $i18n.t("headings.subscribed") +
                " " +
                $i18n.t("headings.products")
              }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-show="syncedBoughtProducts?.length > 0"
              to="/app/bought-products"
            >
              <v-list-item-icon>
                <v-icon>mdi-cash-check</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="v-btn" style="justify-content: start">{{
                $i18n.t("headings.bought") + " " + $i18n.t("headings.products")
              }}</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else to="/app/products">
            <v-list-item-icon>
              <v-icon>mdi-bookshelf</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start">{{
              $i18n.t("headings.resourceLibrary")
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item to="/app/profile">
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start">{{
              $i18n.t("headings.profile")
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item to="/app/calendar">
            <v-list-item-icon>
              <v-icon>mdi-calendar-multiselect-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start">{{
              $i18n.t("headings.calendar")
            }}</v-list-item-title>
          </v-list-item>

          <v-list-group
            prepend-icon="mdi-file-outline"
            no-action
            color="rgba(0, 0, 0, 0.87)"
          >
            <template v-slot:activator>
              <v-list-item-title class="v-btn" style="justify-content: start">
                <!-- {{$i18n.t("otherText.my") + " " + $i18n.t("headings.classroom")}} -->
                {{ $i18n.t("otherText.createdByMe") }}
              </v-list-item-title>
            </template>
            <v-list-item to="/app/lessons">
              <v-list-item-icon>
                <v-icon>mdi-file-document-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="v-btn" style="justify-content: start">{{
                $i18n.t("otherText.my") + " " + $i18n.t("headings.lessons")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/app/assessments">
              <v-list-item-icon>
                <v-icon>mdi-file-document-edit-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="v-btn" style="justify-content: start">{{
                $i18n.t("otherText.my") + " " + $i18n.t("headings.assessments")
              }}</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item to="/app/support">
            <v-list-item-icon>
              <v-icon>mdi-account-question-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start">{{
              $i18n.t("headings.support")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$store.dispatch('signOut')">
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="v-btn" style="justify-content: start">{{
              $i18n.t("headings.signOut")
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <Footer id="printlayout-footer" :showLegal="false" />
  </div>
</template>

<script>
import Vue from "vue";
import router from "@/router";
import { mapState } from "vuex";
import Footer from "@/components/global-components/Footer.vue";
import { DataStore } from "aws-amplify";
import { School } from "@/models";

export default Vue.extend({
  name: "PrintLayout",

  data: () => ({
    leftDrawer: false, // State of the left navigation drawer
    leftDrawerListGroup: null, // Currently selected list item group in left drawer
    isPrintActive: true,
    type: "Questions",
    fontSizes: ["+", "-"],
    closeOnContentClick: false,
    schoolImgKey: "",
    imgType: "ShowSchool",
  }),

  components: {
    Footer,
  },

  computed: {
    ...mapState({
      userGroups: (state) => state.userGroups,
      teacherProfileSchoolID: (state) => state.teacherProfileSchoolID,
      syncedBoughtProducts: (state) => state.syncedBoughtProducts,
    }),
    // Computed property for the screen width
    screenWidth() {
      let width = this.$vuetify.breakpoint.width;
      return width;
    },
    currentRoute() {
      return router.currentRoute;
    },
  },

  watch: {
    teacherProfileSchoolID: {
      handler: async function (val) {
        if (val) {
          const school = await DataStore.query(School, val);
          if (
            typeof school !== "undefined" &&
            school.schoolImgKey &&
            school.schoolImgKey !== ""
          ) {
            this.schoolImgKey = school.schoolImgKey;

            document.dispatchEvent(
              new CustomEvent("changeTemplateImgSrc", {
                detail: school.schoolImgKey,
              })
            );
          }
        }
      },
      immediate: true,
    },
  },

  // mounted() {
  //   try {
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },

  methods: {
    changeImage() {
      if (this.imgType === "ShowSchool") {
        this.imgType = "HideSchool";

        document.dispatchEvent(
          new CustomEvent("changeTemplateImgSrc", { detail: "" })
        );
      } else if (this.imgType === "HideSchool") {
        this.imgType = "ShowSchool";

        document.dispatchEvent(
          new CustomEvent("changeTemplateImgSrc", { detail: this.schoolImgKey })
        );
      }
    },
    changeType(fromBtnType) {
      if (this.type === "Questions" && fromBtnType !== "Questions") {
        this.type = "Memo";
        document.dispatchEvent(new CustomEvent("changeType"));
      } else if (this.type === "Memo" && fromBtnType !== "Memo") {
        this.type = "Questions";
        document.dispatchEvent(new CustomEvent("changeType"));
      }
    },
    changeFontSize(size) {
      if (size === "+") {
        document.dispatchEvent(new CustomEvent("increaseFontSize"));
      } else if (size === "-") {
        document.dispatchEvent(new CustomEvent("decreaseFontSize"));
      }
    },
    printContent() {
      window.print();
    },
    back() {
      // console.log(this.$router)
      if (this.$router.options.history?.state?.back) {
        this.$router.back(); // Go back in the browser history
        // importRoute.value.go(-1); // Go back in the browser history
      } else {
        this.$router.push({
          path:
            this.$router.app._route.name === "AssessmentPrinter"
              ? "/app/assessments"
              : this.$router.app._route.name === "LessonPrinter"
              ? "/app/lessons"
              : "/",
        }); // Navigate to the home page if no history to go back
      }
    },
  },
});
</script>
<style>
.ResizableImageFig img {
  max-width: 100%;
  max-height: 100%;
}

/* #assessmentTemplatePrinterExample>div {
  padding-bottom: 100px;
} */

.print-copyright-footer {
  text-align: left;
  color: #000000;
}

@media print {
  /* Display the print footer only on the last page */
  @page {
    size: auto;
    /* Reset the page size to auto */
    /* margin-bottom: 20px; */
    /* Reset the margins to 0 */
  }

  /* Adjust the position and styles of the print footer */
  .print-copyright-footer {
    position: fixed;
    bottom: -7px;
    left: 0;
    width: 100%;
    /* background-color: #fff; */
    /* Background color for the bottom of the last page */
    display: block;
    /* Display the print footer on the last page */
  }

  /* .v-overlay__scrim {
    opacity: 0 !important;
  } */
  .v-tooltip__content {
    display: none !important;
  }

  #printlayout-v-main {
    padding: 0 !important;
  }

  #printlayout-app-bar {
    display: none !important;
  }

  #printlayout-nav-drawer {
    display: none !important;
  }

  #printlayout-footer {
    display: none !important;
  }
}

#printlayout-app-bar .v-toolbar__content {
  padding-bottom: 0px;
}

#printlayout-app-bar .v-toolbar__extension {
  padding: 0px 4px 8px 4px;
}

/* .productsvlist .v-list-item__icon {
  margin-right: 16px !important;
} */
</style>
