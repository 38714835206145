<template>
  <div id="admincommunications">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col>
          <Communications />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import Communications from "@/components/admin-components/global/Communications.vue";
export default {
  name: "AdminCommunications",
  components: { Communications },
  data: () => ({}),
};
</script>
