import Vue from "vue";
import Vuex from "vuex";
import { Auth, DataStore } from "aws-amplify";
// import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isOnline: true,
    appLoading: false,
    userSignedIn: false,
    userGroups: null,
    userEmail: "",
    syncedTeacherProfile: false,
    syncedTeacherProfileModel: null,
    teacherProfileID: "",
    teacherProfileProvince: "",
    teacherProfileSchoolID: "",
    teacherProfilePayfastSubscriptionToken: "",
    teacherProfilePayfastSubscriptionResponse: null,
    teacherProfileAppSyncVersion: "",
    notReadAnnouncements: null,
    syncedTeacherProductsOrders: false,
    syncedTeacherProductsOrderModels: [],
    syncedBoughtProducts: [],
    syncedPendingProducts: [],
    // teacherProfilePayfastSubscriptionPackage: null,
    // teacherProfileProductSubcriptions: 50,
  },
  getters: {},
  mutations: {
    storeSyncedTeacherProductsOrders(state, payload) {
      // console.log('storeTeacherProfileModel', payload)
      state.syncedTeacherProductsOrders = payload;
    },
    storeTeacherProductsOrderModels(state, payload) {
      state.syncedTeacherProductsOrderModels = payload;

      const boughtProducts =
        payload?.length > 0
          ? // Filter orders with status "PAID"
            payload
              .filter((order) => order?.orderStatus === "PAID")
              // Map to parse orderProducts and extract ids
              .flatMap((order) => order?.orderProducts || [])
              // Map to extract only id
              .map((product) => product?.id || null)
          : [];

      state.syncedBoughtProducts = boughtProducts;

      const pendingProducts =
        payload?.length > 0
          ? // Filter orders with status "PENDING"
            payload
              .filter((order) => order?.orderStatus === "PENDING")
              // Map to parse orderProducts and extract ids
              .flatMap((order) => order?.orderProducts || [])
              // Map to extract only id
              .map((product) => product?.id || null)
          : [];

      state.syncedPendingProducts = pendingProducts;
    },
    storeNotReadAnnouncements(state, payload) {
      state.notReadAnnouncements = payload;
    },
    storeIsOnline(state, payload) {
      if (state.isOnline !== payload) state.isOnline = payload;
    },
    storeUserGroups(state, payload) {
      state.userGroups = payload;
    },
    storeAppLoading(state, payload) {
      state.appLoading = payload;
    },
    storeUserSignedIn(state, payload) {
      state.userSignedIn = payload;
    },
    storeUserEmail(state, payload) {
      // console.log('storeUserEmail', payload)
      state.userEmail = payload;
    },
    storeSyncedTeacherProfile(state, payload) {
      // console.log('storeTeacherProfileModel', payload)
      state.syncedTeacherProfile = payload;
    },
    storeTeacherProfileModel(state, payload) {
      // console.log('storeTeacherProfileModel', payload)
      state.syncedTeacherProfileModel = payload;
    },
    storeTeacherProfileID(state, payload) {
      // console.log('storeTeacherProfileID', payload)
      state.teacherProfileID = payload;
    },
    storeTeacherProfilePayfastSubscriptionToken(state, payload) {
      // console.log('storeTeacherProfilePayfastSubscriptionToken', payload)
      state.teacherProfilePayfastSubscriptionToken = payload;
    },
    storeTeacherProfilePayfastSubscriptionResponse(state, payload) {
      // console.log('storeTeacherProfilePayfastSubscriptionResponse', payload)
      state.teacherProfilePayfastSubscriptionResponse = payload;
    },
    storeTeacherProfileAppSyncVersion(state, payload) {
      // console.log('storeTeacherProfileAppSyncVersion', payload)
      state.teacherProfileAppSyncVersion = payload;
    },
    storeTeacherProfileProvince(state, payload) {
      // console.log('storeTeacherProfileProvince', payload)
      state.teacherProfileProvince = payload;
    },
    storeTeacherProfileSchoolID(state, payload) {
      // console.log('storeTeacherProfileSchoolID', payload)
      state.teacherProfileSchoolID = payload;
    },
    // storeTeacherProfilePayfastSubscriptionPackage(state, payload) {
    //   // console.log('storeTeacherProfilePayfastSubscriptionPackage', payload)
    //   state.teacherProfilePayfastSubscriptionPackage = payload
    // },
    // storeTeacherProfileProductSubcriptions(state, payload) {
    //   // console.log('storeTeacherProfileProductSubcriptions', payload)
    //   state.teacherProfileProductSubcriptions = payload
    // }
  },
  actions: {
    async signOut() {
      await DataStore.stop().catch(() =>
        // error
        {
          // console.log("error:", error);
        }
      );
      await Auth.signOut().catch(() =>
        // error
        {
          // console.log("error:", error);
        }
      );
      await DataStore.clear().catch(() =>
        // error
        {
          // console.log("error:", error);
        }
      );

      this.commit("storeUserSignedIn", false);
      this.commit("storeUserGroups", null);
      this.commit("storeUserEmail", "");
      this.commit("storeTeacherProfileModel", null);
      this.commit("storeSyncedTeacherProfile", false);
      this.commit("storeSyncedTeacherProductsOrders", false);
      this.commit("storeTeacherProductsOrderModels", []);
      this.commit("storeTeacherProfileID", "");
      this.commit("storeTeacherProfileProvince", "");
      this.commit("storeTeacherProfileSchoolID", "");
      this.commit("storeTeacherProfilePayfastSubscriptionToken", "");
      this.commit("storeTeacherProfilePayfastSubscriptionResponse", "");
      this.commit("storeTeacherProfileAppSyncVersion", "");
      this.commit("storeNotReadAnnouncements", null);

      // if (router.currentRoute.path !== '/') {
      //   router.push({ path: '/' })
      // }

      window.location.href = "/";
    },
  },
  modules: {},
});
