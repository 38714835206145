<template>
  <div id="customers">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4"> Customers </v-card-title
        ><v-spacer></v-spacer>
        <v-btn
          class="mr-3"
          @click="createOrUpdateReportingTeacherEmails"
          :disabled="creatingReportingData || !initiallyLoaded"
          :loading="creatingReportingData"
          >Create Reporting Data</v-btn
        >
      </v-row>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="headers"
          :items="syncedCustomerArray"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          :search="search"
          :custom-filter="filterDataTable"
          :loading="syncedCustomerArray.length === 0"
          loading-text="Loading Customers..."
          no-data-text="No Customers found"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            {{
              getCognitoAttributeValue(item.cognitoAttributes, "phone_number")
            }}
          </template>
          <template v-slot:[`item.cognitoAttributes`]="{ item }">
            {{ getCognitoAttributeValue(item.cognitoAttributes, "email") }}
          </template>
          <template v-slot:[`item.payfastSubscriptionResponse`]="{ item }">
            {{
              item.payfastSubscriptionResponse
                ? getPayFastStatus(item.payfastSubscriptionResponse)
                : ""
            }}
          </template>
          <template v-slot:[`item.teacherProfileSchoolId`]="{ item }">
            {{
              item.teacherProfileSchoolId
                ? schoolName(item.teacherProfileSchoolId)
                : ""
            }}
          </template>
          <template v-slot:[`item.productSubscriptions`]="{ item }">
            {{
              item.productSubscriptions?.length > 0
                ? [...new Set(JSON.parse(item.productSubscriptions))].length
                : 0
            }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="openCustomerProductOrders(item)"
                  :disabled="offline"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-book-open
                </v-icon>
              </template>
              <span>View Orders</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="addCustomerProductOrders(item)"
                  :disabled="offline"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-book-plus-multiple-outline
                </v-icon>
              </template>
              <span>New Order</span>
            </v-tooltip>
            <!-- <v-icon small @click="convertProducts(item)" :disabled="offline">
              mdi-account-convert
            </v-icon>
            <v-icon
              small
              @click="removeDuplicateProducts(item)"
              :disabled="offline"
            >
              mdi-content-duplicate
            </v-icon> -->
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Auth, API, DataStore } from "aws-amplify";
import {
  School,
  Product,
  TeacherProfile,
  ProductCategory,
  ReportingTeacher,
} from "@/models";
import _ from "lodash";
import pako from "pako";
// import { Buffer } from "buffer";

export default {
  name: "AdminCustomers",
  data: () => ({
    creatingReportingData: false,
    initiallyLoaded: false,
    syncedCustomerArray: [],

    schoolSubscription: null,
    syncedSchoolArray: [],

    timePickerDateModel: null,
    valid: false,
    dialog: false,
    sortBy: "name",
    sortDesc: true,
    search: "",
    headers: [
      { text: "Title", value: "title" },
      { text: "Name", value: "name" },
      { text: "Surname", value: "surname" },
      { text: "Email", value: "cognitoAttributes" },
      { text: "User Group(s)", value: "cognitoUserGroups" },
      {
        text: "Subscription Status",
        value: "payfastSubscriptionResponse",
      },
      { text: "Province", value: "province" },
      { text: "Language", value: "language" },
      { text: "School", value: "teacherProfileSchoolId" },
      { text: "Products", value: "productSubscriptions" },
      // { text: "Actions", value: "actions", sortable: false },
    ],
    // customers: [],
    editedIndex: -1,
    editedItem: {
      label: "",
      date: null,
    },
    defaultItem: {
      label: "",
      date: null,
    },
    formRules: {
      label: [(v) => !!v || "Label is required"],
      date: [(v) => !!v || "Date is required"],
    },
  }),

  async mounted() {
    try {
      this.headers = [
        { text: "Title", value: "title" },
        { text: "Name", value: "name" },
        { text: "Surname", value: "surname" },
        {
          text: "Username",
          value: "owner",
        },
        {
          text: "Registration Date",
          value: "createdAt",
        },
        { text: "Phone", value: "phone", sortable: false },
        {
          text: "Email",
          value: "cognitoAttributes",
          sort: (a, b) => this.customSortEmail(a, b),
        },
        { text: "User Group(s)", value: "cognitoUserGroups" },
        {
          text: "Subscription Status",
          value: "payfastSubscriptionResponse",
          sort: (a, b) => this.customSortPayfastStatus(a, b),
        },
        { text: "Province", value: "province" },
        { text: "Language", value: "language" },
        { text: "School", value: "teacherProfileSchoolId" },
        {
          text: "Products",
          value: "productSubscriptions",
          sort: (a, b) => this.customSortProducts(a, b),
        },
        { text: "Actions", value: "actions", sortable: false },
      ];

      // Get the Customers
      await this.syncSchools();
      await this.getCustomers();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
    }),
    schoolName() {
      return (schoolId) => {
        const school = _.find(this.syncedSchoolArray, { id: schoolId });
        return school ? school.name : "";
      };
    },
  },

  watch: {},

  methods: {
    openCustomerProductOrders(item) {
      // console.log("openCustomerProductOrders", item);

      const customerOrdersPath = `/admin/productorders/customer/${
        item.id
      }/${this.getCognitoAttributeValue(item.cognitoAttributes, "sub")}::${
        item.owner
      }`;

      this.$router.push({ path: customerOrdersPath });
    },
    addCustomerProductOrders(item) {
      // console.log("addCustomerProductOrders", item);

      const customerOrdersPath = `/admin/productorders/customer/${
        item.id
      }/${this.getCognitoAttributeValue(item.cognitoAttributes, "sub")}::${
        item.owner
      }/new`;

      this.$router.push({ path: customerOrdersPath });
    },
    async removeDuplicateProducts(teacherProfile) {
      if (
        !Array.isArray(teacherProfile.products) ||
        teacherProfile.products.length === 0
      ) {
        return;
      }
      const modelToConvert = teacherProfile;
      const productIdArray = modelToConvert.products;
      console.log("OLDproductIdArray", productIdArray);

      let newProductArray = [...new Set(productIdArray)];

      if (
        newProductArray.length === 0 ||
        JSON.stringify(newProductArray) === JSON.stringify(productIdArray)
      ) {
        return;
      }

      console.log("newProductArray", newProductArray);

      const queriedTeacherProfile = await DataStore.query(
        TeacherProfile,
        teacherProfile.id
      );

      console.log("queriedTeacherProfile", queriedTeacherProfile);

      await DataStore.save(
        TeacherProfile.copyOf(queriedTeacherProfile, (updatedModel) => {
          updatedModel.products = newProductArray;
        })
      );
    },

    async addType(teacherProfile) {
      console.log("teacherProfile", teacherProfile);
      const queriedTeacherProfile = await DataStore.query(
        TeacherProfile,
        teacherProfile.id
      );

      console.log("queriedTeacherProfile", queriedTeacherProfile);

      await DataStore.save(
        TeacherProfile.copyOf(queriedTeacherProfile, (updatedModel) => {
          updatedModel.type = "TeacherProfile";
        })
      );
    },

    async convertProducts(teacherProfile) {
      if (
        !Array.isArray(teacherProfile.products) ||
        teacherProfile.products.length === 0
      ) {
        const queriedTeacherProfile = await DataStore.query(
          TeacherProfile,
          teacherProfile.id
        );

        await DataStore.save(
          TeacherProfile.copyOf(queriedTeacherProfile, (updatedModel) => {
            updatedModel.payfastSubscriptionPackage = {
              title: "Monthly",
              frequency: 3,
              amount: 200,
              limit: 50,
              grades: [],
              grandFathered: true,
            };
          })
        );

        return;
      }
      const modelToConvert = teacherProfile;
      const productIdArray = modelToConvert.products;
      const queryProducts = await DataStore.query(Product, (product) =>
        product.or((product) =>
          productIdArray.reduce((c, id) => c.id("eq", id), product)
        )
      );
      const queryProductCategories = await DataStore.query(ProductCategory);
      // console.log("queryProductCategories", queryProductCategories);

      let newProductArray = [];

      // Current date
      const currentDate = new Date();
      // console.log("currentDate.toISOString()", currentDate.toISOString());

      // Subtracting to get a date before this month
      const pastDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        currentDate.getDate()
      );

      // Generating the ISO string timestamp from the past date
      const timeStamp = pastDate.toISOString();

      for (const productId of productIdArray) {
        const foundProduct = _.find(queryProducts, { id: productId });

        if (!foundProduct) continue;

        const foundCategory = _.find(queryProductCategories, {
          id: foundProduct.categoryId,
        });
        // console.log("foundCategory", foundCategory);

        let objToPush = {
          productId: productId,
          timeStamp: timeStamp,
        };

        if (foundCategory?.grade > "") {
          objToPush.grade = foundCategory.grade;
        }

        newProductArray.push(objToPush);
      }

      if (newProductArray.length === 0) {
        return;
      }
      // console.log("newProductArray", newProductArray);

      const queriedTeacherProfile = await DataStore.query(
        TeacherProfile,
        teacherProfile.id
      );

      // console.log("queriedTeacherProfile", queriedTeacherProfile);

      await DataStore.save(
        TeacherProfile.copyOf(queriedTeacherProfile, (updatedModel) => {
          updatedModel.productSubscriptions = newProductArray;
          updatedModel.payfastSubscriptionPackage = {
            title: "Monthly",
            frequency: 3,
            amount: 200,
            limit: 50,
            grades: [],
            grandFathered: true,
          };
        })
      );
    },

    formatDate(date) {
      if (!date) return "";
      return new Date(date).toISOString().split("T")[0];
    },
    filterDataTable(value, search, item) {
      let searches = search.trim().toLowerCase().split(" ");

      let stringified = JSON.stringify(item).toLowerCase();
      let found = 0;
      for (let s in searches) {
        if (stringified.indexOf(searches[s]) > -1) found++;
      }
      // console.log(found);
      return found === searches.length;
    },
    customSortProducts(a, b) {
      const lengthA = a ? parseFloat(a.length) : 0; // Default to 0 if 'a' is null/undefined
      const lengthB = b ? parseFloat(b.length) : 0; // Default to 0 if 'b' is null/undefined

      if (this.sortDesc) {
        return lengthB - lengthA; // Descending sort
      }
      return lengthA - lengthB; // Ascending sort
    },

    customSortEmail(a, b) {
      const emailA = this.getCognitoAttributeValue(a, "email");
      const emailB = this.getCognitoAttributeValue(b, "email");

      if (!this.sortDesc) {
        return emailA.localeCompare(emailB);
      }

      return emailB.localeCompare(emailA);
    },

    customSortPayfastStatus(a, b) {
      const statusA = this.getPayFastStatus(a);
      const statusB = this.getPayFastStatus(b);

      if (!this.sortDesc) {
        return statusA.localeCompare(statusB);
      }

      return statusB.localeCompare(statusA);
    },

    getPayFastStatus(payFastResponse) {
      const responseObj = JSON.parse(payFastResponse);
      // const status_text = responseObj?.status_text
      //   ? responseObj.status_text
      //   : "";
      // const run_date = responseObj?.run_date ? responseObj.run_date : "";
      // return status_text + " UNTIL " + run_date.split("T")[0];
      return responseObj?.status_text ? responseObj.status_text : "";
    },

    getCognitoAttributeValue(attributesArray, attrName) {
      if (typeof attributesArray !== "object") return "";
      for (const attribute of attributesArray) {
        if (attribute.Name === attrName) {
          return attribute.Value;
        }
      }
      return "";
    },

    async syncSchools() {
      try {
        this.schoolSubscription = DataStore.observeQuery(School).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedSchoolArray !== items) {
              this.syncedSchoolArray = items;
            }
          },
          (error) => {
            console.log(error);
            this.schoolSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async getCustomers() {
      try {
        const user = await Auth.currentAuthenticatedUser().catch(() => {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
        });

        if (!user) return;

        await API.get("adminlambdaapi", "/item/queryCustomers", {
          queryStringParameters: {
            userPoolId: user.pool.userPoolId,
            userName: user.username,
          },
          responseType: "text", // Ensure the response is treated as binary data
        })
          .then(async (response) => {
            // console.log("response", response);

            // Step 1: Decode the Base64 response
            const decodedBase64 = atob(response); // Decodes the Base64 string to binary

            // Step 2: Convert the decoded string to a Uint8Array (required by pako)
            const binaryArray = new Uint8Array(
              decodedBase64.split("").map((char) => char.charCodeAt(0))
            );

            // Step 3: Inflate the gzip data using pako
            const inflatedData = pako.inflate(binaryArray, { to: "string" });

            // Step 4: Parse the inflated JSON data
            const jsonResponse = JSON.parse(inflatedData);
            // console.log("jsonResponse", jsonResponse);

            if (jsonResponse.teacherProfiles) {
              this.syncedCustomerArray = jsonResponse.teacherProfiles;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },

    async createOrUpdateReportingTeacherEmails() {
      this.creatingReportingData = true;
      for (const syncedCustomerArrayItem of this.syncedCustomerArray) {
        console.log("syncedCustomerArrayItem", syncedCustomerArrayItem);

        try {
          // Retrieve the email from cognitoAttributes
          const email = this.getCognitoAttributeValue(
            syncedCustomerArrayItem.cognitoAttributes,
            "email"
          );

          // Check if a ReportingTeacher record already exists for this teacherprofileID
          const existingTeacher = await DataStore.query(ReportingTeacher, (c) =>
            c.teacherprofileID("eq", syncedCustomerArrayItem.id)
          );

          if (existingTeacher.length > 0) {
            // If record exists and email is different, update it
            const teacherRecord = existingTeacher[0];
            if (teacherRecord.email !== email) {
              await DataStore.save(
                ReportingTeacher.copyOf(teacherRecord, (updated) => {
                  updated.email = email;
                })
              );
              console.log(
                `Updated ReportingTeacher for teacherprofileID: ${syncedCustomerArrayItem.id}`
              );
            } else {
              console.log(
                `No update needed for teacherprofileID: ${syncedCustomerArrayItem.id}`
              );
            }
          } else {
            // If record doesn't exist, create a new ReportingTeacher record
            await DataStore.save(
              new ReportingTeacher({
                teacherprofileID: syncedCustomerArrayItem.id,
                email: email,
              })
            );
            console.log(
              `Created new ReportingTeacher for teacherprofileID: ${syncedCustomerArrayItem.id}`
            );
          }
          this.creatingReportingData = false;
        } catch (error) {
          console.log(error);
          this.creatingReportingData = false;
        }
      }
    },

    //     async deCompressGzipRequest(data) {
    //     const buffer = [];
    //     const gunzip = zlib.createGunzip();

    //     // Event handler for handling data chunks during decompression
    //     gunzip.on('data', (chunk) => {
    //         buffer.push(chunk);
    //     });

    //     // Promise to handle the end of decompression
    //     const decompressPromise = new Promise((resolve, reject) => {
    //         // Event handler for handling the end of decompression
    //         gunzip.on('end', () => {
    //             try {
    //                 const decompressedBuffer = Buffer.concat(buffer);
    //                 const jsonData = JSON.parse(decompressedBuffer);
    //                 resolve(jsonData);
    //             } catch (error) {
    //                 reject(error);
    //             }
    //         });

    //         // Event handler for handling errors during decompression
    //         gunzip.on('error', (err) => {
    //             console.error('Error during decompression:', err);
    //             reject(err);
    //         });
    //     });

    //     // Initiate decompression with the base64-encoded data
    //     gunzip.end(Buffer.from(data, 'base64'));

    //     return decompressPromise;
    // },
  },

  beforeDestroy() {
    if (this.schoolSubscription) {
      this.schoolSubscription.unsubscribe();
    }
  },
};
</script>
