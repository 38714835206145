<template>
  <div id="appcommunicationsview">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col cols="12">
          <TeacherCommunications />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import TeacherCommunications from "@/components/app-components/teacher/TeacherCommunications.vue";
export default {
  name: "AppCommunicationsView",
  components: { TeacherCommunications },
  data: () => ({}),
};
</script>
