<template>
  <div id="communicationcard">
    <v-card class="mx-auto my-4" max-width="100%" outlined>
      <v-row dense>
        <v-col
          cols="12"
          v-if="show?.includes('SUBJECT') || show?.includes('BODY')"
        >
          <v-card-title class="text-h5 pb-0" v-if="show?.includes('SUBJECT')">{{
            subject
          }}</v-card-title>
          <v-card-text class="py-0" v-if="show?.includes('BODY')">
            <div class="pt-2 tiptap" v-html="body"></div>
          </v-card-text>
        </v-col>

        <v-col cols="12" v-if="show?.includes('IMAGES')">
          <v-carousel
            v-model="carouselIndex"
            cycle
            hide-delimiters
            show-arrows
            :interval="4000"
            height="min-content"
          >
            <v-carousel-item v-for="(imageKey, index) in images" :key="index">
              <v-sheet
                height="100%"
                tile
                class="d-flex align-center justify-center pa-4"
              >
                <v-img
                  :src="imageUrls[index]"
                  height="250"
                  contain
                  :alt="'Image ' + (index + 1)"
                  :loading="!imageUrls[index]"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0 align-center justify-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-col>

        <v-col cols="12" v-if="show?.includes('VIDEO')">
          <v-card-text>
            <VideoEmbed :src="videoSrc" />
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import VideoEmbed from "@/components/global-components/VideoEmbed.vue";
import { Storage } from "aws-amplify"; // Import AWS Amplify's Storage module

export default {
  name: "CommunicationCard",
  components: {
    VideoEmbed,
  },
  props: {
    show: {
      type: Array,
      required: false,
      default: () => [],
    },
    images: {
      type: Array,
      required: false,
    },
    videoSrc: {
      type: String,
      required: false,
      default: "",
    },
    subject: {
      type: String,
      required: false,
      default: "",
    },
    body: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      carouselIndex: 0, // To control the carousel's current slide
      imageUrls: [], // Array to store the resolved image URLs
    };
  },
  watch: {
    images() {
      if (this.show?.includes("IMAGES")) {
        this.fetchImageUrls();
      }
    },
  },
  methods: {
    async fetchImageUrls() {
      const imageUrlsPromises = this.images.map(async (imageKey) => {
        try {
          const result = await Storage.get(imageKey, { level: "public" });
          return result; // Return the resolved URL
        } catch (error) {
          console.error("Error fetching image from S3:", error);
          return ""; // Return an empty string if there's an error
        }
      });

      // Wait for all the promises to resolve
      this.imageUrls = await Promise.all(imageUrlsPromises);
    },
  },
  mounted() {
    // Fetch image URLs when the component is mounted
    if (this.show?.includes("IMAGES")) {
      this.fetchImageUrls();
    }
  },
};
</script>

<style scoped>
.vimeo-container {
  max-width: 100%;
  margin: 0 auto;
}

.vid-div {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  margin-bottom: 1.2rem;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Style for images */
.v-img {
  margin-bottom: 15px;
}

/* TipTap-specific table styling */
.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    max-width: 100% !important;
    width: auto !important;
    min-width: 200px !important;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}
</style>
