<template>
  <div id="adminproductorders">
    <div class="pa-2">
      <v-row justify="space-between">
        <v-col cols="12" md="12">
          <ProductOrders />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import ProductOrders from "@/components/admin-components/global/ProductOrders.vue";

export default {
  name: "AdminProductOrdersView",
  components: { ProductOrders },
  data: () => ({}),
};
</script>
