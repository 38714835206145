<template>
  <div id="teacherclasssubjects">
    <v-card class="mx-auto" max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4">
          {{ $i18n.t("headings.classes") }}
          <helper topic="classes" />
        </v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-4"
          @click="createItem"
          :disabled="offline"
        >
          {{ $i18n.t("otherText.new") + " " + $i18n.t("headings.class") }}
        </v-btn>
      </v-row>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="headers"
          :items="classSubjectsWithSubject"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          must-sort
          show-group-by
          @update:options="updateSortOptions"
          no-data-text="No Classes found"
          :loading="
            !teacherClassSubjectSubscriptionSynced &&
            classSubjectsWithSubject?.length === 0
          "
          loading-text="Loading Classes..."
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              :disabled="offline"
            >
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" :disabled="offline">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="save">
          <v-card-title>
            <span class="text-h5"
              >{{ formTitle }}
              <helper topic="classesedit" />
            </span>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="selectedSubject"
                  :items="filteredSubjectArray"
                  :item-text="gradeText"
                  item-value="grade"
                  return-object
                  :label="`${$i18n.t('headings.grade')}*`"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="editedItem.Subject"
                  :items="filteredSubjects"
                  item-text="subject"
                  item-value="id"
                  return-object
                  :label="`${$i18n.t('headings.subject')}*`"
                  no-data-text="No subjects available."
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedItem.name"
                  :label="`${
                    $i18n.t('headings.class') + ' ' + $i18n.t('labels.name')
                  }*`"
                  :rules="formRules.name"
                  required
                  :error-messages="
                    duplicateSubject ? ['Class Name already used'] : []
                  "
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close">
              {{ $i18n.t("actions.cancel") }}
            </v-btn>
            <v-btn
              :disabled="duplicateSubject || !validSubject || !valid"
              color="primary"
              @click="save"
            >
              {{ $i18n.t("actions.save") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center">{{
          $i18n.t("otherText.deleteSubject")
        }}</v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="closeDelete">{{
            $i18n.t("actions.cancel")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="deleteItemConfirm">{{
            $i18n.t("actions.yes")
          }}</v-btn>
          <!-- <v-spacer></v-spacer> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import { TeacherClassSubject, Subject } from "@/models";
import _ from "lodash";
import Helper from "@/components/global-components/Helper.vue";

export default {
  name: "TeacherClassSubject",
  components: { Helper },
  data: () => ({
    initiallyLoaded: false,
    teacherClassSubjectSubscription: null,
    teacherClassSubjectSubscriptionSynced: false,
    syncedTeacherClassSubjectArray: [],
    subjectSubscription: null,
    syncedSubjectArray: [],
    filteredSubjectArray: [],
    dialog: false,
    dialogDelete: false,
    sortBy: "Subject.grade",
    sortDesc: true,
    headers: [],
    classSubjects: [],
    editedIndex: -1,
    editedItem: {
      Subject: {
        id: "",
        grade: "",
        subject: "",
      },
      subjectID: "",
      name: "",
    },
    defaultItem: {
      Subject: {
        id: "",
        grade: "",
        subject: "",
      },
      subjectID: "",
      name: "",
    },
    valid: false,
    formRules: {
      name: [(v) => !!v || "Name is required"],
      subject: [
        (v) => !!v || "Title is required",
        // (duplicateSubject) => !!duplicateSubject || "Subject already exists",
      ],
    },
    selectedSubject: {},
  }),

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
      teacherProfileID: (state) => state.teacherProfileID,
    }),

    formTitle() {
      return this.editedIndex === -1
        ? this.$i18n.t("otherText.new") + " " + this.$i18n.t("headings.class")
        : this.$i18n.t("actions.edit") + " " + this.$i18n.t("headings.class");
    },

    filteredSubjects() {
      if (
        this.filteredSubjectArray &&
        this.selectedSubject &&
        this.filteredSubjectArray.length > 0
      ) {
        let subjects = this.filteredSubjectArray.filter((subject) => {
          return subject.grade == this.selectedSubject.grade;
        });

        return subjects;
      } else return [];
    },

    duplicateSubject() {
      // console.log(this.classSubjectsWithSubject);
      if (
        this.classSubjectsWithSubject &&
        this.classSubjectsWithSubject.length > 0 &&
        // this.editedIndex === -1 &&
        this.editedItem.Subject &&
        this.editedItem.Subject.id
      ) {
        let subjects = this.classSubjectsWithSubject.filter((cS) => {
          return (
            cS.name == this.editedItem.name &&
            cS.subjectID == this.editedItem.Subject.id &&
            cS.id !== this.editedItem.id
          );
        });
        return subjects.length > 0;
      } else return false;
    },

    classSubjectsWithSubject() {
      return this.classSubjects.map((classSubject) => {
        const foundSubject = this.filteredSubjectArray.find(
          (subject) => subject.id === classSubject.subjectID
        );
        return {
          ...classSubject,
          Subject: foundSubject,
        };
      });
    },

    validSubject() {
      return (
        this.editedItem.Subject &&
        this.editedItem.Subject.id !== "" &&
        this.editedItem.name !== ""
      );
    },
  },

  async mounted() {
    try {
      // this.functions wont work in data because it this.something refers to something else withing data and not for example a method.
      this.headers = [
        {
          text: this.$i18n.t("headings.grade"),
          value: "Subject.grade",
          sort: (a, b) => this.customSortGrade(a, b),
        },
        { text: this.$i18n.t("headings.subject"), value: "Subject.subject" },
        {
          text:
            this.$i18n.t("headings.class") + " " + this.$i18n.t("labels.name"),
          value: "name",
          groupable: false,
        },
        {
          text: this.$i18n.t("labels.actions"),
          value: "actions",
          sortable: false,
          groupable: false,
        },
      ];
      // Sync the Subject from the datastore
      await this.syncSubject();
      // Sync the TeacherClassSubject from the datastore
      await this.syncTeacherClassSubject();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    updateSortOptions(options) {
      if (options.sortBy === "Subject.grade") {
        // Manual sorting when it's by 'Subject.grade'
        this.classSubjects.sort((a, b) => {
          a =
            a.Subject.grade === "R" ? -Infinity : parseInt(a.Subject.grade, 10);
          b =
            b.Subject.grade === "R" ? -Infinity : parseInt(b.Subject.grade, 10);
          if (options.sortDesc) {
            // Switch the order for descending
            [a, b] = [b, a];
          }
          return a - b;
        });
      } else {
        // Default sorting for other columns
        this.sortBy = options.sortBy;
        this.sortDesc = options.sortDesc;
      }
    },

    customSortGrade(a, b) {
      a = a === "R" ? -Infinity : parseInt(a, 10);
      b = b === "R" ? -Infinity : parseInt(b, 10);
      if (this.sortDesc) {
        // Switch the order for descending
        [a, b] = [b, a];
      }
      return a - b;
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.selectedSubject = {};
      this.valid = false;
    },

    gradeText(item) {
      return `${this.$i18n.t("headings.grade")}: ${item.grade}`;
    },

    createItem() {
      this.resetForm();
      this.dialog = true;
    },

    editItem(item) {
      this.editedIndex = this.classSubjectsWithSubject.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.selectedSubject = item.Subject;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.classSubjectsWithSubject.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.classSubjects.splice(this.editedIndex, 1);
      await DataStore.delete(
        this.syncedTeacherClassSubjectArray[this.editedIndex]
      );
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update schedule
        await this.updateTeacherClassSubject();
      } else {
        // Create new schedule
        await this.createTeacherClassSubject();
      }
      this.close();
    },

    async syncTeacherClassSubject() {
      try {
        this.teacherClassSubjectSubscription = DataStore.observeQuery(
          TeacherClassSubject
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              this.syncedTeacherClassSubjectArray !== items
            ) {
              this.syncedTeacherClassSubjectArray = items;

              this.classSubjects = items.map((newObj) => {
                // const oldObj = oldVal.find(o => o.id === newObj.id) || {};

                const newObjCopy = _.cloneDeep(newObj);
                return newObjCopy;
              });

              this.teacherClassSubjectSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.teacherClassSubjectSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncSubject() {
      try {
        this.subjectSubscription = DataStore.observeQuery(Subject).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedSubjectArray !== items) {
              this.syncedSubjectArray = items;
              this.filteredSubjectArray = _.sortBy(items, (item) => {
                if (item.grade === "R") {
                  return -Infinity; // 'R' should appear first
                } else if (item.grade === "1") {
                  return 0; // '1' should appear second
                } else {
                  return parseInt(item.grade); // Sort the remaining numbers normally
                }
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async createTeacherClassSubject() {
      try {
        if (this.teacherProfileID !== "" && this.editedItem.Subject.id !== "") {
          await DataStore.save(
            new TeacherClassSubject({
              name: this.editedItem.name.trim(),
              subjectID: this.editedItem.Subject.id,
              teacherprofileID: this.teacherProfileID,
            })
          );
        }
        // else {
        //   alert("Please create your Profile before adding a class.");
        // }
      } catch (error) {
        console.log(error);
      }
    },

    async updateTeacherClassSubject() {
      try {
        await DataStore.save(
          TeacherClassSubject.copyOf(
            _.find(this.syncedTeacherClassSubjectArray, {
              id: this.editedItem.id,
            }),
            (updateModel) => {
              (updateModel.name = this.editedItem.name.trim()),
                (updateModel.subjectID = this.editedItem.Subject.id);
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.teacherClassSubjectSubscription) {
      this.teacherClassSubjectSubscription.unsubscribe();
    }
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }
  },
};
</script>
