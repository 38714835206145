<template>
  <div id="communications">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4"> Communications </v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-4"
          @click="createItem"
          :disabled="offline || !initiallyLoaded"
        >
          New Communication
        </v-btn>
      </v-row>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="headers"
          :items="syncedCommunicationArray"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          :search="search"
          :loading="
            (!communicationSubscriptionSynced &&
              syncedCommunicationArray?.length === 0) ||
            !initiallyLoaded
          "
          loading-text="Loading Communications..."
          no-data-text="No Communications found"
        >
          <!-- <template v-slot:[`item.date`]="{ item }">
            {{ item.date ? formatDate(item.date) : "" }}
          </template> -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              :disabled="offline || !initiallyLoaded"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
              :disabled="offline || !initiallyLoaded"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="800px">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="save">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.subject"
                    label="Subject"
                    :rules="formRules.subject"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6" sm="3">
                  <v-dialog v-model="datetimeDialog" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedCommunicationPublishDate"
                        readonly
                        label="Publish Date"
                        v-bind="{ ...attrs, ...$attrs }"
                        v-on="on"
                        clearable
                        @click:clear="
                          updateCommunicationPublishDateTime('clear')
                        "
                      ></v-text-field>
                    </template>
                    <v-card class="t-datetime-picker white">
                      <v-toolbar height="36" color="primary" dark flat>
                        <v-tabs v-model="dateTimeTab" grow height="36">
                          <v-tabs-slider color="white"></v-tabs-slider>
                          <v-tab href="#date">
                            <v-icon>mdi-calendar</v-icon>
                          </v-tab>
                          <v-tab href="#time">
                            <v-icon>mdi-clock</v-icon>
                          </v-tab>
                          <v-btn
                            depressed
                            text
                            class="rounded-0"
                            @click="closeDateTimeDialog"
                          >
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                        </v-tabs>
                      </v-toolbar>

                      <v-tabs-items v-model="dateTimeTab">
                        <v-tab-item value="date">
                          <v-date-picker
                            v-model="communicationPublishDate"
                            @change="updateCommunicationPublishDateTime"
                            class="rounded-0"
                            @input="dateTimeTab = 'time'"
                            full-width
                          >
                          </v-date-picker>
                        </v-tab-item>
                        <v-tab-item value="time">
                          <v-time-picker
                            :key="dateTimeTab"
                            v-model="communicationPublishTime"
                            format="24hr"
                            class="rounded-0"
                            full-width
                            @change="updateCommunicationPublishDateTime"
                          >
                          </v-time-picker>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="editedItem.sendTo"
                    :items="['ALL', ...communicationChannels]"
                    item-text="fullName"
                    item-value="id"
                    label="Send To"
                    multiple
                    @change="sendToChanged"
                    required
                    :rules="formRules.sendTo"
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.video"
                    label="Video"
                    hint='Eg. Vimeo: "1031522389", YouTube: "c_GorHGJ9Qg"'
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  v-if="
                    !focusedVideoInput &&
                    editedItem.video &&
                    editedItem.video !== ''
                  "
                >
                  <VideoEmbed :src="editedItem.video"></VideoEmbed>
                </v-col>

                <v-col cols="12">
                  <v-card class="px-3 pt-3">
                    <v-row
                      no-gutters
                      justify="space-between"
                      style="gap: 12px"
                      class="pb-3"
                    >
                      <span class="text-h6">Images</span>
                      <v-btn
                        color="primary"
                        @click="uploadCommunicationImage"
                        :disabled="
                          offline || editedIndex === -1 || !initiallyLoaded
                        "
                      >
                        {{
                          editedIndex === -1
                            ? "Save communication to add images"
                            : "Add Image"
                        }}
                      </v-btn>
                    </v-row>
                    <v-row
                      v-if="communicationImagesSrc?.length > 0"
                      no-gutters
                      class="thumbnailvrow"
                      style="margin-top: -12px"
                    >
                      <draggable
                        v-model="editedItem.images"
                        style="width: 100%"
                        class="d-flex flex-wrap"
                      >
                        <v-col
                          v-for="(imageKey, imageIndex) in editedItem.images"
                          :key="imageIndex"
                          cols="12"
                          sm="6"
                          md="4"
                          class="pa-3"
                        >
                          <div
                            @mouseenter="mouseOver(imageIndex)"
                            @mouseleave="mouseOut"
                            class="d-flex justify-center align-center"
                            style="
                              position: relative;
                              border: thin solid;
                              border-radius: 4px;
                              height: 100%;
                              width: 100%;
                            "
                            :style="
                              communicationImgHoveringIndex === imageIndex
                                ? 'border-color: #ffa9b8;'
                                : ''
                            "
                          >
                            <v-img
                              max-height="100%"
                              max-width="100%"
                              :src="getCommunicationImageSrc(imageKey)"
                              cover
                              style="border-radius: 4px"
                            ></v-img>

                            <div
                              v-if="
                                communicationImgHoveringIndex === imageIndex &&
                                editedIndex !== -1
                              "
                              style="position: absolute; top: 0; right: 0"
                            >
                              <v-menu
                                offset-x
                                v-model="isCommunicationImgMenuOpen"
                              >
                                <template
                                  v-slot:activator="{ on: menu, attrs }"
                                >
                                  <v-btn v-bind="attrs" icon v-on="{ ...menu }">
                                    <v-icon>mdi-cog</v-icon>
                                  </v-btn>
                                </template>
                                <div
                                  class="d-flex flex-column"
                                  style="background-color: white"
                                  @mouseleave="mouseOutMenu"
                                >
                                  <v-btn
                                    text
                                    color="red"
                                    @click="deleteCommunicationImage(imageKey)"
                                  >
                                    Remove
                                  </v-btn>
                                </div>
                              </v-menu>
                            </div>
                          </div>
                        </v-col>
                      </draggable>
                    </v-row>
                  </v-card>
                </v-col>
                <!-- <v-col cols="12">
                  <v-select
                    v-model="editedItem.readBy"
                    :items="itemsWithFullName"
                    item-text="fullName"
                    item-value="id"
                    label="Read By"
                    multiple
                    readonly
                    append-icon=""
                  ></v-select>
                </v-col> -->
                <!-- <v-col cols="12">
                  <v-textarea
                    v-model="editedItem.message"
                    label="Message"
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </v-col> -->
                <v-col cols="12">
                  <span class="text-h6">Body</span>
                  <RichTextEditor
                    v-model="editedItem.body"
                    :datamodelId="editedItem.id"
                    s3Folder="Communications"
                    s3SubFolder="Body"
                  >
                  </RichTextEditor>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="editedItem.show"
                    label="Show"
                    :items="communicationShowOptions"
                    multiple
                    required
                    :rules="formRules.show"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close"> Cancel </v-btn>
            <v-btn :disabled="!valid" color="primary" @click="save">
              Send
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete this communication?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDelete">Cancel</v-btn>
          <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore, Storage } from "aws-amplify";
import { Communication } from "@/models";
import _ from "lodash";
import RichTextEditor from "@/components/global-components/rich-text-editor/RichTextEditor.vue";
import VideoEmbed from "@/components/global-components/VideoEmbed.vue";
import draggable from "vuedraggable";

export default {
  name: "AdminCommunications",
  components: {
    VideoEmbed,
    draggable,
    RichTextEditor,
  },
  data: () => ({
    initiallyLoaded: false,

    communicationSubscription: null,
    communicationSubscriptionSynced: false,
    syncedCommunicationArray: [],

    valid: false,
    focusedVideoInput: false,
    dialog: false,
    dialogDelete: false,
    sortBy: "publishDate",
    sortDesc: true,
    search: "",
    headers: [
      { text: "Publish Date", value: "publishDate" },
      { text: "Subject", value: "subject" },
      { text: "Send To", value: "sendTo" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    datetimeDialog: false,
    dateTimeTab: "date",
    communicationPublishDate: "",
    communicationPublishTime: "",

    isCommunicationImgMenuOpen: false,
    communicationImgHoveringIndex: -1,
    communicationImagesSrc: [],

    editedIndex: -1,
    editedItem: {
      id: "",
      subject: "",
      body: "",
      images: [],
      video: "",
      sendTo: [],
      show: [],
      publishDate: null,
    },
    defaultItem: {
      id: "",
      subject: "",
      body: "",
      images: [],
      video: "",
      sendTo: [],
      show: [],
      publishDate: null,
    },

    communicationChannels: [
      "General",
      "R",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      // "8",
      // "9",
      // "10",
      // "11",
      // "12"
    ],

    formRules: {
      subject: [(v) => !!v || "Subject is required"],
      sendTo: [(v) => (!!v && v.length > 0) || "A Channel is Required"],
      show: [(v) => (!!v && v.length > 0) || "A Component to show is Required"],
    },
  }),

  async mounted() {
    try {
      // Sync the Communications from the datastore
      this.syncCommunications();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
      appLoading: (state) => !state.appLoading,
    }),

    formTitle() {
      return this.editedIndex === -1
        ? "New Communication"
        : "Edit Communication";
    },

    formattedCommunicationPublishDate() {
      return this.editedItem.publishDate
        ? new Date(this.editedItem.publishDate).toLocaleString()
        : "";
    },

    communicationShowOptions() {
      let showOptions = [];
      const editedItem = this.editedItem;

      if (editedItem.subject && editedItem.subject.trim() !== "") {
        showOptions.push("SUBJECT");
      }
      if (editedItem.body && editedItem.body.trim() !== "") {
        showOptions.push("BODY");
      }
      if (editedItem.video && editedItem.video.trim() !== "") {
        showOptions.push("VIDEO");
      }
      if (editedItem.images && editedItem.images.length > 0) {
        showOptions.push("IMAGES");
      }

      return showOptions;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // "$route.params": {
    //   handler(newVal, oldVal) {
    //     console.log("Route Params", newVal, oldVal);
    //   },
    //   immediate: true,
    // },
    editedItem: {
      handler(newVal, oldVal) {
        if (!newVal.show) return;

        if (
          newVal.show.indexOf("SUBJECT") !== -1 &&
          (!newVal.subject || newVal.subject.trim() === "")
        ) {
          newVal.show.splice(newVal.show.indexOf("SUBJECT"), 1);
        }

        if (
          newVal.show.indexOf("BODY") !== -1 &&
          (!newVal.body ||
            newVal.body.trim() === "" ||
            newVal.body.trim() === "<p></p>")
        ) {
          newVal.show.splice(newVal.show.indexOf("BODY"), 1);
        }
        if (
          newVal.show.indexOf("VIDEO") !== -1 &&
          (!newVal.video || newVal.video.trim() === "")
        ) {
          newVal.show.splice(newVal.show.indexOf("VIDEO"), 1);
        }
        if (
          newVal.show.indexOf("IMAGES") !== -1 &&
          (!newVal.images || newVal.images.length === 0)
        ) {
          newVal.show.splice(newVal.show.indexOf("IMAGES"), 1);
        }
      },
      deep: true,
    },
  },

  methods: {
    updateCommunicationPublishDateTime(action) {
      if (action === "clear") {
        this.communicationPublishDate = null;
        this.communicationPublishTime = null;
        this.editedItem.publishDate = null;
      } else if (
        this.communicationPublishDate &&
        this.communicationPublishTime
      ) {
        this.editedItem.publishDate = new Date(
          new Date(
            this.communicationPublishDate +
              "T" +
              this.communicationPublishTime +
              ":00Z"
          ).getTime() -
            2 * 60 * 60 * 1000
        ).toISOString();
      } else {
        this.editedItem.publishDate = null;
      }
    },

    closeDateTimeDialog() {
      this.datetimeDialog = false;
      this.dateTimeTab = "date";
    },

    sendToChanged() {
      if (this.editedItem.sendTo[this.editedItem.sendTo.length - 1] === "ALL") {
        this.editedItem.sendTo = ["ALL"];
      } else if (this.editedItem.sendTo.includes("ALL")) {
        this.editedItem.sendTo = this.editedItem.sendTo.filter(
          (channel) => channel !== "ALL"
        );
      }
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.valid = false;
    },

    async createItem() {
      this.resetForm();

      const newCommunication = await DataStore.save(
        new Communication({
          subject: "New Communication",
        })
      );

      this.editedItem = _.cloneDeep(newCommunication);

      this.editedItem.publishDate = new Date().toISOString();

      this.communicationPublishDate = this.editedItem.publishDate.substring(
        0,
        10
      );
      this.communicationPublishTime = new Date(
        new Date(this.editedItem.publishDate).getTime() + 2 * 60 * 60 * 1000
      )
        .toISOString()
        .substring(11, 16);

      this.dialog = true;
    },

    editItem(item) {
      this.resetForm();

      this.editedIndex = this.syncedCommunicationArray.indexOf(item);
      this.editedItem = _.cloneDeep(item);

      if (item.images) {
        item.images.forEach(async (imageKey) => {
          if (!_.find(this.communicationImagesSrc, { key: imageKey })) {
            const imgSrc = await this.getCommunicationImage(imageKey);
            this.communicationImagesSrc.push({
              key: imageKey,
              src: imgSrc,
            });
          }
        });
      }
      if (item.publishDate) {
        this.communicationPublishDate = item.publishDate.substring(0, 10);
        this.communicationPublishTime = new Date(
          new Date(item.publishDate).getTime() + 2 * 60 * 60 * 1000
        )
          .toISOString()
          .substring(11, 16);
      }

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.syncedCommunicationArray.indexOf(item);
      this.editedItem = _.cloneDeep(item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      // this.communications.splice(this.editedIndex, 1);
      await DataStore.delete(this.syncedCommunicationArray[this.editedIndex]);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = _.cloneDeep(this.defaultItem);
        this.editedIndex = -1;
        this.focusedVideoInput = false;
        this.communicationPublishDate = "";
        this.communicationPublishTime = "";
        this.communicationImagesSrc = _.cloneDeep([]);
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = _.cloneDeep(this.defaultItem);
        this.editedIndex = -1;
        this.focusedVideoInput = false;
        this.communicationPublishDate = "";
        this.communicationPublishTime = "";
        this.communicationImagesSrc = _.cloneDeep([]);
      });
    },

    async deleteCommunicationImage(imageKey) {
      // console.log("deleteProductThumbnail:", imageKey);
      await Storage.remove(imageKey, {
        level: "public",
      })
        .then(() => {
          const mutableImages = _.cloneDeep(this.editedItem.images);
          _.remove(mutableImages, (image) => image === imageKey);
          this.editedItem.images = mutableImages;

          _.remove(
            this.communicationImagesSrc,
            (image) => image.key === imageKey
          );

          DataStore.save(
            Communication.copyOf(
              _.find(this.syncedCommunicationArray, { id: this.editedItem.id }),
              (communication) => {
                communication.images = this.editedItem.images;
              }
            )
          );
        })
        .catch((err) => console.log(err));
    },

    mouseOver(imageIndex) {
      if (!this.isCommunicationImgMenuOpen) {
        this.communicationImgHoveringIndex = imageIndex;
      }
    },

    mouseOut() {
      if (!this.isCommunicationImgMenuOpen) {
        this.communicationImgHoveringIndex = -1;
      }
    },

    mouseOutMenu() {
      this.isCommunicationImgMenuOpen = false;
      this.communicationImgHoveringIndex = -1;
    },

    getCommunicationImageSrc(imageKey) {
      const imgSrc = _.find(this.communicationImagesSrc, {
        key: imageKey,
      });
      return imgSrc ? imgSrc.src : "";
    },

    async getCommunicationImage(imageKey) {
      const imgSrc = await Storage.get(imageKey, {
        level: "public",
      });
      return imgSrc;
    },

    async uploadCommunicationImage() {
      try {
        const inputElement = document.createElement("input");
        inputElement.type = "file";
        inputElement.accept = "image/*";
        // inputElement.multiple = true; // Allow multiple files to be selected

        inputElement.addEventListener("change", async (event) => {
          if (
            event.target instanceof HTMLInputElement &&
            event.target.files &&
            event.target.files.length > 0
          ) {
            this.loading = true;
            const file = event.target.files[0];
            const maxSize = 800; // Define your maximum size here

            // Create a function to resize the image
            const resizeImage = (image) => {
              return new Promise((resolve) => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                const img = new Image();
                img.onload = () => {
                  let width = img.width;
                  let height = img.height;

                  if (width > height) {
                    if (width > maxSize) {
                      height *= maxSize / width;
                      width = maxSize;
                    }
                  } else {
                    if (height > maxSize) {
                      width *= maxSize / height;
                      height = maxSize;
                    }
                  }

                  canvas.width = width;
                  canvas.height = height;

                  ctx.drawImage(img, 0, 0, width, height);

                  canvas.toBlob(
                    (blob) => {
                      resolve(blob);
                    },
                    "image/jpeg",
                    0.9
                  ); // You can change the format and quality here
                };

                img.src = URL.createObjectURL(image);
              });
            };

            const resizedBlob = await resizeImage(file);

            // Upload the resized file to S3
            const newCommunicationImgPut = await Storage.put(
              "Communications/" +
                this.editedItem.id +
                "/Images/" +
                Date.now() +
                "-" +
                file.name,
              resizedBlob
            ).catch((err) => console.log(err));

            if (!this.editedItem.images) {
              this.editedItem.images = [];
            }

            if (!this.editedItem.images.includes(newCommunicationImgPut.key)) {
              const mutableImages = _.cloneDeep(this.editedItem.images);

              mutableImages.push(newCommunicationImgPut.key);
              this.editedItem.images = mutableImages;

              // Fetch the image source for the newly added thumbnail
              const imgSrc = await this.getCommunicationImage(
                newCommunicationImgPut.key
              );

              // Update the thumbnailImagesSrc array with the new source
              this.communicationImagesSrc.push({
                key: newCommunicationImgPut.key,
                src: imgSrc,
              });

              await DataStore.save(
                Communication.copyOf(
                  _.find(this.syncedCommunicationArray, {
                    id: this.editedItem.id,
                  }),
                  (communication) => {
                    communication.images = this.editedItem.images;
                  }
                )
              )
                .catch((err) => {
                  console.log(err);
                })
                .then(() => {
                  // this.unEditedProduct.images = _.cloneDeep(
                  //   this.editedItem.images
                  // );
                });
            }

            this.loading = false;
          }
        });

        document.body.appendChild(inputElement);
        inputElement.click();
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async save() {
      if (this.editedIndex > -1) {
        // Update schedule
        await this.updateCommunication();
      } else {
        // Create new schedule
        await this.createCommunication();
      }
      this.close();
    },

    async createCommunication() {
      try {
        await DataStore.save(
          new Communication({
            subject: this.editedItem.subject.trim(),
            sendTo: this.editedItem.sendTo,
            show: this.editedItem.show,
            body: this.editedItem.body,
            publishDate: this.editedItem.publishDate,
            images: null,
            video: this.editedItem.video > "" ? this.editedItem.video : null,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updateCommunication() {
      try {
        await DataStore.save(
          Communication.copyOf(
            _.find(this.syncedCommunicationArray, { id: this.editedItem.id }),
            (updateModel) => {
              updateModel.subject = this.editedItem.subject.trim();
              updateModel.sendTo = this.editedItem.sendTo;
              updateModel.show = this.editedItem.show;
              updateModel.body = this.editedItem.body;
              updateModel.publishDate = this.editedItem.publishDate;
              updateModel.images =
                this.editedItem.images?.length > 0
                  ? this.editedItem.images
                  : null;
              updateModel.video =
                this.editedItem.video > "" ? this.editedItem.video : null;
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncCommunications() {
      try {
        this.communicationSubscription = DataStore.observeQuery(
          Communication
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedCommunicationArray !== items) {
              this.syncedCommunicationArray = items;

              if (this.editedItem.id > "") {
                this.editedIndex = this.syncedCommunicationArray.findIndex(
                  (communication) => communication.id === this.editedItem.id
                );
              }

              this.communicationSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.communicationSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.communicationSubscription) {
      this.communicationSubscription.unsubscribe();
    }
  },
};
</script>
