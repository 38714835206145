<template>
  <v-card id="contactformsupport" max-width="100%" outlined>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card-title class="text-h4">
        {{ $i18n.t("headings.support") }}
        <helper topic="contactsupport"
      /></v-card-title>

      <v-card-text>
        <v-text-field
          v-model="formfields.name"
          :label="`${$i18n.t('labels.name')}*`"
          :rules="nameRules"
          required
        ></v-text-field>

        <v-text-field
          v-model="formfields.email"
          :label="`${$i18n.t('labels.email')}*`"
          :rules="emailRules"
          required
        ></v-text-field>

        <v-select
          v-model="formfields.category"
          :label="`${$i18n.t('headings.category')}*`"
          :items="categoryItems"
          :rules="categoryRules"
          required
        ></v-select>

        <v-text-field
          v-model="formfields.subject"
          :label="`${$i18n.t('labels.subject')}*`"
          :rules="subjectRules"
          required
        ></v-text-field>

        <v-textarea
          v-model="formfields.message"
          :label="`${$i18n.t('labels.message')}*`"
          :rules="messageRules"
          required
        ></v-textarea>

        <v-row no-gutters class="text-caption">
          This site is protected by reCAPTCHA and the Google
          <a target="_blank" href="https://policies.google.com/privacy">
            &nbsp;Privacy Policy&nbsp;
          </a>
          and
          <a target="_blank" href="https://policies.google.com/terms">
            &nbsp;Terms of Service&nbsp;
          </a>
          apply.
        </v-row>
      </v-card-text>
      <!-- Submit button -->
      <v-card-actions class="px-4 pb-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid || submitted"
          type="submit"
          x-large
          color="primary"
          :loading="submitting"
        >
          {{
            submitted
              ? $i18n.t("otherText.thankYou")
              : $i18n.t("actions.submit")
          }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { API } from "aws-amplify";
import Helper from "@/components/global-components/Helper.vue";

export default Vue.extend({
  name: "ContactFormSupport", // Name of the component
  components: { Helper },
  data: () => ({
    // Data properties
    valid: true, // Form validation status
    submitting: false, // Is form currently submitting
    submitted: false, // Has form been submitted
    // Form fields
    formfields: {
      name: "",
      email: "",
      subject: "",
      category: "",
      message: "",
      recaptchaToken: "",
    },
    // Validation rules for the name field
    nameRules: [(v: string) => !!v || "Name is required"],
    // Validation rules for the email field
    emailRules: [
      (v: string) => !!v || "Email is required",
      (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    // Validation rules for the subject field
    subjectRules: [(v: string) => !!v || "Subject is required"],
    // Validation rules for the category field
    categoryRules: [(v: string) => !!v || "Category is required"],
    // Validation rules for the message field
    messageRules: [(v: string) => !!v || "Message is required"],

    categoryItems: ["General", "Technical", "Subscription"],
  }),
  methods: {
    // Method that handles form submission
    async onSubmit() {
      // Retrieve reCAPTCHA token
      const token = await this.$recaptcha("submit");

      // Assign reCAPTCHA token to formfields
      this.formfields.recaptchaToken = token;
      // Set submitting to true, indicating the form is being submitted
      this.submitting = true;

      const emailBody = {
        name: this.formfields.name,
        email: this.formfields.email,
        subject: this.formfields.subject,
        category: this.formfields.category,
        message: this.formfields.message,
      };

      // Send the form data to the API for processing
      await API.post("contactformapi", "/submit/support", {
        body: {
          emailBody,
          recaptchaToken: this.formfields.recaptchaToken,
          // toAddresses,
          // ccAddresses,
          // templateName,
          // sourceAddress,
        },
      })
        .then((response) => {
          // Handle successful response from the API
          this.submitting = false; // Set submitting to false, indicating the form has been submitted
          this.submitted = response.success; // Update submitted status based on API response
        })
        .catch((error) => {
          // Log any errors
          console.error(error);
        });
    },
  },
});
</script>
