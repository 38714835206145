<template>
  <div id="terms">
    <v-card max-width="100%" outlined>
      <v-row no-gutters class="align-center">
        <v-card-title class="text-h4"> Terms </v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          class="mx-4"
          @click="createItem"
          :disabled="offline"
        >
          New Term
        </v-btn>
      </v-row>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <div class="px-4">
        <v-data-table
          :footer-props="{
            showFirstLastPage: true,
          }"
          :headers="termsHeaders"
          :items="sortedTerms"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          must-sort
          :single-expand="true"
          :expanded.sync="expanded"
          show-expand
          :search="search"
          :loading="!termSubscriptionSynced && sortedTerms?.length === 0"
          loading-text="Loading Terms..."
          no-data-text="No Terms found"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                  :disabled="offline"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit Term</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="createBiweek(item)"
                  :disabled="offline"
                >
                  mdi-calendar-plus-outline
                </v-icon>
              </template>
              <span>Add Biweek</span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on" @click="deleteItem(item)" :disabled="offline">
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete Term</span>
            </v-tooltip> -->
          </template>
          <template v-slot:[`expanded-item`]="{ headers, item }">
            <td :colspan="headers.length" class="pa-3">
              <v-data-table
                :footer-props="{
                  showFirstLastPage: true,
                }"
                :headers="biweeksHeaders"
                :items="item.biweeks"
                item-key="tmpid"
                v-if="item.biweeks"
                hide-default-footer
                no-data-text="No Biweeks found"
              >
                <template v-slot:[`item.color`]="{ item }">
                  <v-icon :color="item.color"> mdi-circle </v-icon>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="editBiweek(item)"
                        :disabled="offline"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit Biweek</span>
                  </v-tooltip>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on" @click="deleteItem(item)" :disabled="offline">
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Delete Biweek</span>
                  </v-tooltip> -->
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="600px">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="saveTerm">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="editedItem.label"
                    label="Label"
                    :rules="formRules.label"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-dialog
                    ref="dialogStartDate"
                    v-model="timePickerStartDateModel"
                    :return-value.sync="editedItem.start"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.start"
                        label="Start Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="formRules.start"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-if="timePickerStartDateModel"
                      v-model="editedItem.start"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="timePickerStartDateModel = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogStartDate.save(editedItem.start)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-dialog
                    ref="dialogEndDate"
                    v-model="timePickerEndDateModel"
                    :return-value.sync="editedItem.end"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.end"
                        label="End Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="formRules.end"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-if="timePickerEndDateModel"
                      v-model="editedItem.end"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="timePickerEndDateModel = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogEndDate.save(editedItem.end)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="editedItem.provinces"
                    :items="provinces"
                    label="Select Provinces"
                    multiple
                    chips
                    hint="What are the target provinces for this term?"
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="closeDialog"> Cancel </v-btn>
            <v-btn :disabled="!valid" color="primary" @click="saveTerm">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogBiweek" max-width="600px">
      <v-card>
        <v-form
          ref="formBiweek"
          v-model="validBiweek"
          @submit.prevent="saveBiweek"
        >
          <v-card-title>
            <span class="text-h5">{{ formTitleBiweek }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="editedBiweek.label"
                    label="Label"
                    :rules="formRules.label"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-dialog
                    ref="biweekDialogStartDate"
                    v-model="biweeksTimePickerStartDateModel"
                    :return-value.sync="editedBiweek.start"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedBiweek.start"
                        label="Start Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="formRules.start"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-if="biweeksTimePickerStartDateModel"
                      v-model="editedBiweek.start"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="biweeksTimePickerStartDateModel = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.biweekDialogStartDate.save(editedBiweek.start)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-dialog
                    ref="biweekDialogEndDate"
                    v-model="biweeksTimePickerEndDateModel"
                    :return-value.sync="editedBiweek.end"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedBiweek.end"
                        label="End Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="formRules.end"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-if="biweeksTimePickerEndDateModel"
                      v-model="editedBiweek.end"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="biweeksTimePickerEndDateModel = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.biweekDialogEndDate.save(editedBiweek.end)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="closeDialogBiweek"> Cancel </v-btn>
            <v-btn :disabled="!validBiweek" color="primary" @click="saveBiweek">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete
          {{ editedItem.label }} ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDialogDelete">Cancel</v-btn>
          <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogDeleteBiweek" max-width="500px">
      <v-card>
        <v-card-title class="justify-center"
          >Are you sure you want to delete
          {{ editedBiweek.label }} ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDialogDelete">Cancel</v-btn>
          <v-btn color="primary" @click="deleteBiweekConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import { Term } from "@/models";
import _ from "lodash";

export default {
  name: "AdminTerms",
  data: () => ({
    initiallyLoaded: false,
    termSubscription: null,
    termSubscriptionSynced: false,
    syncedTermArray: [],
    timePickerStartDateModel: null,
    timePickerEndDateModel: null,
    biweeksTimePickerStartDateModel: null,
    biweeksTimePickerEndDateModel: null,
    itemToDelete: {},
    valid: false,
    dialog: false,
    dialogBiweek: false,
    dialogDelete: false,
    dialogDeleteBiweek: false,
    sortBy: "start",
    sortDesc: false,
    search: "",
    termsHeaders: [
      { text: "Label", value: "label", sortable: false },
      { text: "Start", value: "start" },
      { text: "End", value: "end" },
      { text: "Provinces", value: "provinces" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    biweeksHeaders: [
      { text: "Label", value: "label", sortable: false },
      { text: "Start", value: "start" },
      { text: "End", value: "end" },
      { text: "Color", value: "color" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    terms: [],
    editedIndex: -1,
    editedItem: {
      label: "",
      start: null,
      end: null,
      provinces: [],
      biweeks: [],
    },
    defaultItem: {
      label: "",
      start: null,
      end: null,
      provinces: [],
      biweeks: [],
    },
    formRules: {
      label: [(v) => !!v || "Label is required"],
      start: [(v) => !!v || "Start Date is required"],
      end: [(v) => !!v || "End Date is required"],
      color: [(v) => !!v || "Color is required"],
    },
    expanded: [],
    validBiweek: false,
    editedBiweek: {
      label: "",
      start: null,
      end: null,
      color: "",
    },
    defaultBiweek: {
      label: "",
      start: null,
      end: null,
      color: "",
    },
    colors: [
      "red  darken-1",
      "cyan  darken-1",
      "pink  darken-1",
      "deep-purple  darken-1",
      "orange  darken-1",
      "indigo  darken-1",
      "deep-orange  darken-1",
      "blue  darken-1",
      "brown  darken-1",
      "light-blue  darken-1",
      "teal  darken-1",
      "blue-grey  darken-1",
      "green  darken-1",
      "purple  darken-1",
      "light-green  darken-1",
    ],
    provinces: [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "Northern Cape",
      "North West",
      "Western Cape",
    ],
  }),

  async mounted() {
    try {
      // Sync the Terms from the datastore
      await this.syncTerms();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Term" : "Edit Term";
    },
    formTitleBiweek() {
      return this.editedIndex === -1 ? "New Biweek" : "Edit Biweek";
    },
    sortedTerms() {
      return _.orderBy(this.terms, ["label", "start"], ["asc", "asc"]);
    },
    currentTerm() {
      return _.find(this.syncedTermArray, { id: this.expanded[0].id });
    },
  },

  watch: {
    dialog(val) {
      val || this.closeDialog();
    },
    dialogDelete(val) {
      val || this.closeDialogDelete();
    },
  },

  methods: {
    resetFormBiweek() {
      if (this.$refs.formBiweek) this.$refs.formBiweek.reset();
      this.editedBiweek = Object.assign({}, this.defaultBiweek);
      this.validBiweek = false;
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.valid = false;
    },

    createBiweek(item) {
      this.editedItem = Object.assign({}, item);
      this.resetFormBiweek();
      this.dialogBiweek = true;
    },

    createItem() {
      this.resetForm();
      this.dialog = true;
    },

    editBiweek(biweek) {
      this.editedIndex = _.findIndex(this.currentTerm.biweeks, function (o) {
        return (
          o.label === biweek.label &&
          o.start === biweek.start &&
          o.end === biweek.end &&
          o.color === biweek.color
        );
      });
      this.editedItem = this.currentTerm;
      this.editedBiweek = Object.assign({}, biweek);
      this.dialogBiweek = true;
    },

    editItem(item) {
      this.editedIndex = this.terms.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      //--------------
      if (item.biweeks) {
        this.editedIndex = this.terms.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.editedIndex = _.findIndex(this.currentTerm.biweeks, function (o) {
          return (
            o.label === item.label &&
            o.start === item.start &&
            o.end === item.end &&
            o.color === item.color
          );
        });
        this.editedBiweek = Object.assign({}, item);
        this.dialogDeleteBiweek = true;
      }
    },

    async deleteBiweekConfirm() {
      try {
        let mutableObj = _.cloneDeep(this.currentTerm);

        mutableObj.biweeks = mutableObj.biweeks.filter(
          (biweek, index) => index !== this.editedIndex
        );

        this.editedItem = Object.assign({}, mutableObj);
        await this.updateTerm();
        this.closeDialogDelete();
      } catch (error) {
        console.log(error);
      }
    },

    async deleteItemConfirm() {
      await DataStore.delete(this.syncedTermArray[this.editedIndex]);
      this.terms.splice(this.editedIndex, 1);
      this.closeDialogDelete();
    },

    closeDialogBiweek() {
      this.dialogBiweek = false;
      this.$nextTick(() => {
        this.editedBiweek = Object.assign({}, this.defaultBiweek);
        this.editedIndex = -1;
      });
    },

    closeDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDialogDelete() {
      this.dialogDelete = false;
      this.dialogDeleteBiweek = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedBiweek = Object.assign({}, this.defaultBiweek);
        this.editedIndex = -1;
      });
    },

    async saveBiweek() {
      if (this.editedIndex > -1) {
        // Update biweek
        let mutableObj = _.cloneDeep(this.editedItem);
        mutableObj.biweeks[this.editedIndex] = this.editedBiweek;
        this.editedItem = Object.assign({}, mutableObj);
      } else {
        // Get a list of all used colors.
        const usedColors = this.editedItem.biweeks.map(
          (biweek) => biweek.color
        );

        // Find the first color in the ordered list that hasn't been used.
        const unusedColor = this.colors.find(
          (color) => !usedColors.includes(color)
        );

        if (!unusedColor) {
          return;
        }

        // Create new biweek
        this.editedBiweek.color = unusedColor;
        let mutableObj = _.cloneDeep(this.editedItem);
        mutableObj.biweeks =
          mutableObj.biweeks && mutableObj.biweeks.length > 0
            ? mutableObj.biweeks
            : [];
        mutableObj.biweeks.push(this.editedBiweek);
        this.editedItem = Object.assign({}, mutableObj);
      }
      await this.updateTerm();
      this.closeDialogBiweek();
    },

    async saveTerm() {
      if (this.editedIndex > -1) {
        // Update Term
        await this.updateTerm();
      } else {
        // Create new Term
        await this.createTerm();
      }
      this.closeDialog();
    },

    async createTerm() {
      try {
        this.editedItem.start = this.convertToAwsTimeFormat(
          this.editedItem.start
        );
        this.editedItem.end = this.convertToAwsTimeFormat(this.editedItem.end);
        if (this.editedItem.biweeks) {
          this.editedItem.biweeks.forEach((biweek) => {
            biweek.label = biweek.label.trim();
            biweek.start = this.convertToAwsTimeFormat(biweek.start);
            biweek.end = this.convertToAwsTimeFormat(biweek.end);
          });
        }
        await DataStore.save(
          new Term({
            label: this.editedItem.label.trim(),
            start: this.editedItem.start,
            end: this.editedItem.end,
            provinces: this.editedItem.provinces,
            biweeks: this.editedItem.biweeks,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },

    async updateTerm() {
      try {
        this.editedItem.start = this.convertToAwsTimeFormat(
          this.editedItem.start
        );
        this.editedItem.end = this.convertToAwsTimeFormat(this.editedItem.end);
        if (this.editedItem.biweeks) {
          this.editedItem.biweeks.forEach((biweek) => {
            biweek.label = biweek.label.trim();
            biweek.start = this.convertToAwsTimeFormat(biweek.start);
            biweek.end = this.convertToAwsTimeFormat(biweek.end);
          });
        }
        await DataStore.save(
          Term.copyOf(
            _.find(this.syncedTermArray, { id: this.editedItem.id }),
            (updateModel) => {
              (updateModel.label = this.editedItem.label.trim()),
                (updateModel.start = this.editedItem.start),
                (updateModel.end = this.editedItem.end),
                (updateModel.provinces = this.editedItem.provinces),
                (updateModel.biweeks = this.editedItem.biweeks);
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    formatDate(dateString) {
      if (!dateString) return ""; // Return an empty string when date is null
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month =
        date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1; // month is 0-indexed
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return `${year}-${month}-${day}`;
    },

    convertToAwsTimeFormat(dateString) {
      if (!dateString) return null; // Return null when date is null
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month =
        date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1; // month is 0-indexed
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return `${year}-${month}-${day}`;
    },

    async syncTerms() {
      try {
        this.termSubscription = DataStore.observeQuery(Term).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedTermArray !== items) {
              this.syncedTermArray = items;

              this.terms = items.map((newObj) => {
                const newObjCopy = _.cloneDeep(newObj);
                newObjCopy.start = this.formatDate(newObj.start);
                newObjCopy.end = this.formatDate(newObj.end);
                newObjCopy.biweeks.forEach((biweek) => {
                  biweek.start = this.formatDate(biweek.start);
                  biweek.end = this.formatDate(biweek.end);
                });
                return newObjCopy;
              });

              this.termSubscriptionSynced = true;
            }
          },
          (error) => {
            console.log(error);
            this.termSubscriptionSynced = true;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.termSubscription) {
      this.termSubscription.unsubscribe();
    }
  },
};
</script>
