<template>
  <div id="websitelanding">
    <section id="hero">
      <div
        align-items="center"
        class="white--text mx-auto d-flex flex-wrap justify-center pt-10"
      >
        <div class="d-flex flex-row align-center">
          <div class="d-flex flex-column align-end">
            <v-img
              :max-width="$vuetify.breakpoint.smAndDown ? '70vw' : '50vw'"
              min-height="125"
              src="@/assets/logo-v2-long_no-space.svg"
              contain
            ></v-img>
            <!-- <div :class="$vuetify.breakpoint.smAndDown ? 'pt-1' : 'pt-2'"></div> -->
            <span
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'font-size: 3.8vw'
                  : 'font-size: 2.8vw'
              "
              class="font-weight-light black--text"
              >THE BEST EDUCATIONAL RESOURCES IN SOUTH AFRICA.</span
            >
          </div>
          <v-img
            :height="$vuetify.breakpoint.smAndDown ? '150' : '100%'"
            :class="$vuetify.breakpoint.smAndDown ? 'mb-0' : 'mb-7'"
            :width="$vuetify.breakpoint.smAndDown ? '93' : 'auto'"
            src="@/assets/juffrou-anri-animate.gif"
            contain
          ></v-img>
        </div>

        <v-col class="white--text text-center" cols="12" tag="h1"> </v-col>
      </div>
    </section>

    <section id="featured-resources">
      <!-- <v-row no-gutters> -->
      <!-- <v-col cols="12"> -->
      <v-container class="text-center">
        <ProductsGrid :search="true" :featured="true" :productLimit="4" />
      </v-container>
      <!-- </v-col> -->
      <!-- <v-col cols="12" md="1" class="pb-0">
          <v-img
            align-start
            max-height="270"
            src="@/assets/juffrou-anri-animate.gif"
            contain
          ></v-img>
        </v-col> -->
      <!-- </v-row> -->
    </section>

    <section id="features-and-video">
      <div class="d-flex" style="flex-flow: wrap-reverse">
        <v-col :cols="screenWidth > 1500 ? '12' : '12'" style="padding: 0 8px">
          <v-container class="text-center">
            <!-- <h2 class="display-2 font-weight-bold mb-3">FEATURES</h2>

            <v-responsive class="mx-auto mb-12" width="56">
              <v-divider></v-divider>

              <v-divider></v-divider>
            </v-responsive> -->

            <v-row>
              <v-col
                v-for="({ icon, title, text }, i) in features"
                :key="i"
                cols="12"
                md="4"
              >
                <v-card
                  class="py-12 px-4 elevation-12"
                  color="grey lighten-5"
                  flat
                  height="100%"
                >
                  <v-theme-provider dark>
                    <div class="mb-6">
                      <v-avatar color="primary" size="88">
                        <v-icon large>{{ icon }}</v-icon>
                      </v-avatar>
                    </div>
                  </v-theme-provider>

                  <v-card-title
                    class="justify-center text-uppercase"
                    v-html="title"
                    style="font-size: 28px"
                  >
                  </v-card-title>
                  <div>
                    {{ text }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <!-- <v-col :cols="screenWidth > 1500 ? '6' : '12'" style="padding: 0px">
          <v-container class="pa-0 text-center">
            <v-row no-gutters>
              <v-col>
                <div
                  id="video-embed"
                  :class="
                    screenWidth > 1750
                      ? 'iframe-padding-huge'
                      : screenWidth > 1618
                      ? 'iframe-padding-small'
                      : screenWidth < 700
                      ? 'iframe-padding-mobile'
                      : screenWidth < 1619
                      ? 'iframe-padding-small'
                      : 'iframe-padding-large'
                  "
                >
                  <div>
                    <iframe
                      width="1280"
                      height="720"
                      src="//www.youtube.com/embed/c_GorHGJ9Qg?rel=0&loop=1&autoplay=1"
                      frameborder="0"
                    ></iframe>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col> -->
      </div>
      <div class="py-8"></div>
    </section>

    <!-- <section id="hero">
      <v-row no-gutters>
        <v-img :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
          :max-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
          src="https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=99">
          <v-theme-provider dark>
            <v-container fill-height fluid ma-0 pa-0>
              <v-row align-items="center" class="white--text mx-auto" justify="center">
                <v-col class="white--text text-center" cols="12" tag="h1">
                  <span :class="[
                    $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2',
                  ]" class="font-weight-light">
                    WELCOME TO
                  </span>

                  <br />

                  <span :class="[
                    $vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4',
                  ]" class="font-weight-bold primary--text">MISS ANRI'S<br />CLASSNETWORK</span>
                </v-col>

                <v-btn class="align-self-end" fab outlined @click="$vuetify.goTo('#about-me')">
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section> -->

    <section id="subscribe-section" style="background-color: #ffa9b8">
      <div class="py-8"></div>
      <v-container class="text-center">
        <v-btn
          id="subscribe-btn"
          width="300"
          height="70"
          color="white"
          class="text-h4"
          to="/app/profile"
          >Subscribe Now!</v-btn
        >
      </v-container>
      <div class="py-8"></div>
    </section>

    <section id="about-me">
      <div class="py-8"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">
          Experience the Future of Education
        </h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive class="mx-auto font-weight-light mb-8" max-width="720">
          Welcome to Juffrou Anri, where innovation meets education. We are a
          passionate team of educators dedicated to revolutionizing teaching and
          learning. Our mission is to empower educators across South Africa with
          the tools and resources they need to create engaging, effective, and
          personalized learning experiences.
        </v-responsive>

        <v-avatar class="pa-3 elevation-12 mb-12" size="158">
          <v-img contain :src="require('@/assets/logo-v2-square.svg')"></v-img>
        </v-avatar>
        <div></div>

        <v-btn color="grey" to="/about" outlined large>
          <span class="grey--text text--darken-1 font-weight-bold">
            Read more
          </span>
        </v-btn>

        <v-row class="mt-12">
          <v-col
            v-for="({ icon, title, text }, i) in planning"
            :key="i"
            cols="12"
            md="4"
          >
            <v-card
              class="py-12 px-4 elevation-12"
              color="grey lighten-5"
              flat
              height="100%"
            >
              <v-theme-provider dark>
                <div class="mb-6">
                  <v-avatar color="primary" size="88">
                    <v-icon large>{{ icon }}</v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>

              <v-card-title
                class="justify-center text-uppercase"
                v-html="title"
                style="font-size: 28px"
              >
              </v-card-title>
              <div>
                {{ text }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>

    <!-- <section id="featured-resources">
      <div class="py-8"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">Featured Resources</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <ProductsGrid :search="false" :featured="true" />
      </v-container>

      <div class="py-12"></div>
    </section> -->

    <section id="stats">
      <div
        style="
          background-attachment: fixed;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          color: #ffffff;
        "
        class="parallax"
      >
        <v-container fill-height style="padding: 120px 0">
          <v-row class="mx-auto">
            <v-col
              v-for="[value, title] of stats"
              :key="title"
              cols="12"
              md="4"
            >
              <div class="text-center">
                <div
                  class="display-3 font-weight-bold mb-4"
                  v-text="value"
                ></div>

                <div
                  class="title font-weight-regular text-uppercase"
                  v-text="title"
                ></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </section>

    <!-- <section id="blog">
      <div class="py-12"></div>

      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">
          Blog
        </h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ src, text, title }, i) in articles"
            :key="i"
            cols="12"
            md="4"
          >
            <v-img
              :src="src"
              class="mb-4"
              height="275"
              max-width="100%"
            ></v-img>

            <h3
              class="font-weight-black mb-4 text-uppercase"
              v-text="title"
            ></h3>

            <div class="title font-weight-light mb-5" v-text="text"></div>

            <v-btn class="ml-n4 font-weight-black" text>
              Continue Reading
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section> -->

    <ContactForm />
  </div>
</template>

<script>
import ContactForm from "@/components/global-components/ContactForm.vue";
import ProductsGrid from "@/components/global-components/ProductsGrid.vue";

export default {
  name: "WebsiteLandingView",

  components: { ContactForm, ProductsGrid },

  data() {
    return {
      articles: [
        {
          src: "https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
          title: "Mobile first & Responsive",
          text: "Phasellus lorem enim, luctus ut velit eget, convallis egestas eros. Sed ornare ligula eget tortor tempor, quis porta tellus dictum.",
        },
        {
          src: "https://images.unsplash.com/photo-1475938476802-32a7e851dad1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
          title: "Think outside the box",
          text: "Nam ut leo ipsum. Maecenas pretium aliquam feugiat. Aenean vel tempor est, vitae tincidunt risus. Sed sodales vestibulum nibh.",
        },
        {
          src: "https://images.unsplash.com/photo-1416339442236-8ceb164046f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1892&q=80",
          title: "Small changes, big difference",
          text: "Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.",
        },
      ],
      features: [
        {
          icon: "mdi-forum-outline",
          title: "BUY INDIVIDUAL<br>PRODUCTS OR<br>JOIN",
        },
        // {
        //   icon: "mdi-forum-outline",
        //   title: "AFRIKAANS<br>ENGLISH",
        // },
        {
          icon: "mdi-file-pdf-box",
          title: "WORKSHEETS<br>ASSESSMENTS<br>PLANNING<br>DECOR",
        },
        {
          icon: "mdi-folder-outline",
          title: "CAPS<br>ATP",
        },
      ],
      planning: [
        {
          icon: "mdi-calendar-month-outline",
          title: "Monthly and bi-weekly planning",
          text: "Plan your teaching schedule with our user-friendly Calendar feature. Seamlessly navigate bi-weekly schedules, ensuring optimal time allocation for lesson planning, assessments, and student engagement.",
        },
        {
          icon: "mdi-tools",
          title: "Planning tool",
          text: "Streamline the assessment and lesson planning process with our planning tool. Set question weightings and define cognitive levels. Seamlessly align your lesson plans and assessments with the South African curriculum, ensuring you cover all the necessary topics and learning outcomes.",
        },
        {
          icon: "mdi-face-agent",
          title: "Teaching support",
          text: "Experience support with our Teacher Support feature. Connect with experienced educators who are ready to answer your questions, provide guidance, and share best practices for effective teaching.",
        },
      ],
      stats: [
        ["10K+", "Customers"],
        ["23900+", "Resources sold"],
        ["25K+", "Social Media followers"],
      ],
    };
  },

  computed: {
    // Computed property for the screen width
    screenWidth() {
      let width = this.$vuetify.breakpoint.width;
      return width;
    },
  },
};
</script>

<style>
.parallax {
  background-image: url("@/assets/app-assets/teacher/teacher-stats-img.jpg");
}

#subscribe-btn {
  border: 1px solid #000000 !important;
}

#stats .v-parallax__image-container > img {
  transform: none !important;
  width: 100% !important;
  left: 0 !important;
}

#welcome-heading {
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black; */
  text-shadow: -1px -1px 0 black, -1px 1px 0 black, 1px -1px 0 black,
    1px 1px 0 black;
}

#features-and-video #video-embed {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#features-and-video #video-embed div {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}

#features-and-video #video-embed iframe {
  box-sizing: border-box;
  background: url(@/assets/app-assets/global/computer.jpg) center center
    no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iframe-padding-huge iframe {
  padding: 3% 12.1% 24% 12.1%;
}

.iframe-padding-large iframe {
  padding: 3% 12.4% 24.2% 12.4%;
}

.iframe-padding-small iframe {
  padding: 3.1% 11.9% 24% 11.9%;
}

.iframe-padding-mobile iframe {
  padding: 3.2% 10.4% 25% 10.4%;
}
</style>
