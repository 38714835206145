<template>
  <div id="teachercalendar">
    <v-card class="mx-auto" max-width="100%" outlined>
      <v-card-title class="text-h4">
        {{ $i18n.t("headings.calendar") }}
        <helper topic="calendar" />
        <v-spacer />

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="print-btn"
              depressed
              fab
              height="36px"
              class="ml-2"
              width="36px"
              v-bind="attrs"
              v-on="on"
              @click="printContent"
              color="primary"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.t("headings.print") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters class="pb-3" style="gap: 12px">
          <v-btn outlined color="grey darken-2" @click="setToday">
            {{ $i18n.t("days.today") }}
          </v-btn>
          <div class="d-flex">
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ year }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list v-for="(y, i) in years" :key="i">
              <v-list-item @click="year = y">
                <v-list-item-content>
                  <v-list-item-title>{{ y }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ term.label }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list v-for="(t, i) in filteredTerms" :key="i">
              <v-list-item @click="selectTerm(t)" two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ t.label }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    `${t.start} to ${t.end}`
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="getEvents"
          >
            <template #event="{ event }">
              <div class="pl-1" v-if="!event.type">
                <!-- Term & Holiday events layout goes here -->
                {{ event.name }}
              </div>
              <v-menu v-else-if="event.type === 'menu'" offset-y class="pa-5">
                <!-- Add TeacherActivity button layout goes here -->
                <template v-slot:activator="{ on, attrs }">
                  <v-btn x-small outlined color="primary">
                    <v-icon color="black" v-bind="attrs" v-on="on"
                      >mdi-dots-horizontal</v-icon
                    ></v-btn
                  >
                </template>

                <v-list>
                  <v-list-item
                    v-for="(option, index) in event.activities"
                    :key="index"
                    @click="addTeacherActivity(event, option.name)"
                    :disabled="option.disabled"
                  >
                    <v-list-item-title>{{ option.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>

                <!-- Menu content goes here, you can use v-list-item or similar components to list the activities -->
              </v-menu>
              <v-btn
                v-else
                text
                block
                height="-webkit-fill-available"
                class="px-1 assessment-button"
                :disabled="offline"
                @click="editTeacherActivity(event)"
                dark
              >
                <!-- TeacherActivity event layout goes here -->

                <div
                  class="d-flex flex-row align-self-start justify-left v-calendar v-event"
                  style="width: -webkit-fill-available; text-transform: none"
                >
                  <v-icon left dark x-small>mdi-pencil</v-icon>{{ event.name }}
                </div>
              </v-btn>
            </template>
          </v-calendar>
          <!-- <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
          >
          <v-card color="grey lighten-4" max-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
         </v-menu> -->
        </v-sheet>
      </v-card-text>
      <div class="print-copyright-footer">
        <span>
          Made with &copy; {{ new Date().getFullYear() }} Miss Anri's
          ClassNetwork.
        </span>
      </div>
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="600px">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="save">
          <v-card-title>
            <span class="text-h5"
              >{{ formTitle }}
              <helper topic="calendaractivity" />
            </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="editedItem.label"
                    :label="`${$i18n.t('labels.label')}`"
                    :rules="formRules.label"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-color-picker
                    hide-inputs
                    hide-canvas
                    v-model="editedItem.color"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <!-- <v-text-field v-model="editedItem.startTime" label="Start Time"></v-text-field> -->
                  <v-dialog
                    ref="dialogStartDate"
                    v-model="timePickerStartDateModel"
                    :return-value.sync="editedItem.startDateTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.startDateTime"
                        :label="`${$i18n.t('labels.startTime')}`"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="formRules.date"
                        required
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="timePickerStartDateModel"
                      v-model="editedItem.startDateTime"
                      full-width
                      format="24hr"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="timePickerStartDateModel = false"
                      >
                        {{ $i18n.t("actions.cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialogStartDate.save(editedItem.startDateTime)
                        "
                      >
                        {{ $i18n.t("actions.ok") }}
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <!-- <v-text-field v-model="editedItem.startTime" label="Start Time"></v-text-field> -->
                  <v-dialog
                    ref="dialogEndDate"
                    v-model="timePickerEndDateModel"
                    :return-value.sync="editedItem.endDateTime"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.endDateTime"
                        :label="`${$i18n.t('labels.endTime')}`"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="formRules.date"
                        required
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="timePickerEndDateModel"
                      v-model="editedItem.endDateTime"
                      full-width
                      format="24hr"
                      :min="editedItem.startDateTime"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="timePickerEndDateModel = false"
                      >
                        {{ $i18n.t("actions.cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialogEndDate.save(editedItem.endDateTime)
                        "
                      >
                        {{ $i18n.t("actions.ok") }}
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="editedItem.type"
                    :items="activities"
                    item-text="name"
                    item-value="name"
                    :label="`${$i18n.t('labels.type')}`"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-if="editedItem.type === 'Assessment'"
                    v-model="editedItem.teacherassessmenttemplateID"
                    class="selectTemplate"
                    :items="teacherAssessmentTemplates"
                    item-text="title"
                    item-value="id"
                    clearable
                    :label="`${
                      $i18n.t('otherText.my') +
                      ' ' +
                      $i18n.t('headings.assessment')
                    }`"
                    :append-outer-icon="
                      editedItem.teacherassessmenttemplateID
                        ? 'mdi-printer'
                        : undefined
                    "
                    @click:append-outer="
                      printAssessment(editedItem.teacherassessmenttemplateID)
                    "
                  ></v-select>
                  <!-- {{ editedItem }} -->

                  <v-select
                    v-if="editedItem.type === 'Lesson'"
                    v-model="editedItem.teachersubjecttemplateID"
                    class="selectTemplate"
                    :items="teacherSubjectTemplates"
                    item-text="name"
                    item-value="id"
                    clearable
                    :label="`${
                      $i18n.t('otherText.my') + ' ' + $i18n.t('headings.lesson')
                    }`"
                    :append-outer-icon="
                      editedItem.teachersubjecttemplateID
                        ? 'mdi-printer'
                        : undefined
                    "
                    @click:append-outer="
                      printLesson(editedItem.teachersubjecttemplateID)
                    "
                  ></v-select>
                </v-col>
                <div
                  v-if="editedIndex === -1"
                  class="d-flex"
                  style="width: 100%"
                >
                  <v-col cols="12" md="6">
                    <v-switch
                      v-model="repeatActivity"
                      :label="`${
                        $i18n.t('labels.repeat') +
                        ' ' +
                        $i18n.t('headings.activity')
                      }`"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="6" v-if="repeatActivity">
                    <v-text-field
                      v-model="repeatLength"
                      :label="`${
                        $i18n.t('calendar.repeatFor') +
                        ' ' +
                        repeatLength +
                        ' ' +
                        $i18n.t('calendar.weeksMax')
                      }`"
                      :rules="formRules.required"
                      required
                      type="number"
                      max="12"
                      min="0"
                    ></v-text-field>
                  </v-col>
                </div>
                <!-- <v-col cols="12" md="1">
                  <v-icon
                    :disabled="
                      !editedItem.teacherassessmenttemplateID &&
                      !editedItem.teachersubjecttemplateID
                    "
                    >mdi-eye</v-icon
                  >
                </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-text class="pt-2 px-2 pb-0">
            <v-alert
              v-if="warningAlert !== ''"
              class="mb-0"
              style="padding: 6px"
              height="fit-content"
              type="warning"
              >{{ warningAlert }}</v-alert
            >
          </v-card-text>

          <v-card-actions class="align-end">
            <v-btn
              color="red"
              fab
              small
              icon
              v-if="this.editedIndex !== -1"
              class="mr-2"
              :disabled="offline"
              @click="deleteActivity()"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="close">
              {{ $i18n.t("actions.cancel") }}
            </v-btn>
            <v-btn
              depressed
              :disabled="!valid || offline"
              color="primary"
              @click="save"
            >
              {{ $i18n.t("actions.save") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogActivityDelete" max-width="500px">
      <v-card>
        <v-card-title class="justify-center">{{
          $i18n.t("otherText.deleteActivity")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="closeActivityDelete">{{
            $i18n.t("actions.cancel")
          }}</v-btn>
          <v-btn depressed color="primary" @click="deleteActivityConfirm">{{
            $i18n.t("actions.ok")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataStore } from "aws-amplify";
import {
  Holiday,
  TeacherSchedule,
  TeacherActivity,
  Term,
  TeacherAssessmentTemplate,
  TeacherSubjectTemplate,
} from "@/models";
import _ from "lodash";
import Helper from "@/components/global-components/Helper.vue";

export default {
  name: "TeacherCalendar",
  components: { Helper },
  data: () => ({
    initiallyLoaded: false,

    holidaySubscription: null,
    syncedHolidayArray: [],

    termSubscription: null,
    syncedTermArray: [],

    teacherScheduleSubscription: null,
    syncedTeacherScheduleArray: [],
    currentSchedule: null,

    teacherActivitySubscription: null,
    syncedTeacherActivityArray: [],
    immutableCurrentTeacherActivityModel: null,

    teacherAssessmentTemplateSubscription: null,
    syncedTeacherAssessmentTemplateArray: [],
    teacherAssessmentTemplates: [],

    teacherSubjectTemplateSubscription: null,
    syncedTeacherSubjectTemplateArray: [],
    teacherSubjectTemplates: [],

    focus: "",
    type: "week",
    repeatActivity: false,
    repeatLength: 1,
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    currentEventDate: "",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    holidays: [],
    teacherActivities: [],
    term: {},
    terms: [],
    year: null,
    currentCalendar: null,
    setCurrentTerm: false,
    weekdays: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    timePickerStartDateModel: null,
    timePickerEndDateModel: null,
    valid: false,
    dialog: false,
    dialogActivityDelete: false,
    editedIndex: -1,
    editedItem: {
      label: "",
      startDateTime: null,
      endDateTime: null,
      color: null,
      type: null,
      teacherassessmenttemplateID: null,
      teachersubjecttemplateID: null,
      repeatID: null,
    },
    defaultItem: {
      label: "",
      startDateTime: null,
      endDateTime: null,
      color: null,
      type: null,
      teacherassessmenttemplateID: null,
      teachersubjecttemplateID: null,
      repeatID: null,
    },
    formRules: {
      label: [(v) => !!v || "Label is required"],
      date: [(v) => !!v || "Date is required"],
      required: [(v) => !!v || "Required"],
      startTime: [
        (v) => !!v || "Time is required",
        // (duplicateSubject) => !!duplicateSubject || "Subject already exists",
      ],
    },
    color: "#FE2626FF",
    activities: [
      { name: "Assessment", disabled: false },
      { name: "Lesson", disabled: false },
      { name: "──────────", disabled: true },
      { name: "Culture", disabled: false },
      { name: "Sport", disabled: false },
      { name: "──────────", disabled: true },
      { name: "Leave", disabled: false },
      { name: "Off Period", disabled: false },
    ],
    provinces: [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "Northern Cape",
      "North West",
      "Western Cape",
    ],
  }),

  async mounted() {
    try {
      this.year = new Date().getFullYear();
      await this.syncHoliday();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
      teacherProfileProvince: (state) => state.teacherProfileProvince,
      teacherProfileID: (state) => state.teacherProfileID,
    }),

    warningAlert() {
      // console.log('Schedule Start Time:', this.teacherScheduleStartTime);
      // console.log('Schedule End Time:', this.teacherScheduleEndTime);

      if (this.editedItem.startDateTime && this.editedItem.endDateTime) {
        let message = "";

        // Check if the activity starts before the schedule starts
        if (this.editedItem.startDateTime < this.teacherScheduleStartTime) {
          message += `Your activity starts at ${this.editedItem.startDateTime}, which is before your scheduled start time of ${this.teacherScheduleStartTime}.\n`;
        }
        // Check if the activity ends after the schedule ends
        if (this.editedItem.endDateTime > this.teacherScheduleEndTime) {
          message += `Your activity ends at ${this.editedItem.endDateTime}, which is after your scheduled end time of ${this.teacherScheduleEndTime}.\n`;
        }
        // Check if the activity ends before the schedule starts (this seems like an unlikely scenario, but including it for completeness)
        if (this.editedItem.endDateTime < this.teacherScheduleStartTime) {
          message += `Your activity ends at ${this.editedItem.endDateTime}, which is before your scheduled start time of ${this.teacherScheduleStartTime}.\n`;
        }
        // Check if the activity starts after the schedule ends
        if (this.editedItem.startDateTime > this.teacherScheduleEndTime) {
          message += `Your activity starts at ${this.editedItem.startDateTime}, which is after your scheduled end time of ${this.teacherScheduleEndTime}.\n`;
        }

        // Trim the last newline character for cleaner output if the message is not empty
        return message.trim();
      } else {
        return "";
      }
    },

    teacherScheduleStartTime() {
      if (this.currentSchedule) {
        return this.currentSchedule.startTime.split(":").slice(0, 2).join(":");
      } else return "";
    },
    teacherScheduleEndTime() {
      if (this.currentSchedule) {
        return this.currentSchedule.endTime.split(":").slice(0, 2).join(":");
      } else return "";
    },

    filteredTerms() {
      if (!this.syncedTermArray) {
        return [];
      }
      return this.syncedTermArray.filter(
        (term) => new Date(term.start).getFullYear() === this.year
      );
    },
    years() {
      const yearsSet = new Set();
      this.terms.forEach((term) => {
        yearsSet.add(new Date(term.start).getFullYear());
        yearsSet.add(new Date(term.end).getFullYear());
      });

      const yearsArray = Array.from(yearsSet);
      yearsArray.sort((a, b) => a - b);

      return yearsArray;
    },
    formTitle() {
      return this.editedIndex === -1
        ? this.$i18n.t("otherText.new") +
            " " +
            this.$i18n.t("headings.activity")
        : this.$i18n.t("actions.edit") +
            " " +
            this.$i18n.t("headings.activity");
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogActivityDelete(val) {
      val || this.closeActivityDelete();
    },
    teacherProfileProvince: {
      handler: async function (val) {
        if (!this.termSubscription && val !== "") {
          await this.syncTerm(val);
        }
      },
      immediate: true,
    },
  },

  methods: {
    printContent() {
      window.print();
    },

    printAssessment(teacherassessmenttemplateID) {
      if (teacherassessmenttemplateID) {
        window.open(
          `${process.env.BASE_URL}app/print/assessment/` +
            teacherassessmenttemplateID,
          "_blank"
        );
        // this.$router.push({
        //   path:
        //     `${process.env.BASE_URL}app/print/assessment/` +
        //     teacherassessmenttemplateID,
        // });
      }
    },

    printLesson(teachersubjecttemplateID) {
      if (teachersubjecttemplateID) {
        window.open(
          `${process.env.BASE_URL}app/print/lesson/` + teachersubjecttemplateID,
          "_blank"
        );
        // this.$router.push({
        //   path:
        //     `${process.env.BASE_URL}app/print/lesson/` +
        //     teachersubjecttemplateID,
        // });
      }
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    getEventColor(event) {
      return event.color;
    },

    setToday() {
      this.focus = "";
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    editTeacherActivity(event) {
      this.editedIndex = 0;
      this.currentEventDate = event.start;

      const currentDay = this.getDayOfWeek(event.start);
      this.currentSchedule = _.find(this.syncedTeacherScheduleArray, {
        day: currentDay,
      });

      this.immutableCurrentTeacherActivityModel = _.find(
        this.syncedTeacherActivityArray,
        {
          label: event.name,
          startDateTime: this.convertDateTimeToAWSDateTime(event.start),
          endDateTime: this.convertDateTimeToAWSDateTime(event.end),
          color: event.color,
        }
      );

      // console.log(
      //   "immutableCurrentTeacherActivityModel",
      //   this.immutableCurrentTeacherActivityModel
      // );

      let mutableEvent = _.cloneDeep(event);

      // console.log("mutableEvent", mutableEvent);

      mutableEvent.start = this.convertAWSDateTimeToTime(mutableEvent.start);
      mutableEvent.end = this.convertAWSDateTimeToTime(mutableEvent.end);
      this.editedItem.label = mutableEvent.name ? mutableEvent.name : "";
      this.editedItem.startDateTime = mutableEvent.start
        ? mutableEvent.start
        : null;
      this.editedItem.endDateTime = mutableEvent.end ? mutableEvent.end : null;
      this.editedItem.color = mutableEvent.color ? mutableEvent.color : null;

      this.editedItem.type = this.immutableCurrentTeacherActivityModel.type
        ? this.immutableCurrentTeacherActivityModel.type
        : null;

      this.editedItem.teacherassessmenttemplateID = this
        .immutableCurrentTeacherActivityModel.teacherassessmenttemplateID
        ? this.immutableCurrentTeacherActivityModel.teacherassessmenttemplateID
        : null;

      this.editedItem.teachersubjecttemplateID = this
        .immutableCurrentTeacherActivityModel.teachersubjecttemplateID
        ? this.immutableCurrentTeacherActivityModel.teachersubjecttemplateID
        : null;

      this.editedItem.repeatID = this.immutableCurrentTeacherActivityModel
        .repeatID
        ? this.immutableCurrentTeacherActivityModel.repeatID
        : null;

      this.dialog = true;
    },

    async addTeacherActivity(event, action) {
      this.editedItem = {
        label: action,
        type: action,
      };

      const currentDay = this.getDayOfWeek(event.start);
      this.currentSchedule = _.find(this.syncedTeacherScheduleArray, {
        day: currentDay,
      });
      // console.log(this.currentSchedule)

      this.editedIndex = -1;
      this.currentEventDate = event.start;

      this.dialog = true;
    },

    getDayOfWeek(dateString) {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const date = new Date(dateString);
      return days[date.getUTCDay()];
    },

    async save() {
      if (this.editedIndex === -1) {
        await this.createTeacherActivity();
      } else {
        await this.updateTeacherActivity();
      }

      this.close();
    },

    async createTeacherActivity() {
      try {
        let repeatLengthInt = 0;

        if (typeof this.repeatLength !== "number") {
          repeatLengthInt = this.repeatLength ? parseInt(this.repeatLength) : 0;
        } else {
          repeatLengthInt = this.repeatLength;
        }

        if (!this.repeatActivity && repeatLengthInt < 2) {
          await DataStore.save(
            new TeacherActivity({
              label: this.editedItem.label.trim(),
              startDateTime: this.convertTimeToAWSDateTime(
                this.currentEventDate,
                this.editedItem.startDateTime
              ),
              endDateTime: this.convertTimeToAWSDateTime(
                this.currentEventDate,
                this.editedItem.endDateTime
              ),
              color: this.editedItem.color.hexa,
              type: this.editedItem.type,

              teacherprofileID: this.teacherProfileID,

              teacherassessmenttemplateID:
                this.editedItem.teacherassessmenttemplateID,

              teachersubjecttemplateID:
                this.editedItem.teachersubjecttemplateID,
            })
          );
        } else {
          const currentTimeStamp = new Date().toISOString();

          for (let i = 0; i < repeatLengthInt; i++) {
            await DataStore.save(
              new TeacherActivity({
                label: this.editedItem.label.trim(),
                startDateTime: this.convertTimeToAWSDateTime(
                  this.currentEventDate,
                  this.editedItem.startDateTime,
                  i
                ),
                endDateTime: this.convertTimeToAWSDateTime(
                  this.currentEventDate,
                  this.editedItem.endDateTime,
                  i
                ),
                color: this.editedItem.color.hexa,

                type: this.editedItem.type,

                teacherprofileID: this.teacherProfileID,

                teacherassessmenttemplateID:
                  this.editedItem.teacherassessmenttemplateID,

                teachersubjecttemplateID:
                  this.editedItem.teachersubjecttemplateID,

                repeatID: currentTimeStamp,
              })
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async updateTeacherActivity() {
      try {
        await DataStore.save(
          TeacherActivity.copyOf(
            this.immutableCurrentTeacherActivityModel,
            (updateModel) => {
              (updateModel.label = this.editedItem.label.trim()),
                (updateModel.startDateTime = this.convertTimeToAWSDateTime(
                  this.currentEventDate,
                  this.editedItem.startDateTime
                )),
                (updateModel.endDateTime = this.convertTimeToAWSDateTime(
                  this.currentEventDate,
                  this.editedItem.endDateTime
                )),
                (updateModel.color = this.editedItem.color),
                (updateModel.type = this.editedItem.type),
                (updateModel.teacherassessmenttemplateID =
                  this.editedItem.teacherassessmenttemplateID),
                (updateModel.teachersubjecttemplateID =
                  this.editedItem.teachersubjecttemplateID),
                (updateModel.repeatID = this.editedItem.repeatID);
            }
          )
        );
      } catch (error) {
        console.log(error);
      }
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    getHolidays(startDate, endDate) {
      // Check if this.syncedHolidayArray exists before trying to filter it
      if (!this.syncedHolidayArray) {
        return [];
      }

      // Filter holidays between start and end date
      this.holidays = this.syncedHolidayArray.filter(
        (holiday) =>
          new Date(holiday.date) >= new Date(startDate) &&
          new Date(holiday.date) <= new Date(endDate)
      );

      // Check if this.holidays exists before trying to filter it
      if (!this.holidays) {
        return [];
      }

      // Map your holidays array
      return this.holidays.map((holiday) => {
        return {
          name: holiday.label,
          start: holiday.date,
          end: holiday.date,
          color: "red",
        };
      });
    },

    getTeacherActivities(startDate, endDate) {
      // Check if this.syncedHolidayArray exists before trying to filter it
      if (!this.syncedTeacherActivityArray) {
        return [];
      }

      // Filter holidays between start and end date
      // this.teacherActivities = this.syncedTeacherActivityArray.filter(
      //   (teacherActivity) =>
      //     new Date(teacherActivity.startDateTime) >= new Date(startDate) &&
      //     new Date(teacherActivity.endDateTime) <= new Date(endDate)
      // );

      this.teacherActivities = this.syncedTeacherActivityArray.filter(
        (teacherActivity) => {
          let activityStart = new Date(teacherActivity.startDateTime);
          activityStart.setHours(0, 0, 0, 0);
          let activityEnd = new Date(teacherActivity.endDateTime);
          activityEnd.setHours(0, 0, 0, 0);

          let comparisonStart = new Date(startDate);
          comparisonStart.setHours(0, 0, 0, 0);
          let comparisonEnd = new Date(endDate);
          comparisonEnd.setHours(0, 0, 0, 0);

          return (
            activityStart >= comparisonStart && activityEnd <= comparisonEnd
          );
        }
      );

      // Check if this.holidays exists before trying to filter it
      if (!this.teacherActivities) {
        return [];
      }

      // Map your holidays array
      // console.log("this.teacherActivities", this.teacherActivities);
      return this.teacherActivities.map((teacherActivity) => {
        return {
          name: teacherActivity.label,
          start: teacherActivity.startDateTime
            .replace("T", " ")
            .split(".")[0]
            .slice(0, -3),
          end: teacherActivity.endDateTime
            .replace("T", " ")
            .split(".")[0]
            .slice(0, -3),
          color: teacherActivity.color || "green",
          type: "activity",
        };
      });
    },

    async syncHoliday() {
      try {
        this.holidaySubscription = DataStore.observeQuery(Holiday).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedHolidayArray !== items) {
              this.syncedHolidayArray = items;
              if (this.currentCalendar) this.getEvents(this.currentCalendar);

              await this.syncTeacherSchedule();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncTeacherSchedule() {
      try {
        this.teacherScheduleSubscription = DataStore.observeQuery(
          TeacherSchedule
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              this.syncedTeacherScheduleArray !== items
            ) {
              this.syncedTeacherScheduleArray = items;
              // console.log('this.syncedTeacherScheduleArray: ', this.syncedTeacherScheduleArray)

              if (this.currentCalendar) {
                this.getEvents(this.currentCalendar);
              }
              // if (!this.setCurrentTerm) {
              //   this.selectTerm(this.getCurrentTerm());
              // }

              this.syncTeacherActivity();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncTeacherActivity() {
      try {
        this.teacherActivitySubscription = DataStore.observeQuery(
          TeacherActivity
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              this.syncedTeacherActivityArray !== items
            ) {
              this.syncedTeacherActivityArray = items;

              if (this.currentCalendar) {
                this.getEvents(this.currentCalendar);
              }
              // if (!this.setCurrentTerm) {
              //   this.selectTerm(this.getCurrentTerm());
              // }

              this.syncTeacherAssessmentTemplates();
              this.syncTeacherSubjectTemplates();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncTerm(province) {
      try {
        const predicate = (term) => term.provinces("contains", province);

        this.termSubscription = DataStore.observeQuery(
          Term,
          predicate
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items && this.syncedTermArray !== items) {
              this.syncedTermArray = items;
              if (this.currentCalendar) {
                this.getEvents(this.currentCalendar);
              }
              if (!this.setCurrentTerm) {
                this.selectTerm(this.getCurrentTerm());
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncTeacherAssessmentTemplates() {
      try {
        this.teacherAssessmentTemplateSubscription = DataStore.observeQuery(
          TeacherAssessmentTemplate
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              this.syncedTeacherAssessmentTemplateArray !== items
            ) {
              this.syncedTeacherAssessmentTemplateArray = items;

              this.teacherAssessmentTemplates = _.cloneDeep(items);
              // console.log(this.teacherAssessmentTemplates);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async syncTeacherSubjectTemplates() {
      try {
        this.teacherSubjectTemplateSubscription = DataStore.observeQuery(
          TeacherSubjectTemplate
        ).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (
              isSynced &&
              items &&
              this.syncedTeacherSubjectTemplateArray !== items
            ) {
              this.syncedTeacherSubjectTemplateArray = items;

              this.teacherSubjectTemplates = _.cloneDeep(items);
              // console.log(this.teacherSubjectTemplates);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    getBiweeks(startDate, endDate) {
      // Check if this.syncedHolidayArray exists before trying to filter it
      if (!this.syncedTermArray) {
        return [];
      }

      this.terms = this.syncedTermArray.filter(
        (term) =>
          new Date(term.start).getFullYear() ===
            new Date(startDate).getFullYear() ||
          new Date(term.end).getFullYear() === new Date(endDate).getFullYear()
      );

      const start = new Date(startDate);
      const end = new Date(endDate);

      let biweeksList = [];

      this.terms.forEach((term) => {
        let filteredBiweeks = [];

        if (term.biweeks) {
          filteredBiweeks = term.biweeks
            .filter((biweek) => {
              const biweekStart = new Date(biweek.start);
              const biweekEnd = new Date(biweek.end);

              return biweekStart <= end && biweekEnd >= start;
            })
            .map((biweek) => {
              // create a copy of biweek and add the new property
              return {
                ...biweek,
                name: `${term.label} - ${biweek.label}`,
              };
            });
        }

        biweeksList = [...biweeksList, ...filteredBiweeks]; // concatenate biweeks
      });

      return biweeksList;
    },

    generateBiweeksNoWeekends(biWeek) {
      let currentStartDate = new Date(biWeek.start);
      let finalEndDate = new Date(biWeek.end);
      let events = [];

      while (currentStartDate <= finalEndDate) {
        // If it's a weekend, skip
        if (
          currentStartDate.getDay() === 0 ||
          currentStartDate.getDay() === 5 ||
          currentStartDate.getDay() === 6
        ) {
          currentStartDate.setDate(currentStartDate.getDate() + 1);
          continue;
        }

        let currentEndDate = new Date(currentStartDate);
        // Go to the end of the day or until a Friday
        while (
          currentEndDate < finalEndDate &&
          !(
            currentEndDate.getDay() === 0 ||
            currentEndDate.getDay() === 5 ||
            currentEndDate.getDay() === 6
          )
        ) {
          currentEndDate.setDate(currentEndDate.getDate() + 1);
        }

        events.push({
          start: currentStartDate.toISOString().split("T")[0],
          end: currentEndDate.toISOString().split("T")[0],
          name: biWeek.name,
          color: biWeek.color,
        });

        currentStartDate = new Date(currentEndDate);
        currentStartDate.setDate(currentStartDate.getDate() + 1);
      }

      return events;
    },

    getEvents({ start, end }) {
      this.currentCalendar = { start, end };

      let events = this.getHolidays(start.date, end.date);
      const biweeks = this.getBiweeks(start.date, end.date);

      const biweeksNoWeekends = biweeks.map((biweek) =>
        this.generateBiweeksNoWeekends(biweek)
      );

      let teacherActivities = this.getTeacherActivities(start.date, end.date);

      events = [...events, ...teacherActivities, ...biweeksNoWeekends.flat()];

      let startDate = new Date(start.date);
      let endDate = new Date(end.date);

      while (startDate <= endDate) {
        const dayOfWeek = this.weekdays[startDate.getDay()];

        if (
          this.syncedTeacherScheduleArray &&
          this.syncedTeacherScheduleArray.some((ts) => ts.day === dayOfWeek)
        ) {
          events.push({
            start: startDate.toISOString().split("T")[0],
            end: startDate.toISOString().split("T")[0],
            type: "menu",
            activities: this.activities,
          });
        }

        startDate.setDate(startDate.getDate() + 1); // Move to the next day
      }

      this.events = events;
    },

    getCurrentTerm() {
      // Check if this.syncedTermArray exists before trying to filter it
      if (!this.syncedTermArray) {
        // console.log("no terms");
        return [];
      }

      const today = new Date();
      const year = today.getFullYear();

      const terms = this.syncedTermArray.filter(
        (term) => new Date(term.start).getFullYear() === year
      );
      let currentTerm = terms[0];
      for (let i = 0; i < terms.length; i++) {
        const term = terms[i];
        const start = new Date(term.start);
        const end = new Date(term.end);

        //Check if today is between start and end date of term else default to next term
        if ((start <= today && end >= today) || start >= today) {
          currentTerm = term;
          break;
        }
      }
      this.setCurrentTerm = true;

      return currentTerm;
    },

    selectTerm(term) {
      if (!term?.start) return;
      this.term = term;
      this.focus = term.start;
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.repeatActivity = false;
      this.repeatLength = 1;
      this.valid = false;
    },

    async deleteActivity() {
      this.dialogActivityDelete = true;
    },

    async deleteActivityConfirm() {
      await DataStore.delete(this.immutableCurrentTeacherActivityModel);
      this.closeActivityDelete();
    },

    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.resetForm();
      });
    },

    closeActivityDelete() {
      this.dialogActivityDelete = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    formatTime(time) {
      if (time !== "00:00:00.000") {
        let [hours, minutes] = time.split(":");
        const formattedTime = hours + ":" + minutes;
        return formattedTime;
      }
      return ""; // Return an empty string or whatever you want when time is null
    },

    formatTimeAMPM(time) {
      if (time !== "00:00:00.000") {
        let [hours, minutes] = time.split(":");
        hours = parseInt(hours, 10);
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const formattedTime = hours + ":" + minutes + " " + ampm;
        return formattedTime;
      }
      return ""; // Return an empty string or whatever you want when time is null
    },

    convertToAwsTimeFormat(timeString) {
      if (timeString) {
        let [hours, minutes] = timeString.split(":");
        return `${hours}:${minutes}:00.000`;
      } else {
        return `00:00:00.000`;
      }
    },

    convertAWSDateTimeToTime(awsDateTime) {
      const date = new Date(awsDateTime);
      const hours = date.getHours();
      const minutes = date.getMinutes();

      // Format hours and minutes with leading zeros if necessary
      const formattedHours = hours.toString().padStart(2, "0");
      const formattedMinutes = minutes.toString().padStart(2, "0");

      return `${formattedHours}:${formattedMinutes}`;
    },

    convertTimeToAWSDateTime(awsDateTime, time, repeatInterval) {
      let [datePart] = awsDateTime.split(" ");
      const [year, month, day] = datePart
        .split("-")
        .map((str) => parseInt(str, 10)); // Extracting year, month and day as integers
      const [hours, minutes] = time.split(":").map((str) => parseInt(str, 10)); // Extracting hours and minutes as integers

      // Create a Date object from the extracted parts
      let dateObject = new Date(year, month - 1, day, hours, minutes);

      if (repeatInterval) {
        // Move the date forward by the number of weeks indicated by repeatInterval
        const daysToAdd = repeatInterval * 7;
        dateObject.setDate(dateObject.getDate() + daysToAdd);
      }

      // Convert the date object back to an AWS datetime string format
      const updatedDatePart = `${dateObject.getFullYear()}-${String(
        dateObject.getMonth() + 1
      ).padStart(2, "0")}-${String(dateObject.getDate()).padStart(2, "0")}`;
      const updatedTimePart = `${String(dateObject.getHours()).padStart(
        2,
        "0"
      )}:${String(dateObject.getMinutes()).padStart(2, "0")}`;
      const updatedAWSDateTime = `${updatedDatePart}T${updatedTimePart}:00.000Z`;

      return updatedAWSDateTime;
    },

    convertDateTimeToAWSDateTime(dateTimeStr) {
      const [datePart, timePart] = dateTimeStr.split(" ");

      const [year, month, day] = datePart.split("-");
      const [hours, minutes] = timePart.split(":");

      const awsDateTime = `${year}-${month}-${day}T${hours.padStart(
        2,
        "0"
      )}:${minutes.padStart(2, "0")}:00.000Z`;

      return awsDateTime;
    },
  },

  beforeDestroy() {
    if (this.holidaySubscription) {
      this.holidaySubscription.unsubscribe();
    }
    if (this.termSubscription) {
      this.termSubscription.unsubscribe();
    }
    if (this.teacherScheduleSubscription) {
      this.teacherScheduleSubscription.unsubscribe();
    }
    if (this.teacherActivitySubscription) {
      this.teacherActivitySubscription.unsubscribe();
    }
    if (this.teacherAssessmentTemplateSubscription) {
      this.teacherAssessmentTemplateSubscription.unsubscribe();
    }
    if (this.teacherSubjectTemplateSubscription) {
      this.teacherSubjectTemplateSubscription.unsubscribe();
    }
  },
};
</script>
<style>
.selectTemplate .mdi-printer {
  color: #ffa9b8;
}

.assessment-button > .v-btn__content {
  height: 100%;
}

.print-copyright-footer {
  display: none;
}

@media print {
  /* Display the print footer only on the last page */
  @page {
    size: auto;
    /* Reset the page size to auto */
    /* margin-bottom: 83px; */
  }

  .v-calendar-daily {
    height: fit-content !important;
  }

  #applayout-footer {
    display: none !important;
  }

  .v-calendar-daily__intervals-body {
    border-bottom: #e0e0e0 1px solid;
  }

  #helper-icon {
    display: none !important;
  }

  #teachercalendar ::-webkit-scrollbar {
    display: none;
  }

  #teachercalendar .v-calendar-daily__head {
    margin: 0 !important;
  }

  /* Adjust the position and styles of the print footer */
  .print-copyright-footer {
    position: fixed;
    bottom: -7px;
    left: 0;
    width: 100%;
    /* background-color: #fff; */
    /* Background color for the bottom of the last page */
    display: block !important;
  }

  #teachercalendar .v-card__title {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
  }

  #teachercalendar .v-card__text {
    padding: 0 !important;
  }

  #teachercalendar .v-sheet--outlined {
    border: none !important;
  }

  #applayout-v-main {
    padding: 0 !important;
  }

  #applayout-app-bar {
    display: none !important;
  }

  #applayout-nav-drawer {
    display: none !important;
  }

  #print-btn {
    display: none !important;
  }
}
</style>
